// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
    // apiKey: process.env.REACT_APP_apiKey,
    // authDomain: process.env.REACT_APP_authDomain,
    // projectId: process.env.REACT_APP_projectId,
    // storageBucket: process.env.REACT_APP_storageBucket,
    // messagingSenderId: process.env.REACT_APP_messageSenderId,
    // appId: process.env.REACT_APP_appId,
    // measurementId: process.env.REACT_APP_measurementId,
    apiKey: "AIzaSyA9106_W3oj1GiSIVzKhiWPLhM7CyzWw_4",
    authDomain: "glocal-care.firebaseapp.com",
    projectId: "glocal-care",
    storageBucket: "glocal-care.appspot.com",
    messagingSenderId: "160961680323",
    appId: "1:160961680323:web:ba7759ae94ec5921146376",
    measurementId: "G-GZVNZ60YDS"
};

const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);



// var admin = require("firebase-admin");

// // var firebase = require("firebase/compat/app");
// // import firebase from 'firebase/compat/app';

// // import { getStorage } from 'firebase/storage';

// var serviceAccount = {
//     "type": "service_account",
//     "project_id": "glocal-care",
//     "private_key_id": "0f3fb5df29a071b4fd32c7ee4a8c158f38459165",
//     "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDnNUT6LCtgVfm1\n65IIjjEpnHLd+V1HmNKClHPvqnDDVpuLRtLljMyJs0a7mPe7Q3P8CAbsJkCiFKOR\nBXFhbSAUwScLB33R8XdtV2iUwmQDapjtyiRYH4hM7jRgrZ/7XgXjZKmH67DV+7bj\nCpxuL81OMDa0FbOrFsyU6u6Tn+7oQ0rKti0SEQUauUE+nTTqlXa3UTy1c+cGxmbl\n7FQK28WmsrWXb/FaSvT2Zelod3Kfmt7x8myTRwfyqQp/R22DRI1ZRc0esJRoQ13I\nes5wffRxodcLZ6xz2rwKrFRWsodiDsxIS4RiTn0LFBQvPFB8PgJTxwg24AVRh7G2\nqfTsqLeFAgMBAAECggEAHxyqcar5kpw5yXKaczSbG06A9dYP6MoA4xm8jhlr1MVL\nPKR6TZxdi6wITnHTktXiqMf1vSzv5pTgu8Df6cHISYgCS816xKz5qp4n+wM7d8A4\n4EqitbRXNYRE9RDNZdhYR39CAYHEM8825CSx5HE3RG21dr8y2Z4W8nUN8ecuu/8h\n77YkBQhQxmZ6IFIUNFOqGNsxUpOmBOmfkoyV7V316Y6dxxNKxESCwj9VBuwCDqRt\nBE6iZYbwFw/cmhuFTH7N9ErmAPaFI80MNA0VH3PqtgCgXnMXxcd+S/1+KU6/cC6B\nX2kH98ad+cLjPUVu3s5F+75YENXbTmeRzQuv6/JzQwKBgQD8OqVOYUTF4HVKdzyc\n6lVHSi7Vxz/JYYHw0n21fszlNJ+XoDsBB3i5a9eWlFD/1UzoTQhcYDse9vJXq9T3\nvzahar7FhizaXW9aGB64KftIIHDyY1ijsaYoTPQK5GS4Njuh8Fp31y5+0fivqXCQ\nyZJUUeD9bFgR13m1Pp/u3cyozwKBgQDqqiuTEgjXKa7HilQjwglCmqNlXWJ40/rz\nfCOhBVCJXROML+1QmILi8BnQOwM97yR+/Y8Zuw0aCX09sN2Mx8Wbh2R405jcjZP7\nS/XQRYDB3lfkas3kWvshvGGHrOzk9ZdPskM6ah8iXhCKbL5vXQ+BktMxiNYaJSxZ\nZDtw95eHawKBgGCjv652SjletNHhOZINnrr/y04RV3k7KOQyYgPmH286aGNZ+wF7\n8V9p2GJTVW2QNjVTPn7WSBCnIVdB+QkrdC0IjytzK0BHSGDGmQ6K10JdW6kjM1UU\nvcr3IRIDTCvga5yyUJdWFPR2UshLLVvsP2qbLI8ssdm0K2esJO8KgF21AoGBAJWy\nLo/oTklrprpV7OzOp/ecDCXql5m6qHjQtS3P5Rtri7+/ByWF1OEOB3mFW8ETn/V+\nrN3eLDJy4TkMbZiUC+P2T8qgOQBhNIh3YX0WCVgjDXCCDYvh2hggQxs43foFDRxi\n/G30Kp5S4TxcuHL0K6Md1angv6v4EL2Jqj2bREe3AoGBAITn4iRfLypfHeSNaCS/\n1ZxlT744WAlqPkyDxQ/4yS4vSXJ5O5m821pkL6BwOGnGMM6FXyYp+vFB5MIECrcI\nnDIydoV65DwilkX9nBgesH07Q+PFlUS9gARKlKIRoJv4C7AVMMUdfaTr/qW/aj4F\no7ArcP8h3K2/d4xCdmq5LCaM\n-----END PRIVATE KEY-----\n",
//     "client_email": "firebase-adminsdk-7dol3@glocal-care.iam.gserviceaccount.com",
//     "client_id": "113071155902105004971",
//     "auth_uri": "https://accounts.google.com/o/oauth2/auth",
//     "token_uri": "https://oauth2.googleapis.com/token",
//     "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
//     "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-7dol3%40glocal-care.iam.gserviceaccount.com"
// }
// export const storage = admin.initializeApp({
//     credential: admin.credential.cert(serviceAccount)
// });

// export const storage = getStorage(app);
// apiKey: "AIzaSyC0occHL6iOB3af4EZoP9wH1ElfcNug8Qc",
// authDomain: "glocal-care-new.firebaseapp.com",
// projectId: "glocal-care-new",
// storageBucket: "glocal-care-new.appspot.com",
// messagingSenderId: "1017501087242",
// appId: "1:1017501087242:web:8b1c57fa957f8b221651f3",
// measurementId: "G-B8JVZ4R520"
import React from "react";
import "./UserBooking.css"
import { AiOutlineLeft } from "react-icons/ai";
import {  useNavigate } from "react-router-dom";

import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";


const UserBooking = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  return (
    <>
  
  <DashboardBoxStyle className=" scroller">
  <TopHeader headerTitle={"User's Bookings "} type={"back"}/>
      <div className="users-booking-container">
        <div className="users-booking-card">
          <div className="booking-card-left">
            <img src="https://i.pinimg.com/originals/3a/15/3b/3a153b05fa5e42e85ccf93eb5e0596fb.jpg" alt="" />
          </div>
          <div className="booking-card-right">
            <div className="booking-card-right-top">
              <div className="card-right-header">
                <div className="booking-card-heading">Premium Fitness Club</div>
                <div className="event-type">(Virtual)</div>
              </div>
              <div className="booking-date">15/Jan/23</div>
            </div>
            <div className="card-mid">
              <div className="card-mid-text">
                It is a long established when looking fact that a reader when
                looking at its layout. It is a long established when looking
                fact that a reader when looking at its layout.
              </div>
              <div>Going On</div>
            </div>
            <div className="card-end">
              <div>Total Amount Paid</div>
              <div>2300/-</div>
            </div>
          </div>
        </div>
        <div className="users-booking-card">
          <div className="booking-card-left">
            <img src="https://i.pinimg.com/originals/3a/15/3b/3a153b05fa5e42e85ccf93eb5e0596fb.jpg" alt="" />
          </div>
          <div className="booking-card-right">
            <div className="booking-card-right-top">
              <div className="card-right-header">
                <div className="booking-card-heading">Premium Fitness Club</div>
                <div className="event-type">(Virtual)</div>
              </div>
              <div className="booking-date">15/Jan/23</div>
            </div>
            <div className="card-mid">
              <div className="card-mid-text">
                It is a long established when looking fact that a reader when
                looking at its layout. It is a long established when looking
                fact that a reader when looking at its layout.
              </div>
              <div>Going On</div>
            </div>
            <div className="card-end">
              <div>Total Amount Paid</div>
              <div>2300/-</div>
            </div>
          </div>
        </div>
        <div className="users-booking-card">
          <div className="booking-card-left">
            <img src="https://i.pinimg.com/originals/3a/15/3b/3a153b05fa5e42e85ccf93eb5e0596fb.jpg" alt="" />
          </div>
          <div className="booking-card-right">
            <div className="booking-card-right-top">
              <div className="card-right-header">
                <div className="booking-card-heading">Premium Fitness Club</div>
                <div className="event-type">(Virtual)</div>
              </div>
              <div className="booking-date">15/Jan/23</div>
            </div>
            <div className="card-mid">
              <div className="card-mid-text">
                It is a long established when looking fact that a reader when
                looking at its layout. It is a long established when looking
                fact that a reader when looking at its layout.
              </div>
              <div>Going On</div>
            </div>
            <div className="card-end">
              <div>Total Amount Paid</div>
              <div>2300/-</div>
            </div>
          </div>
        </div>
      </div>
      </DashboardBoxStyle>
      
    </>
  );
};

export default UserBooking;

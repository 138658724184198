import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AllCategory from "../Routes/Pages/Category/AllCategory/AllCategory.js";
import CreateCategory from "../Routes/Pages/Category/CreateCategory/CreateCategory.js";
import EditCategory from "../Routes/Pages/Category/EditCategory/EditCategory.js";
import AddSubCategory from "../Routes/Pages/Category/AddSubCategory/AddSubCategory.js";
import CategoryDetails from "../Routes/Pages/Category/CategoryDetails/CategoryDetails.js";
import CategoryLevel2 from "./Pages/Category/CategoryLevels/CategoryLevel2.js";
import CategoryLevel1 from "./Pages/Category/CategoryLevels/CategoryLevel1.js";
import CategoryLevel3 from "./Pages/Category/CategoryLevels/CategoryLevel3.js";

const CategoryRoutes = ({ token }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={token ? <AllCategory /> : <Navigate to="/" />} />
        <Route path="/create" element={token ? <CreateCategory /> : <Navigate to="/" />} />
        <Route path="/edit/:id" element={token ? <EditCategory /> : <Navigate to="/" />} />
        <Route path="/add-category/:id" element={token ? <AddSubCategory /> : <Navigate to="/" />} />
        <Route path="/details/:id" element={token ? <CategoryDetails /> : <Navigate to="/" />} />
        <Route path="/level-2/:id" element={token ? <CategoryLevel1 /> : <Navigate to="/" />} />
        <Route path="/level-3/:id" element={token ? <CategoryLevel2 /> : <Navigate to="/" />} />
        <Route path="/level-4/:id" element={token ? <CategoryLevel3 /> : <Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default CategoryRoutes;

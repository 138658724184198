import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RxCross2, RxCrossCircled } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";

import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import SelectAllCategories from "../../Insights/Category/SelectAllCategories.js";

import { toast } from "react-toastify";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { CreateBlog, createMagzine } from "../../../../util/Auth/APIs";
import { storage } from "../../../../firebase";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import Imp from "../../../../Components/Common/Imp/Imp.jsx";

const InsightsWhitePaper = () => {
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [imageTwoLoader, setImageTwoLoader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);

  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const [magzineDetails, setMagzineDetails] = useState({
    isPublished: false,
    magzineTitle: "",
    category: "",
    category2: "",
    category3: "",
    category4: "",
    content: "",
    magzineImages: [],
    magzinePrice: null,
    magzineDiscountPrice: null,
    uploadMagzine: [],
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isPublished") {
      setMagzineDetails({ ...magzineDetails, [name]: e.target.checked });
    } else if (name === "blogVideos" || name === "keywords") {
      setMagzineDetails({ ...magzineDetails, [name]: [e.target.value] });
    } else {
      setMagzineDetails({ ...magzineDetails, [name]: value });
    }
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }], ['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const handleQuill = (content, delta, source, editor) => {
    setMagzineDetails({ ...magzineDetails, content: editor.getHTML() });
    setValue(editor.getHTML());
  };

  const handleImage = (e, type) => {
    console.log(e.target.files);

    if (type === "magzineImage") {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg"
      ) {
        setImageloader(true);
        const storageRef = ref(
          storage,
          `insights/magzine/${e.target.files[0].name}`
        );
        uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
          console.log(snapshot);
          console.log("Uploaded a blob or file!");
          getDownloadURL(snapshot.ref).then((url) => {
            setMagzineDetails({
              ...magzineDetails,
              magzineImages: [...magzineDetails.magzineImages, url],
            });
            setImageloader(false);
            // companyBlogs.blogImages.push(url)
          });
        });
      } else {
        toast.error("Please upload only jpeg, jpg or png image");
        return;
      }
    } else if (type === "uploadImage") {
      setImageTwoLoader(true);
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "application/pdf"
      ) {

        const storageRef = ref(
          storage,
          `insights/magzineDoc/${e.target.files[0].name}`
        );
        uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
          console.log(snapshot);
          console.log("Uploaded a blob or file!");
          getDownloadURL(snapshot.ref).then((url) => {
            setMagzineDetails({
              ...magzineDetails,
              uploadMagzine: [url],
            });
            setImageTwoLoader(false);
            // companyBlogs.blogImages.push(url)
          });
        });
      } else {
        toast.error("Please upload only jpeg, jpg or png image");
        setImageTwoLoader(false);
        return;
      }
    } else {
      return;
    }
  };

  const deleteFromFirebase = (url, type) => {
    let pictureRef = ref(storage, url);

    if (type === "magzineImage") {
      deleteObject(pictureRef)
        .then(() => {
          setMagzineDetails({
            ...magzineDetails,
            magzineImages: magzineDetails.magzineImages.filter(
              (item) => item !== url
            ),
          });
          console.log("success");
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          toast.error("Cannot Remove Image");
          // Uh-oh, an error occurred!
        });
    } else {
      deleteObject(pictureRef)
        .then(() => {
          setMagzineDetails({
            ...magzineDetails,
            uploadMagzine: magzineDetails.uploadMagzine.filter(
              (item) => item !== url
            ),
          });
          console.log("success");
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          toast.error("Cannot Remove Image");
          // Uh-oh, an error occurred!
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (magzineDetails.magzineImages.length < 1) {
      toast.warning("Please upload images!");

      return;
    }
    const data = {
      isPublished: magzineDetails?.isPublished,
      title: magzineDetails?.magzineTitle,
      content: magzineDetails?.content,
      images: magzineDetails?.magzineImages,
      price: magzineDetails?.magzinePrice,
      discount: magzineDetails?.magzineDiscountPrice,
      magazines: magzineDetails?.uploadMagzine,
      type: magzineDetails?.type,
      category: magzineDetails.category,
      category2: magzineDetails.category2,
      category3: magzineDetails.category3,
      category4: magzineDetails.category4,
    };

    console.log(data);



    setSubmitLoader(true)
    try {
      const response = await createMagzine(data)
      toast.success('Magzine Created Successfully');
      setSubmitLoader(false)
      navigate(-1)
      console.log(response)
    } catch (error) {
      setSubmitLoader(false)
      toast.error('Something went wrong')
      console.log(error)
    }
  };

  useEffect(() => {
    console.log(magzineDetails.magzineImages);
  }, [magzineDetails.magzineImages]);
  return (
    <form onSubmit={handleSubmit}>
      <DashboardBoxStyle className="scroller">
        <TopHeader headerTitle={"Create White Paper"} type="back" />

        <div className="form-container position-relative mt-4">
          {/* --------------  Blog Title ----------- */}
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Magazine Title<Imp /></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <input
                  onChange={handleChange}

                  name="magzineTitle"
                  type="text"
                  required
                />
              </div>
            </div>
          </div>

          <SelectAllCategories
            selected={magzineDetails}
            setSelected={setMagzineDetails}
          />



          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Type<Imp /></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <select onChange={handleChange} required name="type" id="">
                  <option value="" selected disabled>
                    select
                  </option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              </div>
            </div>
          </div>

          {/* --------------   Content ----------- */}
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Magazine Content<Imp /></h6>
            </div>

            <div className="profile-input-width">
              <ReactQuill
                style={{ background: "white" }}
                theme="snow"
                modules={Boxmodules}
                required
                value={magzineDetails.content}
                onChange={handleQuill}
                placeholder="Description"
              />
              {/* <div className='profile-input-field'>
                                <input onChange={handleChange} name="content" type="text" />
                            </div> */}
            </div>
          </div>

          {/* --------------  Blog Images ----------- */}
          <div className="d-flex mt-4">
            <div className="profile-input-head">
              <h6>Magazine Images<Imp /></h6>
            </div>
            <div className="profile-input-width">
              <div className="upload-button">
                {imageloader ? (
                  <>
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    <span className="d-flex m-0 text-white">
                      <BiUpload /> Upload Image
                    </span>
                    <input
                      onChange={(e) => handleImage(e, "magzineImage")}
                      accept="image/png, image/gif, image/jpeg"
                      name="magzineImages"
                      type="file"
                    />
                  </>
                )}
              </div>

              {magzineDetails.magzineImages.length !== 0 && (
                <>
                  <div className="mt-3 profile-input-field">
                    <Row>
                      {magzineDetails.magzineImages.map((img, index) => (
                        <Col lg={3}>
                          <div className="upload-img-card">
                            <img
                              src={img}
                              className={`img-size ${deleteloader && "brightness-50"
                                }`}
                              alt=""
                            />
                            {deleteloader && (
                              <div
                                className="spinner-border position-absolute text-white ml-4"
                                role="status"
                              ></div>
                            )}
                            <div
                              onClick={() => deleteFromFirebase(img, "magzineImage")}
                              className="upload-img-cross"
                            >
                              <MdCancel />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>

          {/* -------------- Promotion  ---------- */}
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Magazine Price<Imp /></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <input
                  onChange={handleChange}
                  required
                  name="magzinePrice"
                  type="number"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          {/* -------------- Promotion  ---------- */}
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Magazine Discount Price<Imp /></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <input
                  onChange={handleChange}
                  name="magzineDiscountPrice"
                  required
                  type="number"
                  placeholder=""
                />
              </div>
            </div>
          </div>

          {/* --------------  Blog Images ----------- */}
          <div className="d-flex mt-4">
            <div className="profile-input-head">
              <h6>Upload Magazine</h6>
            </div>
            <div className="profile-input-width">
              <div className="upload-button">
                {imageTwoLoader ? (
                  <>
                    <div className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    <span className="d-flex m-0 text-white">
                      <BiUpload /> Upload Doc
                    </span>
                    <input
                      onChange={(e) => handleImage(e, "uploadImage")}
                      accept="image/png, image/gif, image/jpeg, application/pdf"
                      name="uploadMagzine"
                      type="file"
                    />
                  </>
                )}
              </div>

              {magzineDetails.uploadMagzine.length !== 0 && (
                <>
                  <div className="mt-3 profile-input-field">
                    <Row>
                      <Col lg={12}>
                        <div className="d-flex justify-content-between align-items-center resume-show">
                          <span onClick={() => window.open(magzineDetails.uploadMagzine[0], "_blank")}>Magzine</span>
                          <div onClick={() => deleteFromFirebase(magzineDetails.uploadMagzine[0], "uploadMagzine")} className="remove-button">
                            <RxCrossCircled />
                          </div>
                        </div>
                      </Col>
                      {/* {magzineDetails.uploadMagzine.map((img, index) => (
                        <Col lg={3}>
                          <div className="upload-img-card">
                            <img
                              src={img}
                              className={`img-size ${deleteloader && "brightness-50"
                                }`}
                              alt=""
                            />
                            {deleteloader && (
                              <div
                                className="spinner-border position-absolute text-white ml-4"
                                role="status"
                              ></div>
                            )}
                            <div
                              onClick={() => deleteFromFirebase(img, "uploadMagzine")}
                              className="upload-img-cross"
                            >
                              <MdCancel />
                            </div>
                          </div>
                        </Col>
                      ))} */}
                    </Row>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="mt-5 d-flex justify-content-end common-submit-button">
            <button>
              {submitloader ? (
                <div
                  className="spinner-border text-white ml-4"
                  role="status"
                ></div>
              ) : (
                <> Save & Publish</>
              )}
            </button>
          </div>

          <div
            onClick={() => navigate(-1)}
            className="close-job cross-sign-pos"
          >
            <RxCross2 />
          </div>
        </div>
      </DashboardBoxStyle>
    </form>
  );
};

export default InsightsWhitePaper;

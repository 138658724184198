import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Col, Row, Spinner } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx"

import { DashboardBoxStyle } from '../../../../SideBar/SidepanelStyled'
import { getDiscountById, postDiscount } from '../../../../util/Auth/APIs';
// import "../EditProduct/ProductPage/EditProduct/EditProduct.css"

const AddDiscount = () => {


    const prodType = useSelector((state) => state.product.isVirtual);
    const [SubmitLoader, setSubmitLoader] = useState(false);
    const [DiscountForm, setDiscountForm] = useState({
        name: "",
        code: "",
        percent_off: 0,
        max_redemptions: 0,
        redeem_by: ""
    });
    const { id } = useParams()

    const [activeProduct, setActiveProduct] = useState({
        formHead: "General",
        // formComp: <ProductGeneral />
    })

    const navigate = useNavigate()

    const [productForm, setProductForm] = useState([
        {
            formHead: "General",
            tabLink: "general",
        }
    ])

    const handleActiveProduct = (e) => {
        navigate(`?tab=${e.tabLink}`)
    }


    const handleClose = () => {
        navigate("/discount")
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setDiscountForm({ ...DiscountForm, [name]: value })
    }
    const handleSubmit = async () => {
        try {
            setSubmitLoader(true)
            await postDiscount(DiscountForm)
            navigate('/discount')
            setSubmitLoader(false)
        } catch (error) {
            console.log(error)
            setSubmitLoader(false)
        }
    }
    const handleUpdate = async () => {
        try {
            setSubmitLoader(true)
            await postDiscount(DiscountForm)
            navigate('/discount')
            setSubmitLoader(false)
        } catch (error) {
            console.log(error)
            setSubmitLoader(false)
        }
    }

    useEffect(() => {
        if (!!id) {
            fetchDiscount()
        }
    }, [])
    const fetchDiscount = async () => {
        try {
            const res = await getDiscountById(id)
            setDiscountForm(res?.data?.data)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <DashboardBoxStyle className="scroller">
            <div className="form-container position-relative p-0 mt-4">
                <div className="d-flex">
                    <div className="companyProd-left-side col-w-25">
                        <div className="form-top">
                            <h5>Add Discount</h5>
                        </div>
                        <hr className='m-0' />
                        <div className="form-menu-tab">
                            {
                                productForm.map((e) => (
                                    <div onClick={() => handleActiveProduct(e)} className={` ${activeProduct.formHead === e.formHead && "menu-tab-active"} d-flex align-items-center py-2 my-2 menu-tab`}>
                                        <h6>{e.formHead}</h6>
                                    </div>
                                ))
                            }
                        </div>
                    </div>


                    <div className="companyProd-right-side p-4  col-w-75">
                        <Row className='mt-4'>
                            <Col lg={6}>
                                <div className="half-input-box">
                                    <label>Name </label>
                                    <div className="profile-input-field">
                                        <input
                                            value={DiscountForm?.name}
                                            onChange={handleChange}
                                            name="name"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="half-input-box">
                                    <label>Code</label>
                                    <div className="profile-input-field">
                                        <input
                                            value={DiscountForm?.code}
                                            onChange={handleChange}
                                            name="code"
                                            required
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="half-input-box">
                                    <label>Percent Off</label>
                                    <div className="profile-input-field">
                                        <input
                                            value={DiscountForm?.percent_off}
                                            onChange={handleChange}
                                            name="percent_off"
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="half-input-box">
                                    <label>Max Redemption</label>
                                    <div className="profile-input-field">
                                        <input
                                            value={DiscountForm?.max_redemptions}
                                            onChange={handleChange}
                                            name="max_redemptions"
                                            type="number"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="half-input-box">
                                    <label>Redeem By</label>
                                    <div className="profile-input-field">
                                        <input
                                            value={DiscountForm?.redeem_by}
                                            onChange={handleChange}
                                            required
                                            name="redeem_by"
                                            type="date"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Row className='d-flex justify-content-end'>
                                <div className="form-width-btn">
                                    {
                                        !!id ?
                                            <button className="form-action-next" onClick={handleUpdate}>
                                                {
                                                    SubmitLoader ? <Spinner animation="border" variant="light" /> : 'Update'
                                                }
                                            </button> :
                                            <button className="form-action-next" onClick={handleSubmit}>
                                                {
                                                    SubmitLoader ? <Spinner animation="border" variant="light" /> : 'Submit'
                                                }
                                            </button>
                                    }
                                </div>
                            </Row>
                            <Col lg={12} className="d-flex flex-column justify-content-between">
                                {activeProduct.formComp}
                            </Col>
                        </Row>
                    </div>

                    <div onClick={handleClose} className="close-job cross-sign-pos">
                        <RxCross2 />
                    </div>
                </div>
            </div>
        </DashboardBoxStyle>

    )
}

export default AddDiscount
import React, { Fragment, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import searchIcon from "../../../../assets/searchIcon.svg";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import PlanTable from "../../../../Pages/Table/PlanTable.js";
import { ToastContainer } from "react-toastify";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBar from "../../../../Components/SearchBar/SearchBar";

import {
  getAddOnPlans,
  getAllPlans,
  getPlanByCategory,
} from "../../../../util/Auth/APIs";
import "./AllPlans.css";
import { RiArrowDropDownLine } from "react-icons/ri";

import PlanType from "../../../../Components/Modals/PlanType";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import {
  Box,
  FormControl,
  InputLabel,
  NativeSelect,
  Pagination,
  Stack,
  TablePagination,
} from "@mui/material";
import Select from 'react-select'


const options = [
  { value: 'foundation', label: 'Foundation' },
  { value: 'addon', label: 'Add On' },

]

const AllPlans = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  



  const [data, setData] = useState(null);
  const [planFor, setPlanFor] = useState("foundation");

  const handleChange = (e) => {
    const { value } = e.target;
    setPlanFor(value);
  };


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getPlans = async (plantype) => {
    const page = currentPage + 1;
    try {
      
      let res = null;

      if (plantype === "foundation") {
        res = await getAllPlans({
          page,
          limit,
          search,
          month,
        });
      } else {
        res = await getAddOnPlans({
          page,
          limit,
          search,
          month,
        });
      }
      setData(res?.data?.plans?.data);
      console.log(res?.data?.plans?.total_page);
      setTotalPages(res?.data?.plans?.total_page * limit);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  function handleClick() {
    setIsOpen(!isOpen);
  }
  function handleIconClick() {
    document.querySelector(".select-input-all").focus();
  }
  useEffect(() => {
    console.log(planFor);
    getPlans(planFor);
  }, [planFor,currentPage, search, month, limit]);


  const handleSelect = (e) => {
    if (e !== null) {

      setPlanFor(e.value);
     
    }
  };
  return (
    <Fragment>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <PlanType isOpen={isOpen} handleClick={handleClick} />

          <TopHeader
            headerBtnName={"Create"}
            headerTitle={"Plan Management Section"}
            handleClick={handleClick}
          />

          

          <div className="plan-select-bar">
          <Select className="half-select speakers-multiselect " options={options} placeholder={"Select Plan Type"}   onChange={handleSelect} />
          </div>

          <SearchBar
            search={search}
            setSearch={setSearch}
            setMonth={setMonth}
            placeholder="Search for Plan"
          />

          <PlanTable data={data} planFor={planFor} />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

         
        </DashboardBoxStyle>
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default AllPlans;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AllBanner from "../Routes/Pages/Banner/AllBanner.js";
import CreateBanner from "../Routes/Pages/Banner/CreateBanner.js";
import EditBanner from "../Routes/Pages/Banner/EditBanner.js";
import BannerUsage from "../Routes/Pages/Banner/BannerUsage.js";


const BannerRoutes = ({ token }) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={token ? <AllBanner /> : <Navigate to="/" />}
        />
        <Route
          path="/create"
          element={token ? <CreateBanner /> : <Navigate to="/" />}
        />
        <Route
          path="/edit/:id"
          element={token ? <EditBanner /> : <Navigate to="/" />}
        />
        <Route
          path="/usage/:id"
          element={token ? <BannerUsage /> : <Navigate to="/" />}
        />
      
       
       
      </Routes>
    </>
  );
};

export default BannerRoutes;

import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
   LineElement,
   CategoryScale,
   LinearScale,
   PointElement,
   BarElement,
   Title,
   Tooltip,
   Legend,
} from "chart.js";

ChartJS.register(
    LineElement,
   CategoryScale,
   LinearScale,
   PointElement,
   BarElement,
   Title,
   Tooltip,
   Legend,

)

const LineChart = () => {

    const data={
        labels:["120M", "150M", "100M", "200M", "11M","20M", "25M", "31M"],
        datasets:[{
            data:[0,3,2 , 3.5, 4, 6.4 ,7,6,4,5.5,6,2,1,5 ,5],
            backgroundColor: "#dedede",
            fill: true,
            borderColor:'#422F8A',
            pointBorderColor:'#422F8A',
            pointBorderWidth:4,
            tension:0.5

        }]

    };
    const options={
        plugins:{
            legend:false
        },
        scales:{
            x:{
                grid:{
                    display:false,
                }
            },
            y:{
                min:2,
                max:10,
                ticks:{
                    stepsSize:2,
                    callback:(value)=>value+'K'
                }
            },
            
        }
    };
  return (
    <div className='LineStyle' >
        <Line data={data} options={options}/>

    </div>
  )
}

export default LineChart
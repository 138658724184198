import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import EventAgendaAccordian from "./EventAgendaAccordian.js";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
// import { AllEventAgenda, AllEventSpeaker, CreateEventAgenda } from '../../../../axios/apis';
import { useEffect } from "react";
import { useSelector } from "react-redux";
import LoaderDot from "../../../../Components/Common/LoaderDot.js";

import { toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import {
  AllEventAgenda,
  AllEventSpeaker,
  CreateEventAgenda,
} from "../../../../util/Auth/APIs.js";

const EventAgenda = ({ type }) => {
  const [active, setActive] = useState(null);
  const [agendaShow, setAgendaShow] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [agendaList, setAgendaList] = useState([]);

  const [selectedSpeakers, setselectedSpeakers] = useState([]);
  const [speakersOptions, setSpeakersOption] = useState([]);

  const EventID = useSelector((state) => state.community.eventId);

  const [agendaData, setAgendaData] = useState({
    event: EventID,
    title: "",
    groupTitle: "",
    date: "",
    start: "",
    end: "",
    about: "",
    speakers: [],
  });

  console.log(agendaData);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleInputSpeakers = (value) => {
    console.log(value);
    let arr = [];
    value.map((data) => {
      arr = [...arr, { name: data.label, _id: data.value }];
    });
    setAgendaData({ ...agendaData, speakers: arr });
    setselectedSpeakers(value);
  };

  const handleChange = (e) => {
    setAgendaData({ ...agendaData, [e.target.name]: e.target.value });
  };

  const handleClose = () => {
    setAgendaShow(false);
    setAgendaData({
      ...agendaData,
      date: "",
      start: "",
      end: "",
      about: "",
      speakers: [],
    });
    setselectedSpeakers([]);
  };

  const convermaterialuiDate = (date) => {
    return date.format("YYYY-MM-DD");
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      if (EventID) {
        const res = await CreateEventAgenda(agendaData);
        if (res.data.success) {
          getAllAgenda();
        }
        toast.success("Agenda Added Successfully");
        setSubmitLoader(false);
        handleClose();
      } else {
        toast.error("Please create event first");
        setSubmitLoader(false);
      }
    } catch (error) {
      console.log(error);
      setSubmitLoader(false);
      toast.error("Something went wrong");
    }
  };

  const getAllAgenda = async () => {
    setLoader(true);
    try {
      if (EventID) {
        const res = await AllEventAgenda(EventID);
        setAgendaList(res.data.message.agenda.reverse());
        console.log(res.data.message.agenda);
        setLoader(false);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getAllSpeakers = async () => {
    setLoader(true);
    try {
      if (EventID) {
        const res = await AllEventSpeaker(EventID);
        let arr = [];
        // setLoader(false)
        arr = res.data.message.speakers.map((data) => {
          return { value: data._id, label: data.name };
        });
        setSpeakersOption(arr);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllAgenda();
    getAllSpeakers();
  }, []);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Event Agenda</h5>

        {loader ? (
          <LoadingPage />
        ) : (
          <>
            <Row className="mt-4">
              <Col
                lg={12}
                className="d-flex flex-column justify-content-between"
              >
                <Row>
                  {agendaList.length === 0 && (
                    <>
                      <Col lg={12}>
                        <div className="no-list-btn">No Agenda</div>
                      </Col>
                    </>
                  )}

                  {agendaList.length !== 0 &&
                    agendaList.map((data, index) => (
                      <Col lg={12}>
                        <EventAgendaAccordian
                          key={index}
                          active={active}
                          getAllAgenda={getAllAgenda}
                          speakersOptions={speakersOptions}
                          handleToggle={handleToggle}
                          data={data}
                        />
                      </Col>
                    ))}
                  {type === "edit" && (
                    <Col lg={12}>
                      <div className="d-flex mt-2 justify-content-end">
                        <div
                          onClick={() => setAgendaShow(true)}
                          className="add-sign position-relative"
                        >
                          <i class="fa-solid x-cross2 fa-circle-plus"></i>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Modal show={agendaShow} onHide={handleClose} centered>
          <div className="event-form-container">
            <div className="">
              <div className="event-form-top">
                <span>Add Agenda</span>
                <i
                  onClick={handleClose}
                  class="fa-solid form-x-cross fa-circle-xmark"
                ></i>
              </div>
            </div>

            <form onSubmit={handleSave}>
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Title</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="title"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Group Title</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="groupTitle"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="half-input-box">
                    <label>Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="MM/DD/YYYY"
                        placeHolder="select Time"
                        value={dayjs(agendaData?.date)}
                        onChange={(value) =>
                          setAgendaData({
                            ...agendaData,
                            date: convermaterialuiDate(value),
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23) !important",
                                borderRadius: "10px",
                              },
                              ".MuiInputBase-input": {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "15px",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="half-input-box">
                    <label>Start Time</label>
                    <div className="profile-input-field">
                      <input
                        type="time"
                        value={agendaData.start}
                        onChange={(e) =>
                          setAgendaData({
                            ...agendaData,
                            start: e.target.value,
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="half-input-box">
                    <label>End Time</label>
                    <div className="profile-input-field">
                      <input
                        type="time"
                        value={agendaData.end}
                        onChange={(e) =>
                          setAgendaData({ ...agendaData, end: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Speaker</label>
                    <Select
                      value={selectedSpeakers}
                      options={speakersOptions}
                      isMulti
                      className="speakers-multiselect"
                      // id={error.amenities ? 'red-border' : ''}
                      onChange={(e) => handleInputSpeakers(e)}
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>About Agenda</label>
                    <div className="profile-input-field">
                      <textarea
                        onChange={handleChange}
                        className="faq-answer-textarea"
                        name="about"
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                </Col>

                <div className="d-flex justify-content-end mt-4">
                  <div className="form-width-btn">
                    <button className="form-action-next">
                      {submitloader ? (
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      ) : (
                        <> Save</>
                      )}
                    </button>
                  </div>
                </div>
              </Row>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EventAgenda;

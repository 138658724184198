import React, { useState } from "react";
import { menuItem } from "./SidebarData";
import "./sidebarheader.css";
import "./sidebar.css";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";
import SidePanel from "./SidePanel";
import { BiLogOutCircle } from "react-icons/bi";
import AdminNavBar from "./AdminNavBar";
import Dashboard from "../../Routes/Pages/Dashboard/Dashboard";
import Companies from "../../Pages/TrendingMovies/TrendingMovies";
import CompanyRoutes from "../../Routes/CompanyRoutes";
import UserRoutes from "../../Routes/UserRoutes";
import EventsRoutes from "../../Routes/EventsRoutes.js";
import ProductRoutes from "../../Routes/ProductRoutes";
import BlogRoutes from "../../Routes/BlogRoutes";
import PlanManagementRoutes from "../../Routes/PlanManagementRoutes";
import TransactionsRoutes from "../../Routes/TransactionsRoutes";
import CommunityRoutes from "../../Routes/CommunityRoutes";
import CategoryRoutes from "../../Routes/CategoryRoutes";
import InsightsRoutes from "../../Routes/InsightsRoutes";
import AdminRoutes from "../../Routes/AdminRoutes.js";
import GuidelinesRoutes from "../../Routes/GuidelinesRoutes.js";
import PayOutRoutes from "../../Routes/PayOutRoutes.js";
import SupportRoutes from "../../Routes/SupportRoutes.js";
import PromotionRoutes from "../../Routes/PromotionRoutes.js";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import BannerRoutes from "../../Routes/BannerRoutes";
import { FiGrid } from "react-icons/fi";
import SubMenu from "./Menues/SubMenu";
import { SuperAdminRoutes } from "../../Protected/Protected";
import DiscountRoutes from "../../Routes/DiscountRoutes";

const SidebarHeader = () => {
  const [hideSidebar, setHideSidebar] = useState(false);
  const token = localStorage.getItem("accessToken");

  // const role = localStorage.getItem("role");

  const handleLogout = () => {
    localStorage.clear();
    window.location.replace("/");
  };

  const manu = [
    // {
    //   title: "Package Management",
    //   icon: <FiGrid />,
    //   subMenu: [
    //     {
    //       path: "/plan-management",
    //       name: "Plan Management",
    //       icon: <FiGrid />,
    //       tag: "/plan-management",
    //     },
    //     {
    //       path: "/transaction",
    //       name: "Transaction",
    //       icon: <FiGrid />,
    //       tag: "/transaction",
    //     },
    //   ],
    // },
    // {
    //   title: "Content Management",
    //   icon: <FiGrid />,
    //   subMenu: [
    //     {
    //       path: "/blog",
    //       name: "Blog",
    //       icon: <FiGrid />,
    //       tag: "/blog",
    //     },
    //   ],
    // },
  ];

  return (
    <div className="d-flex" style={{ background: "#f7f8f8" }}>
      <div
        className={`${hideSidebar
            ? "col-w-6 side-bar-left-shadow"
            : "col-w-20 side-bar-left-shadow"
          }`}
      >
        <div
          onClick={() => setHideSidebar(!hideSidebar)}
          className="arrow-hide"
        >
          {hideSidebar ? <FaAngleLeft /> : <FaAngleRight />}
        </div>
        <SidePanel hideSidebar={hideSidebar}>
          <div className="px-4 py-4">
            {/* <div className="admin-user-top">
                            <div className={`${hideSidebar ? "" : "admin-user-pic"}`} >
                                <img src={userData?.img || User2} className='img-size br-50' alt="" />
                            </div>
                            {
                                !hideSidebar && <h5>{userData?.firstName}</h5>
                            }
                        </div> */}

            <div className="min-height-sidebar">
              {menuItem.map((item) => {
                // if (item.tag === "admin" && role !== "admin") {
                //   return null; 
                // }

                return (
                  <NavLink
                    to={item.path}
                    className="sidebar__link"
                    key={item.id}
                  >
                    <div
                      className={`${hideSidebar ? "wrapper__sidebar_hide" : ""
                        } d-flex align-items-center py-2 my-2 wrapper__sidebar`}
                    >
                      {item.icon}
                      {!hideSidebar && (
                        <h6 className="sidebar__heading px-2 mt-2">
                          {item.name}
                        </h6>
                      )}
                    </div>
                  </NavLink>
                );
              })}

              {manu.map((item, index) => (
                <SubMenu data={item} index={index} hideSidebar={hideSidebar} />
              ))}
              <hr />
              <div
                style={{ cursor: "pointer" }}
                className="sidebar__link"
                onClick={handleLogout}
              >
                {!hideSidebar ? (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar">
                    <BiLogOutCircle />
                    <h6 className="sidebar__heading px-2 mt-2">Log-Out</h6>
                  </div>
                ) : (
                  <div className="d-flex align-items-center py-2 wrapper__sidebar cursor-pointer">
                    <BiLogOutCircle />
                  </div>
                )}
              </div>
            </div>
          </div>
        </SidePanel>
      </div>
      <div className={`${hideSidebar ? "col-w-94" : "col-w-80"}`}>
        <div className="w-100">
          <AdminNavBar />
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Navigate to="/dashboard" />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route
                path="/company/*"
                element={<CompanyRoutes token={token} />}
              />
              <Route path="/user/*" element={<UserRoutes token={token} />} />
              <Route
                path="/product/*"
                element={<ProductRoutes token={token} />}
              />
              <Route
                path="/discount/*"
                element={<DiscountRoutes token={token} />}
              />
              <Route path="/blog/*" element={<BlogRoutes token={token} />} />
              <Route
                path="/plan-management/*"
                element={<PlanManagementRoutes token={token} />}
              />
              <Route
                path="/transaction/*"
                element={<TransactionsRoutes token={token} />}
              />
              <Route
                path="/community/*"
                element={<CommunityRoutes token={token} />}
              />
              <Route
                path="/category/*"
                element={<CategoryRoutes token={token} />}
              />
              <Route
                path="/banner/*"
                element={<BannerRoutes token={token} />}
              />
              <Route
                path="/insights/*"
                element={<InsightsRoutes token={token} />}
              />
              <Route
                path="/promotion/*"
                element={<PromotionRoutes token={token} />}
              />
              {/* <Route element={<SuperAdminRoutes />}> */}
              <Route
                path="/sub-admin-management/*"
                element={<AdminRoutes token={token} />}
              />
              {/* </Route> */}
              <Route
                path="/guidelines/*"
                element={<GuidelinesRoutes token={token} />}
              />
              <Route
                path="/support/*"
                element={<SupportRoutes token={token} />}
              />
              <Route
                path="/payout/*"
                element={<PayOutRoutes token={token} />}
              />
              <Route
                path="/events/*"
                element={<EventsRoutes token={token} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;

import React from "react";
import "./UserOrders.css";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";

const UserOrders = () => {
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  return (
    <>
    <DashboardBoxStyle className=" scroller">
    <TopHeader headerTitle={"User's Orders "} type={"back"}/>
        <div className="center">
          <div className="userOrderContainer">
            <div className="userOrderTable">
              <div className="tableheader">
                <div>Item</div>
                <div>Delievery Date</div>
                <div>Status</div>
                <div>Remarks</div>
              </div>

              <div className="orderCardContainer">
                <div className="order-card-image">
                  <img
                    src="https://rukminim1.flixcart.com/image/832/832/ktketu80/mobile/6/n/d/iphone-13-mlpg3hn-a-apple-original-imag6vpyghayhhrh.jpeg?q=70"
                    alt=""
                  />
                </div>
                <div className="order-items-details">
                  <div>Brewin Cafe Coffee</div>
                  <div>
                    It is a long established fact that a reader will be
                    distracted.
                  </div>
                  <div>$545</div>
                </div>
                <div className="order-card-date">20 feb, 2023</div>
                <div className="order-card-status">Pending</div>
                <div className="order-card-remarks">20 feb, 2023</div>
              </div>
              <div className="orderCardContainer">
                <div className="order-card-image">
                  <img
                    src="https://rukminim1.flixcart.com/image/832/832/ktketu80/mobile/6/n/d/iphone-13-mlpg3hn-a-apple-original-imag6vpyghayhhrh.jpeg?q=70"
                    alt=""
                  />
                </div>
                <div className="order-items-details">
                  <div>Brewin Cafe Coffee</div>
                  <div>
                    It is a long established fact that a reader will be
                    distracted.
                  </div>
                  <div>$545</div>
                </div>
                <div className="order-card-date">20 feb, 2023</div>
                <div className="order-card-status">Pending</div>
                <div className="order-card-remarks">20 feb, 2023</div>
              </div>
            </div>
          </div>
        </div>


    </DashboardBoxStyle>
     
       
     
    </>
  );
};

export default UserOrders;

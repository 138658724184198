import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FiCamera } from "react-icons/fi";
import User3 from "../../../../assets/user.png";
import { storage } from "../../../../firebase";

import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import {
  eventChange,
  eventId,
} from "../../../../app/features/Community/communitySlice";

import { UpdateEvent, CreateEvent } from "../../../../util/Auth/APIs";

const AddOrganizer = ({ type }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const EventID = useSelector((state) => state.community.eventId);
  const sampleEvent = useSelector((state) => state.community.eventDetails);

  const [submitloader, setSubmitLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const [organizer, setOrganizer] = useState({
    name: sampleEvent?.organizer?.name || "",
    designation: sampleEvent?.organizer?.designation || "",
    description: sampleEvent?.organizer?.description || "",
    img: sampleEvent?.organizer?.img || User3,
  });

  const handleImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageLoader(true);
      const storageRef = ref(storage, `Organiser/${e.target.files[0].name}`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setOrganizer({ ...organizer, img: url });
          setImageLoader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg and png file");
      return;
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOrganizer({ ...organizer, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const payload = {
      basicInfo: sampleEvent.basicInfo,
      organizer: organizer,
    };

    if (!EventID) {
      try {
        const res = await CreateEvent(payload);
        toast.success("Event Created Successfully");
        dispatch(eventId(res.data.message._id));
        setSubmitLoader(false);
      } catch (error) {
        toast.error("Event Creation Failed");
        console.log(error);
        setSubmitLoader(false);
      }
    } else {
      try {
        const res = UpdateEvent({ id: EventID, data: payload });
        toast.success("Event Updated Successfully");
        setSubmitLoader(false);
        console.log(res);
      } catch (error) {
        toast.error("Event Updation Failed");
        console.log(error);
        setSubmitLoader(false);
      }
    }
  };

  const handleNext = () => {
    if (sampleEvent?.basicInfo?.mode === "Online") {
      navigate("?tab=online");
    } else {
      navigate("?tab=offline");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventChange({ name: "organizer", payload: organizer }));
    }, 300);
  }, [organizer]);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Add Organizer</h5>
        <form onSubmit={handleSave}>
          <Row className="mt-4">
            <Col lg={12} className="d-flex flex-column justify-content-between">
              <Row>
                <Col lg={6}>
                  <div className="half-input-box">
                    <div className="organiser-profile-icon">
                      <img
                        src={organizer?.img}
                        className={`img-size br-50 ${
                          imageLoader && "brightness-50"
                        }`}
                        alt=""
                      />
                      {imageLoader && (
                        <div
                          className="spinner-border position-absolute text-white ml-4"
                          role="status"
                        ></div>
                      )}
                      <div class="organiser-camera">
                        <span className="d-flex m-0">
                          <FiCamera />
                        </span>
                        <input
                          onChange={handleImage}
                          name="profilePic"
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Organizer name</label>
                    <div className="profile-input-field">
                      <input
                        value={organizer?.name}
                        name="name"
                        type="text"
                        onChange={handleChange}
                        placeholder="Enter the organiser name"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Designation</label>
                    <div className="profile-input-field">
                      <input
                        value={organizer?.designation}
                        name="designation"
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter Designation"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Description of Organizer</label>
                    <div className="profile-input-field">
                      <textarea
                        value={organizer?.description}
                        name="description"
                        onChange={handleChange}
                        className="description-textarea"
                        placeholder="Write something about the organizer..."
                        id=""
                        cols="30"
                        rows=""
                      ></textarea>
                    </div>
                  </div>
                </Col>
              </Row>
              {type === "edit" && (
                <div className="d-flex justify-content-between mt-5">
                  <div className="form-width-btn">
                    <button className="save-draft">
                      {submitloader ? (
                        <div
                          className="spinner-border theme-colour ml-4"
                          role="status"
                        ></div>
                      ) : (
                        <> Save</>
                      )}
                    </button>
                  </div>
                  <div onClick={handleNext} className="form-width-btn">
                    <button type="button" className="form-action-next">
                      Next
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

export default AddOrganizer;

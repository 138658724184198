import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../../../firebase";
import { BiUpload } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import { CreateEvent, UpdateEvent } from "../../../../util/Auth/APIs";
import {
  eventChange,
  eventId,
} from "../../../../app/features/Community/communitySlice";

const EventBasicInfo = ({ type }) => {
  const EventID = useSelector((state) => state.community.eventId);
  const eventDetails = useSelector((state) => state.community.eventDetails);

  const sampleEvent = useSelector((state) => state.community.eventDetails);
  const [imageloader, setImageLoader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);

  const [submitloader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [basicInfo, setBasicInfo] = useState({
    mode: sampleEvent?.basicInfo?.mode || "",
    name: sampleEvent?.basicInfo?.name || "",
    start: {
      date: sampleEvent?.basicInfo?.start?.date || "",
      time: sampleEvent?.basicInfo?.start?.time || "",
    },
    end: {
      date: sampleEvent?.basicInfo?.end?.date || "",
      time: sampleEvent?.basicInfo?.end?.time || "",
    },
    category: sampleEvent?.basicInfo?.category || "",
    description: sampleEvent?.basicInfo?.description || "",
    img: sampleEvent?.basicInfo?.img || [],
  });

  const convermaterialuiDate = (date) => {
    return date.format("YYYY-MM-DD");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBasicInfo({ ...basicInfo, [name]: value });
  };

  const handleImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageLoader(true);
      const storageRef = ref(storage, `EventImages/${e.target.files[0].name}`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setBasicInfo({ ...basicInfo, img: [...basicInfo.img, url] });
          setImageLoader(false);
          // companyBlogs.blogImages.push(url)
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };

  const deleteFromFirebase = async (url) => {
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    deleteObject(pictureRef)
      .then(() => {
        setBasicInfo({
          ...basicInfo,
          img: basicInfo.img.filter((item) => item !== url),
        });
        console.log("success");
        setDeleteLoader(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error("Cannot Remove Image");
        // Uh-oh, an error occurred!
      });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const payload = {
      basicInfo: basicInfo,
    };

    if (!EventID) {
      try {
        const res = await CreateEvent(payload);
        toast.success("Event Created Successfully");
        dispatch(eventId(res.data.message._id));
        setSubmitLoader(false);
        console.log(res);
      } catch (error) {
        toast.error("Event Creation Failed");
        setSubmitLoader(false);
      }
    } else {
      try {
        const res = UpdateEvent({ id: EventID, data: payload });
        toast.success("Event Updated Successfully");
        setSubmitLoader(false);
        console.log(res);
      } catch (error) {
        toast.error("Event Updation Failed");
        setSubmitLoader(false);
      }
    }
  };

  const handleNext = () => {
    navigate("?tab=organizer");
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventChange({ name: "basicInfo", payload: basicInfo }));
      console.log("eventDetails: ", eventDetails);
    }, 300);
  }, [basicInfo]);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Basic Info</h5>
        <form onSubmit={handleSave}>
          <Row className="mt-4">
            <Col lg={12} className="d-flex flex-column justify-content-between">
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Mode of event</label>
                    <div className="profile-input-field">
                      <select
                        value={basicInfo?.mode}
                        name="mode"
                        onChange={handleChange}
                        id=""
                      >
                        <option value="" selected>
                          Select mode
                        </option>
                        <option value="Online">Online</option>
                        <option value="Offline">Offline</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Event Name</label>
                    <div className="profile-input-field">
                      <input
                        value={basicInfo?.name}
                        name="name"
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                <div className="half-input-box">
                    <label>Event Start Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="MM/DD/YYYY"
                        placeHolder="select Time"
                        value={dayjs(basicInfo?.start?.date)}
                        onChange={(value) =>
                          setBasicInfo({
                            ...basicInfo,
                            start: {
                              ...basicInfo.start,
                              date: convermaterialuiDate(value),
                            },
                          })
                        }
                        format="DD-MM-YYYY"
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23) !important",
                                borderRadius: "10px",
                              },
                              ".MuiInputBase-input": {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "15px",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="half-input-box">
                    <label>Event Start Time</label>
                    <div className="profile-input-field">
                      <input
                        value={basicInfo?.start?.time}
                        type="time"
                        onChange={(e) =>
                          setBasicInfo({
                            ...basicInfo,
                            start: { ...basicInfo.start, time: e.target.value },
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="half-input-box">
                    <label>Event End Date</label>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="MM/DD/YYYY"
                        placeHolder="select Time"
                        value={dayjs(basicInfo?.end?.date)}
                        onChange={(value) =>
                          setBasicInfo({
                            ...basicInfo,
                            end: {
                              ...basicInfo.end,
                              date: convermaterialuiDate(value),
                            },
                          })
                        }
                        renderInput={(params) => (
                          <TextField
                            sx={{
                              ".MuiOutlinedInput-notchedOutline": {
                                borderColor: "rgba(0, 0, 0, 0.23) !important",
                                borderRadius: "10px",
                              },
                              ".MuiInputBase-input": {
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "400",
                                fontSize: "15px",
                              },
                            }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="half-input-box">
                    <label>Event End Time</label>
                    <div className="profile-input-field">
                      <input
                        value={basicInfo?.end?.time}
                        type="time"
                        onChange={(e) =>
                          setBasicInfo({
                            ...basicInfo,
                            end: { ...basicInfo.end, time: e.target.value },
                          })
                        }
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Event Category</label>
                    <div className="profile-input-field">
                      <select
                        value={basicInfo?.category}
                        name="category"
                        onChange={handleChange}
                        id=""
                      >
                        <option value="" selected>
                          Pick event category
                        </option>
                        <option value="Live">Live</option>
                        <option value="Concert">Concert</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Description of Event</label>
                    <div className="profile-input-field">
                      <textarea
                        value={basicInfo?.description}
                        className="description-textarea"
                        onChange={handleChange}
                        name="description"
                        id=""
                        cols="30"
                        rows=""
                      ></textarea>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Event Images</label>
                    <div className="upload-button">
                      {imageloader ? (
                        <>
                          <div
                            className="spinner-border text-white ml-4"
                            role="status"
                          ></div>
                        </>
                      ) : (
                        <>
                          <span className="d-flex m-0 text-white">
                            <BiUpload /> Upload Image
                          </span>
                          <input
                            onChange={handleImage}
                            accept="image/png, image/gif, image/jpeg"
                            name="blogImages"
                            type="file"
                          />
                        </>
                      )}
                    </div>

                    {basicInfo.img.length !== 0 && (
                      <>
                        <div className="mt-3 profile-input-field">
                          <Row>
                            {basicInfo?.img.map((img, index) => (
                              <Col lg={3}>
                                <div className="upload-img-card">
                                  <img
                                    src={img}
                                    className={`img-size ${
                                      deleteloader && "brightness-50"
                                    }`}
                                    alt=""
                                  />
                                  {deleteloader && (
                                    <div
                                      className="spinner-border position-absolute text-white ml-4"
                                      role="status"
                                    ></div>
                                  )}
                                  <div
                                    onClick={() => deleteFromFirebase(img)}
                                    className="upload-img-cross"
                                  >
                                    <MdCancel />
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </div>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
              {type === "edit" && (
                <div className="d-flex justify-content-between mt-5">
                  <div className="form-width-btn">
                    <button className="save-draft">
                      {submitloader ? (
                        <div
                          className="spinner-border theme-colour ml-4"
                          role="status"
                        ></div>
                      ) : (
                        <> Save</>
                      )}
                    </button>
                  </div>
                  <div onClick={handleNext} className="form-width-btn">
                    <button type="button" className="form-action-next">
                      Next
                    </button>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </form>
      </div>
    </>
  );
};

export default EventBasicInfo;

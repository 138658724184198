import { createSlice } from "@reduxjs/toolkit";



const initialState = {
  isVirtual: true,
  general: {},
  inventoryy: {},
  linkedProduct: {},
  attribute: [],
  downloadable: {},
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    default: (state) => {
      return {
        ...state,
      };
    },
    clearAllState: (state) => {
      state.general = {};
      state.inventoryy = {};
      state.linkedProduct = {};
      state.attribute = [];
      state.downloadable = {};
    },
    setIsVirtual: (state, value) => {
      state.isVirtual = value.payload;
    },
    setGeneral: (state, value) => {
      state.general = value.payload;
    },
    setProductInventory: (state, value) => {
      state.inventoryy = value.payload;
    },
    setLinkedProduct: (state, value) => {
      state.linkedProduct = value.payload;
    },
    setAttribute: (state, value) => {
      state.attribute = [...value.payload];
    },
    setDownloadable: (state, value) => {
      state.downloadable = value.payload;
    },
  },
});

export const {
  setIsVirtual,
  setGeneral,
  clearAllState,
  setProductInventory,
  setLinkedProduct,
  setAttribute,
  setDownloadable,
  
} = productSlice.actions;

export default productSlice.reducer;

import styled from "styled-components";

export const SidePanelPrimaryBox = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.white};
  height: calc(100vh - 100px);
  background: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  ::-webkit-scrollbar {
    width: 3px;
    border-radius: 5px;
    background: #808191;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #4eb7bd;
  }
  ${"" /* box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15); */
  }
`;

export const SidePanelSecondaryBox = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.white};
  /* background-color: ${({ theme }) => theme.white}; */
  height: 99px;
  background:white;

  &.aligncenter {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const DashboardBoxStyle = styled.div`
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  padding: 25px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  &.padding-0 {
    padding: 0;
  }
  &.scroller {
    overflow-y: auto;
    height: calc(100vh - 80px);
    ${'' /* height: calc(100vh - 10px); */}
    overflow-x: hidden;
    &.h-70 {
      height: calc(100vh - 70px);
    }
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      height: 100px;
      background: #808191;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #4eb7bd;
    }
  }
`;

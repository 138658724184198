import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoadingPage } from "../../../../Components/Loading/Loading";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PromotionTable from "../../../../Pages/Table/PromotionTable.js";
import { getPromotionByCategory } from "../../../../util/Auth/APIs";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SearchBar from "../../../../Components/SearchBar/SearchBar";
import { Pagination, Stack } from "@mui/material";
import FilterModal from "../../../../Components/Modals/FilterModal/FilterModal";
import TablePagination from "@mui/material/TablePagination";


const PromotionList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState(["Page", "Community"]);

  const [filterData, setFilterData] = useState({
    category: "",
  });

 
  function handleIconClick() {
    document.querySelector(".select-input-all").focus();
  }

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getPlans = async () => {
    const category = filterData.category;
    const page = currentPage + 1;
    try {
     

      const res = await getPromotionByCategory({
        page,
        limit,
        search,
        category,
        month,
      });
      setData(res?.data?.plans?.data);

      setTotalPages(res?.data?.plans?.total_page * limit);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPlans();
  }, [currentPage, search, month, limit]);

  const handleClick = () => {
    navigate(`/promotion/create`);
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleFilter = () => {
    getPlans();
    setShow(false);
  };
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader
            backLink={-1}
            headerTitle=" Promotion Plans"
            headerBtnName="Create new"
            handleClick={handleClick}
          />

          <SearchBar
            search={search}
            setSearch={setSearch}
            setMonth={setMonth}
            placeholder="Search for Promotion.."
            handleShow={handleShow}
          />
          {data !== null && <PromotionTable data={data} />}
          <FilterModal
            categories={categories}
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleClose={handleClose}
            handleFilter={handleFilter}
          />
           <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default PromotionList;

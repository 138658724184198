import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import Select from "react-select";
import {
  AllSubCategoryList,
  getAllCategories,
} from "../../../../util/Auth/APIs";
import { toast } from "react-toastify";
import { useEffect } from "react";
import "./SelectAllCategories";

const SelectAllCategories = ({ selected, setSelected }) => {
  const catList = useSelector((state) => state.category.categories);
  const [loader, setLoader] = useState(false);

  const [catlevel2, setCatLevel2] = useState([]);
  const [catlevel3, setCatLevel3] = useState([]);
  const [catlevel4, setCatLevel4] = useState([]);

  const [sampleData, setSampleData] = useState([]);

  const [effectCategory, setEffectCategory] = useState("");

  const convertCategory = (data) => {
    let arr = [];
    data?.map((item) => {
      arr.push({ value: item.name, label: item.name });
    });
    return arr;
  };

  const convertSelected = (data) => {
    if (data) {
      return { value: data, label: data };
    } else {
      return null;
    }
  };

  const handleChange = async (e, str, level) => {
    if (e == null) {
      setSelected({ ...selected, [str]: "" });
      return;
    }

    setSelected({ ...selected, [str]: e.value });

    switch (str) {
      case "category":
        console.log(e.value);
        getLevelCategory(catList, 2, e.value).then((data) => {
          setCatLevel2(data);
          setLoader(false);
        });
        break;
      case "category2":
        getLevelCategory(catlevel2, 3, e.value).then((data) => {
          setCatLevel3(data);
          setLoader(false);
        });
        break;
      case "category3":
        getLevelCategory(catlevel3, 4, e.value).then((data) => {
          setCatLevel4(data);
          setLoader(false);
        });
        break;
      case "category4":
        break;
      default:
        break;
    }
  };

  // const id = catList.find((item) => item.name === e.value)._id

  // const data1 = await getLevelCategory(id, 2)
  // setCatLevel2(data1)
  // setSampleData(data1)
  // setLoader(false)
  // debugger
  // setLoader(true)
  // try {
  //     const res = await AllSubCategoryList({ id: id, level: level })
  //     setCatLevel2(res.data.data)
  //     debugger
  //     setLoader(false)
  // } catch (err) {
  //     toast.error("Sub Category not Found")
  //     setLoader(false)
  //     console.log(err)
  // }
  // getLevelCategory(id, level)
  // if (level === 2 && e !== null) {
  // }

  const getLevelCategory = async (list, level, value) => {
    setLoader(true);
    if (list.length !== 0 && value) {
      const id = list.find((item) => item.name === value)._id;
      try {
        const res = await AllSubCategoryList({ id: id, level: level });
        return res.data.data;
      } catch (err) {
        toast.error("Sub Category not Found");
        setLoader(false);
        console.log(err);
      }
    } else {
      return [];
    }
  };

  const getSelectedList = async () => {
    getLevelCategory(catList, 2, selected?.category).then((data) => {
      setCatLevel2(data);
    });
  };

  const getLevel2 = async () => {
    getLevelCategory(catlevel2, 3, selected?.category2).then((data) => {
      setCatLevel3(data);
      setLoader(false);
    });
  };

  useEffect(() => {
    getSelectedList();
  }, []);

  useEffect(() => {
    getLevel2();
  }, [catlevel2]);

  useEffect(() => {
    getLevelCategory(catlevel3, 4, selected?.category3).then((data) => {
      setCatLevel4(data);
      setLoader(false);
    });
  }, [catlevel3]);

  // console.log("category2 :  ", catlevel2, "\ncategory3 : ", catlevel3)

  return (
    <>
      {loader ? (
        <Col className="text-center" lg={12}>
          <div className="spinner-border theme-colour ml-4" role="status"></div>
        </Col>
      ) : (
        <>
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Category 1</h6>
            </div>

            <div className="profile-input-width">
              <div className="insight-select-field">
                <Select
                  options={convertCategory(catList)}
                  value={convertSelected(selected?.category)}
                  classNamePrefix="select country"
                  placeholder="Category"
                  defaultValue="select country"
                  className="select-insight"
                  isSearchable={true}
                  isClearable={true}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      padding: "10px",
                      borderColor: "#b0b0b0",
                      borderRadius: "10px",
                    }),
                  }}
                  onChange={(e) => handleChange(e, "category", 2)}
                />
              </div>
            </div>
          </div>

          {catlevel2?.length !== 0 && (
            <>
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Category 2</h6>
                </div>

                <div className="profile-input-width">
                  <div className="insight-select-field">
                    <Select
                      options={convertCategory(catlevel2)}
                      value={convertSelected(selected?.category2)}
                      classNamePrefix="select country"
                      placeholder="Sub Category 1"
                      defaultValue="select country"
                      className="select-insight"
                      isSearchable={true}
                      isClearable={true}
                      onChange={(e) => handleChange(e, "category2", 3)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "15px",
                          borderColor: "#b0b0b0",
                          borderRadius: "10px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {catlevel3?.length !== 0 && (
            <>
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Category 3</h6>
                </div>

                <div className="profile-input-width">
                  <div className="insight-select-field">
                    <Select
                      options={convertCategory(catlevel3)}
                      value={convertSelected(selected?.category3)}
                      classNamePrefix="select country"
                      placeholder="Sub Category 2"
                      defaultValue="select country"
                      className="select-insight"
                      isSearchable={true}
                      isClearable={true}
                      onChange={(e) => handleChange(e, "category3", 4)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "15px",
                          borderColor: "#b0b0b0",
                          borderRadius: "10px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}

          {catlevel4.length !== 0 && (
            <>
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Category 4</h6>
                </div>

                <div className="profile-input-width">
                  <div className="insight-select-field">
                    <Select
                      options={convertCategory(catlevel4)}
                      value={convertSelected(selected?.category4)}
                      classNamePrefix="select country"
                      placeholder="Sub Category 4"
                      defaultValue="select country"
                      className="select-insight"
                      isSearchable={true}
                      isClearable={true}
                      onChange={(e) => handleChange(e, "category4", 5)}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          padding: "15px",
                          borderColor: "#b0b0b0",
                          borderRadius: "10px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default SelectAllCategories;

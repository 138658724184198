import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Stack, ToastContainer } from "react-bootstrap";

import { LoadingPage } from "../../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";

import CommunityTable from "../../../../Pages/Table/CommunityTable.js";
import "./AllCommunities.css";
import { getAllCategories, getAllCommunities } from "../../../../util/Auth/APIs";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SearchBar from "../../../../Components/SearchBar/SearchBar";
import TablePagination from "@mui/material/TablePagination";
import FilterModal from "../../../../Components/Modals/FilterModal/FilterModal";

const AllCommunities = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [filterData, setFilterData] = useState({
    category: "",
  });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const getBlogs = async () => {
    const category = filterData.category;
    const page = currentPage + 1;
    try {
      const res = await getAllCommunities({
        page,
        limit,
        search,
        category,
        month,
      });

     
      setData(res?.data?.data?.data);
     
      setTotalPages(res?.data?.data?.total_page * limit);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogs();
  }, [currentPage, search, month, limit]);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleFilter = () => {
    getBlogs();
    setShow(false);
  };

  const handleClick = () => {
    navigate("/community/create");

    
  };


  const getCategories = async () => {
    try {
      const res = await getAllCategories();

      {
        res?.data?.data?.map((item) => {
          categories.push(item.name);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  return (
    <Fragment>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader
            headerTitle={"Community Section"}
            headerBtnName={"Create"}
            handleClick={handleClick}
          />
          <div>
            <SearchBar
              search={search}
              setSearch={setSearch}
              placeholder="Search for Community"
              setMonth={setMonth}
              handleShow={handleShow}
            />
          </div>

          <CommunityTable data={data} />

          <FilterModal
            categories={categories}
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleClose={handleClose}
            handleFilter={handleFilter}
          />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DashboardBoxStyle>
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default AllCommunities;

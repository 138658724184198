import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";

import { toast, ToastContainer } from "react-toastify";

import "./Table.css";
import { removeBanner } from "../../util/Auth/APIs";

const ProductTable = ({ data }) => {


 
 

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>ID</th>
            <th>Amount</th>
            <th>Buyer Name</th>
            <th>Created On</th>
            <th>End Date</th>
            <th>Expired</th>
            <th>Type</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item._id}>
              <td>{index+1}</td>
              <td>{item?.amount}</td>
              <td >{item?.buyerName}</td>
              <td>{item?.createdAt.slice(0, 10)}</td>
              <td >{item?.endDate.slice(0, 10)}</td>
              <td style={{ color: item?.isExpired ? `red` : `green` }}>{item?.isExpired ? "Expired":"Active"}</td>
             
              <td>{item?.name}</td>
              <td>{item?.quantity}</td>

             
            </tr>
          ))}
        </tbody>
        <ToastContainer />
      </table>
    </div>
  );
};

export default ProductTable;

import { Navigate, Route, Routes } from "react-router-dom";
import EventList from "./Pages/Event/EventList/EventList.js";
import CreateEvent from "./Pages/Event/CreateEvent/CreateEvent.js";
import EditEvent from "./Pages/Event/EditEvent/EditEvent.js";
import EventDetail from "./Pages/Event/EventDetail/EventDetail.js";


function EventsRoutes({ token }) {
  return (
    <Routes>
      <Route path="/" element={token ? <EventList /> : <Navigate to="/" />} />
      <Route path="/details/:id" element={token ? <EventDetail /> : <Navigate to="/" />} />
      <Route path="/add" element={token ? <CreateEvent type={"edit"} /> : <Navigate to="/" />} />
      <Route path="/edit/:id" element={token ? <EditEvent type={"edit"} navigateTo={"/events"}/> : <Navigate to="/" />} />
    </Routes>
  );
}

export default EventsRoutes;

import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";

import "./SearchBar.css";
import { useEffect, useState } from "react";
import { useCallback } from "react";

const OrderSearch = ({
  handleShow,
  title,
  placeholder,
  search,
  setSearch,
  setMonth,
  showFilter = true,
}) => {
  const [date, setDate] = useState(getCurrentDate());

  function getCurrentDate() {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = "01";
    return `${year}-${month}-${day}`;
  }

  const handleChange = useCallback(({ target: { value } }) => {
    setSearch(value);
  }, []);

  function handleInputChange(event) {
    const inputValue = event.target.value;
    if (inputValue === "") {
      setMonth("");
      setDate("");
    } else {
      const inputDate = new Date(event.target.value);
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
      const day = "01";
      setDate(`${year}-${month}-${day}`);
      setMonth(`${year}-${month}-${day}`);
    }
  }

  return (
    <div className="orderSearchWrapper">
      <div className="orderSearchInput">
        {title === "Recently raised tickets" ? (
          <h6>{title}</h6>
        ) : (
          <>
            <SearchIcon />
            <input
              value={search}
              onChange={handleChange}
              placeholder={placeholder}
              type="text"
            />
          </>
        )}
      </div>
      <div className="orderSearchFilterContainer">
        {showFilter && (
          <div onClick={handleShow} className="orderSearchFilter">
            <FilterListIcon />
            <p>Filters</p>
          </div>
        )}

        <div className="orderSearchDate">
          <label>
            <input
              placeholder="Choose Month"
              type="month"
              value={date.substring(0, 7)}
              onChange={handleInputChange}
            />
          </label>
        </div>
      </div>
    </div>
  );
};

export default OrderSearch;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Col, Row } from "react-bootstrap";
import Inventory from '../EditProduct/ProductPage/EditProduct/Inventory';
import { RxCross2 } from "react-icons/rx"
import ProductGeneral from '../EditProduct/ProductPage/EditProduct/ProductGeneral'

import LinkedProducts from '../EditProduct/ProductPage/EditProduct/LinkedProducts'
import ProductAttribute from '../EditProduct/ProductPage/EditProduct/ProductAttribute'
import DownloadableFiles from '../EditProduct/ProductPage/EditProduct/DownloadableFiles'
import { clearAllState, setIsVirtual } from '../../../../app/features/adminProducts/productSlice'
import { DashboardBoxStyle } from '../../../../SideBar/SidepanelStyled'
import "../EditProduct/ProductPage/EditProduct/EditProduct.css"

const AddProduct = () => {


    const [downloadProduct, setDownloadProduct] = useState()
    

    const prodType = useSelector((state)=>state.product.isVirtual );

    const dispatch = useDispatch()
    const [activeProduct, setActiveProduct] = useState({
        formHead: "General",
        formComp: <ProductGeneral />
    })

    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const [productForm, setProductForm] = useState([
        {
            formHead: "General",
            tabLink: "general",
        },
        {
            formHead: "Inventory",
            tabLink: "inventory",
        },
        {
            formHead: "Linked Products",
            tabLink: "linked",
        },
        {
            formHead: "Attributes",
            tabLink: "attributes",
        }
    ])

    console.log(prodType)

    const handleTypeChange = (event) => {
   
        // const isVirtualTrue = event.target.value === 'Virtual';
        // setProdType(isVirtualTrue);
        // dispatch(setIsVirtual(isVirtualTrue));
        //  console.log(prodType);
         
      };
    

    useEffect(() => {
        if (prodType === true) {
            setProductForm([...productForm, {
                formHead: "Downloadable files",
                tabLink: "downloadable",
            }])
        } else {
            setProductForm(productForm.filter(e => e.formHead !== "Downloadable files"))
        }
    }, [prodType])


    const handleActiveProduct = (e) => {
        navigate(`?tab=${e.tabLink}`)
    }


    const handleClose = () => {
       navigate("/product")
    }

    useEffect(() => {
        switch (searchParams.get("tab")) {
            case "general":
                setActiveProduct({ formHead: "General", formComp: <ProductGeneral /> })
                break;
            case "inventory":
                setActiveProduct({ formHead: "Inventory", formComp: <Inventory /> })
                break;
            case "linked":
                setActiveProduct({ formHead: "Linked Products", formComp: <LinkedProducts /> })
                break;
            case "attributes":
                setActiveProduct({ formHead: "Attributes", formComp: <ProductAttribute /> })
                break;
            case "downloadable":
                setActiveProduct({ formHead: "Downloadable files", formComp: <DownloadableFiles/> })
                break;
            default:
                setActiveProduct({ formHead: "General", formComp: <ProductGeneral /> })
        }
    }, [searchParams.get("tab")])

    return (
       

       
        <DashboardBoxStyle className="scroller">
            <div className="form-container position-relative p-0 mt-4">
                <div className="d-flex">
                    <div className="companyProd-left-side col-w-25">
                        <div className="form-top">
                            <h5>Add Product</h5>
                        </div>
                        <hr className='m-0' />
                        <div className="form-menu-tab">
                            {
                                productForm.map((e) => (
                                    <div onClick={() => handleActiveProduct(e)} className={` ${activeProduct.formHead === e.formHead && "menu-tab-active"} d-flex align-items-center py-2 my-2 menu-tab`}>
                                        <h6>{e.formHead}</h6>
                                    </div>
                                ))
                            }
                        </div>
                    </div>


                    <div className="companyProd-right-side p-4  col-w-75">
                        <h5>{activeProduct.formHead}</h5>
                        <Row className='mt-4'>
                            {/* <Col lg={9}>
                                <div className="d-flex justify-content-between half-input-box">
                                    <div className='d-flex align-item-center'>
                                        <span className='m-0'>Physical Product :</span>
                                        <input className='ms-3 product-checkbox' name='type' value="Physical" checked={prodType === "Physical" && "checked"} onChange={handleChange} type="radio" />
                                    </div>
                                    <div className='d-flex align-item-center'>
                                        <span className='m-0'>Virtual Product :</span>
                                        <input className='ms-3 product-checkbox' name='type' value="Virtual" checked={prodType === "Virtual" && "checked"} onChange={handleChange} type="radio" />
                                    </div>
                                </div>
                            </Col> */}
                            <Col lg={12} className="d-flex flex-column justify-content-between">
                                {activeProduct.formComp}
                            </Col>
                        </Row>
                    </div>

                    <div onClick={handleClose} className="close-job cross-sign-pos">
                        <RxCross2 />
                    </div>
                </div>
            </div>
        </DashboardBoxStyle>
       
    )
}

export default AddProduct
import React from 'react';
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import CompanyFaqAccordian from "../../../Components/Common/Card/CompanyFaqAccordian.js";
import {  useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from '../../../Components/TopHeader/TopHeader.js';


const Impact = () => {
    const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
    
  return (
  <>
 


 
   <DashboardBoxStyle className=" scroller">
   <TopHeader headerTitle={"Impact"} type={"back"}/>
                        <div className="mt-4">


                            <div className="factoryInfoComp mb-5">

                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="rowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Equal Employment
                                                Opportunity / Non-discrimination</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>We believe that all terms and conditions of employment should be based on an individual's ability to do the job and not on the basis of personal characteristics or beliefs. We provide employees with a working environment free of discrimination, harassment, intimidation or coercion relating directly or indirectly to race, religion, sexual orientation, political opinion or disability.</span>
                                        </div>
                                    </Col>
                                </Row>

                                <Row className="rowClass removeRowClass">
                                    <Col lg={3} className="factoryCol">
                                        <div className="firstInfo2">
                                            <span>Address</span>
                                        </div>
                                    </Col>
                                    <Col lg={9} className="factoryCol factoryCol1">
                                        <div className="firstInfo2 fw">
                                            <span>32323</span>
                                        </div>
                                    </Col>
                                </Row>




                            </div>


                        </div>

                    </DashboardBoxStyle>
                  
  
  </>
  )
}

export default Impact
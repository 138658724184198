import React from 'react'
import { useState } from 'react'
import "./FilterModal.css";
import CustomButton from '../../Button/CustomButton/CustomButton';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import moment from 'moment'
import CustomModal from "../CustomModal/CustomModal.jsx";
import DateRangePicker from '../../DateRangePicker/DateRangePicker';


const FilterModal = ({ categories, handleFilter, show, handleClose, filterData, setFilterData }) => {
    const [openCalander, setOpenCalander] = useState(false);


    return (
        <CustomModal show={show} handleClose={handleClose} >
            <div className="filterModalWrapper">
                <h6>Filters</h6>
                <div className="filterModalInputContainer">
                    <div className="filterModalinput">

                        <label htmlFor="category">Select Category</label>
                        <div className='select-tag-container'>
                            <select value={filterData.category} className='select-tag' onChange={e => setFilterData(prev => ({ ...prev, category: e.target.value }))} name="category" id="category" required>
                                <option value="" hidden> Select Category</option>
                                <option value="" > All</option>
                                {categories && categories.map((item, index) => <option key={index} value={item}>{item}</option>)}
                            </select>

                        </div>

                    </div>

                    <div className="filterModalBtn">
                        <CustomButton handleClick={handleFilter} classId="filterapplyButton">Apply</CustomButton>
                        <h6 onClick={() => {
                            setFilterData({
                                category: ""
                            })
                            // handleFilter()
                            handleClose()
                        }}>Cancel</h6>
                    </div>
                </div>
            </div>
        </CustomModal>
    )
}

export default FilterModal
import React from "react";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";

import DealsOfferCard from '../../../Components/Common/Card/DealsOfferCard.js'
import TopHeader from "../../../Components/TopHeader/TopHeader.js";





const CompanyDeals = () => {
  const { deals } = useSelector((state) => state.company);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  console.log(deals);
  return (
    <>
   

   
     
      <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Deals And Offers "} type={"back"}/>
        <div className="mt-4">
          <Row className="py-3">
            {deals.map((e, i) => (
              <Col className="mb-3" lg={4}>
                <DealsOfferCard
                  data={e}
                 
                 
                //   link={dealsisting.link}
                  dot={true}
                />
              </Col>
            ))}
          </Row>
        </div>
      </DashboardBoxStyle>
     
    </>
  
  );
};

export default CompanyDeals;

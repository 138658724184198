import React from "react";
import { DashboardBoxStyle } from "../../SideBar/SidepanelStyled";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
const EventDetails = () => {
  const { events } = useSelector((state) => state.company);
  const navigate = useNavigate();
  return (
    <>
      <DashboardBoxStyle className="scroller">
        <TopHeader headerTitle={"Events Details"} path={"/company"} type="back" />

        <div className="table-container">
          <table className="Table">
            <thead className="table-head">
              <tr>
                <th>ID</th>
                <th>Title</th>
                <th>Date</th>
                <th>Start</th>
                <th>End</th>
                <th>Discription</th>
                <th>Ticket Details</th>
              </tr>
            </thead>
            <tbody>
              {events.map((item, index) => (
                <tr
                  key={item._id}
                  onClick={() => navigate(`/company/event/${item?._id}`)}
                >
                  <td>{index + 1}</td>
                  <td>
                    {item?.basicInfo?.name
                      ? item?.basicInfo?.name.split(" ").slice(0, 5).join(" ")
                      : ""}

                    {item?.basicInfo?.name.split(" ").length > 5 ? "..." : ""}
                  </td>
                  <td>{item?.basicInfo?.start?.date?.slice(0, 10)}</td>
                  <td>{item?.basicInfo?.start?.time}</td>
                  <td>{item?.basicInfo?.end?.time}</td>
                  <td>
                    {item?.basicInfo?.description
                      ? item.basicInfo.description
                          .split(" ")
                          .slice(0, 5)
                          .join(" ")
                      : ""}
                    {item?.basicInfo?.description &&
                    item.basicInfo.description.split(" ").length > 5
                      ? " ..."
                      : ""}
                  </td>
                  <td>
                    <div onClick={(e)=>{
                      navigate(`/company/events/ticketDetails/${item._id}`);
                      e.stopPropagation();


                    }} className="edit">
                      <LocalActivityIcon />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </DashboardBoxStyle>
    </>
  );
};

export default EventDetails;

import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import { editPromotion, getPlanById, getPromotionById, updatePlan } from '../../../../util/Auth/APIs';
import { LoadingPage } from "../../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { RxCross2 } from "react-icons/rx";
import { TextField, inputLabelClasses } from '@mui/material';
import TopHeader from '../../../../Components/TopHeader/TopHeader';

const borderStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#acb5bd",
      },
      "&:hover fieldset": {
        borderColor: "#acb5bd",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: "#4eb7bd",
      },
    },
  };


  const labelInput = {
    sx: {
      color: "#acb5bd",
      [`&.${inputLabelClasses.focused}`]: {
        color: "#4eb7bd",
      },
    },
  };
const EditPromotion = () => {
    const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [form, setForm] = useState({
    name: "",
    yearPrice: null,
    category:"",
    credits: null
  });

  const handleChange = ( name, value) => {
   
    let parsedValue;
  
    if (value === "") {
      parsedValue = ""; // Set to an empty string when the value is empty
    } else if (!isNaN(value)) {
      parsedValue = parseFloat(value);
    } else {
      parsedValue = value;
    }
   
    setForm({ ...form, [name]: parsedValue });
  };

  const handleQuill = (content, delta, source, editor) => {
    setForm({ ...form, benifits: editor.getHTML() });
  };

  const handleSubmit = async () => {
    console.log(form);
    setSubmitLoader(true);

    const params = {
      id: id,
      data: {
        name: form.name,
        yearPrice: form.yearPrice,
        category:form.category,
        credits: form.credits,
      },
    };
    try {
      const res = await editPromotion(params);
      toast.success("Forms Edited successfully", {
        onClose: () => navigate("/promotion/"),
      });
      setSubmitLoader(false);
      navigate(-1);
      console.log(res.data.message);
    } catch (error) {
      toast.error("Something went wrong");
      setSubmitLoader(false);
      console.log(error);
    }
   
  };

  const singlePlan = async () => {
    setLoader(true);
    try {
      console.log(id);
      const res = await getPromotionById(id);
      // console.log(res);

      setForm({
        name: res?.data?.plans?.name,
        yearPrice: res?.data?.plans?.yearPrice,
        category:res?.data?.plans?.category,
        credits: res?.data?.plans?.credits

      });
      setLoader(false);
      console.log(form);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    singlePlan();
  }, []);
  return (
    <>
    {loader ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller ">
          <TopHeader headerTitle={"Edit Plan"} type="back"/>

          <div className="edit-plan-container form-container">
            <div
              onClick={() => navigate(-1)}
              className="close-job edit-cross-pos"
            >
              <RxCross2 />
            </div>
            <div className="plan-form-contianer  edit-plan-form">

              
             

              <TextField
                label="Name"
                type="text"
                value={form.name}
                onChange={(e) => {
                    handleChange( "name", e.target.value);
                  }}
               
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
             
            
             
              <TextField
                label="Yearly Price"
                type="number"
                  value={form.yearPrice}
                onChange={(e) => {
                    handleChange( "yearPrice", e.target.value);
                  }}
               
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
            
              <TextField
                label="Category"
                type="text"
                value={form.category}
                onChange={(e) => {
                    handleChange( "category", e.target.value);
                  }}
               
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Credits"
                type="number"
                value={form.credits}
                onChange={(e) => {
                    handleChange( "credits", e.target.value);
                  }}
               
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
             
             
            </div>
           
            <div
              className="listing-addnew-btn edit-plan-btn "
              onClick={handleSubmit}
            >
              <button>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Edit Plan</>
                )}
              </button>
            </div>
          </div>

          <ToastContainer />
        </DashboardBoxStyle>
      )}
    
    </>
  )
}

export default EditPromotion
import { Route, Navigate, Routes } from "react-router-dom";
import UsersList from "./Pages/UsersPage/UsersList/UsersList.js";
import UsersProfile from "./Pages/UsersPage/UserProfile/UserProfile.js";
import UserBooking from "./Pages/UsersPage/UserBooking/UserBooking.js";
import UserOrders from "./Pages/UsersPage/UserOrders/UserOrders.js";
import UserEnquiry from "./Pages/UsersPage/UserEnquiry/UserEnquiry.js";
import UsersFeature from "./Pages/UsersPage/UsersFeature/UsersFeature.js";

function UserRoutes({ token }) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={token ? <UsersList /> : <Navigate to="/" />}
        />
        <Route
          path="/:id/feature"
          element={token ? <UsersFeature /> : <Navigate to="/" />}
        />
        <Route
          path="/profile"
          element={token ? <UsersProfile /> : <Navigate to="/" />}
        />
        <Route
          path="/bookings"
          element={token ? <UserBooking /> : <Navigate to="/" />}
        />
        <Route
          path="/orders"
          element={token ? <UserOrders /> : <Navigate to="/" />}
        />
        <Route
          path="/enquiry"
          element={token ? <UserEnquiry /> : <Navigate to="/" />}
        />

      </Routes>

    </>
  );
}

export default UserRoutes;

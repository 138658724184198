import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import { AllPayOut } from "../../../util/Auth/APIs";
import SearchBar from "../../../Components/SearchBar/SearchBar";
// import { Pagination } from "antd";
import FilterModal from "../../../Components/Modals/FilterModal/FilterModal";
import TablePagination from "@mui/material/TablePagination";
import PayOutTable from "../../../Pages/Table/PayOutTable";

const AllPayOutList = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState(["Completed", "Pending"]);
  const [filterData, setFilterData] = useState({
    category: "",
  });

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getPayoutDetails = async () => {
    const status = filterData.category;
    const page = currentPage + 1;

    try {
      const res = await AllPayOut({
        page,
        limit,
        search,
        status,
        month,
      });
     
      setData(res?.data?.data?.data);
      setTotalPages(res?.data?.data?.total_pages * limit);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getPayoutDetails();
  }, [currentPage, search, month, limit , filterData]);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const handleFilter = () => {
    getPayoutDetails();
    setShow(false);
  };


  

  
  return (
    <>
      {loader ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Payout Management"} type={"noaction"} />
          <SearchBar
            search={search}
            setSearch={setSearch}
            setMonth={setMonth}
            placeholder="Search for User"
            handleShow={handleShow}
          />

          <PayOutTable data={data} />

          <FilterModal
            categories={categories}
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleClose={handleClose}
            handleFilter={handleFilter}
          />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default AllPayOutList;

import React, { useEffect, useState } from "react";
import SpeakerAccordian from "./SpeakerAccordian.js";
import { Col, Row } from "react-bootstrap";
import User3 from "../../../../assets/comm-user.svg";

import { FiCamera } from "react-icons/fi";
import Modal from "react-bootstrap/Modal";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { uploadImg } from "../../../../util/Healper/imageUpload.js";
import { useSelector } from "react-redux";
// import { AllEventSpeaker, CreateEventSpeaker } from '../../../../axios/apis';
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import {
  AllEventSpeaker,
  CreateEventSpeaker,
} from "../../../../util/Auth/APIs.js";

const AddSpeaker = ({ type }) => {
  const [speakerShow, setSpeakerShow] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);

  const [loader, setLoader] = useState(false);
  const [active, setActive] = useState(null);

  const EventID = useSelector((state) => state.community.eventId);

  const [speakersList, setSpeakersList] = useState([]);

  const [speakerData, setSpeakerData] = useState({
    event: EventID,
    name: "",
    description: "",
    img: "" || User3,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSpeakerData({ ...speakerData, [name]: value });
  };

  console.log(speakerData);

  const handleImage = async (e) => {
    await uploadImg(
      "Speakers",
      e.target.files[0],
      setSpeakerData,
      setImageLoader
    );
  };

  const handleClose = () => {
    setSpeakerShow(false);
    setSpeakerData({ ...speakerData, img: "" || User3 });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      if (EventID) {
        const res = await CreateEventSpeaker(speakerData);
        if (res.data.success) {
          getAllSpeakers();
        }
        toast.success("Speaker created successfully");
        setSubmitLoader(false);
        handleClose();
      } else {
        toast.error("Please create event first");
      }
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const getAllSpeakers = async () => {
    setLoader(true);
    try {
      const res = await AllEventSpeaker(EventID);
      setSpeakersList(res.data.message.speakers.reverse());
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllSpeakers();
  }, []);

  return (
    <div className="company-right-side p-4  col-w-75">
      <h5>Speakers</h5>

      {loader ? (
        <LoadingPage />
      ) : (
        <>
          <Row className="mt-4">
            <Col lg={12} className="d-flex flex-column justify-content-between">
              <Row>
                {speakersList.length === 0 && (
                  <>
                    <Col lg={12}>
                      <div className="no-list-btn">No Speakers</div>
                    </Col>
                  </>
                )}
                {speakersList !== 0 &&
                  speakersList.map((data, index) => (
                    <Col lg={12}>
                      <SpeakerAccordian
                        key={index}
                        active={active}
                        getAllSpeakers={getAllSpeakers}
                        handleToggle={handleToggle}
                        data={data}
                      />
                    </Col>
                  ))}
                {type === "edit" && (
                  <Col lg={12}>
                    <div className="d-flex mt-2 justify-content-end">
                      <div
                        onClick={() => setSpeakerShow(true)}
                        className="add-sign position-relative"
                      >
                        <i class="fa-solid x-cross2 fa-circle-plus"></i>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </>
      )}

      <Modal show={speakerShow} onHide={handleClose} centered>
        <div className="event-form-container">
          <div className="">
            <div className="event-form-top">
              <span>Add Speaker</span>
              <i
                onClick={handleClose}
                class="fa-solid form-x-cross fa-circle-xmark"
              ></i>
            </div>
          </div>

          <form onSubmit={handleSave}>
            <Row>
              <Col lg={6}>
                <div className="half-input-box organiser-profile-icon">
                  <img
                    src={speakerData?.img}
                    className={`img-size br-50 ${
                      imageLoader && "brightness-50"
                    }`}
                    alt=""
                  />
                  {imageLoader && (
                    <div
                      className="spinner-border position-absolute text-white ml-4"
                      role="status"
                    ></div>
                  )}
                  <div class="organiser-camera">
                    <span className="d-flex m-0">
                      <FiCamera />
                    </span>
                    <input
                      onChange={handleImage}
                      name="profilePic"
                      type="file"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Speaker Name</label>
                  <div className="profile-input-field">
                    <input
                      name="name"
                      onChange={handleChange}
                      type="text"
                      placeholder="Enter the event name"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Description</label>
                  <div className="profile-input-field">
                    <textarea
                      name="description"
                      onChange={handleChange}
                      className="faq-answer-textarea"
                      id=""
                      cols="30"
                      rows=""
                    ></textarea>
                  </div>
                </div>
              </Col>
              <div className="d-flex justify-content-end mt-4">
                <div className="form-width-btn">
                  <button className="form-action-next">
                    {submitloader ? (
                      <div
                        className="spinner-border text-white ml-4"
                        role="status"
                      ></div>
                    ) : (
                      <> Save</>
                    )}
                  </button>
                </div>
              </div>
            </Row>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddSpeaker;

import React from 'react'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

const CommunityHead = ({ secHead }) => {

    return (
        <>
            <div className="detail-top">
                <Box
                    sx={{
                        width: "100%",
                        ".MuiTabs-flexContainer": {
                            borderBottom: "2px solid  #c3e6e8 !important",
                        },
                    }}
                >
                    <Tabs
                        value="About"
                        sx={{
                            color: "black",
                            ".Mui-selected": {
                                color: "#4EB7BD !important",
                            },
                            ".MuiTab-textColorPrimary": {
                                color: "black",
                                fontFamily: "Roboto",
                                fontStyle: "normal",
                                fontWeight: "600",
                                fontSize: "16px",
                                lineHeight: "19px",
                                color: "#4EB7BD",
                                textTransform: "capitalize",
                            },
                            ".MuiTabs-indicator": {
                                backgroundColor: "#4EB7BD !important",
                                borderRadius: "10px",
                                height: "4px",
                                left: "20px !important",
                                width: "70px !important",
                                borderRadius: "8px 8px 0px 0px",

                            },
                            indicatorColor: "black",
                        }}
                        aria-label="secondary tabs example"
                    >
                        <Tab value={secHead} label={secHead} />

                    </Tabs>
                </Box>
            </div>
        </>
    )
}

export default CommunityHead
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CustomTable from "../../../../Components/CustomTable/CustomTable.jsx";
import { SubAdminTableHeader } from "../../../../data/TableHeaderData";
import { useNavigate } from "react-router-dom";
import { getAllAdmin } from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";

const SubAdminList = () => {
  const [data, setData] = useState([]);
  const[loading , setLoading]= useState(true);
  const navigate = useNavigate();

  const getAllUser = async () => {
    try {
      const res = await getAllAdmin();
      setData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);

    }
  };

  useEffect(() => {
    getAllUser();
  }, []);

  const handleClick = () => {
    navigate("/sub-admin-management/create");
  };
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader
            headerTitle={"Sub Admin Management"}
            headerBtnName={"Create"}
            handleClick={handleClick}
          />

          <CustomTable
            data={data}
            header={SubAdminTableHeader}
            type={"subAdmin"}
          />
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default SubAdminList;

import React from 'react'
import { DashboardBoxStyle } from '../../SideBar/SidepanelStyled'
import TopHeader from '../../../Components/TopHeader/TopHeader'
import { useSelector } from 'react-redux';

const Promotion = () => {
    const { promoPlans } = useSelector((state) => state.company);
  return (
    <DashboardBoxStyle className="scroller" >
        <TopHeader headerTitle={"Promotion Details"} type="back"   />

        <div className="table-container">
            <table className="Table">
              <thead className="table-head">
                <tr>
                  <th>ID</th>
                  <th> Plan Name</th>
                  <th>Plan Id</th>
                  <th>Amount</th>
                  <th>Transaction Id</th>
                  <th>Active</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Add On</th>
                  <th>Cancelled</th>
                </tr>
              </thead>
              <tbody>
                { promoPlans.map((item, index) => (
                  <tr key={item._id} >
                    <td>{index + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.adplanId}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.txnId}</td>
                    <td style={{color:item?.isExpired?`red`:`green`}}>{item?.isExpired?"Expired":"Active"}</td>
                    <td>{item?.date.slice(0,10)}</td>
                    <td>{item?.endDate.slice(0,10)}</td>
                    <td>{item?.isAddOn?"true":"false"}</td>
                    <td>{item?.isCancelled?"true":"false"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>


    </DashboardBoxStyle>
  )
}

export default Promotion
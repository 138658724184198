import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { Col, Row } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import ProductGeneral from "./ProductPage/EditProduct/ProductGeneral.js";
import Inventory from "./ProductPage/EditProduct/Inventory.js";
import LinkedProducts from "./ProductPage/EditProduct/LinkedProducts.js";
import ProductAttribute from "./ProductPage/EditProduct/ProductAttribute.js";
import DownloadableFiles from "./ProductPage/EditProduct/DownloadableFiles.js";
import { GetAdminProductById } from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllState,
  setAttribute,
  setDownloadable,
  setGeneral,
  setInventory,
  setIsVirtual,
  setLinkedProduct,
  setProductInventory,
} from "../../../../app/features/adminProducts/productSlice";

const EditProduct = () => {
  const { id } = useParams();
  // console.log(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);

  const getProductDetails = async () => {
    try {
      setLoader(true);
      const response = await GetAdminProductById(id);
      dispatch(setIsVirtual(response?.data?.message?.isVirtual));
      dispatch(setGeneral(response?.data?.message?.general));
      dispatch(setProductInventory(response?.data?.message?.inventory));
      dispatch(setLinkedProduct(response?.data?.message?.linkedProduct));
      dispatch(setAttribute(response?.data?.message?.attribute));
      dispatch(setDownloadable(response?.data?.message?.downloadable));

      setLoader(false);
      console.log(response?.data?.message);
    } catch (error) {
      console.log(error);
    }
  };

  const [searchParams] = useSearchParams();
  const [productDet, setProductDet] = useState(null);
  useEffect(() => {
    getProductDetails();
  }, []);

  useEffect(() => {
    console.log(productDet);
  }, [productDet]);

  const [activeProduct, setActiveProduct] = useState({
    formHead: "General",
    formComp: <ProductGeneral />,
  });

  const prodType = useSelector((state) => state.product.isVirtual);

  const [productForm, setProductForm] = useState([
    {
      formHead: "General",
      tabLink: "general",
    },
    {
      formHead: "Inventory",
      tabLink: "inventory",
    },
    {
      formHead: "Linked Products",
      tabLink: "linked",
    },
    {
      formHead: "Attributes",
      tabLink: "attributes",
    },
  ]);

  useEffect(() => {
    if (prodType === true) {
      setProductForm([
        ...productForm,
        {
          formHead: "Downloadable files",
          tabLink: "downloadable",
        },
      ]);
    } else {
      setProductForm(
        productForm.filter((e) => e.formHead !== "Downloadable files")
      );
    }
  }, [prodType]);

  useEffect(() => {
    switch (searchParams.get("tab")) {
      case "general":
        setActiveProduct({ formHead: "General", formComp: <ProductGeneral /> });
        break;
      case "inventory":
        setActiveProduct({ formHead: "Inventory", formComp: <Inventory /> });
        break;
      case "linked":
        setActiveProduct({
          formHead: "Linked Products",
          formComp: <LinkedProducts />,
        });
        break;
      case "attributes":
        setActiveProduct({
          formHead: "Attributes",
          formComp: <ProductAttribute />,
        });
        break;
      case "downloadable":
        setActiveProduct({
          formHead: "Downloadable files",
          formComp: <DownloadableFiles />,
        });
        break;
      default:
        setActiveProduct({ formHead: "General", formComp: <ProductGeneral /> });
    }
  }, [searchParams.get("tab")]);

  const handleClose = () => {
    navigate("/product");
    dispatch(clearAllState());
  };

  const handleActiveProduct = (e) => {
    navigate(`?tab=${e.tabLink}`);
  };
  return (
    <>

      {loader ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="product-form-container position-relative p-0 mt-4">
            <div className="d-flex">
              <div className="companyProd-left-side ">
                <div className="form-top">
                  <h5>Add Product</h5>
                </div>
                <hr className="m-0" />
                <div className="form-menu-tab">
                  {productForm.map((e) => (
                    <div
                      onClick={() => handleActiveProduct(e)}
                      className={` ${activeProduct.formHead === e.formHead
                          ? "menu-tab-active"
                          : ""
                        } d-flex align-items-center py-2 my-2 menu-tab`}
                    >
                      <h6>{e.formHead}</h6>
                    </div>
                  ))}
                </div>
              </div>

              <div className="companyProd-right-side p-4">
                <h5>{activeProduct.formHead}</h5>
                <Row className="mt-4">
                  {/* <Col lg={9}>
                                <div className="d-flex justify-content-between half-input-box">
                                    <div className='d-flex align-item-center'>
                                        <span className='m-0'>Physical Product :</span>
                                        <input className='ms-3 product-checkbox' name='type' value="Physical" checked={prodType === "Physical" && "checked"} onChange={handleChange} type="radio" />
                                    </div>
                                    <div className='d-flex align-item-center'>
                                        <span className='m-0'>Virtual Product :</span>
                                        <input className='ms-3 product-checkbox' name='type' value="Virtual" checked={prodType === "Virtual" && "checked"} onChange={handleChange} type="radio" />
                                    </div>
                                </div>
                            </Col> */}
                  <Col
                    lg={12}
                    className="d-flex flex-column justify-content-between"
                  >
                    {activeProduct.formComp}
                  </Col>
                </Row>
              </div>

              <div onClick={handleClose} className="close-job cross-sign-pos">
                <RxCross2 />
              </div>
            </div>
          </div>
        </DashboardBoxStyle>
      )}



    </>

  );
};

export default EditProduct;

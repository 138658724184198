import React, { useState } from "react";

import logo from "../../assets/logoFull.png";
import Banner from "../../assets/loginBanner.svg";
import { loginAdmin } from "../../util/Auth/APIs";
import "./Login.css";
import { LoadingButton } from "../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { inputLabelClasses, TextField } from "@mui/material";

const initialData = {
  email: "",
  password: "",
};

const LoginPage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState(initialData);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    let access = [];
    if (formData.email && formData.password) {
      try {
        const loginInfo = {
          type: "admin",
          email: formData.email,
          password: formData.password,
        };
        console.log("log..");
        const res = await loginAdmin(loginInfo);

        console.log(res?.data);
        if (res?.data?.success) {
          localStorage.setItem("accessToken", res?.data?.message?.token);
          localStorage.setItem(
            "accessType",
            res?.data?.message?.data?.usageType
          );
          localStorage.setItem("role", res?.data?.message?.data?.role);
          toast.success("Login successful");
          setLoading(false);
          window.location.replace("/dashboard");
        }
      } catch (error) {
        console.log(error);
        toast.error("Invalid Credentials");
        setLoading(false);
      }
    } else {
      toast.error("Both fields required");
    }
  };

  const borderStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#acb5bd",
      },
      "&:hover fieldset": {
        borderColor: "#acb5bd",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: "#4eb7bd",
      },
    },
  };

  const labelInput = {
    sx: {
      color: "#acb5bd",
      [`&.${inputLabelClasses.focused}`]: {
        color: "#4eb7bd",
      },
    },
  };

  return (
    <div className="loginPage-container">
      <div className="left">
        <img src={logo} alt="logo" />
        <img src={Banner} alt="loginBanner" />
      </div>
      <div className="right">
        <div className="loginPage-formHeaderDiv">
          <div className="loginPage-headerContent">
            <h3 className="loginPage-heading">Welcome to Admin Login </h3>
            <p className="loginPage-headerSub">Enter your email and password</p>
          </div>
        </div>
        <form onSubmit={handleLogin} className="loginPage-formContent">
          <TextField
            label="Enter Your Email"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant="outlined"
            InputLabelProps={labelInput}
            sx={borderStyle}
            className="loginPage-inputField"
          />

          <TextField
            label="Enter Your Password"
            type="password"
            name="password"
            onChange={handleChange}
            value={formData.password}
            variant="outlined"
            InputLabelProps={labelInput}
            sx={borderStyle}
            className="loginPage-inputField"
          />

          <div className="loginPage-submitBtnDiv">
            <button className="loginPage-submitBtn" type="submit">
              {loading ? <LoadingButton /> : "Login"}
            </button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default LoginPage;

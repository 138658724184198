import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CustomTable from "../../../../Components/CustomTable/CustomTable";
import { useNavigate } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Alert from "react-bootstrap/Alert";
import "./Guideline.css";
import { AllGuidlines, removeGuidline } from "../../../../util/Auth/APIs";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import DOMPurify from 'dompurify';


function Guideline({data,setData}) {
  const [option, setOption] = useState(false);

  const navigate = useNavigate();

  const toggleOption = () => {
    setOption(!option);
  };

  

  const guidelineHtml = data?.guideline;
  const sanitizedHtml = DOMPurify.sanitize(guidelineHtml);


  const removeItem = async(id) => {
    setOption(false);

    try {
      const res = await removeGuidline(id);
      toast.success("item removed successfully!");
      setData((prevData) => prevData.filter((item) => item._id !== id));
      
    } catch (error) {
      toast.error("Something went wrong!")
    }
    

  }


  return (
    <>
      <div className="guideline-container">
        <div className="Guideline-menu">
          <MoreVertIcon onClick={toggleOption} />
        </div>

        {option && (
          <ul className="options-menu">
            <li onClick={() => navigate(`/guidelines/edit/${data?._id}`)}>Edit</li>
            <li onClick={()=>removeItem(data?._id)}>Delete</li>
          </ul>
        )}

        <Alert key={data._id} variant={"secondary"}>
          <h5>{data?.name}</h5>
          <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />
        </Alert>
      </div>
    </>
  );
}

const GuidelineList = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getAllGuidlines = async () => {
    try {
      const res = await AllGuidlines();
      setData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllGuidlines();
  }, []);
  useEffect(() => {}, [data]);

  const handleClick = () => {
    navigate("/guidelines/create");
  };
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <DashboardBoxStyle className=" scroller">
            <TopHeader
              headerTitle={"Community Guideline Management"}
              handleClick={handleClick}
              headerBtnName={"Create"}
            />

            {data.length>0 && data.map((item) => {
              return <Guideline data={item} setData={setData} />;
            })}
          </DashboardBoxStyle>
        </>
      )}
    </>
  );
};

export default GuidelineList;

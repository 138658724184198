import axios from "axios";


// const API = axios.create({
//   baseURL: "https://api.glocalcarex.com/",
// });

const API = axios.create({
  baseURL: "https://glocal-api.onrender.com",
});


API.interceptors.request.use((req) => {

  if (localStorage.getItem("accessToken")) {
    req.headers["x-access-token"] = localStorage.getItem("accessToken")
    req.headers["Content-Type"] = "application/json"
  }
  return req;
});


// APIs for authentication
export const loginAdmin = (formData) => API.post("/admin/auth/login", formData);

// API for Companies Section
export const AllCompanyDetails = ({ page, limit, search, category, month }) => API.get(`/admin/views/getCompanyDetials?page=${page}&limit=${limit}&search=${search}&category=${category}&date=${month}`);
export const CompanyDetailsById = (id) => API.get(`/admin/views/getCompanyDetials/${id}`);
export const getProfuctDetailsById = (id) => API.get(`/admin/views/getProductDetails/${id}`);
export const updateCompany = ({ id, data }) => API.patch(`/admin/views/getCompanyDetials/${id}`, data);


// API for users section
export const getAllUsers = ({ page, limit, search, category, month }) => API.get(`/admin/views/getUserDetials?page=${page}&limit=${limit}&search=${search}&category=${category}&date=${month}`);
export const getUserDetialsById = (id) => API.get(`/admin/views/getUserDetials/${id}`);
export const updateUser = ({ id, data }) => API.patch(`/admin/views/getUserDetials/${id}`, data);

//API  for admin products
export const getAllAdminProducts = ({ page, limit, search, category, month }) => API.get(`/admin/views/adminProduct/products?page=${page}&limit=${limit}&search=${search}&category=${category}&date=${month}`);
export const DeleteAdminProduct = (id) => API.delete(`/admin/views/adminProduct/product/${id}`);
export const GetAdminProductById = (id) => API.get(`/admin/views/adminProduct/product/${id}`);
export const UpdateProduct = ({ id, data }) => API.post(`/admin/views/adminProduct/product/update/${id}`, data);
export const CreateProduct = (data) => API.post("/admin/views/adminProduct/product/create", data);




//APIs for admin plans

export const getAllPlans = ({ page, limit, search, month }) => API.get(`/plans/plan?page=${page}&limit=${limit}&search=${search}&date=${month}`);
export const getAddOnPlans = ({ page, limit, search, month }) => API.get(`plans/plan/addon/all?page=${page}&limit=${limit}&search=${search}&date=${month}`);
export const createPlan = (data) => API.post("/plans/plan", data);
export const createAddOnPlan = (data) => API.post("/plans/plan/addon/create", data);
// export const getPlanByCategory = (category) => API.get(`/plans/plan?category=${category}`);
export const getPlanById = (id) => API.get(`/plans/plan/${id}`);
export const deletePlan = (id) => API.delete(`/plans/plan/${id}`);
export const updatePlan = ({ id, data }) => API.patch(`/plans/plan/${id}`, data);


// APIs for transactions

export const getAllTransactions = ({ page, limit, search, month }) => API.get(`/admin/views/companyPayment?page=${page}&limit=${limit}&search=${search}&date=${month}`);


// APIs for  community section

export const getAllCommunities = ({ page, limit, search, category, month }) => API.get(`/com/community/?page=${page}&limit=${limit}&search=${search}&category=${category}&date=${month}`);
export const createCommunity = (data) => API.post("/com/community/", data);
export const getCommunityById = (id) => API.get(`/com/community/${id}`);
export const deleteCommunity = (id) => API.delete(`/com/community/${id}`);
export const updateCommunityDetails = ({ id, data }) => API.patch(`/com/community/${id}`, data);
export const getAllComMembers = (id) => API.get(`/com/community/single/members?id=${id}`);




// API for Category Section

export const getAllCategories = () => API.get("/category/");
export const getSingleCategory = (id) => API.get(`/category/${id}`);
export const createCategory = (data) => API.post("/category/", data);
export const removeCategory = (id) => API.delete(`/category/${id}`);
export const updateCategory = ({ id, data }) => API.patch(`/category/${id}`, data);
export const addSubCategory = (payload) => API.post("/category/subcategory", payload);
export const editSubCategory = (payload) => API.post("/category/subcategory/edit", payload);
export const removeSubCategory = (payload) => API.post(`/category/subcategory/remove`, payload);



// APIs for Banner Section


export const getAllBanner = () => API.get("/banner/");
export const bannerUsage = ({ id, page, limit, search, month }) => API.get(`/banner/usage/${id}?page=${page}&limit=${limit}&search=${search}&date=${month}`);
export const getBannerById = (id) => API.get(`/banner/${id}`);
export const createBanner = (data) => API.post("/banner/", data);
export const removeBanner = (id) => API.delete(`/banner/${id}`);
export const updateBanner = ({ id, data }) => API.patch(`/banner/${id}`, data);


// APIs for Insights Section


// API for admin blogs

export const getAllBlogs = () => API.get("/admin/views/adminBlog/blogs/");
export const DeleteBlog = (id) => API.delete(`/admin/views/adminBlog/blog/${id}`);
export const GetBlogById = (id) => API.get(`/admin/views/adminBlog/blog/${id}`);
export const UpdateBlog = ({ id, data }) => API.post(`/admin/views/adminBlog/blog/update/${id}`, data);
export const CreateBlog = (data) => API.post("/admin/views/adminBlog/blog/create", data);


// Podcasts

export const createPodcast = (data) => API.post("/admin/views/adminPodcast/podcast/create", data);
export const getAllPodcast = () => API.get("/admin/views/adminPodcast/podcasts/");
export const getPodcastById = (id) => API.get(`/admin/views/adminPodcast/podcast/${id}`);
export const updatePodcastById = ({ id, data }) => API.post(`/admin/views/adminPodcast/podcast/update/${id}`, data);
export const removePodcast = (id) => API.delete(`/admin/views/adminPodcast/podcast/${id}`);


// White Paper


export const createMagzine = (data) => API.post("/admin/views/adminWhitepaper/whitepaper/create", data);
export const getAllMagzine = () => API.get("/admin/views/adminWhitepaper/whitepapers");
export const getMagzineById = (id) => API.get(`/admin/views/adminWhitepaper/whitepaper/${id}`);
export const updateMagzine = ({ id, data }) => API.post(`/admin/views/adminWhitepaper/whitepaper/update/${id}`, data);
export const removeMagzine = (id) => API.delete(`/admin/views/adminWhitepaper/whitepaper/${id}`);

export const getAllInsights = () => API.get("/admin/views/insights");


// Promotions Plan



export const createPromotion = (data) => API.post("/plans/ad/", data);
export const getPromotionByCategory = ({ page, limit, search, category, month }) => API.get(`/plans/ad?page=${page}&limit=${limit}&search=${search}&category=${category}&date=${month}`);
export const getPromotionById = (id) => API.get(`/plans/ad/${id}`);
export const deletePromotion = (id) => API.delete(`/plans/ad/${id}`);
export const editPromotion = ({ id, data }) => API.patch(`/plans/ad/${id}`, data);

// Category 
export const AllCategoryLevel2 = (id) => API.get(`/category/cat2?category=${id}`);
export const AllCategoryLevel3 = (id) => API.get(`/category/cat3?category=${id}`);
export const AllCategoryLevel4 = (id) => API.get(`/category/cat4?category=${id}`);

export const AllCategoryForLevel = (id, level) => API.get(`/category/cat${level}?category=${id}`);

// Add Category 
export const AddCategoryLevel2 = (data) => API.post("/category/cat2", data);
export const AddCategoryLevel3 = (data) => API.post("/category/cat3", data);
export const AddCategoryLevel4 = (data) => API.post("/category/cat4", data);

export const AddCategoryForAll = ({ id, data }) => API.post(`/category/cat${id}`, data);

export const AllSubCategoryList = ({ id, level }) => API.get(`/category/cat${level}?category=${id}`);



// Support Management

export const GetAllSupport = () => API.get(`/sup/support/`);
export const UpdateSupportStatus = ({ id, data }) => API.get(`/sup/support/${id}`, data);

// Sub Admin Management

export const getAllAdmin = () => API.get(`/subadmin/subadmin/`);
export const createNewAdmin = (data) => API.post("/subadmin/subadmin/", data);
export const getAdminById = (id) => API.get(`/subadmin/subadmin/${id}`);
export const deleteAdminById = (id) => API.delete(`/subadmin/subadmin/${id}`);
export const updateAdminById = ({ id, data }) => API.patch(`/subadmin/subadmin/${id}`, data);

// Guideline section


export const AllGuidlines = () => API.get(`/com/community/guidelines/all`);
export const getSingleGuidline = (id) => API.get(`/com/community/guideline/${id}`);
export const removeGuidline = (id) => API.delete(`/com/community/guideline/${id}`);
export const updateGuidline = ({ id, data }) => API.patch(`/com/community/guideline/${id}`, data);
export const postGuidline = (data) => API.post(`/com/community/guideline/add`, data);


// Payout Section

export const AllPayOut = ({ page, limit, search, status, month }) => API.get(`/common/booking/allpayouts?page=${page}&limit=${limit}&search=${search}&status=${status}&date=${month}`);
export const UpdatePayOut = ({ id, data }) => API.post(`/common/booking/updatePayout/${id}`, data);

// support type

export const getAllSupportTypes = () => API.get(`/sup/support/category/all`);
export const createSupportType = (data) => API.post("/sup/support/category/", data);
export const updateSupportType = ({ id, data }) => API.patch(`/sup/support/category/${id}`, data);
export const deleteSupportType = (id) => API.delete(`/sup/support/category/${id}`);


// Events

// Company Profile - Events
export const CreateEvent = (payload) => API.post("/admin/views/event/create", payload);
export const AllEvents = ({ page, limit, search, month }) => API.get(`/admin/views/event?page=${page}&limit=${limit}&search=${search}&date=${month}`);
export const SingleEvent = (id) => API.get(`/admin/views/event/${id}`);
export const UpdateEvent = ({ id, data }) => API.post(`/admin/views/event/update/${id}`, data);
export const DeleteEvent = (id) => API.delete(`/admin/views/event/${id}`);

// Events -> FAQ
export const CreateEventFAQ = (payload) => API.post("/admin/views/event/faq/create", payload);
export const AllEventFAQ = (id) => API.get(`admin/views/event/faq/all/${id}`);
export const SingleEventFAQ = (id) => API.get(`/admin/views/event/faq/${id}`);
export const UpdateEventFAQ = ({ id, data }) => API.post(`/admin/views/event/faq/update/${id}`, data);
export const DeleteEventFAQ = (id) => API.delete(`/admin/views/event/faq/${id}`);

// Events -> Speakers
export const CreateEventSpeaker = (payload) => API.post("/admin/views/event/speaker/create", payload);
export const AllEventSpeaker = (id) => API.get(`/admin/views/event/speaker/all/${id}`);
export const SingleEventSpeaker = (id) => API.get(`/admin/views/event/speaker/${id}`);
export const UpdateEventSpeaker = ({ id, data }) => API.post(`/admin/views/event/speaker/update/${id}`, data);
export const DeleteEventSpeaker = (id) => API.delete(`/admin/views/event/speaker/${id}`);

// Events -> Agenda
export const CreateEventAgenda = (payload) => API.post("/admin/views/event/agenda/create", payload);
export const AllEventAgenda = (id) => API.get(`/admin/views/event/agenda/all/${id}`);
export const SingleEventAgenda = (id) => API.get(`/admin/views/event/agenda/${id}`);
export const UpdateEventAgenda = ({ id, data }) => API.post(`/admin/views/event/agenda/update/${id}`, data);
export const DeleteEventAgenda = (id) => API.delete(`/admin/views/event/agenda/${id}`);

// Events -> Ticket
export const CreateEventTicket = (payload) => API.post("/admin/views/event/ticket/create", payload);
export const AllEventTicket = (id) => API.get(`/admin/views/event/ticket/all/${id}`);
export const SingleEventTicket = (id) => API.get(`/admin/views/event/ticket/${id}`);
export const UpdateEventTicket = ({ id, data }) => API.post(`/admin/views/event/ticket/update/${id}`, data);
export const DeleteEventTicket = (id) => API.delete(`/admin/views/event/ticket/${id}`);

// Events -> Social
export const CreateEventSocial = (payload) => API.post("/admin/views/event/social/create", payload);
export const AllEventSocial = (id) => API.get(`/admin/views/event/social/all/${id}`);
export const SingleEventSocial = (id) => API.get(`/admin/views/event/social/${id}`);
export const UpdateEventSocial = ({ id, data }) => API.post(`/admin/views/event/social/update/${id}`, data);
export const DeleteEventSocial = (id) => API.delete(`/admin/views/event/social/${id}`);

// Events -> Sponsors
export const CreateEventSponsor = (payload) => API.post("/admin/views/event/sponsor/create", payload);
export const AllEventSponsor = (id) => API.get(`/admin/views/event/sponsor/all/${id}`);
export const SingleEventSponsor = (id) => API.get(`/admin/views/event/sponsor/${id}`);
export const UpdateEventSponsor = ({ id, data }) => API.post(`/admin/views/event/sponsor/update/${id}`, data);
export const DeleteEventSponsor = (id) => API.delete(`/admin/views/event/sponsor/${id}`);


// Event ticket's apis

export const EventBooking = (id) => API.get(`/common/booking/mybookings?event=${id}`);
export const CancelBooking = (data) => API.post(`/pay/payment/cancelTicket`, data);


// Dashboard

export const getDashboardDetails = () => API.get(`/admin/views/getUserDetials/dashboard`);

// discount
export const postDiscount = (payload) => API.post(`/coupon/`, payload);
export const getDiscount = ({ page, limit, search, month }) => API.get(`/coupon/?page=${page}&limit=${limit}&search=${search}&date=${month}`);
export const getDiscountById = (id) => API.get(`coupon/${id}`);
export const deleteDiscount = (id) => API.delete(`/coupon/${id}`);

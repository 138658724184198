import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  communityDetail: {},
  eventDetails:{},
  eventId:"",
};

export const communitySlice = createSlice({
  name: "community",
  initialState,
  reducers: {
    default: (state) => {
      return {
        ...state,
      };
    },

    setCommunityDetail: (state, value) => {
      state.communityDetail = value.payload;
    },
    eventChange: (state, action) => {
      const { name, payload } = action.payload;
      state.eventDetails = { ...state.eventDetails, [name]: payload };
    },
    eventClear:(state) => {
      state.eventDetails={};
    },
    eventId:(state,value)=>{
      state.eventId = value.payload;
    }
  },
});

export const { setCommunityDetail,eventChange ,eventClear,eventId} = communitySlice.actions;

export default communitySlice.reducer;

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "react-select";
import {
  getCountry,
  getState,
  labelSelect,
} from "../../../../util/Healper/globalDetails.js";
import { useEffect } from "react";
// import { CreateEvent, UpdateEvent } from '../../../../axios/apis'
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  eventChange,
  eventId,
} from "../../../../app/features/Community/communitySlice.js";
import { CreateEvent, UpdateEvent } from "../../../../util/Auth/APIs.js";

const OfflineEvent = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const EventID = useSelector((state) => state.community.eventId);
  const sampleEvent = useSelector((state) => state.community.eventDetails);

  const [submitloader, setSubmitLoader] = useState(false);

  const [allcountry, setCountryAll] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const [countryVal, setCountryVal] = useState({ name: "", label: "" });

  const [venueDetails, setVenueDetails] = useState({
    country: sampleEvent?.venue?.country || "",
    state: sampleEvent?.venue?.state || "",
    address: sampleEvent?.venue?.address || "",
    postCode: sampleEvent?.venue?.postCode || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "postCode") {
      setVenueDetails({ ...venueDetails, [name]: parseInt(value) });
    } else {
      setVenueDetails({ ...venueDetails, [name]: value });
    }
  };

  console.log(venueDetails);

  const handleNext = () => {
    navigate("?tab=faq");
  };

  const getCountryTime = async () => {
    try {
      const res = await getCountry();
      setCountryAll(res);
      setCountryList(labelSelect(res));
      if (venueDetails?.country) {
        const resCity = await getState(venueDetails?.country);
        setStateList(labelSelect(resCity));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCountry = async (e) => {
    if (e !== null) {
      setVenueDetails({ ...venueDetails, country: e.value });
    } else {
      setVenueDetails({ ...venueDetails, country: e });
      console.log("Hiii");
    }
    try {
      const resCity = await getState(e.value);
      setStateList(labelSelect(resCity));
    } catch (error) {
      console.log(error);
    }
  };

  const handleState = (e) => {
    if (e !== null) {
      setVenueDetails({ ...venueDetails, state: e.value });
    } else {
      setVenueDetails({ ...venueDetails, state: e });
      console.log("Hiii");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const payload = {
      basicInfo: sampleEvent.basicInfo,
      venue: venueDetails,
      virtualDetail: {},
    };

    if (!EventID) {
      try {
        const res = await CreateEvent(payload);
        toast.success("Event Created Successfully");
        dispatch(eventId(res.data.message._id));
        setSubmitLoader(false);
        console.log(res);
      } catch (error) {
        toast.error("Event Creation Failed");
        setSubmitLoader(false);
      }
    } else {
      try {
        const res = await UpdateEvent({ id: EventID, data: payload });
        toast.success("Event Updated Successfully");
        setSubmitLoader(false);
        console.log(res);
      } catch (error) {
        toast.error("Event Updation Failed");
        setSubmitLoader(false);
      }
    }
  };

  useEffect(() => {
    getCountryTime();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventChange({ name: "venue", payload: venueDetails }));
    }, 300);
  }, [venueDetails]);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Venue</h5>

        <form onSubmit={handleSave}>
          <Row className="mt-4">
            <Col lg={12} className="d-flex flex-column justify-content-between">
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Country</label>
                    <Select
                      options={countryList}
                      placeholder="Select Country"
                      value={{
                        value: venueDetails?.country,
                        label: venueDetails?.country,
                      }}
                      className="company-select"
                      isSearchable={true}
                      isClearable={true}
                      onChange={handleCountry}
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>State/City</label>
                    <Select
                      options={stateList}
                      classNamePrefix="select country"
                      placeholder="Select Country"
                      value={{
                        value: venueDetails?.state || "",
                        label: venueDetails?.state || "",
                      }}
                      className="company-select"
                      isSearchable={true}
                      isClearable={true}
                      onChange={handleState}
                    />
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Address</label>
                    <div className="profile-input-field">
                      <textarea
                        value={venueDetails?.address}
                        className="description-textarea"
                        onChange={handleChange}
                        name="address"
                        id=""
                        cols="30"
                        rows=""
                        placeholder=""
                      ></textarea>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Postcode</label>
                    <div className="profile-input-field">
                      <input
                        value={venueDetails?.postCode}
                        onChange={handleChange}
                        type="number"
                        name="postCode"
                        placeholder="Postcode"
                        id=""
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            {type === "edit" && (
              <div className="d-flex justify-content-between mt-5">
                <div className="form-width-btn">
                  <button className="save-draft">
                    {submitloader ? (
                      <div
                        className="spinner-border theme-colour ml-4"
                        role="status"
                      ></div>
                    ) : (
                      <> Save</>
                    )}
                  </button>
                </div>
                <div onClick={handleNext} className="form-width-btn">
                  <button type="button" className="form-action-next">
                    Next
                  </button>
                </div>
              </div>
            )}
          </Row>
        </form>
      </div>
    </>
  );
};

export default OfflineEvent;

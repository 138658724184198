import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DashboardBoxStyle, Input } from "../../../../SideBar/SidepanelStyled";
import ReactQuill from "react-quill";
import { AiFillPlusCircle, AiOutlineLeft } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { createAddOnPlan, createPlan } from "../../../../util/Auth/APIs";
import { RiArrowDropDownLine } from "react-icons/ri";
import { TextField, inputLabelClasses } from "@mui/material";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import Select from 'react-select'

const options = [
  { value: 'Product', label: 'Products' },
  { value: 'Deals', label: 'Deals' },
  { value: 'Communities', label: 'Communities' },
  { value: 'Jobs', label: 'Jobs' },
  { value: 'MediaCoverages', label: 'Media Coverages' },
  { value: 'MediaGallery', label: 'Media Gallery' },
  { value: 'Blogs', label: 'Blogs' },
]

const borderStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#acb5bd",
      },
      "&:hover fieldset": {
        borderColor: "#acb5bd",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: "#4eb7bd",
      },
    },
  };

   const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

   const labelInput = {
    sx: {
      color: "#acb5bd",
      [`&.${inputLabelClasses.focused}`]: {
        color: "#4eb7bd",
      },
    },
  };
const CreatePlan = () => {
  const navigate = useNavigate();
  const [planFor, setPlanFor] = useState("");
  const [planValue, setPlanValue]=useState("");
  const [submitloader, setSubmitLoader] = useState(false);

  const [forms, setForms] = useState([
    {
      id: 1,
      category: planFor,
      name: "",
      monthPrice: null,
      yearPrice: null,
      addOn: null,
    },
  ]);
  const [nextFormId, setNextFormId] = useState(2);

  const handleAddForm = () => {
    if (forms.length >= 3) {
      toast.error("You can only add 3 Plans at a time");
      return;
    }
    const newForm = {
      id: nextFormId,
      category: planFor,
      name: "",
      monthPrice: null,
      yearPrice: null,
      addOn: null,
    };
    setForms([...forms, newForm]);
    setNextFormId(nextFormId + 1);
  };

  // const handleFormChange = (index, field, value) => {
  //   const updatedForms = forms.map((form, i) => {
  //     if (i === index) {
  //       return { ...form, [field]: value, category: planFor };
  //     }
  //     return form;
  //   });
  //   setForms(updatedForms);
  // };
  const handleFormChange = (index, field, value) => {
    let parsedValue = value; // Store the value as it is if it's not a number

    // Convert the value to a number if it's a valid number
    if (!isNaN(value)) {
      parsedValue = parseFloat(value);
    }

    const updatedForms = forms.map((form, i) => {
      if (i === index) {
        return { ...form, [field]: parsedValue, category: planFor };
      }
      return form;
    });

    setForms(updatedForms);
  };

  console.log(forms);

  const handleChange = (e) => {
    const { value } = e.target;
    setPlanFor(value);
  };
  useEffect(() => {
    const updatedForms = forms.map((form) => ({
      ...form,
      category: planFor,
    }));
    setForms(updatedForms);
  }, [planFor]);

  // console.log(planFor);

 
  const handleSubmit = async () => {
    setSubmitLoader(true);

    const isAnyFormEmpty = forms.some((form) => {
      return (
        form.name.trim() === "" ||
        form.monthPrice === null ||
        form.yearPrice === null ||
        form.category === "" ||
        form.addOn === null
      );
    });
    if (isAnyFormEmpty) {
      // Handle the case where there are empty fields
      toast.error("Please fill all the fields");
      setSubmitLoader(false);
      return;
    }
    const data = { plans: forms };
    // console.log(data);
    try {
      const response = await createAddOnPlan(data);
      toast.success("plan info added in the list", {
        onClose: () => navigate("/plan-management/all"),
      });
      setSubmitLoader(false);
      navigate(-1);
      console.log(response);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };
  function handleIconClick() {
    document.querySelector(".select-input-all").focus();
  }

  const handleSelect = (e) => {
    setPlanFor(e.value);
    setPlanValue(e);

  }
  

 

  return (
    <>
      <DashboardBoxStyle className=" scroller">
        <div className="d-flex justify-content-between align-items-center">
        <TopHeader headerTitle={"Create Add-on Plan"} type={"back"}/>
        <Select options={options} value={planValue} placeholder={"Select Plan Type"}  onChange={handleSelect} />
          
        </div>

        <div className="multiple-plans-contianer">
          {forms.map((form, index) => (
            <div className="plan-form-contianer" key={form.id}>
              <TextField
                label="Plan Name"
                type="text"
                value={form.name}
                onChange={(e) => {
                  handleFormChange(index, "name", e.target.value);
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Monthly Price"
                type="number"
                value={form.monthPrice}
                onChange={(e) =>
                  handleFormChange(index, "monthPrice", e.target.value)
                }
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Yearly Price"
                type="number"
                value={form.yearPrice}
                onChange={(e) =>
                  handleFormChange(index, "yearPrice", e.target.value)
                }
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Choose the Plan Type"
                type="text"
                value={planFor}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Add on Value"
                type="number"
                value={form.addOn}
                onChange={(e) =>
                  handleFormChange(index, "addOn", e.target.value)
                }
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
            </div>
          ))}
        </div>
        <div className="plan-plus-icon">
          <div className="addIcon" onClick={handleAddForm}>
            <AiFillPlusCircle />
          </div>
        </div>

        <div
          className="listing-addnew-btn plan-submit-btn "
          id="mb-20"
          onClick={handleSubmit}
        >
          <button>
            {submitloader ? (
              <div
                className="spinner-border text-white ml-4"
                role="status"
              ></div>
            ) : (
              <> Create Plan</>
            )}
          </button>
        </div>

        <ToastContainer />
      </DashboardBoxStyle>
    </>
  );
};

export default CreatePlan;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Table.css";
import { toast } from "react-toastify";
import { Switch } from "@mui/material";
import { updateCompany } from "../../util/Auth/APIs";
import { LoadingPage } from "../../Components/Loading/Loading";
import LoaderDot from "../../Components/Common/LoaderDot";

function TableRow({ item, index }) {

  const [statusLoad, setStatusLoader] = useState(false)

  const navigate = useNavigate();
  const [activeStatus, setActiveStatus] = useState(item.isActive);

  const handleRowClick = (id) => {
    navigate(`/company/${id}/profile`);
  };

  const handleToggle = async (id) => {
    setStatusLoader(true)
    try {
      const data = {
        isActive: !activeStatus,
      };

      const res = await updateCompany({ id, data });
      setActiveStatus(!activeStatus);
      if (activeStatus !== true) {
        toast.success(`${item.companyName.slice(0, 30)} unblocked successfully!`);
      } else {
        toast.success(`${item.companyName.slice(0, 30)} blocked successfully!`);
      }
      setStatusLoader(false)
    } catch (error) {
      toast.error(error.message);
      setStatusLoader(false)
    }
  };
  const handleSwitchClick = (e) => {

    e.stopPropagation();
    handleToggle(item.id);
  };

  return (
    <>
      <tr key={item.id} onClick={() => handleRowClick(item.id)}>
        <td>{index + 1}</td>
        <td>{item.companyName.slice(0, 30)}</td>
        <td>{item.companyDescription.slice(0, 30)}..</td>
        <td>{item.country}</td>
        <td>{item.address.slice(0, 20)}..</td>
        <td>{item.state.slice(0, 15)}</td>
        {
          statusLoad ? <td><LoaderDot /> </td> : <td onClick={handleSwitchClick}>
            <Switch
              checked={activeStatus}
              inputProps={{ "aria-label": "controlled" }}

            />
          </td>
        }
      </tr>
    </>
  );
}

const Table = ({ data }) => {
  return (<>
    <div className="table-container">

      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>ID</th>
            <th>Company Name</th>
            <th>Company Description</th>
            <th>Country</th>
            <th>Address</th>
            <th>State</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableRow item={item} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  </>
  );
};

export default Table;

import React, { useEffect, useState } from "react";
import "./CustomButton.css";
import { LoadingButton } from "../../Loading/Loading";

const CustomButton = ({ children, classId, handleClick, loading }) => {
    
  return (
    <button
      onClick={handleClick}
      type="submit"
      className={`customButton ${classId}`}
      disabled={loading} 
    >
      {loading===true ? <LoadingButton /> : children}
    </button>
  );
};

export default CustomButton;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";
import deleteImg from "../../assets/delete.png";
import editImg from "../../assets/edit.png";
import { toast, ToastContainer } from "react-toastify";

import "./Table.css";
import { removeBanner } from "../../util/Auth/APIs";

const ProductTable = ({ data }) => {
  const type = localStorage.getItem("accessType");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [bannerList, setBannerList] = useState(data);

  const navigate = useNavigate();

  useEffect(() => {}, [data.length]);

  const removeItem = async (id) => {
    setDeleteLoader(true);
    setDeletingItemId(id);

    try {
      const res = await removeBanner(id);
      setDeleteLoader(false);
      toast.success("Community deleted successfully");
      setBannerList(bannerList.filter((item) => item._id !== id)); // update the blogsList state
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteLoader(false);
      console.log(error);
    }
    // console.log(id);
  };

  const handleRowClick = (id) => {
    navigate(`/community/details/${id}`);
  };

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>S.No</th>
            <th>Banner Name</th>
            <th>Created On</th>
            <th>Usage</th>
            <th>Size</th>
        <th>Action</th> 
          </tr>
        </thead>
        <tbody>
          {bannerList.map((item, index) => (
            <tr key={item._id}>
              <td>{index + 1}</td>
              <td>{item?.type.slice(0, 10)}</td>
              <td>{item?.createdAt.slice(0, 10)}</td>
              <td
                style={{ color: "#02a9ac" }}
                onClick={() => navigate(`/banner/usage/${item?._id}`)}
              >
                {item?.usage}
              </td>
              <td>{item?.size}</td>

             
                <td>
                  <div className="d-flex ">
                    <div
                      onClick={() => navigate(`/banner/edit/${item?._id}`)}
                      className="edit"
                    >
                      <img src={editImg} alt="edit" />
                    </div>
                    <div
                      onClick={() => removeItem(item?._id)}
                      className="delete"
                    >
                      {deleteloader && deletingItemId === item?._id ? (
                        <div
                          className="spinner-border  spinner-border-sm"
                          style={{ color: "grey" }}
                          role="status"
                        ></div>
                      ) : (
                        <img src={deleteImg} alt="delete" />
                      )}
                    </div>
                  </div>
                </td>
             
            </tr>
          ))}
        </tbody>
        <ToastContainer />
      </table>
    </div>
  );
};

export default ProductTable;

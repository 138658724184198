import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  allDetails: {},
  companyDetails: {},
  visitUs: {},
  companyGallery: {},
  products: [],
  mediaLibrary: [],
  deals: [],
  blogs: [],
  keyPersonal: [],
  promotions: [],
  leads: [],
  leadAndEquiry:{},
  appointments: [],
  events: [],
  FAQ: [],
  pressAndNews: [],
  basicInfo: {},
  oem_odm: {},
  productionCapacity: {},
  qualityControl: {},
  registrationDetails: {},
  registrationProfile: {},
  tradingCapabilities: {},
  tradingServices: {},
  companyFactory: {},
  certification: [],
  codOfConduct: {},
  companyAppointment: {},
  socialMedia: [],
  subscription:[],
  promoPlans:[],
};

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    default: (state) => {
      return {
        ...state,
      };
    },
    setPressAndNews:(state,value) => {
      state.pressAndNews = [...value.payload];
    },
    setKeyPersonal: (state,value) => {
      state.keyPersonal=[...value.payload];
    },
    setAllDetails: (state, value) => {
      state.allDetails = value.payload;
    },
    setLeadAndEnquiry: (state, value) => {
      state.leadAndEquiry=value.payload;
    },
    setCompanyDetails: (state, value) => {
      state.companyDetails = value.payload;
    },
    setVisitUs: (state, value) => {
      state.visitUs = value.payload;
    },
    setCompanyGallery: (state, value) => {
      state.companyGallery = value.payload;
    },
    setProducts: (state, value) => {
      state.products = [...value.payload];
    },
    setMediaLibrary: (state, value) => {
      state.mediaLibrary = [...value.payload];
    },
    setDeals: (state, value) => {
      state.deals = [...value.payload];
    },
    setBlogs: (state, value) => {
      state.blogs = [...value.payload];
    },
    setPromotions: (state, value) => {
      state.promotions = [...value.payload];
    },
    setLeads: (state, value) => {
      state.leads = [...value.payload];
    },
    setAppointments: (state, value) => {
      state.appointments = [...value.payload];
    },
    setEvents: (state, value) => {
      state.events = [...value.payload];
    },
    setFaqs: (state, value) => {
      state.FAQ = [...value.payload];
    },
    setBasicInfo: (state, value) => {
      state.basicInfo = value.payload;
    },
    setOem: (state, value) => {
      state.oem_odm = value.payload;
    },
    setProductionCapacity: (state, value) => {
      state.productionCapacity = value.payload;
    },
    setQualityControl: (state, value) => {
      state.qualityControl = value.payload;
    },
    setRegistrationDetails: (state, value) => {
      state.registrationDetails = value.payload;
    },
    setRegistrationProfile: (state, value) => {
      state.registrationProfile = value.payload;
    },
    setTradingCapabilities: (state, value) => {
      state.tradingCapabilities = value.payload;
    },
    setTradingServices: (state, value) => {
      state.tradingServices = value.payload;
    },
    setCompanyFactory: (state, value) => {
      state.companyFactory = value.payload;
    },
    setCertification: (state, value) => {
      state.certification = [...value.payload];
    },
    setCodOfConduct: (state, value) => {
      state.codOfConduct = value.payload;
    },
    setSocialMedia: (state, value) => {
      state.socialMedia = [...value.payload];
    },
    setSubscription:(state,value)=>{
      state.subscription = [...value.payload];
    },
    setPromoPlans:(state,value)=>{
      state.promoPlans = [...value.payload];
    }
  },
});

export const {
  setKeyPersonal,
  setPressAndNews,
  setAllDetails,
  setBlogs,
  setCompanyDetails,
  setVisitUs,
  setCompanyGallery,
  setMediaLibrary,
  setProducts,
  setDeals,
  setPromotions,
  setLeads,
  setAppointments,
  setEvents,
  setFaqs,
  setBasicInfo,
  setOem,
  setLeadAndEnquiry,
  setProductionCapacity,
  setQualityControl,
  setRegistrationDetails,
  setRegistrationProfile,
  setTradingCapabilities,
  setTradingServices,
  setCompanyFactory,
  setCertification,
  setCodOfConduct,
  setSocialMedia,
  setSubscription,
  setPromoPlans
} = companySlice.actions;

export default companySlice.reducer;

import { useEffect } from "react";
import { Toaster } from "react-hot-toast";

import {
  BrowserRouter as Router,
  Route,
  useLocation,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Companies from "./Pages/TrendingMovies/TrendingMovies";
import LoginPage from "./Pages/Login/Login";
import UserRoutes from "./Routes/UserRoutes.js";
import ProductRoutes from "./Routes/ProductRoutes.js";
import CompanyRoutes from "./Routes/CompanyRoutes.js";
import CommunityRoutes from "./Routes/CommunityRoutes.js";
import BlogRoutes from "./Routes/BlogRoutes.js";
import { toast, ToastContainer } from "react-toastify";
import PlanManagementRoutes from "./Routes/PlanManagementRoutes.js";
import TransactionsRoutes from "./Routes/TransactionsRoutes.js";
import Dashboard from "./Routes/Pages/Dashboard/Dashboard.js";

import Navbar from "./Components/Navbar/Navbar.js";
import CategoryRoutes from "./Routes/CategoryRoutes.js";
import BannerRoutes from "./Routes/BannerRoutes.js";
import InsightsRoutes from "./Routes/InsightsRoutes.js";
import PromotionRoutes from "./Routes/PromotionRoutes.js";
import SidebarHeader from "./Components/NewSidebar/SidebarHeader";
import { ProtectedRoutes } from "./Protected/Protected";

const LogoutHandler = () => {
  const token = localStorage.getItem("accessToken");
  const location = useLocation();

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  useEffect(() => {
    if (token) {
      const decodedJwt = parseJwt(token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        localStorage.clear();
      }
    }
  }, [location.pathname]);

  return null;
};

export const PageRoute = () => {
  const token = localStorage.getItem("accessToken");
  return (
    <>
      <Router>
        <Routes>
          {!token && <Route path="/" element={<LoginPage />} />}

          <Route element={<ProtectedRoutes />}>
            <Route path="/*" element={<SidebarHeader />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};

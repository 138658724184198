import React from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';

import heartIcon from "../../../assets/heart-white.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useState } from 'react';
import {  useNavigate } from 'react-router-dom';

function ProductCard(props) {

  
    // const { handleEdit, handleDelete } = props
    const { _id } = props.data;
    const { productsImage, name, description, salePrice } = props.data.general;

   const navigate = useNavigate();


   

   
    return (
        <>
            <div className='position-relative' id="product-card">
                <div style={{ background: "white", cursor: "pointer" }} className="product-card"  onClick={() => navigate(`/company/company-product/details/${_id}`)} >
               
                    <div className="product-img">
                        <img
                            src={productsImage}
                            className="img-size event-card-img"
                            alt={name}
                        />
                      
                    </div>


                    <div className="mt-3 mid-product-details">
                        <h5>{name}</h5>
                        {/* <p dangerouslySetInnerHTML={{ __html: description || "" }}></p> */}
                        <span className='product-price'><i class="fa-solid product-rupee fa-indian-rupee-sign"></i>
                            {salePrice}
                        </span>
                    </div>
                </div>
               
            </div>
        </>
    )
}

export default ProductCard
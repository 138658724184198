import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";
import Insights from "./Pages/Insights/InsightsOverview/InsightsOverview.js";
import InsightsBlogs from "./Pages/Insights/InsightsBlogs/InsightsBlogs.js";
import InsightsWhitePaper from "./Pages/Insights/InsightsWhitePaper/InsightsWhitePaper.js";
import InsightsPodCast from "./Pages/Insights/InsightsPodCast/InsightsPodCast.js";
import EditBlog from "./Pages/Insights/InsightsBlogs/EditBlog.js";
import BlogDetails from "./Pages/Insights/InsightsBlogs/BlogDetails.js";
import EditWhitePaper from "./Pages/Insights/InsightsWhitePaper/EditWhitePaper.js";
import WhitePaperDetails from "./Pages/Insights/InsightsWhitePaper/WhitePaperDetails.js";
import EditPodCast from "./Pages/Insights/InsightsPodCast/EditInsightPodcast.js";
import PodcastDetails from "./Pages/Insights/InsightsPodCast/PodcastDetails.js";


const InsightsRoutes = ({ token }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={token ? <Insights /> : <Navigate to="/" />} />
        <Route
          path="/create/insight-blogs"
          element={token ? <InsightsBlogs /> : <Navigate to="/" />}
        />
        <Route
          path="/create/insight-whitepaper"
          element={token ? <InsightsWhitePaper /> : <Navigate to="/" />}
        />
        <Route
          path="/create/insight-podcast"
          element={token ? <InsightsPodCast /> : <Navigate to="/" />}
        />
        <Route
          path="/blog/:id"
          element={token ? <BlogDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/blog/edit/:id"
          element={token ? <EditBlog /> : <Navigate to="/" />}
        />
        <Route
          path="/podcast/:id"
          element={token ? <PodcastDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/podcast/edit/:id"
          element={token ? <EditPodCast /> : <Navigate to="/" />}
        />
        <Route
          path="/whitepaper/:id"
          element={token ? <WhitePaperDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/whitepaper/edit/:id"
          element={token ? <EditWhitePaper /> : <Navigate to="/" />}
        />
      </Routes>
    </>
  );
};

export default InsightsRoutes;

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import companyReducer from "./features/company/companySlice.js";
import productReducer from "./features/adminProducts/productSlice.js";
import communityReducer from "./features/Community/communitySlice.js";
import categoryReducer from "./features/Category/categorySlice.js";
import userReducer from "./features/Users/userSlice.js";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";


const companyPersistConfig = {
    key: "company-persist",
    storage,
  };
const productPersistConfig = {
    key: "product-persist",
    storage,
  };
const communityPersistConfig = {
    key: "community-persist",
    storage,
  };
const categoryPersistConfig = {
    key: "category-persist",
    storage,
  };
const userPersistConfig = {
    key: "user-persist",
    storage,
  };
const companyPersistedReducer = persistReducer(companyPersistConfig, companyReducer);
const productPersistedReducer = persistReducer(productPersistConfig, productReducer);
const communityPersistedReducer = persistReducer(communityPersistConfig, communityReducer);
const categoryPersistReducer = persistReducer(categoryPersistConfig, categoryReducer);
const userPersistReducer = persistReducer(userPersistConfig, userReducer);

const rootReducer = combineReducers({
  company: companyPersistedReducer,
  product: productPersistedReducer,
  community:communityPersistedReducer,
  category:categoryPersistReducer,
  user:userPersistReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export const persistor = persistStore(store);

import { Route, Navigate, Routes } from "react-router-dom";
import CompanyProfile from "../Pages/CompanyPages/CompanyProfile/CompanyProfile.js";
import CompanyDetails from "../Pages/CompanyPages/CompanyDetails/CompanyDetails";
import NewsPressRelease from "../Pages/CompanyPages/NewsPressRelease/NewsPressRelease.js";
import ProductDetails from "../Pages/CompanyPages/ProductDetails/ProductDetails.js";
import CompanyProducts from "../Pages/CompanyPages/CompanyProducts/CompanyProducts";
import CompanyDeals from "../Pages/CompanyPages/CompanyDeals/CompanyDeals";
import CompanyBlogs from "../Pages/CompanyPages/CompanyBlogs/CompanyBlogs";
import FactoryDetails from "../Pages/CompanyPages/CompanyDetails/FactoryDetails";
import Inquiry from "../Pages/CompanyPages/Inquiry/Inquiry.js";
import SocialMedia from "../Pages/CompanyPages/SocialMedia/SocialMedia.js";
import KeyPersonnel from "../Pages/CompanyPages/KeyPersonnel/KeyPersonnel.js";
import Certification from "../Pages/CompanyPages/Certification/Certification";
import FAQDetails from "../Pages/CompanyPages/FAQ/FAQDetails";
import CodeOfConduct from "../Pages/CompanyPages/CodeOfConduct/CodeOfConduct";
import Impact from "../Pages/CompanyPages/Impact/Impact";
import Media from "../Pages/CompanyPages/CompanyMedia/CompanyMedia.js"
import Companies from "../Pages/TrendingMovies/TrendingMovies";
import Subscription from "../Pages/CompanyPages/Subscription/Subscription.js";
import Promotion from "../Pages/CompanyPages/Promotion/Promotion.js";
import EventDetails from "../Pages/CompanyPages/EventDetails/EventDetails.js";
import EventDetail from "../Pages/CompanyPages/EventDetail/EventDetail.js";
import EditEvent from "./Pages/Event/EditEvent/EditEvent.js";
import EventDetailss from "./Pages/Event/EventDetail/EventDetail.js";



function CompanyRoutes({ token }) {

 

  return (
    <>
      <Routes>
        <Route path="/" element={<Companies />} />
        <Route
          path="/:id/profile"
          element={token ? <CompanyProfile /> : <Navigate to="/" />}
        />

        <Route
          path="/company-details"
          element={token ? <CompanyDetails /> : <Navigate to="/" />}
        />

        <Route
          path="news-release"
          element={token ? <NewsPressRelease /> : <Navigate to="/" />}
        />
        <Route
          path="/company-product/details/:id"
          element={token ? <ProductDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/company-product"
          element={token ? <CompanyProducts /> : <Navigate to="/" />}
        />
        <Route
          path="/deals-offer"
          element={token ? <CompanyDeals /> : <Navigate to="/" />}
        />
        <Route
          path="/company-blogs"
          element={token ? <CompanyBlogs /> : <Navigate to="/" />}
        />
        <Route
          path="/media-library"
          element={token ? <Media /> : <Navigate to="/" />}
        />
        <Route
          path="/factory-details"
          element={token ? <FactoryDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/inquiry-lead"
          element={token ? <Inquiry /> : <Navigate to="/" />}
        />
        <Route
          path="/social-media"
          element={token ? <SocialMedia /> : <Navigate to="/" />}
        />
        <Route
          path="/key-personal"
          element={token ? <KeyPersonnel /> : <Navigate to="/" />}
        />
        <Route
          path="/certification"
          element={token ? <Certification /> : <Navigate to="/" />}
        />
        <Route
          path="/faq-details"
          element={token ? <FAQDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/code-of-conduct"
          element={token ? <CodeOfConduct /> : <Navigate to="/" />}
        />
        <Route
          path="/impact"
          element={token ? <Impact /> : <Navigate to="/" />}
        />
        <Route
          path="/impact"
          element={token ? <Impact /> : <Navigate to="/" />}
        />
        <Route
          path="/subscriptions"
          element={token ? <Subscription /> : <Navigate to="/" />}
        />
        <Route
          path="/promotions"
          element={token ? <Promotion /> : <Navigate to="/" />}
        />
        <Route
          path="/events"
          element={token ? <EventDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/events/ticketDetails/:id"
          element={token ? <EventDetailss /> : <Navigate to="/" />}
        />
        <Route
          path="/event/:id"
          element={token ? <EditEvent  type={"view"} navigateTo={"/company/events"} /> : <Navigate to="/" />}
        />

      </Routes>
    </>
  );
}

export default CompanyRoutes;

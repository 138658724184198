import { AiOutlineRise } from "react-icons/ai";
import { BiCommentEdit, BiNews } from "react-icons/bi";
import { FaUsers } from "react-icons/fa";
import { FiGrid } from "react-icons/fi";

import { MdCategory, MdOutlineCategory, MdOutlineDashboard, MdOutlineDiscount } from "react-icons/md";
import { TiShoppingCart } from "react-icons/ti";
import { CgCommunity } from "react-icons/cg";
import { BsImages } from "react-icons/bs";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import InsightsIcon from '@mui/icons-material/Insights';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import RuleFolderOutlinedIcon from '@mui/icons-material/RuleFolderOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import EventIcon from '@mui/icons-material/Event';
export const menuItem = [
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: <MdOutlineDashboard />,
        tag: "/dashboard",

    },

    {
        path: "/company",
        name: " Companies",
        icon: <BusinessIcon />,
        tag: "/company"
    },
    {
        path: "/user",
        name: "Users",
        icon: <PeopleAltOutlinedIcon />,
        tag: "/user"
    },
    {
        path: "/product",
        name: "Products",
        icon: <TiShoppingCart />,
        tag: "/product"
    },
    {
        path: "/discount",
        name: "Discount",
        icon: <MdOutlineDiscount />,
        tag: "/discount"
    },
    {
        path: "/plan-management",
        name: "Plan Management",
        icon: <FiGrid />,
        tag: "/plan-management"
    },
    {
        path: "/transaction",
        name: "Transaction",
        icon: <AccountBalanceOutlinedIcon />,
        tag: "/transaction"
    },
    {
        path: "/community",
        name: "Community",
        icon: <LocalLibraryOutlinedIcon />,
        tag: "/community"
    },
    {
        path: "/category",
        name: "Category",
        icon: <CategoryOutlinedIcon />,
        tag: "/category"
    },
    {
        path: "/banner",
        name: "Banner",
        icon: <BiNews />,
        tag: "/banner"
    },
    {
        path: "/insights",
        name: "Insights",
        icon: <InsightsIcon />,
        tag: "/insights"
    },
    {
        path: "/promotion",
        name: "Promotions ",
        icon: <AutoStoriesOutlinedIcon />,
        tag: "/promotion"
    },
    {
        path: "/sub-admin-management",
        name: "Sub-Admin Management ",
        icon: <AdminPanelSettingsOutlinedIcon />,
        tag: "admin"
    },
    {
        path: "/guidelines",
        name: "Community Guidelines ",
        icon: <RuleFolderOutlinedIcon />,
        tag: "/guidelines"
    },
    {
        path: "/support",
        name: "Support Management ",
        icon: <SupportAgentOutlinedIcon />,
        tag: "/support"
    },
    {
        path: "/payout",
        name: "Payout ",
        icon: < PaymentsOutlinedIcon />,
        tag: "/payout"
    },
    {
        path: "/events",
        name: "Events ",
        icon: <EventIcon />,
        tag: "/events"
    },

]
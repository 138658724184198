import React from 'react'
import { useNavigate } from 'react-router-dom';
import { BsThreeDotsVertical } from 'react-icons/bs';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useState } from 'react';
import "./SocialMedia.css"

const SocialMediaCard = ({ data }) => {

    const { _id } = data;
    const { link, img, name } = data.socialMedia[0]


    


   

    return (
        <>
            <div className='position-relative'>
                <div onClick={() => window.open(link, "_blank")} className="socialIconsBox">
                    <div className="social-icon-img">
                        <img className="img-size" src={img} alt={"img1"} />
                    </div>
                    <p className='mt-3'>{name}</p>
                </div>

               
               
            </div>
        </>
    )
}

export default SocialMediaCard
import { toast } from "react-toastify";
import { storage } from "../../firebase";
import { ref, getDownloadURL, deleteObject, uploadBytes, uploadBytesResumable } from 'firebase/storage';

export const uploadImg = async (filePath, file, setImageUrl, setImageLoader) => {
    setImageLoader(true)
    if (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') {
        const storageRef = ref(storage, `${filePath}/${file.name}`);
        await uploadBytes(storageRef, file).then((snapshot) => {
            console.log(snapshot);
            console.log('Uploaded a blob or file!');
            getDownloadURL(snapshot.ref).then((url) => {
                console.log(url)
                setImageLoader(false)
                setImageUrl((prev) => ({ ...prev, img: url }))
                // return url
            });
        });
    } else {
        toast.error("Please upload only jpeg, jpg and png file")
        return
    }
}


export const uploadFileToFirebase = async (file, storagePath) => {
    try {
        const storageRef = ref(storage, storagePath);
        const uploadTask = uploadBytesResumable(storageRef, file);
        const snapshot = await uploadTask;

        const downloadURL = await getDownloadURL(snapshot.ref);
        return downloadURL;
    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
};


import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { AiFillPlusCircle, AiOutlineLeft } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { inputLabelClasses } from "@mui/material/InputLabel";
import "./CreatePlan.css";

import { createPlan } from "../../../../util/Auth/APIs";
import { TextField } from "@mui/material";
import TopHeader from "../../../../Components/TopHeader/TopHeader";

const CreatePlan = () => {
  const navigate = useNavigate();

  const [submitloader, setSubmitLoader] = useState(false);

  const [forms, setForms] = useState([
    {
      id: 1,
      name: "",
      monthPrice: null,
      yearPrice: null,
      products: null,
      deals: null,
      communities: null,
      events: null,
      jobs: null,
      coverages: null,
      gallery: null,
      forms: null,
      blogs: null,
      eventCommission: null,
    },
  ]);
  const [nextFormId, setNextFormId] = useState(2);

  const handleAddForm = () => {
    if (forms.length >= 3) {
      toast.error("You can only add 3 Plans at a time");
      return;
    }
    const newForm = {
      id: nextFormId,
      name: "",
      monthPrice: null,
      yearPrice: null,
      products: null,
      deals: null,
      communities: null,
      events: null,
      jobs: null,
      coverages: null,
      gallery: null,
      forms: null,
      blogs: null,
      eventCommission: null,
    };
    setForms([...forms, newForm]);
    setNextFormId(nextFormId + 1);
  };

  // const handleFormChange = (index, field, value) => {
  //   const updatedForms = forms.map((form, i) => {
  //     if (i === index) {
  //       return { ...form, [field]: value, category: planFor };
  //     }
  //     return form;
  //   });
  //   setForms(updatedForms);
  // };
  const handleFormChange = (index, field, value) => {
    let parsedValue = value; // Store the value as it is if it's not a number

    // Convert the value to a number if it's a valid number
    if (value === "") {
      parsedValue = ""; // Set to an empty string when the value is empty
    } else if (!isNaN(value)) {
      parsedValue = parseFloat(value);
    } else {
      parsedValue = value;
    }

    const updatedForms = forms.map((form, i) => {
      if (i === index) {
        return { ...form, [field]: parsedValue };
      }
      return form;
    });

    setForms(updatedForms);
  };

  const handleSubmit = async () => {
    setSubmitLoader(true);
    // console.log(forms);
    // const isAnyFormEmpty = forms.some((form) => {
    //   return (
    //     form.name.trim() === "" ||
    //     form.monthPrice === null ||
    //     form.yearPrice === null ||
    //     form.products === null ||
    //     form.deals === null ||
    //     form.communities === null ||
    //     form.events === null ||
    //     form.jobs === null ||
    //     form.coverages === null ||
    //     form.gallery === null ||
    //     form.forms === null ||
    //     form.blogs === null ||
    //     form.eventCommission
    //   );
    // });

    // console.log(isAnyFormEmpty)

    // if (!isAnyFormEmpty) {
    //   // Handle the case where there are empty fields
    //   toast.error("Please fill all the fields");
    //   setSubmitLoader(false);
    //   return;
    // }

    const data = { plans: forms };
    console.log(data);
    try {
      const response = await createPlan(data);
      toast.success("plan info added in the list", {
        onClose: () => navigate("/plan-management/"),
      });
      setSubmitLoader(false);
      navigate(-1);
      console.log(response);
    } catch (error) {
      setSubmitLoader(false);
      // console.log(error.response);
      toast.error(error.response.data.message);
      // toast.error("Something went wrong");
      console.log(error);
    }
  };

  const borderStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#acb5bd",
      },
      "&:hover fieldset": {
        borderColor: "#acb5bd",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: "#4eb7bd",
      },
    },
  };

  const labelInput = {
    sx: {
      color: "#acb5bd",
      [`&.${inputLabelClasses.focused}`]: {
        color: "#4eb7bd",
      },
    },
  };

  return (
    <>
      <DashboardBoxStyle className=" scroller">
        <TopHeader headerTitle={"Create Foundation Plan"} type={"back"} />

        <div className="multiple-plans-contianer">
          {forms.map((form, index) => (
            <div className="plan-form-contianer" key={form.id}>
              <TextField
                label="Plan Name"
                type="text"
                value={form.name}
                onChange={(e) => {
                  handleFormChange(index, "name", e.target.value);
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Monthly Price"
                type="number"
                value={form.monthPrice}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "monthPrice", e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Yearly Price"
                type="number"
                value={form.yearPrice}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "yearPrice", e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Products"
                type="number"
                value={form.products}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "products", e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Deals"
                type="number"
                value={form.deals}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "deals", e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Communities"
                type="number"
                value={form.communities}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "communities", e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Events"
                type="number"
                value={form.events}
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "events", e.target.value);
                  }
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Jobs"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "jobs", e.target.value);
                  }
                }}
                variant="outlined"
                value={form.jobs}
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
                type="number"
              />

              <TextField
                label="Media Coverages"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "coverages", e.target.value);
                  }
                }}
                variant="outlined"
                value={form.coverages}
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
                type="number"
              />

              <TextField
                label="Media Gallery"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "gallery", e.target.value);
                  }
                }}
                variant="outlined"
                value={form.gallery}
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
                type="number"
              />

              <TextField
                label="Contact Forms"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "forms", e.target.value);
                  }
                }}
                variant="outlined"
                value={form.forms}
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
                type="number"
              />

              <TextField
                label="Blogs"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "blogs", e.target.value);
                  }
                }}
                variant="outlined"
                value={form.blogs}
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
                type="number"
              />
              <TextField
                label="Event Commission"
                onChange={(e) => {
                  if (e.target.value >= 0) {
                    handleFormChange(index, "eventCommission", e.target.value);
                  }
                }}
                variant="outlined"
                value={form.eventCommission}
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
                type="number"
              />
            </div>
          ))}
        </div>
        <div className="plan-plus-icon">
          <div className="addIcon" onClick={handleAddForm}>
            <AiFillPlusCircle />
          </div>
        </div>

        <div
          className="listing-addnew-btn plan-submit-btn"
          onClick={handleSubmit}
        >
          <button>
            {submitloader ? (
              <div
                className="spinner-border text-white ml-4"
                role="status"
              ></div>
            ) : (
              <> Create Plan</>
            )}
          </button>
        </div>

        <ToastContainer />
      </DashboardBoxStyle>
    </>
  );
};

export default CreatePlan;

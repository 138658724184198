import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CreateTypeModal from "../../../../Components/Modals/SupportModal/CreateTypeModal";
import { toast } from "react-toastify";
import {
  deleteSupportType,
  updateSupportType,
} from "../../../../util/Auth/APIs";

const SupportCard = ({ data ,onCardDelete }) => {
  const toggleOption = () => {
    setOption(!option);
  };
  const [option, setOption] = useState(false);
  const [show, setShow] = useState(false);
  const [name, setName] = useState(data.name);
  const [updatedName, setUpdatedName] = useState(data.name);

  useEffect(() => {

    setUpdatedName(data.name);
    setName(data.name);
 
  }, [data])
  

  const handleClose = () => setShow(false);
  const id = data._id;

  const handleShow = () => setShow(true);

  const handleEdit = async () => {
    if (name.trim() === "") {
      toast.warning("Support types cannot be empty");
      return;
    }

    const data = {
      name: name,
    };

    handleClose();

    try {
      const res = await updateSupportType({ id, data });
      toast.success("Support Type Updated Successfully!");
      setUpdatedName(name);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDelete = async () => {
    toggleOption();
    try {
      const res = await deleteSupportType(id);
      toast.success("Support Type Deleted Successfully!");
      onCardDelete();
     
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <>
      <div className="guideline-container">
        <div className="Guideline-menu">
          <MoreVertIcon onClick={toggleOption} />
        </div>

        {option && (
          <ul className="options-menu">
            <li
              onClick={() => {
                toggleOption();
                handleShow();
              }}
            >
              Edit
            </li>
            <li onClick={handleDelete}>Delete</li>
          </ul>
        )}

        <Alert key={data._id} variant={"primary"}>
          <h5>{updatedName}</h5>
          <div />
        </Alert>
      </div>

      <CreateTypeModal
        show={show}
        handleCreate={handleEdit}
        placeholderVal="Edit Support Type Name"
        handleClose={handleClose}
        value={name}
        setName={setName}
        btnName={"Edit"}
      />
    </>
  );
};

export default SupportCard;

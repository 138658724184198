import React from 'react'
import CustomModal from '../CustomModal/CustomModal'
import CustomButton from '../../Button/CustomButton/CustomButton'

const CreateTypeModal = ({ show, handleClose, handleCreate, value, setName, btnName, placeholderVal }) => {
    return (
        <CustomModal show={show} handleClose={handleClose} >
            <div className="filterModalWrapper">
                <div className="filterModalInputContainer">
                    <div className="filterModalinput">

                        <label htmlFor="category">Support Type</label>
                        <input value={value} required onChange={(e) => setName(e.target.value)} type="text" placeholder={placeholderVal} />

                    </div>

                </div>
                <div className="filterModalBtn">
                    <CustomButton handleClick={handleCreate} classId="filterapplyButton">{btnName}</CustomButton>
                    <h6 onClick={() => {
                        if (btnName !== "Edit") {
                            setName("");
                        }
                        handleClose();
                    }}>Cancel</h6>
                </div>


            </div>

        </CustomModal>
    )
}

export default CreateTypeModal
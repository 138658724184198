import React from 'react'


const CertificateCard = (props) => {

   
    const { _id, certificationName, description, uploadFile } = props.data

 
    return (
        <div className="position-relative " >
            <div className='podcast-card border'>
                <div className="podcast-video">
                    <img src={uploadFile} alt="pod" className="img-size" />
                </div>

                <div className="podcast-detail" style={{ fontFamily: 'Roboto' }}>
                    <h5 >{certificationName}</h5>
                    <p >{description}</p>
                </div>
            </div>

          
        </div>
    )
}

export default CertificateCard
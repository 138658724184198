import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./BlogSection.css";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import {
  DeleteBlog,
  getAllBlogs,
  getAllCategories,
} from "../../../../../util/Auth/APIs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { LoadingPage } from "../../../../../Components/Loading/Loading";
import { useDispatch } from "react-redux";
import { setCategoryDetails } from "../../../../../app/features/Category/categorySlice";

const BlogSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const [cardsData, setCardsData] = useState([]);

  const handleOptionsClick = (id) => {
    const updatedCardsData = cardsData.map((card) => {
      if (card._id === id) {
        return {
          ...card,
          showOptions: !card.showOptions,
        };
      }
      return card;
    });

    setCardsData(updatedCardsData);
  };

  const getBlogs = async () => {
    try {
      const res = await getAllBlogs();
      setCardsData(
        res?.data?.message?.data.map((card) => ({
          ...card,
          showOptions: false,
        }))
      );

      // console.log(res?.data?.data);
    } catch (error) {}
  };

  const getCategoryDetails = async () => {
    try {
      const res = await getAllCategories();
      dispatch(setCategoryDetails(res?.data?.data));
    } catch (error) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([getBlogs(), getCategoryDetails()]);
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const removeItem = async (id) => {
    setLoading(true);

    try {
      const res = await DeleteBlog(id);
      setLoading(true);
      toast.success("Blog deleted successfully");
      setLoading(false);

      setCardsData(cardsData.filter((item) => item._id !== id)); // update the blogsList state
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <Container fluid>
          <Row>
            {cardsData.map((item) => (
              <Col lg={4} className="mb">
                <div className="insights-card" key={item._id}>
                  <div className="category-card-menuIcon">
                    <BsThreeDotsVertical
                      onClick={() => handleOptionsClick(item._id)}
                    />
                  </div>
                  {item.showOptions && (
                    <ul className="options-menu">
                      <li
                        onClick={() =>
                          navigate(`/insights/blog/edit/${item._id}`)
                        }
                      >
                        Edit
                      </li>

                      <li onClick={() => removeItem(item._id)}>Delete</li>
                    </ul>
                  )}
                  <img src={item.images} alt="" />
                  <div className="insights-card-details">
                    <button>
                      Blogs{" "}
                      <CircleIcon
                        fontSize="small"
                        style={{ color: "#4DCB52" }}
                      />
                    </button>
                    <h5>
                      {item?.title.slice(0, 40)}
                      {item?.title.length > 40 ? "..." : null}
                    </h5>
                    <p>
                      {item?.description.slice(0, 70)}
                      {item?.title.length > 70 ? "..." : null}
                    </p>
                    <button
                      onClick={() => navigate(`/insights/blog/${item._id}`)}
                    >
                      View Details
                    </button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default BlogSection;

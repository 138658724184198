import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import "./CreateCommunity.css";
import profileIcon from "../../../../assets/profileIcon.svg";
import { AiOutlineLeft } from "react-icons/ai";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import Select from "react-select";

import { storage } from "../../../../firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { toast } from "react-toastify";
import { createCommunity, getAllCategories } from "../../../../util/Auth/APIs";
import { BiCross } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { RiArrowDropDownLine } from "react-icons/ri";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SelectGuidlines from "./SelectGuidlines.js";
import SelectCategoryAll from "./SelectCategoryAll.js";

const options = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
];

const CreateCommunity = () => {
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [coverFileName, setCoverFileName] = useState(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [profileImageLoader, setProfileImageLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ques, setQues] = useState("");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [tags, setTags] = useState([]);

  const [profileImage, setProfileImage] = useState(null);
  const [profileFileName, setProfileFileName] = useState(null);

  const [communityDetails, setCommunityDetails] = useState({
    name: "",
    category: "",
    category2: "",
    category3: "",
    category4: "",
    cover: "",
    profile: "",
    keyword: [],
    limitation: null,
    guidelines: [],
    condition: "public",
    description: "",
    forum: [
      {
        question: "",
        date: new Date().toISOString(),
      },
    ],
  });

  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setCommunityDetails({ ...communityDetails, keyword: [...updatedTags] });
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.type === "click") {
      e.preventDefault(); // Prevent form submission
      const value = e.target.parentElement
        .querySelector(".input-tag")
        .value.trim();
      if (value === "") return;
      const updatedTags = [...tags, value];
      setTags(updatedTags);
      setCommunityDetails({ ...communityDetails, keyword: updatedTags });
      e.target.parentElement.querySelector(".input-tag").value = "";
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommunityDetails({ ...communityDetails, [name]: value });
  };

  const handleCoverImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      setCoverFileName(e.target.files[0].name);
      setCoverImage(URL.createObjectURL(e.target.files[0]));
      const storageRef = ref(
        storage,
        `CommunityImage/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCommunityDetails({
            ...communityDetails,
            cover: url,
          });
          setImageloader(false);
          // companyBlogs.blogImages.push(url)
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };
  const handleProfileImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setProfileImageLoader(true);
      setProfileFileName(e.target.files[0].name);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      const storageRef = ref(
        storage,
        `CommunityImage/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCommunityDetails({
            ...communityDetails,
            profile: url,
          });
          setProfileImageLoader(false);
          // companyBlogs.blogImages.push(url)
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };

  const [categoryNames, setCategoryNames] = useState([]);

  const getCategoryDetails = async () => {
    setLoading(true);
    try {
      const res = await getAllCategories();

      const names = res?.data?.data?.map((category) => category.name);
      setCategoryNames(names);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong in fetching category details");
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoryDetails();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: communityDetails.name,

      cover: communityDetails.cover,
      profile: communityDetails.profile,
      keyword: communityDetails.keyword,
      limitation: communityDetails.limitation,
      guidelines: communityDetails.guidelines,
      condition: communityDetails.condition,
      description: communityDetails.description,
      category: communityDetails.category,
      category2: communityDetails.category2,
      category3: communityDetails.category3,
      category4: communityDetails.category4,
      forum: [
        {
          question: ques,
          date: new Date().toISOString(),
        },
      ],
    };
    setSubmitLoader(true);
    console.log(data);
    try {
      const res = await createCommunity(data);
      toast.success("Community Created Successfully");
      setSubmitLoader(false);
      navigate(-1);
      console.log(res);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
    setSubmitLoader(false);
  };

  const deleteFromFirebase = (url, type) => {
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    if (type == "cover") {
      deleteObject(pictureRef)
        .then(() => {
          setCommunityDetails({ ...communityDetails, cover: "" });
          setCoverImage(null);
          setCoverFileName(null);
          console.log(communityDetails);
          toast.success("Image removed successfully");
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          toast.error("Cannot Remove Image");
        });
    } else if (type == "profile") {
      deleteObject(pictureRef)
        .then(() => {
          setCommunityDetails({ ...communityDetails, profile: "" });
          setProfileImage(null);
          setProfileFileName(null);
          console.log(communityDetails);
          toast.success("Image removed successfully");
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          toast.error("Cannot Remove Image");
        });
    }
  };
  const handleClick = () => {
    navigate(-1);
  };

  const handleSelect = (e) => {
    setCommunityDetails({ ...communityDetails, condition: e.value });
    console.log(e.value);
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Create Community"} type={"back"} />
          <form onSubmit={handleSubmit} className="create-community-container">
            <div className="uploader-community-image">
              <div
                className="communityCover"
                onClick={() => document.querySelector(".input-cover").click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="input-cover"
                  hidden
                  onChange={handleCoverImage}
                />
                {imageloader ? (
                  <>
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    {coverImage ? (
                      <img src={coverImage} alt={coverFileName} />
                    ) : (
                      <p>Add Cover Image</p>
                    )}
                  </>
                )}
              </div>
              {coverImage ? (
                <div
                  className="coverDeleteIcon"
                  onClick={() =>
                    deleteFromFirebase(communityDetails.cover, "cover")
                  }
                >
                  <RxCross2 />
                </div>
              ) : (
                ""
              )}

              <div
                className="communityProfile"
                onClick={() => document.querySelector(".input-profile").click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="input-profile"
                  hidden
                  onChange={handleProfileImage}
                />

                {profileImageLoader ? (
                  <>
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    {profileImage ? (
                      <img src={profileImage} alt={profileFileName} />
                    ) : (
                      <div className="profileIcon">
                        <img src={profileIcon} alt="image" />
                      </div>
                    )}
                  </>
                )}
              </div>
              {profileImage ? (
                <div
                  className="profileDeleteIcon"
                  onClick={() =>
                    deleteFromFirebase(communityDetails.profile, "profile")
                  }
                >
                  <RxCross2 />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="community-input-container">
              <input
                type="text"
                name="name"
                required
                placeholder="Community Name"
                className="community-input-half"
                onChange={handleChange}
              />

              <input
                type="number"
                name="limitation"
                required
                placeholder="Members Limitation"
                className="community-input-half"
                onChange={handleChange}
              />

              <SelectGuidlines
                communityDetails={communityDetails}
                setCommunityDetails={setCommunityDetails}
              />

              <Select
                options={options}
                onChange={handleSelect}
                placeholder="select type"
                className="multi-select-bar"
              />
              <div className="select-category-container">
                <SelectCategoryAll
                  selected={communityDetails}
                  setSelected={setCommunityDetails}
                />
              </div>

              <textarea
                name="description"
                placeholder="Short Description"
                cols="100"
                rows="7"
                onChange={handleChange}
                required
                className="communityTextBox"
              ></textarea>
              <textarea
                placeholder="Forum Question for Disscussion"
                onChange={(e) => setQues(e.target.value)}
                cols="100"
                className="communityTextBox"
                required
                rows="7"
              ></textarea>

              <div className="tags-container mt-20 w-full">
                {tags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>
                      &times;
                    </span>
                  </div>
                ))}
                <input
                  type="text"
                  className="input-tag b-20"
                  placeholder="Add Keywords"
                />
                <p onClick={handleKeyDown} className="add-btn">
                  Add
                </p>
              </div>
            </div>
            <div className="CreateCommunityBtn">
              <button>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Create Now</>
                )}
              </button>
            </div>
          </form>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default CreateCommunity;

import { Route, Navigate, Routes } from "react-router-dom";
import Blogs from "../Routes/Pages/Blogs/Blogs";
import AddBlogs from "../Routes/Pages/Blogs/AddBlogs.js";
import EditBlogs from "../Routes/Pages/Blogs/EditBlog.js";

const BlogRoutes = ({ token }) => {
  return (
    <>
      <Routes>

        <Route
          path="/"
          element={token ? <Blogs /> : <Navigate to="/" />}
        />
        <Route
          path="/add"
          element={token ? <AddBlogs /> : <Navigate to="/" />}
        />
        <Route
          path="/edit/:id"
          element={token ? <EditBlogs /> : <Navigate to="/" />}
        />

      </Routes>
    </>
  )
}

export default BlogRoutes
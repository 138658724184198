import React from 'react'

import { useRef } from 'react';


const CompanyFaqAccordian = (props) => {





    const {  data } = props;

    const { question } = data;

   

    return (
        <>
            <div className='edit-accordian-card'>
                <div className="edit-accordian-head">
                    <div className="edit-accordian-toggle p-3">
                        <h4>{question}</h4>
                       
                    </div>
                   
                </div>
            </div>
        </>
    )
}

export default CompanyFaqAccordian
import React, { useState } from 'react'
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { BiPencil, BiTrash } from "react-icons/bi"
import { AiOutlineCamera } from "react-icons/ai"
import { FiCamera } from "react-icons/fi"
import User3 from "../../../../assets/comm-user.svg"

import { uploadImg } from '../../../../util/Healper/imageUpload'; 
import { toast } from 'react-toastify';
// import { DeleteEventSponsor, UpdateEventSponsor } from '../../../../axios/apis';

const SponsorAccordian = (props) => {
    const contentEl = useRef();

    const [imageLoader, setImageLoader] = useState(false)
    const [submitloader, setSubmitLoader] = useState(false)
    const [deleteloader, setDeleteLoader] = useState(false)

    const { handleToggle, active, getAllSponsors, data } = props;
    const { img, _id, name, type, url, description } = data;

    const [sponsorData, setSponsorData] = useState({
        img: img || User3,
        name: name || '',
        type: type || '',
        url: url || '',
        description: description || ''
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSponsorData({ ...sponsorData, [name]: value });
    }

    const handleImage = async (e) => {
        await uploadImg('Sponsor', e.target.files[0], setSponsorData, setImageLoader)
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        // try {
        //     const res = await UpdateEventSponsor({ id: _id, data: sponsorData })
        //     toast.success('Sponsor Updated successfully')
        //     setSubmitLoader(false)
        //     if (res.data.success) {
        //         handleToggle(_id)
        //         getAllSponsors()
        //     }
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setSubmitLoader(false)
        //     console.log(error)
        // }
    }

    const removeItem = async () => {
        setDeleteLoader(true)
        // try {
        //     const res = await DeleteEventSponsor(_id)
        //     if (res.data.success) {
        //         toast.success('Sponsor deleted successfully')
        //         getAllSponsors()
        //     }
        //     setDeleteLoader(false)
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setDeleteLoader(false)
        //     console.log(error)
        // }
    }


    return (
        <>
            <div className='edit-accordian-card'>
                <div className="edit-accordian-head">
                    <div className="edit-accordian-toggle p-3">
                        <h4>{name}</h4>
                        <div className='d-flex'>
                            <div onClick={() => handleToggle(_id)} className="pencil-edit-btn accordian-edit-btns">
                                <BiPencil />
                            </div>
                            <div onClick={removeItem} className={`trash-edit-btn accordian-edit-btns ${deleteloader && "theme-red"}`}>
                                {
                                    deleteloader ? <div
                                        className="spinner-border text-white spinner-border-sm"
                                        role="status"
                                    ></div> : <>  <BiTrash /></>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        ref={contentEl}
                        className={`rc-collapse ${active === _id ? "show" : ""}`}
                        style={
                            active === _id
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0px" }
                        }
                    >
                        <div className="rc-accordion-body">
                            <Row>
                                <Col lg={6}>
                                    <div className="half-input-box organiser-profile-icon">
                                        <div className="organiser-profile-icon">
                                            <img src={sponsorData?.img} className={`img-size br-50 ${imageLoader && "brightness-50"}`} alt="" />
                                            {
                                                imageLoader && <div
                                                    className="spinner-border position-absolute text-white ml-4"
                                                    role="status"
                                                ></div>
                                            }
                                            <div class="organiser-camera">
                                                <span className='d-flex m-0'>
                                                    <FiCamera />
                                                </span>
                                                <input onChange={handleImage} name="profilePic" type="file" />
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Sponsor name</label>
                                        <div className='profile-input-field'>
                                            <input value={sponsorData?.name} onChange={handleChange} name="name" type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Sponsor type</label>
                                        <div className='profile-input-field'>
                                            <input value={sponsorData?.type} onChange={handleChange} name="type" type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Link URL</label>
                                        <div className='profile-input-field'>
                                            <input value={sponsorData?.url} onChange={handleChange} name="url" type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Description of Sponsor</label>
                                        <div className='profile-input-field'>
                                            <textarea value={sponsorData?.description} onChange={handleChange} name="description" className='faq-answer-textarea' id="" cols="30" rows=""></textarea>
                                        </div>
                                    </div>
                                </Col>
                                <div className='d-flex justify-content-end mt-4' >
                                    <div className="form-width-btn">
                                        <button onClick={handleSave} className='form-action-next'>
                                            {
                                                submitloader ? <div
                                                    className="spinner-border text-white ml-4"
                                                    role="status"
                                                ></div> : <> Save</>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SponsorAccordian
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Table from "react-bootstrap/Table";
import { toast, ToastContainer } from "react-toastify";

import { ImCross } from "react-icons/im";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAttribute } from "../../../../../../app/features/adminProducts/productSlice";
import { CreateProduct } from "../../../../../../util/Auth/APIs";
import { UpdateProduct } from "../../../../../../util/Auth/APIs";
import { AiFillPlusCircle } from "react-icons/ai";
import Imp from "../../../../../../Components/Common/Imp/Imp";

const ProductAttribute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [submitloader, setSubmitLoader] = useState(false);

  const { id } = useParams();

  const Virtual = useSelector((state) => state.product.isVirtual);
  const [productType, setProductType] = useState(Virtual);
  const sample = useSelector((state) => state.product.attribute);
  
  const  general  = useSelector((state) => state.product.general);
  const inventoryy  = useSelector((state) => state.product.inventoryy);
  const linkedProduct  = useSelector((state) => state.product.linkedProduct);


  // console.log(general, inventoryy, linkedProduct);


  const [attributeList, setAttributeList] = useState([...sample]);
  const [attributeValue, setAttributeValue] = useState({
    name: "",
    description: "",
  });

  const handleChange = (e) => {

    setAttributeValue({ ...attributeValue, name: e.target.value })
    console.log(attributeValue);
  };

  const handleQuill = (content, delta, source, editor) => {
    setAttributeValue({ ...attributeValue, description: editor.getHTML() });
  };

  const handleAdd = () => {
    if (attributeValue.name && attributeValue.description) {
      setAttributeList([...attributeList, attributeValue]);

      setAttributeValue({ name: "", description: "" });
    } else {
      toast.error("Please fill all the fields");
    }
  };

  const handleRemove = (index) => {
    setAttributeList(attributeList.filter((item, i) => i !== index));
  };
  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    if (productType === false) {
      const payload = {
        isVirtual: productType,
        general: general,
        inventory: inventoryy,
        linkedProduct: linkedProduct,
        attribute: attributeList,
      };
      console.log(payload);
      try {
        console.log(window.location.pathname);
        if (
          window.location.pathname === "/product/add" ||
          window.location.pathname === "/company/product/add"
        ) {
          const res = await CreateProduct(payload);
          console.log(res);
          dispatch(setAttribute(res?.data?.message?.attribute));
          toast.success("Product saved successfully");
          setSubmitLoader(false);
          if (window.location.pathname === "/product/add") {
            navigate("/product/all");
          } else {
            navigate("/company/product");
          }
        } else {
          const res = await UpdateProduct({ id, data: payload });
          if (window.location.pathname.includes(`product/edit`)) {
            navigate(`/product/all`);
          } else {
            navigate(`/company/product/`);
          }
          console.log(res);
          toast.success("Product Update successfully");
          setSubmitLoader(false);
        }
      } catch (err) {
        setSubmitLoader(false);
        toast.error("Something went wrong");
        console.log(err);
      }
    } else {
      toast.error("Please select Virtual Product type");
      setSubmitLoader(false);
    }
  };

  const handleNext = () => {

  

    
    dispatch(setAttribute(attributeList));
    window.scrollTo({ top: 0, left: 0 });
    navigate("?tab=downloadable");
  };

  useEffect(() => {
    
    setTimeout(() => {
      dispatch(setAttribute(attributeList));
      console.log(attributeList);
    }, 300);
  }, [attributeList]);


  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  return (
    <>
      <form onSubmit={handleSave}>
        <Row className="mt-2">
          <Col lg={12} className="d-flex flex-column justify-content-between">
            <Row>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Name<Imp/></label>
                  <div className="profile-input-field">
                    <input
                      onChange={handleChange}
                      value={attributeValue?.name}
                      required
                      name="name"
                      type="text"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Product Description</label>
                  <ReactQuill
                    theme="snow"
                    name="quill"
                    modules={Boxmodules}
                    value={attributeValue.description}
                    onChange={handleQuill}
                    placeholder="Description"
                  />
                </div>
              </Col>
            </Row>
           <div className="plusIcon">
           <div className="addIcon" onClick={handleAdd}>
                <AiFillPlusCircle />
              </div>

           </div>
            
           

            {attributeList.length !== 0 && (
              <>
                <div className="mt-3">
                  <Table className="product-table" responsive="sm" bordered>
                    <tbody>
                      {attributeList.map((el, i) => (
                        <tr className="product-details-row">
                          <td className="product-table-col table-bold-head">
                            {el.name}
                          </td>
                          <td
                            className="product-detail-column"
                            colSpan={3}
                            dangerouslySetInnerHTML={{
                              __html: el.description || "",
                            }}
                          ></td>
                          <div
                            onClick={() => handleRemove(i)}
                            className="cross-icon"
                          >
                            <ImCross />
                          </div>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}

            <div className="d-flex justify-content-end mt-5">
              <div className="form-width-btn">
                {productType === false ? (
                  <button className="form-action-next">
                    {submitloader ? (
                      <div
                        className="spinner-border text-white ml-4"
                        role="status"
                      ></div>
                    ) : (
                      <> Save</>
                    )}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="form-action-next"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                )}
              </div>
              {/* <div className="form-width-btn">
        <button type="button" className='form-action-next'>Next</button>
      </div> */}
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </form>
    </>
  );
};

export default ProductAttribute;

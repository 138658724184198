import React, { useEffect, useState } from 'react'
import { EventBooking } from '../../../../util/Auth/APIs'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../../../Components/Loading/Loading'
import { DashboardBoxStyle } from '../../../../SideBar/SidepanelStyled'
import { Container } from 'react-bootstrap';
import BookedTable from './BookedTable.js';
import TicketProgress from './TicketProgress.js';
import TopHeader from '../../../../Components/TopHeader/TopHeader'

const EventDetail = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(true)
    const { id } = useParams()
    const [eventDetail, setEventDetail] = useState({})
    const [bookedList, setBookedList] = useState([])


    const eventDetails = async () => {
        setLoader(true)
        try {
           const res = await EventBooking(id)
           
           setBookedList(res?.data?.data)
           setEventDetail(res?.data)
          
           setLoader(false)
        } catch {
           console.log('error')
           setLoader(false)
        }
     }
  

  
     useEffect(() => {
        eventDetails()
     }, [])
 
  return (
 <>

{
            loader ? <LoadingPage /> : <>
               <DashboardBoxStyle className=" scroller">
                  <Container fluid>
                    <TopHeader headerTitle={"Event Details"} type="back" />
                     <div className="mt-4">
                        <TicketProgress data={eventDetail} />
                     </div>

                     <div className="mt-4">
                        <BookedTable bookedList={bookedList} setBookedList={setBookedList} />
                     </div>
                  </Container>
               </DashboardBoxStyle>
            </>
         }
 
 
 </>
  )
}

export default EventDetail
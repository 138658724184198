import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { TextField } from "@mui/material";
import { RiArrowDropDownLine } from "react-icons/ri";
import { inputLabelClasses } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { AiFillPlusCircle, AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { createPromotion } from "../../../../util/Auth/APIs";
import Select from 'react-select'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TopHeader from "../../../../Components/TopHeader/TopHeader";


const options = [
  { value: 'Page', label: 'Page' },
  { value: 'Community', label: 'Community' },
]

const Boxmodules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const borderStyle = {
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#acb5bd",
    },
    "&:hover fieldset": {
      borderColor: "#acb5bd",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& fieldset": {
      borderColor: "#4eb7bd",
    },
  },
};


const labelInput = {
  sx: {
    color: "#acb5bd",
    [`&.${inputLabelClasses.focused}`]: {
      color: "#4eb7bd",
    },
  },
};


const CreatePromotion = () => {
  const navigate = useNavigate();
    const [planFor, setPlanFor] = useState("");
  const [planValue, setPlanValue]=useState("");
  const [submitloader, setSubmitLoader] = useState(false);

  const [forms, setForms] = useState([
    {

      category: planFor,
      name: "",
      yearPrice: null,
      credits: null,
    },
  ]);
  const [nextFormId, setNextFormId] = useState(2);

  const handleAddForm = () => {
    if (forms.length >= 3) {
      toast.error("You can only create 3 Promotions at a time");
      return;
    }
    const newForm = {
      category: planFor,
      name: "",
      yearPrice: null,
      credits: null,
    };
    setForms([...forms, newForm]);
    // setNextFormId(nextFormId + 1);

  };

  const handleFormChange = (index, field, value) => {
    let parsedValue = value; // Store the value as it is if it's not a number

    // Convert the value to a number if it's a valid number
    if (!isNaN(value)) {
      parsedValue = parseFloat(value);
    }

    const updatedForms = forms.map((form, i) => {
      if (i === index) {
        return { ...form, [field]: parsedValue, category: planFor };
      }
      return form;
    });

    setForms(updatedForms);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setPlanFor(value);
  };

  useEffect(() => {
    const updatedForms = forms.map((form) => ({
      ...form,
      category: planFor,
    }));
    setForms(updatedForms);
  }, [planFor]);

  function handleIconClick() {
    document.querySelector(".select-input-all").focus();
  }
    const handleSelect = (e) => {
    setPlanFor(e.value);
    setPlanValue(e);

  }
  

  const handleSubmit = async () => {
    setSubmitLoader(true);

    const isAnyFormEmpty = forms.some((form) => {
      return (
        form.name.trim() === "" ||
        form.monthPrice === null ||
        form.yearPrice === null ||
        form.category === "" ||
        form.addOn === null
      );
    });
    if (isAnyFormEmpty) {
      // Handle the case where there are empty fields
      toast.error("Please fill all the fields");
      setSubmitLoader(false);
      return;
    }


    const data = { plans: forms };

    try {
      const response = await createPromotion(data);
      toast.success("Promotion created successfully", {
        onClose: () => navigate("/promotion/"),
      });
      setSubmitLoader(false);


    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");

    }
  };
  return (
    <>
      <DashboardBoxStyle className=" scroller">
        <div className="d-flex justify-content-between align-items-center">
          <TopHeader headerTitle="Create Promotion" type="back" />
           <Select options={options} placeholder="Select Plan Type" value={planValue}  onChange={handleSelect} />
          
        </div>
        

        <div className="multiple-plans-contianer">
          {forms.map((form, index) => (
            <div className="plan-form-contianer" >
              <TextField
                label="Plan Name"
                type="text"
                value={form.name}
                onChange={(e) => {
                  handleFormChange(index, "name", e.target.value);
                }}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />


              <TextField
                label="Yearly Price"
                type="number"
                value={form.yearPrice}
                onChange={(e) =>
                  handleFormChange(index, "yearPrice", e.target.value)
                }
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Choose the Plan Type"
                type="text"
                value={planFor}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Credits"
                type="number"
                value={form.credits}
                onChange={(e) =>
                  handleFormChange(index, "credits", e.target.value)
                }
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
            </div>
          ))}
        </div>
        <div className="plan-plus-icon">
          <div className="addIcon" onClick={handleAddForm}>
            <AiFillPlusCircle />
          </div>
        </div>

        <div
          className="listing-addnew-btn plan-submit-btn "
          id="mb-20"
          onClick={handleSubmit}
        >
          <button className="">
            {submitloader ? (
              <div
                className="spinner-border text-white ml-4 "
                role="status"
              ></div>
            ) : (
              <> Create Plan</>
            )}
          </button>
        </div>

        <ToastContainer />
      </DashboardBoxStyle>
    </>
  );
};

export default CreatePromotion;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";
import {
  eventChange,
  eventId,
} from "../../../../app/features/Community/communitySlice";
import { CreateEvent, UpdateEvent } from "../../../../util/Auth/APIs";

const OnlineEvent = ({ type }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const EventID = useSelector((state) => state.community.eventId);
  const sampleEvent = useSelector((state) => state.community.eventDetails);

  const [submitloader, setSubmitLoader] = useState(false);

  const [onlineEvent, setOnlineEvent] = useState({
    description: sampleEvent?.virtualDetail?.description || "",
    remarks: sampleEvent?.virtualDetail?.remarks || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setOnlineEvent({ ...onlineEvent, [name]: value });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const payload = {
      basicInfo: sampleEvent.basicInfo,
      virtualDetail: onlineEvent,
      venue: {},
    };
    if (!EventID) {
      try {
        const res = await CreateEvent(payload);
        toast.success("Event Created Successfully");
        dispatch(eventId(res.data.message._id));
        setSubmitLoader(false);
      } catch (error) {
        setSubmitLoader(false);
        toast.error("Event Creation Failed");
        console.log(error);
      }
    } else {
      try {
        const res = await UpdateEvent({ id: EventID, data: payload });
        toast.success("Event Updated Successfully");
        setSubmitLoader(false);
      } catch (error) {
        toast.error("Event Updation Failed");
        setSubmitLoader(false);
        console.log(error);
      }
    }
  };

  const handleNext = () => {
    navigate("?tab=faq");
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(eventChange({ name: "virtualDetail", payload: onlineEvent }));
    }, 300);
  }, [onlineEvent]);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Virtual Event Details</h5>

        <form onSubmit={handleSave}>
          <Row className="mt-4">
            <Col lg={12} className="d-flex flex-column justify-content-between">
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>
                      Description of virtual Event link & login code etc..
                    </label>
                    <div className="profile-input-field">
                      <textarea
                        value={onlineEvent?.description}
                        className="description-textarea"
                        onChange={handleChange}
                        name="description"
                        id=""
                        cols="30"
                        rows=""
                        placeholder="Write something about the event..."
                      ></textarea>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Remarks</label>
                    <div className="profile-input-field">
                      <textarea
                        value={onlineEvent?.remarks}
                        className="description-textarea"
                        onChange={handleChange}
                        name="remarks"
                        id=""
                        cols="30"
                        rows=""
                        placeholder="Write something about the event..."
                      ></textarea>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>

            {type === "edit" && (
              <div className="d-flex justify-content-between mt-5">
                <div className="form-width-btn">
                  <button className="save-draft">
                    {submitloader ? (
                      <div
                        className="spinner-border theme-colour ml-4"
                        role="status"
                      ></div>
                    ) : (
                      <> Save</>
                    )}
                  </button>
                </div>
                <div onClick={handleNext} className="form-width-btn">
                  <button type="button" className="form-action-next">
                    Next
                  </button>
                </div>
              </div>
            )}
          </Row>
        </form>
      </div>
    </>
  );
};

export default OnlineEvent;

import React from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import Table from "react-bootstrap/Table";
import "./FactoryDetails.css";

import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";



const FactoryDetails = () => {
    const { companyFactory } = useSelector((state) => state.company);
    console.log(companyFactory);
    const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  
  return (
    <>
   

   
      <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Factory Details "} type={"back"}/>
        <div className="mt-4">
          <Table className="company-table" responsive="sm" bordered>
            <thead></thead>
            <tbody>
              <tr>
                <td>Factory Name</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.factoryName}
                </td>
              </tr>
              <tr>
                <td>Address</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.address}
                </td>
              </tr>
              <tr>
                <td>Description</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.description}
                </td>
              </tr>
              <tr>
                <td>Factory Size</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.factorySize}
                </td>
              </tr>
              <tr>
                <td> No. of Production Staff</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.numOfProductionStaff}
                </td>
              </tr>
              <tr>
                <td>No. of Production lines</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.numofProductionLines}
                </td>
              </tr>
              <tr>
                <td> No.of QC Staff</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.numOfQCStaff}
                </td>
              </tr>
              <tr>
                <td> No. of R&D Staff</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.numOfRnD}
                </td>
              </tr>
              <tr>
                <td>Year Established</td>
                <td className="table-bold-head" colSpan={3}>
                  {companyFactory?.yearOFEstablishment}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </DashboardBoxStyle>
     
    </>
  );
};

export default FactoryDetails;

import React from 'react'
import { BsHeart, BsChat, BsThreeDotsVertical } from "react-icons/bs"
import { RiShareForwardLine } from "react-icons/ri"
import { useNavigate } from 'react-router-dom';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "@mui/material";
import { useState } from 'react';


const NewReleaseCard = (props) => {

 
    const { _id, newsTitle, newDescription, featuredImage } = props.data

    

   

    const isDot = props.isDot || false
    return (
        <>
            <div className="position-relative podcast-card border">
                <div>
                    <div className="podcast-video">
                        <img src={featuredImage} alt="pod" className="img-size" />
                        {/* <div className="play-icon">
                            <i class="fa-sharp fa-solid fa-circle-play"></i>
                        </div> */}
                    </div>

                    <div className="podcast-detail">
                        <h5>{newsTitle}</h5>
                        <p>{newDescription}</p>
                    </div>

                    <div className="contentLibraryCartIcons d-flex justify-content-between align-items-center mb-0  podcast-detail">
                        <div className="d-flex justify-content-between align-items-center gap-2">
                            <span>
                                <BsHeart color="#acb5bd" size={24} />
                            </span>
                            <span>0</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2">
                            <span>
                                <BsChat color="#acb5bd" size={24} />
                            </span>
                            <span>0</span>
                        </div>
                        <div className="d-flex justify-content-between align-items-center gap-2">
                            <span>
                                <RiShareForwardLine color="#acb5bd" size={24} />
                            </span>
                            <span>0</span>
                        </div>

                    </div>
                </div>
               
            </div>
        </>
    )
}

export default NewReleaseCard
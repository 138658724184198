import React from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import "./Inquiry.css";
import { AiOutlineLeft } from "react-icons/ai";
import {  useNavigate } from "react-router-dom";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";



const Inquiry = () => {
  const { leadAndEquiry } = useSelector((state) => state.company);
  console.log(leadAndEquiry);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  

  return (
    <>
   

  
      <DashboardBoxStyle className=" scroller">
       
        <TopHeader headerTitle={"Inquiry & Lead Delivery "} type={"back"}/>
        <div className="mt-4" style={{ position: "relative" }}>
          <div className="factoryInfoComp mb-5">
           

            <Row className="rowClass ">
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2">
                  <span>Inquiry form name</span>
                </div>
              </Col>
              <Col lg={9} className="factoryCol factoryCol1">
                <div className="firstInfo2 fw">
                  <span>{leadAndEquiry?.inquiryFormName}</span>
                </div>
              </Col>
            </Row>

            <Row className="rowClass">
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2">
                  <span>Name</span>
                </div>
              </Col>
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2 fw">
                  <span>{leadAndEquiry?.name}</span>
                </div>
              </Col>
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2">
                  <span>Email</span>
                </div>
              </Col>
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2 fw">
                  <span>{leadAndEquiry?.email}</span>
                </div>
              </Col>
            </Row>
            <Row className="rowClass">
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2">
                  <span>Subject</span>
                </div>
              </Col>
              <Col lg={3} className="factoryCol">
                <div className="firstInfo2 fw">
                  <span>{leadAndEquiry?.subject}</span>
                </div>
              </Col>
            </Row>

            {leadAndEquiry?.createHeading.map((item, index) => (
              <Row className="rowClass removeRowClass">
                <Col lg={3} className="factoryCol">
                  <div className="firstInfo2">
                    <span>{item.head}</span>
                  </div>
                </Col>
                <Col lg={9} className="factoryCol factoryCol1">
                  <div className="firstInfo2 fw">
                    <span>{item.type}</span>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </DashboardBoxStyle>
     
    </>
  );
};

export default Inquiry;

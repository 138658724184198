import React, { useState } from 'react'
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { BiPencil, BiTrash } from "react-icons/bi"
// import { DeleteEventSocial, UpdateEventSocial } from '../../../../axios/apis';
import { toast } from 'react-toastify';

const SocialShareAccordian = (props) => {
    const contentEl = useRef();

    const [deleteloader, setDeleteLoader] = useState(false)
    const [submitloader, setSubmitLoader] = useState(false)

    const { handleToggle, active, getAllSocial, data } = props;
    const { socialShare, _id, url } = data;

    const [socialData, setSocialData] = useState({
        socialShare: socialShare || '',
        url: url || ''
    })

    const handleChange = (e) => {
        setSocialData({ ...socialData, [e.target.name]: e.target.value })
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        // try {
        //     const res = await UpdateEventSocial({ id: _id, data: socialData })
        //     toast.success('Social Share Updated successfully')
        //     setSubmitLoader(false)
        //     if (res.data.success) {
        //         handleToggle(_id)
        //         getAllSocial()
        //     }
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setSubmitLoader(false)
        //     console.log(error)
        // }
    }

    const removeItem = async () => {
        setDeleteLoader(true)
        // try {
        //     const res = await DeleteEventSocial(_id)
        //     if (res.data.success) {
        //         toast.success('Social Share deleted successfully')
        //         getAllSocial()
        //     }
        //     setDeleteLoader(false)
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setDeleteLoader(false)
        //     console.log(error)
        // }
    }


    return (
        <>
            <div className='edit-accordian-card'>
                <div className="edit-accordian-head">
                    <div className="edit-accordian-toggle p-3">
                        <h4>{socialShare}</h4>
                        <div className='d-flex'>
                            <div onClick={() => handleToggle(_id)} className="pencil-edit-btn accordian-edit-btns">
                                <BiPencil />
                            </div>
                            <div onClick={removeItem} className={`trash-edit-btn accordian-edit-btns ${deleteloader && "theme-red"}`}>
                                {
                                    deleteloader ? <div
                                        className="spinner-border text-white spinner-border-sm"
                                        role="status"
                                    ></div> : <>  <BiTrash /></>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        ref={contentEl}
                        className={`rc-collapse ${active === _id ? "show" : ""}`}
                        style={
                            active === _id
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0px" }
                        }>
                        <div className="rc-accordion-body">
                            <Row>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Social Share</label>
                                        <div className='profile-input-field'>
                                            <input value={socialData?.socialShare} onChange={handleChange} name="socialShare" type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Link URL</label>
                                        <div className='profile-input-field'>
                                            <input value={socialData?.url} onChange={handleChange} name='url' type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <div className='d-flex justify-content-end mt-4' >
                                    <div className="form-width-btn">
                                        <button onClick={handleSave} className='form-action-next'>
                                            {
                                                submitloader ? <div
                                                    className="spinner-border text-white ml-4"
                                                    role="status"
                                                ></div> : <> Save</>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SocialShareAccordian
import React from "react";
import { useNavigate } from "react-router-dom";

const TransactionTable = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>ID</th>
            <th>Company Name</th>
            <th>Date</th>
            <th>Type</th>
            <th>Transaction ID</th>
            <th>Amount</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item?.company_id?.companyDetails?.companyName}</td>
              <td>{item?.date?.slice(0, 10)}</td>
              <td>
                {item?.type && item.type.toLowerCase() === "event"
                  ? "Foundation Plan"
                  : "Add On Plan"}
              </td>
              <td>{item?.txnId}</td>
              <td>${item?.amount} USD</td>
              <td style={{ color: item?.amount_status ? `green` : `red` }}>
                {item?.amount_status ? "Success" : "Failure"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;

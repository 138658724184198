import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  categories:[],
};

export const categorySlice = createSlice({
  name: "category",
  initialState,
  reducers: {
    default: (state) => {
      return {
        ...state,
      };
    },

    setCategoryDetails: (state, value) => {
      state.categories = [...value.payload];
    },
  },
});

export const { setCategoryDetails } = categorySlice.actions;

export default categorySlice.reducer;

import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RxCross2 } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";

import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import {
  CreateBlog,
  createMagzine,
  getMagzineById,
  updateMagzine,
} from "../../../../util/Auth/APIs";
import { storage } from "../../../../firebase";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SelectAllCategories from "../Category/SelectAllCategories";

const InsightsWhitePaper = () => {
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [imageTwoLoader, setImageTwoLoader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(false);

  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();

  const [magzineDetails, setMagzineDetails] = useState({
    isPublished: false,
    magzineTitle: "",
    category: "",
    category2: "",
    category3: "",
    category4: "",
    content: "",
    magzineImages: [],
    magzinePrice: null,
    magzineDiscountPrice: null,
    uploadMagzine: [],
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isPublished") {
      setMagzineDetails({ ...magzineDetails, [name]: e.target.checked });
    } else if (name === "blogVideos" || name === "keywords") {
      setMagzineDetails({ ...magzineDetails, [name]: [e.target.value] });
    } else {
      setMagzineDetails({ ...magzineDetails, [name]: value });
    }
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }], ['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const handleQuill = (content, delta, source, editor) => {
    setMagzineDetails({ ...magzineDetails, content: editor.getHTML() });
    setValue(editor.getHTML());
  };

  const handleImage = (e, type) => {
    console.log(e.target.files);

    if (type === "magzineImage") {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg"
      ) {
        setImageloader(true);
        const storageRef = ref(
          storage,
          `insights/magzine/${e.target.files[0].name}`
        );
        uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
          console.log(snapshot);
          console.log("Uploaded a blob or file!");
          getDownloadURL(snapshot.ref).then((url) => {
            setMagzineDetails({
              ...magzineDetails,
              magzineImages: [...magzineDetails.magzineImages, url],
            });
            setImageloader(false);
            // companyBlogs.blogImages.push(url)
          });
        });
      } else {
        toast.error("Please upload only jpeg, jpg or png image");
        return;
      }
    } else if (type === "uploadImage") {
      if (
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "application/pdf"
      ) {
        setImageTwoLoader(true);
        const storageRef = ref(
          storage,
          `insights/magzineFile/${e.target.files[0].name}`
        );
        uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
          console.log(snapshot);
          console.log("Uploaded a blob or file!");
          getDownloadURL(snapshot.ref).then((url) => {
            setMagzineDetails({
              ...magzineDetails,
              uploadMagzine: [url],
            });
            setImageTwoLoader(false);
            // companyBlogs.blogImages.push(url)
          });
        });
      } else {
        toast.error("Please upload only jpeg, jpg or png image");
        return;
      }
    } else {
      return;
    }
  };


  console.log(magzineDetails)


  const singleMagzine = async () => {
    setLoader(true);
    try {
      const res = await getMagzineById(id);
      setMagzineDetails({
        isPublished: res?.data?.message?.isPublished,
        magzineTitle: res?.data?.message?.title,
        category: res?.data?.message?.category,
        category2: res?.data?.message?.category2,
        category3: res?.data?.message?.category3,
        category4: res?.data?.message?.category4,
        magzinePrice: res?.data?.message?.price,
        magzineDiscountPrice: res?.data?.message?.discount,
        magzineImages: res?.data?.message?.images,
        uploadMagzine: res?.data?.message?.magazines,
        type: res?.data?.message?.type,
        content: res?.data?.message?.content,
      });

      setLoader(false);
      console.log(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    singleMagzine();
  }, []);
  return (
    <>
      {loader ? (
        <LoadingPage />
      ) : (
        <form >
          <DashboardBoxStyle className="scroller">
            <TopHeader headerTitle={"Magzine Details"} type="back" />

            <div className="form-container position-relative mt-4">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  defaultChecked={magzineDetails.isPublished ? true : false}
                  disabled={true}

                  className="hr-select"
                  name="isPublished"
                  id=""
                />

                <span className="ispublished-text">
                  Do you want to add this in company view page
                </span>
              </div>
              {/* --------------  Blog Title ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Magazine Title</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      disabled={true}

                      value={magzineDetails?.magzineTitle}
                      name="magzineTitle"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>

              <SelectAllCategories
                selected={magzineDetails}
                setSelected={setMagzineDetails}
              />



              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Type</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <select value={magzineDetails.type} required name="type" id="">
                      <option selected disabled>
                        select
                      </option>
                      <option value="Public">Public</option>
                      <option value="Private">Private</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* --------------   Content ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Magazine Content</h6>
                </div>

                <div className="profile-input-width">
                  <ReactQuill
                    style={{ background: "white" }}
                    theme="snow"
                    modules={Boxmodules}

                    value={magzineDetails?.content}

                    placeholder="Description"
                  />
                  {/* <div className='profile-input-field'>
                                      <input  name="content" type="text" />
                                  </div> */}
                </div>
              </div>

              {/* --------------  Blog Images ----------- */}
              <div className="d-flex mt-4">
                <div className="profile-input-head">
                  <h6>Magazine Images</h6>
                </div>
                <div className="profile-input-width">
                  <div className="upload-button">
                    {imageloader ? (
                      <>
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      </>
                    ) : (
                      <>
                        <span className="d-flex m-0 text-white">
                          <BiUpload /> Upload Image
                        </span>
                        <input
                          disabled={true}

                          accept="image/png, image/gif, image/jpeg"
                          name="magzineImages"
                          type="file"
                        />
                      </>
                    )}
                  </div>

                  {magzineDetails.magzineImages.length !== 0 && (
                    <>
                      <div className="mt-3 profile-input-field">
                        <Row>
                          {magzineDetails.magzineImages.map((img, index) => (
                            <Col lg={3}>
                              <div className="upload-img-card">
                                <img
                                  src={img}
                                  className={`img-size ${deleteloader && "brightness-50"
                                    }`}
                                  alt=""
                                />
                                {deleteloader && (
                                  <div
                                    className="spinner-border position-absolute text-white ml-4"
                                    role="status"
                                  ></div>
                                )}
                                <div

                                  className="upload-img-cross"
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* -------------- Promotion  ---------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Magazine Price</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      disabled={true}

                      value={magzineDetails?.magzinePrice}
                      name="magzinePrice"
                      type="number"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              {/* -------------- Promotion  ---------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Magazine Discount Price</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      disabled={true}

                      value={magzineDetails?.magzineDiscountPrice}
                      name="magzineDiscountPrice"
                      type="number"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              {/* --------------  Blog Images ----------- */}
              <div className="d-flex mt-4">
                <div className="profile-input-head">
                  <h6>Upload Magazine</h6>
                </div>
                <div className="profile-input-width">
                  <div className="upload-button">
                    {imageTwoLoader ? (
                      <>
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      </>
                    ) : (
                      <>
                        <span className="d-flex m-0 text-white">
                          <BiUpload /> Upload File
                        </span>
                        <input
                          disabled={true}
                          name="uploadMagzine"
                          type="file"
                        />
                      </>
                    )}
                  </div>

                  {magzineDetails.uploadMagzine.length !== 0 && (
                    <>
                      <div className="mt-3 profile-input-field">
                        <Row>
                          {magzineDetails.uploadMagzine.map((img, index) => (
                            <Col lg={3}>
                              <div className="upload-img-card">
                                <img
                                  src={img}
                                  className={`img-size ${deleteloader && "brightness-50"
                                    }`}
                                  alt=""
                                />
                                {deleteloader && (
                                  <div
                                    className="spinner-border position-absolute text-white ml-4"
                                    role="status"
                                  ></div>
                                )}
                                <div

                                  className="upload-img-cross"
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </div>



              <div
                onClick={() => navigate(-1)}
                className="close-job cross-sign-pos"
              >
                <RxCross2 />
              </div>
            </div>
          </DashboardBoxStyle>
        </form>
      )}
    </>
  );
};

export default InsightsWhitePaper;

import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const CategoryCard = ({ data, handleLevel, removeItem }) => {

    const { _id, name, description, cover } = data
    const [showOptions, setShowOptions] = useState(false);

    const navigate = useNavigate()

    return (
        <div className="category-card">
            <div onClick={() => handleLevel(_id)} style={{ position: "relative" }}>
                <div className="category-img">
                    <img src={cover} className='img-size' alt="" />
                </div>
                <h5>{name}</h5>
                <p>{description}</p>
            </div>
            <div className="category-card-menuIcon">
                <BsThreeDotsVertical
                    onClick={(event) => {
                        event.stopPropagation(); // Stop event propagation
                        setShowOptions(!showOptions);
                        // handleOptionsClick(item._id);
                    }}
                />
            </div>
            {showOptions && (
                <ul className="options-menu">
                    {/* <li
                        onClick={(event) => {
                            event.stopPropagation();
                            navigate(`/category/add-category/${_id}`);
                        }}>
                        Add Categories
                    </li> */}
                    <li
                        onClick={(event) => {
                            // event.stopPropagation();

                            navigate(`/category/edit/${_id}`);
                        }}>
                        Edit
                    </li>
                    <li
                        onClick={(event) => {
                            event.stopPropagation();
                            removeItem(_id);
                        }}>
                        Delete
                    </li>
                </ul>
            )}
        </div>
    )
}

export default CategoryCard
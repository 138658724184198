import React from 'react'
import CommunityHead from './CommunityHead.js'
import { useSelector } from "react-redux";

const CommunityAbout = () => {

    const {communityDetail} = useSelector((state) => state.community);

    const commdesc = [
        {
            head: "Public",
            para: "Anyone can see who’s in the group and what they post"
        },
        {
            head: "Visible",
            para: "Anyone can find this group"
        }
    ]

    return (
        <>
            <div className="community-detail-container">
                <CommunityHead secHead="About" />

                <div className=" community-detail-subhead">
                    <div className="community-post-card">
                        <p className='community-about'>{communityDetail?.description}</p>

                        {
                            commdesc.map((e) => (
                                <div className="community-head mt-3">
                                    <h6>{e.head}</h6>
                                    <p>{e.para}</p>
                                </div>
                            ))
                        }
                    </div>

                </div>
            </div>
        </>
    )
}

export default CommunityAbout
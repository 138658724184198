import React from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import EventFaqAccordian from "./EventFaqAccordian.js";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import {
  AllEventFAQ,
  CreateEvent,
  CreateEventFAQ,
  UpdateEvent,
} from "../../../../util/Auth/APIs";

const EventFAQ = ({ type }) => {
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);

  const EventID = useSelector((state) => state.community.eventId);

  const [active, setActive] = useState(null);
  const [faqShow, setFaqShow] = useState(false);

  const [faqlist, setFaqlist] = useState([]);

  const [faqForm, setFaqForm] = useState({
    event: EventID,
    question: "",
    answer: "",
  });

  const handleChange = (e) => {
    setFaqForm({ ...faqForm, [e.target.name]: e.target.value });
  };

  const handleClose = () => setFaqShow(false);

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      if (EventID) {
        const res = CreateEventFAQ(faqForm);
        if ((await res).data.success) {
          getAllFAQ();
        }
        toast.success("FAQ created successfully");
        setSubmitLoader(false);
        setFaqShow(false);
      } else {
        toast.error("Please create event first");
      }
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const getAllFAQ = async () => {
    setLoader(true);
    try {
      if (EventID) {
        const res = await AllEventFAQ(EventID);
        setFaqlist(res.data.message.FAQ.reverse());
        console.log(res);
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFAQ();
  }, []);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Create FAQ</h5>

        {loader ? (
          <LoadingPage />
        ) : (
          <>
            <Row className="mt-4">
              <Col
                lg={12}
                className="d-flex flex-column justify-content-between"
              >
                <Row>
                  {faqlist.length === 0 && (
                    <>
                      <Col lg={12}>
                        <div className="no-list-btn">No Faq</div>
                      </Col>
                    </>
                  )}
                  {faqlist.length !== 0 &&
                    faqlist.map((data, index) => (
                      <Col lg={12}>
                        <EventFaqAccordian
                          key={index}
                          active={active}
                          getAllFAQ={getAllFAQ}
                          handleToggle={handleToggle}
                          data={data}
                        />
                      </Col>
                    ))}

                  {type === "edit" && (
                    <Col lg={12}>
                      <div className="d-flex mt-2 justify-content-end">
                        <div
                          onClick={() => setFaqShow(true)}
                          className="add-sign position-relative"
                        >
                          <i class="fa-solid x-cross2 fa-circle-plus"></i>
                        </div>
                      </div>
                    </Col>
                  )}
                  
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Modal show={faqShow} onHide={handleClose} centered>
          <div className="event-form-container">
            <div className="">
              <div className="event-form-top">
                <span>Add FAQ</span>
                <i
                  onClick={handleClose}
                  class="fa-solid form-x-cross fa-circle-xmark"
                ></i>
              </div>
            </div>

            <form onSubmit={handleSave}>
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Question</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="question"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Answer</label>
                    <div className="profile-input-field">
                      <textarea
                        onChange={handleChange}
                        name="answer"
                        className="faq-answer-textarea"
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                </Col>
              </Row>

              <div className="d-flex justify-content-end mt-4">
                <div className="form-width-btn">
                  <button className="form-action-next">
                    {submitloader ? (
                      <div
                        className="spinner-border text-white ml-4"
                        role="status"
                      ></div>
                    ) : (
                      <> Save</>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default EventFAQ;

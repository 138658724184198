import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import deleteImg from "../../assets/delete.png";
import editImg from "../../assets/edit.png";



import './CustomTable.css';
import { Switch } from '@mui/material';
import { deleteAdminById, updateAdminById } from "../../util/Auth/APIs";
import { toast } from "react-toastify";


const CustomTable = ({ type, header, data }) => {

  const [list , setList] = useState(data);

  const [deleteloader , setDeleteLoader]= useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");


  const navigate = useNavigate();

  const removeAdmin = async (id) => {
    setDeleteLoader(true);
    setDeletingItemId(id);
    try {
      const res = await deleteAdminById(id);
      setDeleteLoader(false);
      toast.success("User deleted successfully");
      setList(data.filter((item) => item._id !== id));
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteLoader(false);
      console.log(error);
    }
  };

  const handleToggle = async (id) => {

    console.log(id);
   
    const planIndex = list.findIndex((item) => item._id === id);
    const planToUpdate = list[planIndex];

    const updatedPlan = {
      ...planToUpdate,
      isActive: !planToUpdate.isActive,
    };
    

   
    try {
      const res = await updateAdminById({
        id: planToUpdate._id,
        data: updatedPlan,
      });
      setList([
        ...list.slice(0, planIndex),
        updatedPlan,
        ...list.slice(planIndex + 1),
      ]);
      toast.success("Plan Updated Successfully");
      console.log("success");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };




  return (
    <div className='table-container'>
      <table className='Table'>
        <thead className='table-head'>
          <tr>
            {header.map((item) => (
              <th

                key={item.id}
              >
                {item.label}
              </th>
            ))}

          </tr>

        </thead>
        <tbody>
          {type === "supportTable" && list.map((item, index) => (
            <tr key={item.id} >
              <td>{index + 1}</td>
              <td>{item?.company_id?.companyDetails?.companyName}</td>
              <td>{item?.company_id?.companyDetails?.email}</td>
              <td>{item?.message.length > 25 ? item?.message.slice(0, 25) + '...' : item?.message}</td>

              <td>{item?.number}</td>
              <td style={{ color: item?.status == "pending" ? `red` : `green` }}>{item?.status}</td>
            </tr>
          ))}
          {type === "subAdmin" && list.map((item, index) => (
            <tr key={item.id} >
              <td>{index + 1}</td>
              <td>{item?.name}</td>
              <td>{item?.email}</td>
              <td>{item?.number}</td>

              <td>{item?.role}</td>
              <td>{item?.usageType === "viewer"? "Viewer" : "Editor"}</td>
              <td>
                <Switch
                  checked={item.isActive}
                  onChange={() => handleToggle(item._id)}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </td>
              <td>
                <div className="d-flex ">
                  <div
                    onClick={() =>  navigate(`/sub-admin-management/update/${item._id}`)}
                    className="edit"
                  >
                    <img src={editImg} alt="edit" />
                  </div>
                  <div onClick={() => removeAdmin(item?._id)} className="delete">
                    {deleteloader && deletingItemId === item?._id ? (
                      <div
                        className="spinner-border text-white spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <img src={deleteImg} alt="delete" />
                    )}
                  </div>
                </div>
              </td>

            </tr>
          ))}
        </tbody>
      </table>

    </div>

  );
};

export default CustomTable;

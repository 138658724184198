import React from "react";
import "./DealsOfferCard.css"


const DealsOfferCard = (props) => {
  const { _id, name, image, description } = props.data;

  return (
    <>
      <div className="position-relative">
        <div className={`event-cards my-4`}>
          <div className="event-img">
            <img src={image[0]} className="img-size" alt="" />
            {/* <img  className="card-user-pic" alt="" /> */}
          </div>

          <div className="top-event-details">
            <div className="d-flex card-heading">
              <h5>{name}</h5>
            </div>
          </div>
          <div className="low-event-details">
            <p>{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DealsOfferCard;

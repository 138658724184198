import React from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import SocialMediaCard from './SocialMediaCard.js';
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";


const SocialMedia = () => {
  const { socialMedia } = useSelector((state) => state.company);

  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  console.log(socialMedia);
  return (
    <>

      <DashboardBoxStyle className=" scroller">
        <TopHeader headerTitle={"Social Media"} type={"back"} />
        <div className="mt-4">
          <Row>
            {socialMedia.map((el, index) => {
              return (
                <Col lg={3} className="mb-4">
                  <SocialMediaCard data={el} />
                </Col>
              );
            })}
          </Row>
        </div>
      </DashboardBoxStyle>

    </>
  );
};

export default SocialMedia;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import {
  DeleteAdminProduct,
  deletePlan,
  updatePlan,
} from "../../util/Auth/APIs";
import { toast, ToastContainer } from "react-toastify";
import ToggleButton from "react-toggle-button";
import deleteImg from "../../assets/delete.png";
import editImg from "../../assets/edit.png";

import "./Table.css";
import { Switch } from "@mui/material";

const ProductTable = ({ data, planFor }) => {
  const type = localStorage.getItem("accessType");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [productList, setProductList] = useState(data);

  const navigate = useNavigate();

  useEffect(() => {
    setProductList(data);

  }, [data])


  const removeItem = async (id) => {
    setDeleteLoader(true);
    setDeletingItemId(id);
    try {
      const res = await deletePlan(id);
      setDeleteLoader(false);
      toast.success("Product deleted successfully");
      setProductList(data.filter((item) => item._id !== id));
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteLoader(false);
      console.log(error);
    }
  };
  const handleToggle = async (id) => {
    const planIndex = productList.findIndex((item) => item._id === id);
    const planToUpdate = productList[planIndex];
    const updatedPlan = {
      ...planToUpdate,
      isActive: !planToUpdate.isActive,
    };
    try {
      const res = await updatePlan({ id: planToUpdate._id, data: updatedPlan });
      setProductList([
        ...productList.slice(0, planIndex),
        updatedPlan,
        ...productList.slice(planIndex + 1),
      ]);
      toast.success("Plan Updated Successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };



  function handleNavigate(id) {
    if (planFor == "foundation") {
      navigate(`/plan-management/edit/foundation/${id}`);
    } else {
      navigate(`/plan-management/edit/add-on/${id}`);
    }
  }

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>S.No</th>
            <th>Created Date</th>
            <th>Plan Name</th>
            <th>Monthly Price</th>
            <th>Yearly Price</th>
            <th>Action</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {productList.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item?.createdAt.slice(0, 10)}</td>
              <td>{item?.name}</td>
              <td>{item?.monthPrice}</td>
              <td>{item?.yearPrice}</td>


              <td>
                <div className="d-flex ">
                  <div
                    onClick={() => handleNavigate(item?._id)}
                    className="edit"
                  >
                    <img src={editImg} alt="edit" />
                  </div>
                  <div
                    onClick={() => removeItem(item?._id)}
                    className="delete"
                  >
                    {deleteloader && deletingItemId === item?._id ? (
                      <div
                        className="spinner-border text-white spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <img src={deleteImg} alt="delete" />
                    )}
                  </div>
                </div>
              </td>



              <td>
                <div className="d-flex justify-content-center">
                  <Switch
                    checked={item.isActive}
                    onChange={() => handleToggle(item._id)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </td>

            </tr>
          ))}
        </tbody>
        <ToastContainer />
      </table>
    </div>
  );
};

export default ProductTable;

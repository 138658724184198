import React, { useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CustomButton from "../../../../Components/Button/CustomButton/CustomButton";
import { createNewAdmin } from "../../../../util/Auth/APIs";
import { toast } from "react-toastify";
import "./CreateSubAdmin.css";

const CreateSubAdmin = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isValidMobile, setIsValidMobile] = useState(true);

  const [user, setUser] = useState({
    name: "",
    email: "",
    number: "",
    password: "",
    role: "",
    usageType:"",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "number") {
      const mobilePattern = /^\d{10}$/; // Assuming 10-digit mobile number
      setIsValidMobile(mobilePattern.test(value));
    }
    setUser({
      ...user,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);
    
    try {
      const res = await createNewAdmin(user)
      toast.success(`${user.role} created successfully`);
      setLoadingBtn(false);
      
    } catch (error) {
      toast.error("Something went wrong");
      setLoadingBtn(false);
      
    }
  };
  return (
    <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Create Sub Admin"} type={"back"} />

      <form onSubmit={handleSubmit} className="create-community-container">
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Name</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="name"
                required
                type="text"
                onChange={handleChange}
                placeholder="Enter Name"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Email</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="email"
                required
                type="email"
                onChange={handleChange}
                placeholder="Enter Email"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Password</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="password"
                required
                type="text"
                onChange={handleChange}
                placeholder="Enter Password"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Mobile Number</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="number"
                type="number"
                placeholder="Enter Mobile Number"
                required
                onChange={handleChange}
                inputMode="numeric"
              />
            </div>
              {!isValidMobile && <p style={{ color: "red" }}>Invalid mobile number!</p>}
          </div>
        </div>

        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Role</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <select
                placeholder="Select role"
                onChange={handleChange}
                required
                name="role"
                value={user.role}
                id=""
              >
                <option value=""  disabled selected>
                  Select Role
                </option>
                <option value="sub-admin">Sub Admin</option>
                <option value="admin">Super Admin</option>
              </select>
              {/* <input value={companyBlogs.blogCategory} onChange={handleChange} name="blogCategory" type="text" /> */}
            </div>
          </div>
        </div>

        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Access Type</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <select
                placeholder="Select role"
                onChange={handleChange}
                required
                name="usageType"
                value={user.usageType}
                id=""
              >
                <option value="" disabled selected>
                  Select Access Type
                </option>
                <option value="viewer">Viewer</option>
                <option value="editor">Editor</option>
              </select>
              {/* <input value={companyBlogs.blogCategory} onChange={handleChange} name="blogCategory" type="text" /> */}
            </div>
          </div>
        </div>

        <div className="middle">
          <CustomButton loading={loadingBtn} classId="small-btn">
            Submit
          </CustomButton>
        </div>
      </form>
    </DashboardBoxStyle>
  );
};

export default CreateSubAdmin;

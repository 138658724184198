import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { CreateProduct, UpdateProduct } from "../../../../../../util/Auth/APIs";
import { storage } from "../../../../../../firebase.js";
import { toast, ToastContainer } from "react-toastify";
import { CgLogOff } from "react-icons/cg";
import { setDownloadable } from "../../../../../../app/features/adminProducts/productSlice";

const DownloadableFiles = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [submitloader, setSubmitLoader] = useState(false);
  const [fileloader, setFileLoader] = useState(false);
  const { id } = useParams();

  const Virtual = useSelector((state) => state.product.isVirtual);
  console.log(Virtual);
  const [productType, setProductType] = useState(Virtual);

  const sample = useSelector((state) => state.product.downloadable);

  const isVirtual = useSelector((state) => state.product.isVirtual);
  const general = useSelector((state) => state.product.general);
  const inventoryy = useSelector((state) => state.product.inventoryy);
  const linkedProduct = useSelector((state) => state.product.linkedProduct);
  const attribute = useSelector((state) => state.product.attribute);

  // const [downloadProduct, setDownloadProduct] = useState({ ...sample });
  const [downloadProduct, setDownloadProduct] = useState({
    limit: sample?.limit || "",
    expiry: sample?.expiry || "",
    files: sample?.files || [],
    // || [],
    // url: sample?.downloadable?.url || ''
  });
  // console.log(sample);

  const [files, setFiles] = useState(downloadProduct?.files);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "limit") {
      setDownloadProduct({ ...downloadProduct, [name]: parseInt(value) });
    } else {
      setDownloadProduct({ ...downloadProduct, [name]: value });
    }
  };

  const handleFile = (e) => {
    if (e.target.files[0].type) {
      setFileLoader(true);
      setDownloadProduct({
        ...downloadProduct,
        files: { ...downloadProduct.files },
      });
      const storageRef = ref(
        storage,
        `DownloadableFile/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setFiles([...files, { name: e.target.files[0].name, url: url }]);
          // setDownloadProduct({ ...downloadProduct, files: [...downloadProduct.files, { name: e.target.files[0].name, url: url }] })
          // setProductGeneral({ ...productGeneral, productsImage: [...productGeneral.productsImage, url] })
          debugger;
          setFileLoader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    if (productType) {
      const payload = {
        isVirtual: productType,
        general: general,
        inventory: inventoryy,
        linkedProduct: linkedProduct,
        attribute: attribute,
        downloadable: downloadProduct,
      };

      try {
        if (
          window.location.pathname === "/product/add" ||
          window.location.pathname === "/company/product/add"
        ) {
          console.log("adding product section running");
          const res = await CreateProduct(payload);
          toast.success("Product Created Successfully");
          setSubmitLoader(false);
          if (window.location.pathname === "/product/add") {
            navigate("/product/all");
          } else {
            navigate("/company/product");
          }
          console.log(res);
        } else {
          console.log("editing product section running");
          console.log(id);
          console.log(payload);

          const res = await UpdateProduct({ id, data: payload });
          console.log({ message: res.data.message });
          toast.success("Product Update successfully");

          if (window.location.pathname.includes(`product/edit`)) {
            navigate(`/product/all`);
          } else {
            navigate(`/company/product/`);
          }
          // console.log(res);
          toast.success("Product Update successfully");
          setSubmitLoader(false);
        }
      } catch (err) {
        toast.error("Something went wrong");
        setSubmitLoader(false);
        console.log(err);
      }
    } else {
      setSubmitLoader(false);
      toast.error("Something went wrong, please select Physical Product");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      console.log(downloadProduct);
      dispatch(setDownloadable(downloadProduct));
    }, 300);
  }, []);

  useEffect(() => {
    setDownloadProduct({
      ...downloadProduct,
      files: files,
    });
  }, [files]);
  return (
    <>
      <form onSubmit={handleSave}>
        <Row className="mt-2">
          <Col lg={12} className="d-flex flex-column justify-content-between">
            <Row>
              <Col lg={6}>
                <div className="half-input-box">
                  <label>Download Limit</label>
                  <div className="profile-input-field">
                    <input
                      value={downloadProduct?.limit}
                      onChange={handleChange}
                      name="limit"
                      type="text"
                      placeholder="Unlimited"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="half-input-box">
                  <label>Download Expiry</label>
                  <div className="profile-input-field">
                    <select
                      value={downloadProduct?.expiry}
                      onChange={handleChange}
                      required
                      name="expiry"
                      id=""
                    >
                      <option value="" selected>
                        select category
                      </option>
                      <option value="Never">Never</option>
                      <option value="1 Week">1 Week</option>
                      <option value="1 Month">1 Month</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Downloadable Files</label>
                  <div className="upload-button">
                    {fileloader ? (
                      <>
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      </>
                    ) : (
                      <>
                        <span className="d-flex m-0 text-white">
                          <BiUpload /> Upload File
                        </span>
                        <input
                          onChange={handleFile}
                          name="blogImages"
                          type="file"
                        />
                      </>
                    )}
                  </div>

                  {downloadProduct?.files?.length !== 0 && (
                    <>
                      <Table className="product-table mt-3" responsive="sm">
                        <tbody>
                          {files.map((item, index) => (
                            <tr>
                              <td>
                                <a
                                  onClick={() => {
                                  
                                    if (item?.url) {
                                      window.open(item.url, "_blank");
                                    } else {
                                      console.error("Invalid URL:", item?.url);
                                    }
                                  }}
                                >
                                  {item?.name}
                                </a>
                              </td>
                              <td className="download-table-remove">
                                <div className="upload-img-cross position-relative">
                                  <MdCancel />
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </Col>
              {/* <Col lg={12}>
                <div className="half-input-box">
                  <div className='profile-input-field'>
                    <input onChange={handleChange} name="url" type="text" placeholder='File URL' />
                  </div>
                </div>
              </Col> */}
            </Row>
            <div className="d-flex justify-content-end mt-5">
              <div className="form-width-btn">
                <button className="form-action-next">
                  {submitloader ? (
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  ) : (
                    <> Save</>
                  )}
                </button>
              </div>
              {/* <div className="form-width-btn">
                <button className='form-action-next'>Next</button>
              </div> */}
            </div>
          </Col>
        </Row>
        <ToastContainer />
      </form>
    </>
  );
};

export default DownloadableFiles;

import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RxCross2 } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { GetBlogById, UpdateBlog } from "../../../util/Auth/APIs";
import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";

import { storage } from "../../../firebase";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";

const EditBlog = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);

  const [companyBlogs, setCompanyBlogs] = useState({
    isPublished: false,
    blogTitle: "",
    blogCategory: "",
    blogDescription: "",
    content: "",
    blogImages: [],
    blogVideos: [],
    remarks: "",
    keywords: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isPublished") {
      setCompanyBlogs({ ...companyBlogs, [name]: e.target.checked });
    } else if (name === "blogVideos" || name === "keywords") {
      setCompanyBlogs({ ...companyBlogs, [name]: [e.target.value] });
    } else {
      setCompanyBlogs({ ...companyBlogs, [name]: value });
    }
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const handleQuill = (content, delta, source, editor) => {
    setCompanyBlogs({ ...companyBlogs, content: editor.getHTML() });
    setValue(editor.getHTML());
  };

  const handleImage = (e) => {
    console.log(e.target.files);
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      const storageRef = ref(
        storage,
        `BlogsPictures/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCompanyBlogs({
            ...companyBlogs,
            blogImages: [...companyBlogs.blogImages, url],
          });
          setImageloader(false);
          // companyBlogs.blogImages.push(url)
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png file");
      return;
    }
  };

  const deleteFromFirebase = (url) => {
    console.log(url);
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    deleteObject(pictureRef)
      .then(() => {
        setCompanyBlogs({
          ...companyBlogs,
          blogImages: companyBlogs.blogImages.filter((item) => item !== url),
        });
        console.log("success");
        setDeleteLoader(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error("Cannot Remove Image ");
        // Uh-oh, an error occurred!
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const payload = {
      id: id,
      data: {
        title: companyBlogs.blogTitle,
        isPublished: companyBlogs.isPublished,
        category: companyBlogs.blogCategory,
        description: companyBlogs.blogDescription,
        content: companyBlogs.content,
        images: companyBlogs.blogImages,
        videos: companyBlogs.blogVideos,
        remarks: companyBlogs.remarks,
        keywords: companyBlogs.keywords,
      },
    };

    try {
      const res = await UpdateBlog(payload);
      toast.success("Blog Updated Successfully");
      setSubmitLoader(false);
      navigate(-1);
      console.log(res.data.message);
    } catch (error) {
      toast.error("Something went wrong");
      setSubmitLoader(false);
      console.log(error);
    }
  };

  const singleBlog = async () => {
    setLoader(false);
    try {
      const res = await GetBlogById(id);
      setCompanyBlogs({
        isPublished: res.data.message.isPublished,
        blogTitle: res.data.message.title,
        blogCategory: res.data.message.category,
        blogDescription: res.data.message.description,
        content: res.data.message.content,
        blogImages: res.data.message.images,
        blogVideos: res.data.message.videos,
        remarks: res.data.message.remarks,
        keywords: res.data.message.keywords,
      });
      setLoader(true);
      console.log(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    singleBlog();
  }, []);

  useEffect(() => {
    console.log(companyBlogs.blogImages);
  }, [companyBlogs.blogImages]);

  return (
    <>
     
        <form onSubmit={handleSubmit}>
          <DashboardBoxStyle className="scroller">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="admin-page-heading">Blog</h4>
            </div>

            <div className="form-container position-relative mt-4">
              <div className="d-flex align-items-center">
                <input
                  type="checkbox"
                  defaultChecked={
                    companyBlogs.isPublished === true ? true : false
                  }
                  onChange={handleChange}
                  className="hr-select"
                  name="isPublished"
                  id=""
                />
                <span className="ispublished-text">
                  Do you want to add this in company view page
                </span>
              </div>

              {/* --------------  Blog Title ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Blog Title</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      value={companyBlogs.blogTitle}
                      onChange={handleChange}
                      name="blogTitle"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              {/* --------------  Blog Category ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Blog Category</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <select
                      value={companyBlogs.blogCategory}
                      onChange={handleChange}
                      name="blogCategory"
                      id=""
                    >
                      <option value="">select</option>
                      <option value="Personal">Personal</option>
                      <option value="Business">Business</option>
                    </select>
                    {/* <input value={companyBlogs.blogCategory} onChange={handleChange} name="blogCategory" type="text" /> */}
                  </div>
                </div>
              </div>

              {/* -------------- Blog Description ----------- */}
              <div className="d-flex mt-4">
                <div className="profile-input-head">
                  <h6>Blog Description</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <textarea
                      value={companyBlogs.blogDescription}
                      onChange={handleChange}
                      name="blogDescription"
                      className="description-textarea"
                      id=""
                      cols="30"
                      rows=""
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* --------------   Content ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Content</h6>
                </div>

                <div className="profile-input-width">
                  <ReactQuill
                    style={{ background: "white" }}
                    theme="snow"
                    modules={Boxmodules}
                    value={companyBlogs.content}
                    onChange={handleQuill}
                    placeholder="Description"
                  />
                  {/* <div className='profile-input-field'>
                                <input onChange={handleChange} name="content" type="text" />
                            </div> */}
                </div>
              </div>

              {/* --------------  Blog Images ----------- */}
              <div className="d-flex mt-4">
                <div className="profile-input-head">
                  <h6>Blog Images</h6>
                </div>
                <div className="profile-input-width">
                  <div className="upload-button">
                    {imageloader ? (
                      <>
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      </>
                    ) : (
                      <>
                        <span className="d-flex m-0 text-white">
                          <BiUpload /> Upload Image
                        </span>
                        <input
                          onChange={handleImage}
                          accept="image/png, image/gif, image/jpeg"
                          name="blogImages"
                          type="file"
                        />
                      </>
                    )}
                  </div>

                  {companyBlogs.blogImages.length !== 0 && (
                    <>
                      <div className="mt-3 profile-input-field">
                        <Row>
                          {companyBlogs.blogImages.map((img, index) => (
                            <Col lg={3}>
                              <div className="upload-img-card">
                                <img
                                  src={img}
                                  className={`img-size ${
                                    deleteloader && "brightness-50"
                                  }`}
                                  alt=""
                                />
                                {deleteloader && (
                                  <div
                                    className="spinner-border position-absolute text-white ml-4"
                                    role="status"
                                  ></div>
                                )}
                                <div
                                  onClick={() => deleteFromFirebase(img)}
                                  className="upload-img-cross"
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </div>

              {/* --------------   Blog Videos----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Blog Videos</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      value={companyBlogs.blogVideos[0]}
                      onChange={handleChange}
                      name="blogVideos"
                      type="text"
                      placeholder="Add Link"
                    />
                  </div>
                </div>
              </div>

              {/* -------------- Promotion  ---------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Remarks</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      value={companyBlogs.remarks}
                      onChange={handleChange}
                      name="remarks"
                      type="text"
                      placeholder=""
                    />
                  </div>
                </div>
              </div>

              {/*---------    Keywords ------------------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Keywords</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                      value={companyBlogs.keywords[0]}
                      onChange={handleChange}
                      name="keywords"
                      type="text"
                    />
                  </div>
                </div>
              </div>

              <div className="mt-5 d-flex justify-content-end common-submit-button">
                <button>
                  {submitloader ? (
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  ) : (
                    <> Submit</>
                  )}
                </button>
              </div>

              <div
                onClick={() => navigate(-1)}
                className="close-job cross-sign-pos"
              >
                <RxCross2 />
              </div>
            </div>
          </DashboardBoxStyle>
        </form>
      
    </>
  );
};

export default EditBlog;

import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import "./EditCategory.css";
import deleteImg from "../../../../assets/delete.png";
import editImg from "../../../../assets/edit.png";

import { AiOutlineLeft } from "react-icons/ai";

import {
  getSingleCategory,
  removeSubCategory,
  updateCategory,
} from "../../../../util/Auth/APIs";

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";

const EditCategory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tags, setTags] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coverImage, setCoverImage] = useState(null);
  const [coverFileName, setCoverFileName] = useState(null);
  const [coverLink, setCoverLink] = useState(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [saveChangesLoader, setSaveChangesLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [CategoryDetails, setCategoryDetails] = useState({
    name: "",
    description: "",
    cover: "",
    keywords: [],
  });
  const inputRef = useRef(null);

  const handleClick = () => {
    navigate(-1);
  };
  const handleCoverImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      setCoverFileName(e.target.files[0].name);
      setCoverImage(URL.createObjectURL(e.target.files[0]));
      const storageRef = ref(storage, `Category/${e.target.files[0].name}`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCoverLink(url);
          setCategoryDetails({ ...CategoryDetails, cover: url });
          setImageloader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };
  // console.log(coverLink);
  const deleteFromFirebase = (url) => {
    console.log(url);
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    deleteObject(pictureRef)
      .then(() => {
        setCoverImage(null);
        setCoverFileName(null);
        setCategoryDetails({ ...CategoryDetails, cover: "" });
        console.log("deleted");

        toast.success("Image removed successfully");
        setDeleteLoader(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error("Cannot Remove Image");
      });
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.type === "click") {
      const value = e.target.parentElement
        .querySelector(".input-tag")
        .value.trim();
      if (value === "") return;
      const updatedTags = [...tags, value];
      setTags(updatedTags);
      setCategoryDetails({ ...CategoryDetails, keywords: [...updatedTags] });
      e.target.parentElement.querySelector(".input-tag").value = "";
    }
  }

  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setCategoryDetails({ ...CategoryDetails, keywords: [...updatedTags] });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setCategoryDetails({ ...CategoryDetails, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: CategoryDetails.name,
      description: CategoryDetails.description,
      cover: CategoryDetails.cover,
      keywords: CategoryDetails.keywords,
    };

    console.log(data);
    setSubmitLoader(true);

    try {
      const res = await updateCategory({ id, data });
      toast.success("Category Updated Successfully");
      setSubmitLoader(false);
      navigate(-1);
      // console.log(res);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      // console.log(error);
    }
  };

  const getCategoryDetails = async () => {
    try {
      const res = await getSingleCategory(id);
      console.log(res?.data?.data);
      setCategoryDetails({
        name: res?.data?.data?.name,
        description: res?.data?.data?.description,
        cover: res?.data?.data?.cover,

        keywords: [...res?.data?.data?.keywords],
      });
      setTags(res?.data?.data?.keywords);
      setCoverLink(res?.data?.data?.cover);
      setCoverImage(res?.data?.data?.cover);
      setLoading(false);
      setSubCategories(res?.data?.data?.subCategory);

      //   console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategoryDetails();
  }, []);

  // for sub categories

  const handleCardClick = (index) => {
    if (selectedCard === index) {
      setSelectedCard(null);
    } else {
      setSelectedCard(index);
    }
  };

  const handleDeleteCategory = async (index, subCategoryId) => {
    const updatedData = [...subCategories];

    const payload = {
      id: id,
      subCategoryId: subCategoryId,
    };

    try {
      const res = await removeSubCategory(payload);
      toast.success("Sub-category deleted successfully");
      updatedData.splice(index, 1);
      setSubCategories(updatedData);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  async function SaveChanges() {
    setSaveChangesLoader(true);

    const payload = {
      id: id,
      data: {
        subCategory: [...subCategories],
      },
    };

    try {
      const res = await updateCategory(payload);
      setSubmitLoader(false);
      toast.success("Changes saved successfully");
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
    }
  }

  const AddSubCategory = (index, e) => {
    const updatedData = [...subCategories];
    console.log(updatedData[index].subcategory);

    updatedData[index].subcategory.push(inputRef.current.value);
    setSubCategories(updatedData);
    inputRef.current.value = "";
  };

  const removeSubCategories = (index, subCategoryIndex) => {
    const updatedData = [...subCategories];

    updatedData[index].subcategory.splice(subCategoryIndex, 1);
    setSubCategories(updatedData);
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={CategoryDetails.name} type="back" />
          <div className="create-community-container">
            <div className="uploader-community-image">
              <div
                className="communityCover"
                onClick={() => document.querySelector(".input-cover").click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="input-cover"
                  hidden
                  onChange={handleCoverImage}
                />
                {imageloader ? (
                  <>
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    {coverImage ? (
                      <img src={coverImage} alt={coverFileName} />
                    ) : (
                      <p>Add Image </p>
                    )}
                  </>
                )}
              </div>
              <div
                className="CategoryDeleteIcon"
                onClick={() => deleteFromFirebase(coverLink)}
              >
                <RxCross2 />
              </div>
            </div>
            <div className="community-input-container">
              <input
                type="text"
                name="name"
                placeholder="Category Name"
                className="category-input-full"
                onChange={handleChange}
                value={CategoryDetails.name}
              />
              <input
                type="text"
                name="description"
                placeholder="Category Description"
                className="category-input-full"
                onChange={handleChange}
                value={CategoryDetails.description}
              />
              <div className="tags-input-container">
                {tags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>
                      &times;
                    </span>
                  </div>
                ))}
                <input
                  type="text"
                  className="input-tag"
                  placeholder="Add Keywords"
                />
                <p onClick={handleKeyDown} className="add-btn">
                  Add
                </p>
              </div>
            </div>
            <div className="CreateCategoryBtn">
              <button onClick={handleSubmit}>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Edit Now</>
                )}
              </button>
            </div>
          </div>
          {/* 
          <div>
            <div>
              {subCategories.length > 0 ? (
                <>
                  <TopHeader
                    headerTitle={"Sub Categories"}
                    headerBtnName={"Save Changes"}
                    type="notBack"
                    handleClick={SaveChanges}
                  />
                  <div className="edit-subcategory-container">
                    {subCategories.map((item, index) => (
                      <>
                        <div className="category-accordian">
                          <div key={index} className={`subcategory-card `}>
                            <p>{item.name}</p>

                            <div className="d-flex justify-content-center">
                            <div className="edit">
                             
                                <img src={editImg} alt="edit" onClick={() => handleCardClick(index)} />

                            </div>

                            <div className="delete">
                              <img
                                src={deleteImg}
                                alt="delete"
                                onClick={() =>
                                  handleDeleteCategory(index, item._id)
                                }
                              />
                            </div>

                            </div>

                            
                          </div>
                          {selectedCard === index && (
                            <form className="edit-subcategories">
                              <input
                                type="text"
                                name="name"
                                required
                                placeholder="Enter Category Name"
                                className="category-input-full"
                                value={item.name}
                                onChange={(e) => {
                                  const updatedData = [...subCategories];
                                  updatedData[index].name = e.target.value;
                                  setSubCategories(updatedData);
                                }}
                              />
                              <div className="tags-input-container ">
                                {item.subcategory.map((tag, subIndex) => (
                                  <div className="tag-item" key={index}>
                                    <span className="text">{tag}</span>
                                    <span
                                      className="close"
                                      onClick={() =>
                                        removeSubCategories(index, subIndex)
                                      }
                                    >
                                      &times;
                                    </span>
                                  </div>
                                ))}
                                <input
                                  ref={inputRef}
                                  type="text"
                                  className="input-tag"
                                  placeholder="Enter Sub-categories"
                                />
                                <p
                                  className="add-btn"
                                  onClick={() => AddSubCategory(index)}
                                >
                                  Add
                                </p>
                              </div>
                            </form>
                          )}
                        </div>
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="headline-middle">
                    <p>No sub categories</p>
                  </div>
                </>
              )}
            </div>
          </div> */}
          <ToastContainer />
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default EditCategory;

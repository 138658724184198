import React, { useEffect } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled.js";
import { CgProfile } from "react-icons/cg";
import { BsJournalBookmarkFill } from "react-icons/bs";
import { MdOutlineBorderColor } from "react-icons/md";
import { RiQuestionnaireFill } from "react-icons/ri";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import TopHeader from "../../../../Components/TopHeader/TopHeader.js";
import { getUserDetialsById } from "../../../../util/Auth/APIs.js";
import { toast } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  setBooking,
  setOrder,
  setUser,
} from "../../../../app/features/Users/userSlice.js";

const Features = [
  {
    profileIcon: <CgProfile />,
    profileHead: "User Profile",
    profilePara: "Complete User Profile",
    link: "profile",
  },
  {
    profileIcon: <BsJournalBookmarkFill />,
    profileHead: "Bookings",
    profilePara: "All the past and upcoming bookings",
    link: "bookings",
  },
  {
    profileIcon: <MdOutlineBorderColor />,
    profileHead: "Orders",
    profilePara: "All the active and Previous Orders",
    link: "orders",
  },
  {
    profileIcon: <RiQuestionnaireFill />,
    profileHead: "Enquiry",
    profilePara: "All User's Enquiry",
    link: "enquiry",
  },
];
const UsersFeature = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const getSingleUserDetails = async () => {
    try {
      const res = await getUserDetialsById(id);
      
      dispatch(setUser(res?.data?.message?.user));
      dispatch(setBooking(res?.data?.message?.booking));
      dispatch(setOrder(res?.data?.message?.order));
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getSingleUserDetails();
  }, []);

  return (
    <>
      <DashboardBoxStyle className="scroller">
        <TopHeader headerTitle={"User Profile "} type={"back"} />
        <div className="mt-4">
          <Row>
            {Features.map((e) => (
              <Col lg={4} className="mb-4">
                <Link to={`/user/${e.link}`} style={{ textDecoration: "none" }}>
                  <div className="profile-box">
                    <div className="profile-icon d-flex justify-content-center align-item-center">
                      {e.profileIcon}
                    </div>
                    <div className="profile-head text-align-center mt-3">
                      <h5>{e.profileHead}</h5>
                      <h6>{e.profilePara}</h6>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </div>
      </DashboardBoxStyle>
    </>
  );
};

export default UsersFeature;

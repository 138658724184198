import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import ReactQuill from "react-quill";
import CustomButton from "../../../../Components/Button/CustomButton/CustomButton";
import { getSingleGuidline, updateGuidline } from "../../../../util/Auth/APIs";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";

const Boxmodules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const EditGuideline = () => {
  const [Guideline, setGuideline] = useState("");
  const [GuidelineName, setGuidelineName] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  const getDetails = async () => {
    try {
      const res = await getSingleGuidline(id);
      console.log(res?.data?.data);
      setGuidelineName(res?.data?.data?.name);
      setGuideline(res?.data?.data?.guideline);
      setLoading(false);
    } catch (error) {
      toast.message(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleQuill = (content, delta, source, editor) => {
    setGuideline(editor.getHTML());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Guideline.trim()) {
      toast.warning("guideline cannot be empty!");
      return;
    }

    setSubmitLoader(true);

    const data = {
      name: GuidelineName,
      guideline: Guideline,
    };

    try {
      const res = await updateGuidline({ id, data });
      toast.success("Guideline Updated successfully!");
      setSubmitLoader(false);
    } catch (error) {
      toast.error("Something went wrong!");
      setSubmitLoader(false);
    }
  };
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Edit Guideline"} type={"back"} />

          <form onSubmit={handleSubmit} className="create-community-container">
            <div>
              <input
                type="text"
                required
                placeholder="Guideline Name"
                className="category-input-full mb-20"
                value={GuidelineName}
                onChange={(e) => setGuidelineName(e.target.value)}
              />
            </div>

            <div>
              <ReactQuill
                theme="snow"
                modules={Boxmodules}
                value={Guideline}
                onChange={handleQuill}
                placeholder="Write a Guideline..."
              />
            </div>

            <CustomButton loading={submitLoader} classId="full-btn ">
              Submit
            </CustomButton>
          </form>
          
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default EditGuideline;

import React from 'react'
import { SidePanelPrimaryBox, SidePanelSecondaryBox } from './SidepanelStyled'
import logo from "./logo.png"
import Fulllogo from "./logoFull.png"
import { useNavigate } from 'react-router-dom'

const SidePanel = ({ children, hideSidebar }) => {

    const navigate = useNavigate()

    return (
        <>
            <div className="sidebar">
                <SidePanelSecondaryBox className="aligncenter">
                    <div className={`${hideSidebar ? "" : "admin-logo"}`} onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                        {hideSidebar ? (
                            <img className="small-logo" src={logo} />
                        ) : (
                            <img className="img-size" src={Fulllogo} />
                        )}
                    </div>
                </SidePanelSecondaryBox>
                <hr className="m-0" />
                <SidePanelPrimaryBox>{children}</SidePanelPrimaryBox>
            </div>
        </>
    )
}

export default SidePanel
import React from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import CompanyFaqAccordian from "../../../Components/Common/Card/CompanyFaqAccordian.js";
import "./FAQDetails.css";
import {  useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";


const FAQDetails = () => {
  const { FAQ } = useSelector((state) => state.company);

  // console.log(FAQ);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
 
  return (
    <>
   
      <DashboardBoxStyle className=" scroller">
       
        
        <TopHeader headerTitle={"FAQ'S "} type={"back"}/>

        <Row className="mt-4">
          <Col lg={12} className="d-flex flex-column justify-content-between">
            <Row>
              {FAQ.length === 0 && (
                <>
                  <Col lg={12}>
                    <div className="no-list-btn">No Faq</div>
                  </Col>
                </>
              )}
              {FAQ.length !== 0 &&
                FAQ.map((data, index) => (
                  <Col lg={12}>
                    <CompanyFaqAccordian key={index} data={data} />
                  </Col>
                ))}

              <Col lg={12}>
                <div className="d-flex mt-2 justify-content-end">
                  <div className="add-sign position-relative">
                    <i class="fa-solid x-cross2 fa-circle-plus"></i>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </DashboardBoxStyle>
    
    </>
  );
};

export default FAQDetails;

import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { toast } from 'react-toastify'
import { CancelBooking } from '../../../../util/Auth/APIs'

const BookedtableRow = ({ index, data, list, setBookedList }) => {

    const { amount, quantity, ticketId, createdAt, isCancelled } = data

    const [cancelloading, setCancelLoading] = useState(false)

    const navigate = useNavigate()

    const convertDate = (date) => {
        const dateObj = new Date(date)
        const month = dateObj.toLocaleString('default', { month: 'long' })
        const date1 = dateObj.getDate()
        // const day = getDaysFullName(dateObj.getDay())
        const year = dateObj.getFullYear()
        return `${date1} ${month} ${year}`
    }

    const handleCancel = async () => {
        setCancelLoading(true)
        const payload = {
            bookingId: data._id
        }
        try {
            const res = await CancelBooking(payload)
            toast.success('Ticket Cancel Successfully')
            setBookedList(list.filter((e) => e._id !== data._id ? e : e.isCancelled = true))
            setCancelLoading(false)
        } catch (error) {
            toast.error('Something went wrong')
            setCancelLoading(false)
            console.log(error)
        }
    }

    return (
        <>
            <tr style={{ borderBottom: `${list.length !== index - 1 ? "1px solid #dad6d6" : "none"}` }}>
                <td>{index + 1}</td>
                {
                    Object.keys(data).map((key, index) => (
                        <>
                            {
                                key === "user" ? <td>{data[key].firstName + " " + data[key].lastName}</td> : key === "company" && <td>{data[key].firstName + " " + data[key].lastName}</td>
                            }
                        </>
                    ))
                }
                <td>{convertDate(createdAt)}</td>
                <td>{ticketId?.title}</td>
                <td>{quantity}</td>
                <td>{amount}</td>
                <td>
                    {
                        isCancelled ? <div className="cancel-text">
                            Cancelled
                        </div> : <button onClick={handleCancel} className='cancel-btn-event'>
                            {
                                cancelloading ? <div
                                    className="spinner-border text-white spinner-border-sm"
                                    role="status"
                                ></div> : <>Cancel</>
                            }
                        </button>
                    }
                </td>
            </tr>
        </>
    )
}

export default BookedtableRow
import React, { useEffect, useState } from 'react'
// import CommunityHead from './CommunityHead'
import CommUser from "../../assets/comm-user.svg"
import { FiSearch } from "react-icons/fi";
import CommunityHead from './CommunityHead';
import { getAllComMembers } from '../../util/Auth/APIs';
import { useParams } from 'react-router-dom';
import { LoadingButton, LoadingPage } from '../../Components/Loading/Loading';

const CommunityMembers = () => {
    const {id}= useParams();
    const [members, setMembers] = useState([]);
    const [loading, setLoading] = useState(true);
    const getMemberDetails = async () => {
        try {
          const res = await getAllComMembers(id);
          setMembers(res?.data?.data);
          setLoading(false);
    
          console.log(members);
            console.log(res?.data?.data);
        } catch (error) {
          console.log(error);
        }
      };
      useEffect(() => {
        getMemberDetails();
      }, []);


    return (
        <>
        {loading ? (
            <LoadingButton />
          ) : (
            <div className="community-detail-container">
            <CommunityHead secHead="Members" />

            <div className=" community-detail-subhead">
                <div className="community-post-card">
                    <div className="d-flex justify-content-between align-item-center">
                        <div>
                            <div className='d-flex community-members-head'>
                                <h6>Members</h6>
                                <span>{members.length}</span>
                            </div>
                            <p className='community-members-subhead'>All the people who join this group  will appear here.</p>
                        </div>
                        <div className="member-search d-flex align-items-center">
                            <FiSearch />
                            <input type="text" placeholder='Find a member' />
                        </div>
                    </div>
                    <hr className='my-3' />
                    {
                       members?.map((item,index) => (
                            <div className="d-flex mb-4">
                                <div className="comm-disc-img">
                                    <img src={item?.company?.img} className='img-size' alt="" />
                                </div>
                                <div className="comm-card-head">

                                    {item?.company &&  <div>
                                        <h5>{`${item?.company?.firstName} `}{item?.company?.lastName}</h5>
                                        <h6>{item?.company?.bio}</h6>
                                    </div>}
                                    {item?.user &&  <div>
                                        <h5>{`${item?.user?.firstName} `}{item?.user?.lastName}</h5>
                                        <h6>{item?.user?.bio}</h6>
                                    </div>}
                                   
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
          )}
        
        </>
        
       
    )
}

export default CommunityMembers
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { LoadingPage } from "../../../Components/Loading/Loading.js";
import Table from "react-bootstrap/Table";
import "./CompanyDetails.css";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";


const CompanyDetails = () => {
  const {
    company: { allDetails },
  } = useSelector((state) => state);

  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }


  const [loader, setLoader] = useState(false);

  const [busniessHours, setBusniessHours] = useState([]);

  console.log("all");
  console.log(allDetails);
  console.log(allDetails?.companyDetails?.companyName);




  return (
    <>






      {loader ? (
        <LoadingPage />
      ) : (
        <>
          <DashboardBoxStyle className=" scroller">
            <TopHeader headerTitle={"Company Details "} type={"back"} />
            <div className="mt-4">
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <thead></thead>
                  <tr>
                    <td rowSpan={2} colSpan={4}>
                      <h5 className="company-name">
                        {allDetails?.companyDetails?.companyName || ""}
                      </h5>
                      <p>
                        {" "}
                        {allDetails?.companyDetails?.companyDescription || ""}
                      </p>
                    </td>
                  </tr>
                  <tr></tr>
                  <tr>
                    <td>Address</td>
                    <td className="table-bold-head" colSpan={3}>
                      {allDetails?.companyDetails?.address || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Country</td>
                    <td className="table-bold-head">
                      {allDetails?.companyDetails?.country || ""}
                    </td>
                    <td className="">State</td>
                    <td className="table-bold-head">
                      {allDetails?.companyDetails?.state || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Post Code</td>
                    <td className="table-bold-head">
                      {allDetails?.companyDetails?.postcode || ""}
                    </td>
                    <td>Currency</td>
                    <td className="table-bold-head">
                      {allDetails?.companyDetails?.currency || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Company Strength</td>
                    <td className="table-bold-head">
                      {allDetails?.companyDetails?.companyStrength || ""}
                    </td>
                    <td>Industry Category</td>
                    <td className="table-bold-head">
                      {allDetails?.companyDetails?.industryCategory || ""}
                    </td>
                  </tr>

                  <tr>
                    <td>Time Zone</td>
                    <td colSpan={3} className="table-bold-head">{`${allDetails?.companyDetails?.timezone?.tzName || ""
                      } (${allDetails?.companyDetails?.timezone?.gmtOffsetName || ""
                      })`}</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.companyDetails?.email || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Phone</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.companyDetails?.phone || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td colSpan={3} className="table-bold-head">
                      <Link
                        onClick={() =>
                          window.open(
                            `https://${allDetails?.companyDetails?.websiteLink}`,
                            "_blank"
                          )
                        }
                        target="_blank"
                      >
                        {allDetails?.companyDetails?.websiteLink || ""}
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>

              {allDetails?.copmanyDetails?.businessHours.length !== 0 && (
                <>
                  <h5 className="my-4">Business Hours</h5>
                  <div className="profile-input-field mt-3">
                    <table class="table m-0 table-overflow table-borderless">
                      <tbody>
                        {busniessHours?.map((e, index) => {
                          console.log(e);
                          return (
                            <tr className="openHourTable" key={index}>
                              <td className="company-day">{e.day}</td>
                              <td
                                className="company-hour"
                                style={{ fontWeight: "400" }}
                              >
                                {/* {
                                                            convert24to12(e?.start) + " - " + convert24to12(e?.end)
                                                        } */}
                              </td>
                              {/* <td className="openHour-cross">
                                                    <i class="fa-solid x-cross1 fa-circle-xmark"></i>
                                                </td> */}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              )}

              {/*---------------- Business Info ------------ */}
              <h5 className="my-4">Basic Info</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td>Total Capitalization</td>
                    <td className="table-bold-head" colSpan={3}>
                      {allDetails?.basicInfo?.totalCapitalization || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Year Established</td>
                    <td className="table-bold-head">
                      {allDetails?.basicInfo?.yearEstablished || ""}
                    </td>
                    <td>Total Employees</td>
                    <td className="table-bold-head">
                      {allDetails?.basicInfo?.totalEmployees || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Company Certificate</td>
                    <td className="table-bold-head">
                      {allDetails?.basicInfo?.companyCertificate || ""}
                    </td>
                    <td>Product Certificate</td>
                    <td className="table-bold-head">
                      {allDetails?.basicInfo?.productCertificate || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Business Type</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.basicInfo?.businessType || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Registration Detail -------------- */}
              <h5 className="my-4">Registration Detail</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="w-col-30">Registered Company</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationDetails?.registeredCompany || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Registration Number</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationDetails?.registrationNumber ||
                        ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Registration Address</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationDetails?.registrationAddress ||
                        ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Registration Profile -------------- */}
              <h5 className="my-4">Registration Profile</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="">Registered Address</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationProfile?.registeredAddress || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Incorporation Date</td>
                    <td className="table-bold-head">
                      {allDetails?.registrationProfile?.incorporationDate || ""}
                    </td>
                    <td className="">Legal Form</td>
                    <td className="table-bold-head">
                      {allDetails?.registrationProfile?.legalForm || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Company Status</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationProfile?.companyStatus || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Registration Agency</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationProfile?.registrationAgency ||
                        ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Registration No.</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationProfile?.registrationNo || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Authorized Capital</td>
                    <td className="table-bold-head">
                      {allDetails?.registrationProfile?.registrationNo || ""}
                    </td>
                    <td className="">Paid-Up Capital</td>
                    <td className="table-bold-head">
                      {allDetails?.registrationProfile?.paidUpCapital || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Import & Export License</td>
                    <td className="table-bold-head">
                      {allDetails?.registrationProfile?.importExportLicense ||
                        ""}
                    </td>
                    <td className="">Legal Representative</td>
                    <td className="table-bold-head">
                      {allDetails?.registrationProfile?.legalRepresentative ||
                        ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Business Scope</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationProfile?.businessScope || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Shareholders</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.registrationProfile?.shareholders || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Trading Capabilities -------------- */}
              <h5 className="my-4">Trading Capabilities</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="">Total Annual Sales</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities?.totalAnnualSales || ""}
                    </td>
                    <td className="">Export Percentage</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities?.exportPercentage || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">OEM Services</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingCapabilities?.oemServices || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Brand Names</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities?.brandNames || ""}
                    </td>
                    <td className="">Payment Terms</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities?.paymentTerms || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Main Competitive Advantages</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities
                        ?.mainCompetitiveAdvantages || ""}
                    </td>
                    <td className="">Other Competitive Advantages</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities
                        ?.otherCompetitiveAdvantages || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Major Customer</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities?.majorCustomer || ""}
                    </td>
                    <td className="">Export Markets</td>
                    <td className="table-bold-head">
                      {allDetails?.tradingCapabilities?.exportMarket || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Trading Services -------------- */}
              <h5 className="my-4">Trading Services</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="w-col-30">Trading Activities</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.tradingActivities || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Product Range</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.productRange || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Supply Chain Elements</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.supplyChainElements || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">OEM Services</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.oemServices || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Quality Control Measures</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.qualityControlServices ||
                        ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">
                      Product Inspection and Documentation Policies
                    </td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.documentationPolicies || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">
                      Product Shipping Terms for Buyers
                    </td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.shippingTerms || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">
                      Minimum Order andDelivery Terms
                    </td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.productRange || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Major Buyers</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.majorBuyer || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Major Suppliers</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.majorSupplier || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Major Brands Traded</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.tradingServices?.majorBrands || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Production Capacity -------------- */}
              <h5 className="my-4">Production Capacity</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="">Materials/ComponentsUsed</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.productionCapacity?.materialUsed || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Machinery/ProductionProcess</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.productionCapacity?.machineryProcess || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Site Advantages</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.productionCapacity?.siteAdvantages || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Quality Control -------------- */}
              <h5 className="my-4">Quality Control</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="">
                      Description of QualityControl/Technical SupportDepartment
                    </td>
                    <td
                      colSpan={3}
                      className="table-bold-head"
                      dangerouslySetInnerHTML={{
                        __html: allDetails?.qualityControl?.description || "",
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td className="">
                      Additional Info about the Quality Control Process
                    </td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.qualityControl?.additionalInfo || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Origin of Materials andComponents</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.qualityControl?.originMaterials || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Quality Control Procedures</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.qualityControl?.controlProcedures || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- OEM/ODM -------------- */}
              <h5 className="my-4">OEM/ODM</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="">Factory OEM/ODM Capabilities</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.factoryOem || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Number of Production Lines</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.productionLines || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Factory Size</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.factorySize || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Number of QC Staff</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.qcStaff || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Number of Production Staff</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.productionStaff || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Number of R & D Staff</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.RdStaff || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Years of OEM/ODM Experience</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.oem_odm?.oemExperience || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Visit Us -------------- */}
              <h5 className="my-4">Visit Us</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="">
                      Direction to the Main Office Building Location
                    </td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.visitUs?.direction || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Contact Person</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.visitUs?.contactPerson || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">Job Title</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.visitUs?.jobTitle || ""}
                    </td>
                  </tr>

                  <tr>
                    <td className="">Department</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.visitUs?.department || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="">E-mail Address</td>
                    <td colSpan={3} className="table-bold-head">
                      {allDetails?.visitUs?.email || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </DashboardBoxStyle>
        </>
      )}

    </>
  );
};

export default CompanyDetails;

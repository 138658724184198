import React from "react";
import "./BlogCard.css";

const BlogCard = ({ data, isDot }) => {
  const monthnumbertomonthword = (month) => {
    switch (month) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "Jan";
    }
  };

  const ddmmyyyy = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear();
    let monthword = monthnumbertomonthword(month);

    return `${day} ${monthword}, ${year}`;
  };

  isDot = isDot || false;

  return (
    <>
      <div className="position-relative">
        <div className="blog-card" style={{ position: "relative", top: "0" }}>
          <div>
            <div className="blog-img ">
              <img src={data.images[0]} className="img-size" alt="" />
            </div>

            <div className="p-3">
              <h5>{data.title}</h5>
              <p>{data.description}</p>
              <div className="blog-read-more">
                <button>Read More</button>
                <span>{ddmmyyyy(data.createdAt)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;

import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RxCross2 } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";

import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import {
  CreateBlog,
  createPodcast,
  getPodcastById,
  updatePodcastById,
} from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SelectAllCategories from "../Category/SelectAllCategories";

const PodcastDetails = () => {
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [tags, setTags] = useState([]);

  const { id } = useParams();

  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const [magzineDetails, setMagzineDetails] = useState({
    isPublished: true,
    podcastTitle: "",
   category: "",
    category2: "",
    category3: "",
    category4: "",
    podcastDescription: "",
    podcastLink: "",
    keywords: [],
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isPublished") {
      setMagzineDetails({ ...magzineDetails, [name]: e.target.checked });
    } else if (name === "blogVideos" || name === "keywords") {
      setMagzineDetails({ ...magzineDetails, [name]: [e.target.value] });
    } else {
      setMagzineDetails({ ...magzineDetails, [name]: value });
    }
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const handleQuill = (content, delta, source, editor) => {
    setMagzineDetails({
      ...magzineDetails,
      podcastDescription: editor.getHTML(),
    });
    setValue(editor.getHTML());
  };

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      const value = e.target.value.trim();
      if (value !== "") {
        const updatedTags = [...tags, value];
        setTags(updatedTags);
        setMagzineDetails({ ...magzineDetails, keywords: updatedTags });
        e.target.value = "";
      }
    }
  }

  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setMagzineDetails({ ...magzineDetails, keywords: [...updatedTags] });
  }

  
  useEffect(() => {
    console.log(magzineDetails.magzineImages);
  }, [magzineDetails.magzineImages]);

  const singlePodcast = async () => {
    setLoader(true);
    try {
      const res = await getPodcastById(id);
      setMagzineDetails({
        isPublished: res?.data?.message?.isPublished,
        podcastTitle: res?.data?.message?.title,
        category: res?.data?.message?.category,
    category2: res?.data?.message?.category2,
    category3: res?.data?.message?.category3,
    category4: res?.data?.message?.category4,
        podcastDescription: res?.data?.message?.description,
        podcastLink: res?.data?.message?.link,
        keywords: res?.data?.message?.keywords,
        type: res?.data?.message?.type
      });

      setLoader(false);
      console.log(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    singlePodcast();
  }, []);
  useEffect(() => {
    setTags(magzineDetails?.keywords);
  }, [magzineDetails?.keywords.length]);
  return (
    <>
      {loader ? (
        <LoadingPage />
      ) : (
        <form>
          <DashboardBoxStyle className="scroller">
          <TopHeader headerTitle={"Podcast Details "} type="back"/>

            <div className="form-container position-relative mt-4">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                defaultChecked={magzineDetails?.isPublished ? true : false}
                disabled={true}
              
                className="hr-select"
                name="isPublished"
                id=""
              />

              <span className="ispublished-text">
                Do you want to add this in company view page
              </span>
            </div>
              {/* --------------  Blog Title ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Podcast Title</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                     disabled={true}
                    
                      value={magzineDetails?.podcastTitle}
                      name="podcastTitle"
                      type="text"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Podcast Link</h6>
                </div>
                <div className="profile-input-width">
                  <div className="profile-input-field">
                    <input
                     disabled={true}
                    
                      value={magzineDetails?.podcastLink}
                      name="podcastLink"
                      type="text"
                      placeholder="Add Link"
                    />
                  </div>
                </div>
              </div>

               <SelectAllCategories
                selected={magzineDetails}
                setSelected={setMagzineDetails}
              />


              <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Type</h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <select   required name="type" id="">
                  <option value={magzineDetails?.type} selected disabled>
                    select
                  </option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              </div>
            </div>
          </div>



              {/* -------------- Blog Description ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Keywords</h6>
                </div>
                <div className="tags-container">
                  {tags.map((tag, index) => (
                    <div className="tag-item" key={index}>
                      <span className="text">{tag}</span>
                      <span className="close" onClick={() => removeTag(index)}>
                        &times;
                      </span>
                    </div>
                  ))}
                  <input
                   disabled={true}
                    
                    type="text"
                    className="input-tag"
                    placeholder="Add Keywords"
                  />
                </div>
              </div>

             

              {/* --------------   Content ----------- */}
              <div className="d-flex mt-4 align-items-center">
                <div className="profile-input-head">
                  <h6>Podcast Discription</h6>
                </div>

                <div className="profile-input-width">
                  <ReactQuill
                    style={{ background: "white" }}
                    theme="snow"
                    modules={Boxmodules}
                    value={magzineDetails.podcastDescription}
                    disabled={true}
                   
                    placeholder="Description"
                  />
                  {/* <div className='profile-input-field'>
                                      <input onChange={handleChange} name="content" type="text" />
                                  </div> */}
                </div>
              </div>

             

              <div
                onClick={() => navigate(-1)}
                className="close-job cross-sign-pos"
              >
                <RxCross2 />
              </div>
            </div>
          </DashboardBoxStyle>
        </form>
      )}
    </>
  );
};

export default PodcastDetails;

import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SponsorAccordian from "./SponsorAccordian.js";
import Modal from "react-bootstrap/Modal";
import User3 from "../../../../assets/comm-user.svg";
import { FiCamera } from "react-icons/fi";
// import { AllEventSponsor, CreateEventSponsor } from '../../../../axios/apis';
import { toast } from "react-toastify";

import { uploadImg } from "../../../../util/Healper/imageUpload";
import { useSelector } from "react-redux";
import { LoadingPage } from "../../../../Components/Loading/Loading.js";
import {
  AllEventSponsor,
  CreateEventSponsor,
} from "../../../../util/Auth/APIs.js";

const AddSponsor = ({ type }) => {
  const [sponsorShow, setSponsorShow] = useState(false);
  const [active, setActive] = useState(null);

  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);

  const EventID = useSelector((state) => state.community.eventId);

  const [sponsorList, setSponsorList] = useState([]);

  const [sponsorData, setSponsorData] = useState({
    event: EventID,
    img: "" || User3,
    name: "",
    type: "",
    url: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSponsorData({ ...sponsorData, [name]: value });
  };

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleClose = () => {
    setSponsorShow(false);
    setSponsorData({
      ...sponsorData,
      img: "" || User3,
    });
  };

  const handleImage = async (e) => {
    await uploadImg(
      "Sponsor",
      e.target.files[0],
      setSponsorData,
      setImageLoader
    );
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      if (EventID) {
        const res = await CreateEventSponsor(sponsorData);
        if (res.data.success) {
          getAllSponsors();
        }
        toast.success("Sponsor created successfully");
        setSubmitLoader(false);
        handleClose();
      } else {
        toast.error("Please create event first");
      }
    } catch (error) {
      setSubmitLoader(false);
    }
  };

  const getAllSponsors = async () => {
    setLoader(true);
    try {
      if (EventID) {
        const res = await AllEventSponsor(EventID);
        setSponsorList(res.data.message.sponsors.reverse());
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllSponsors();
  }, []);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Sponsors</h5>

        {loader ? (
          <LoadingPage />
        ) : (
          <>
            <Row className="mt-4">
              <Col
                lg={12}
                className="d-flex flex-column justify-content-between"
              >
                <Row>
                  {sponsorList.length === 0 && (
                    <>
                      <Col lg={12}>
                        <div className="no-list-btn">No Sponsors</div>
                      </Col>
                    </>
                  )}

                  {sponsorList.length !== 0 &&
                    sponsorList.map((data, index) => (
                      <Col lg={12}>
                        <SponsorAccordian
                          key={index}
                          active={active}
                          getAllSponsors={getAllSponsors}
                          handleToggle={handleToggle}
                          data={data}
                        />
                      </Col>
                    ))}
                  {type === "edit" && (
                    <Col lg={12}>
                      <div className="d-flex mt-2 justify-content-end">
                        <div
                          onClick={() => setSponsorShow(true)}
                          className="add-sign position-relative"
                        >
                          <i class="fa-solid x-cross2 fa-circle-plus"></i>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Modal show={sponsorShow} onHide={handleClose} centered>
          <div className="event-form-container">
            <div className="">
              <div className="event-form-top">
                <span>Add Sponsor</span>
                <i
                  onClick={handleClose}
                  class="fa-solid form-x-cross fa-circle-xmark"
                ></i>
              </div>
            </div>

            <form onSubmit={handleSave}>
              <Row>
                <Col lg={6}>
                  <div className="half-input-box organiser-profile-icon">
                    <div className="organiser-profile-icon">
                      <img
                        src={sponsorData?.img}
                        className={`img-size br-50 ${
                          imageLoader && "brightness-50"
                        }`}
                        alt=""
                      />
                      {imageLoader && (
                        <div
                          className="spinner-border position-absolute text-white ml-4"
                          role="status"
                        ></div>
                      )}
                      <div class="organiser-camera">
                        <span className="d-flex m-0">
                          <FiCamera />
                        </span>
                        <input
                          onChange={handleImage}
                          name="profilePic"
                          type="file"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Sponsor name</label>
                    <div className="profile-input-field">
                      <input
                        name="name"
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Sponsor type</label>
                    <div className="profile-input-field">
                      <input
                        name="type"
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Link URL</label>
                    <div className="profile-input-field">
                      <input
                        name="url"
                        onChange={handleChange}
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Description of Sponsor</label>
                    <div className="profile-input-field">
                      <textarea
                        name="description"
                        onChange={handleChange}
                        className="faq-answer-textarea"
                        id=""
                        cols="30"
                        rows=""
                      ></textarea>
                    </div>
                  </div>
                </Col>
                <div className="d-flex justify-content-end mt-4">
                  <div className="form-width-btn">
                    <button className="form-action-next">
                      {submitloader ? (
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      ) : (
                        <> Save</>
                      )}
                    </button>
                  </div>
                </div>
              </Row>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddSponsor;

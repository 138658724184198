import { Route, Navigate, Routes } from "react-router-dom";
import AllPayOutList from "./Pages/PayOutSection/AllPayOutList.js";


function ProductRoutes({ token }) {
  return (

    <>
      <Routes>
        <Route
          path="/"
          element={token ? <AllPayOutList /> : <Navigate to="/" />}
        />

      </Routes>

    </>
  )
}


export default ProductRoutes;
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { DashboardBoxStyle } from '../../SideBar/SidepanelStyled.js'
import "./companyprofile.css"
import { IoDocumentText } from "react-icons/io5"
import { BsGiftFill } from "react-icons/bs"
import { BiGlobe } from "react-icons/bi"
import { FaBuilding } from "react-icons/fa"
import { AiFillTag, AiFillPicture, AiFillVideoCamera, AiFillQuestionCircle } from "react-icons/ai"
import { SiAnaconda, SiMicrodotblog, SiZoom } from "react-icons/si"
import { HiDocumentChartBar } from "react-icons/hi2"
import { HiReceiptTax, HiVideoCamera } from "react-icons/hi"
import { RiSecurePaymentFill, RiInformationFill, RiHeadphoneFill, RiUserAddFill, RiFilePaper2Fill } from "react-icons/ri"
import { NavLink, Link, useNavigate } from 'react-router-dom'
import { MdOutlineMiscellaneousServices, MdOutlineHomeWork, MdHomeWork } from "react-icons/md"
import { BsHeadphones } from "react-icons/bs"
import { VscDeviceCameraVideo } from "react-icons/vsc"
import CampaignIcon from '@mui/icons-material/Campaign';
import { BsCheckSquareFill } from "react-icons/bs"
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { CompanyDetailsById } from '../../../util/Auth/APIs.js'
import {
    setAllDetails,
    setCompanyDetails,
    setCompanyGallery,
    setCompleteDetails,
    setDeals,
    setBlogs,
    setLeads,
    setOem,
    setProductionCapacity,
    setProducts,
    setQualityControl,
    setRegistrationDetails,
    setRegistrationProfile,
    setTradingCapabilities,
    setTradingServices,
    setVisitUs,
    setMediaLibrary,
    setCompanyFactory,
    setLeadAndEnquiry,
    setSocialMedia,
    setKeyPersonal,
    setCertification,
    setFaqs,
    setCodOfConduct,
    setPressAndNews,
    setPromoPlans,
    setSubscription,
    setEvents,
} from "../../../app/features/company/companySlice.js";
import { LoadingPage } from '../../../Components/Loading/Loading.js'
import TopHeader from '../../../Components/TopHeader/TopHeader.js'
import EventIcon from '@mui/icons-material/Event';




const CompanyMenu = () => {

    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const [companyDet, setCompanyDet] = useState(null); // Initialize to null instead of undefined
    const [businessHours, setBusinessHours] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();



    const getCompanyDetails = async () => {
        try {
            setLoader(true);
            const response = await CompanyDetailsById(id);
            const companyDetails = response?.data?.message?.companyDetails;
            if (companyDetails?.businessHours[0]?.start === undefined) {
                setBusinessHours([]);
            } else {
                setBusinessHours(companyDetails?.businessHours);
            }
            setCompanyDet(response?.data?.message);
            dispatch(setAllDetails(response?.data?.message));
            dispatch(setCompanyDetails(companyDetails));
            dispatch(setVisitUs(response?.data?.message.visitUs));
            dispatch(setMediaLibrary(response?.data?.message.mediaLibrary));
            dispatch(setBlogs(response?.data?.message.blogs));
            dispatch(setCompanyGallery(response?.data?.message.companyGallery));
            dispatch(setProducts(response?.data?.message.products));
            dispatch(setDeals(response?.data?.message.deals));
            dispatch(setOem(response?.data?.message.oem_odm));
            dispatch(setLeads(response?.data?.message.leads));
            dispatch(setTradingServices(response?.data?.message.tradingServices));
            dispatch(setTradingCapabilities(response?.data?.message.tradingCapabilities));
            dispatch(setRegistrationProfile(response?.data?.message.registrationProfile));
            dispatch(setRegistrationDetails(response?.data?.message.registrationDetails));
            dispatch(setQualityControl(response?.data?.message.qualityControl));
            dispatch(setCompanyFactory(response?.data?.message.companyFactory));
            dispatch(setProductionCapacity(response?.data?.message.productionCapacity));
            dispatch(setLeadAndEnquiry(response?.data?.message.leadAndEquiry));
            dispatch(setSocialMedia(response?.data?.message.socialMedia));
            dispatch(setKeyPersonal(response?.data?.message.keyPersonal));
            dispatch(setCertification(response?.data?.message.certification));
            dispatch(setFaqs(response?.data?.message.FAQ));
            dispatch(setCodOfConduct(response?.data?.message.codOfConduct));
            dispatch(setPressAndNews(response?.data?.message.pressAndNews));
            dispatch(setPromoPlans(response?.data?.message.promoPlans));
            dispatch(setSubscription(response?.data?.message.subscription));
            dispatch(setEvents(response?.data?.message?.events));

            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    useEffect(() => {
        getCompanyDetails();
    }, []);

    useEffect(() => {
        console.log(companyDet);
    }, [companyDet]);


    const jobTop = [
        {
            profileIcon: <IoDocumentText />,
            profileHead: "Company Details",
            profilePara: "Contact information and key demographic data",
            link: "company-details"
        },
        {
            profileIcon: <EventIcon />,
            profileHead: "Events Details",
            profilePara: "Contact information about events of this particular company",
            link: "events"
        },
        {
            profileIcon: <SubscriptionsIcon />,
            profileHead: "Subscription Details",
            profilePara: "Presenting information about subscription details.",
            link: "subscriptions"
        },
        {
            profileIcon: <CampaignIcon />,
            profileHead: "Promotion Plans",
            profilePara: "Information ,regarding promotion plans like start date ,end date ,price etc.",
            link: "promotions"
        },
        {
            profileIcon: <BsGiftFill />,
            profileHead: "Product",
            profilePara: "Virtual or physical products for buyers",
            link: "company-product"
        },
        {
            profileIcon: <AiFillTag />,
            profileHead: "Deals & Offer",
            profilePara: "Make good deals & offers to get more attractions",
            link: "deals-offer"
        },
        {
            profileIcon: <SiMicrodotblog />,
            profileHead: "Blogs",
            profilePara: "Share your opinions, insights on a particular topic often in the form of short, informal articles or posts.",
            link: "company-blogs"
        },
        {
            profileIcon: <AiFillPicture />,
            profileHead: "News & Press Release",
            profilePara: "Add or view all News & Press Releases published by your company.",
            link: "news-release"
        },
        {
            profileIcon: <AiFillVideoCamera />,
            profileHead: "Media Library",
            profilePara: "Customize images & Videos to support your company description",
            link: "media-library"
        },
        {
            profileIcon: <FaBuilding />,
            profileHead: "Factory Details",
            profilePara: "Production capabilities, machinery and equipment, workforce & logistical details.",
            link: "factory-details"
        },
        // {
        //     profileIcon: <RiSecurePaymentFill />,
        //     profileHead: "Payment Gateway/Bank",
        //     profilePara: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod temp.",
        //     link: "payment-options"
        // },
        {
            profileIcon: <RiInformationFill />,
            profileHead: "Inquiry & Lead Delivery",
            profilePara: "Add verified email address to receive Sales Inquiries and Marketing Leads.",
            link: "inquiry-lead"
        },
        {
            profileIcon: <BiGlobe />,
            profileHead: "Social Media",
            profilePara: "Add or edit links to your company’s social media accounts that appear on your profile.",
            link: "social-media"

        },
        {
            profileIcon: <RiUserAddFill />,
            profileHead: "Key Personnel",
            profilePara: "Add company officials to your Company Profile.",
            link: "key-personal"
        },
        {
            profileIcon: <RiFilePaper2Fill />,
            profileHead: "Certification",
            profilePara: "View and edit the list of classification (s) / registration (s) your company holds.",
            link: "certification"
        },
        // {
        //     profileIcon: <HiReceiptTax />,
        //     profileHead: "Tax",
        //     profilePara: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod temp.",
        //     link: "price-tax"
        // },
        {
            profileIcon: <AiFillQuestionCircle />,
            profileHead: "FAQ's",
            profilePara: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod temp.",
            link: "faq-details"
        },
        {
            profileIcon: <BsCheckSquareFill />,
            profileHead: "Code of Conduct",
            profilePara: "Lorem ipsum dolor sit amet, consect adipiscing elit, sed do eiusmod temp.",
            link: "code-of-conduct"
        },
        {
            profileIcon: <RiHeadphoneFill />,
            profileHead: "Service & Support",
            profilePara: "Available for 24/7 Need any help",
            link: "faq-details"
        },
        {
            profileIcon: <HiDocumentChartBar />,
            profileHead: "Impact Report",
            profilePara: "Make a Impact Report",
            link: "impact"
        },
        {
            profileIcon: <HiVideoCamera />,
            profileHead: "Zoom Integration",
            profilePara: "Add your zoom integration for upcoming live sessions",
            link: "faq-details"
        },
        {
            profileIcon: <SiAnaconda />,
            profileHead: "Circular Economy Directory",
            profilePara: "Map your company or products with circular features",
            link: "faq-details"
        },
        {
            profileIcon: <MdHomeWork />,
            profileHead: "Communities",
            profilePara: "Manage your Communities",
            link: "faq-details"
        },
    ]

    return (
        <>

            {loader ? <LoadingPage /> : (<DashboardBoxStyle className="scroller" >
                <TopHeader headerTitle={"Company Profile "} type={"back"} />
                <div className="mt-4">
                    <Row>
                        {

                            jobTop.map((e) => (
                                <Col lg={4} className="mb-4">
                                    <Link to={`/company/${e.link}`} style={{ textDecoration: 'none' }}>
                                        <div className='profile-box'>
                                            <div className="profile-icon d-flex justify-content-center align-item-center">
                                                {e.profileIcon}
                                            </div>
                                            <div className="profile-head text-align-center mt-3">
                                                <h5>{e.profileHead}</h5>
                                                <h6>{e.profilePara}</h6>
                                            </div>
                                        </div>
                                    </Link>
                                </Col>
                            ))
                        }
                    </Row>
                </div>
            </DashboardBoxStyle>)}




        </>
    )
}

export default CompanyMenu
import React from 'react'
import CustomButton from '../Button/CustomButton/CustomButton'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from 'react-router-dom';

const NewTopHeader = ({ headerTitle, headerBtnName, handleClick, addBtn, backBtn }) => {

    const navigate = useNavigate();

    return (
        <>
            <div className="topHeaderWrapper">
                <div className="topHeaderTitle">
                    {
                        backBtn && <div onClick={() => navigate(-1)} className="topHeaderBackBtn backIcon">
                            <ArrowBackIcon />
                        </div>
                    }
                    <h5>{headerTitle}</h5>
                </div>

                <div className="topHeaderButtons">
                    {
                        addBtn && <button
                            onClick={handleClick}
                            className={`customButton topHeaderBtn`}>
                            {headerBtnName}
                        </button>
                    }
                </div>
            </div>
        </>
    )
}

export default NewTopHeader
import React, { useState } from 'react'
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { BiPencil, BiTrash } from "react-icons/bi"
import User3 from "../../../../assets/comm-user.svg"
import { FiCamera } from "react-icons/fi"
import { uploadImg } from '../../../../util/Healper/imageUpload';
// import { DeleteEventSpeaker, UpdateEventSpeaker } from '../../../../axios/apis';
import { toast } from 'react-toastify';

const SpeakerAccordian = (props) => {

    const contentEl = useRef();
    const [imageLoader, setImageLoader] = useState(false)
    const [submitloader, setSubmitLoader] = useState(false)
    const [deleteloader, setDeleteLoader] = useState(false)

    const { handleToggle, active, getAllSpeakers, data } = props;
    const { name, img, description, _id } = data;

    const [speakerData, setSpeakerData] = useState({
        name: name || "",
        description: description || "",
        img: img || User3
    })

    const handleImage = async (e) => {
        await uploadImg('Speakers', e.target.files[0], setSpeakerData, setImageLoader)
        // setImageLoader(true)
    }

    const handleChange = (e) => {
        setSpeakerData({ ...speakerData, [e.target.name]: e.target.value })
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        // try {
        //     const res = await UpdateEventSpeaker({ id: _id, data: speakerData })
        //     toast.success('FAQ updated successfully')
        //     setSubmitLoader(false)
        //     if (res.data.success) {
        //         handleToggle(_id)
        //         getAllSpeakers()
        //     }
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setSubmitLoader(false)
        //     console.log(error)
        // }
    }

    const removeItem = async () => {
        setDeleteLoader(true)
        // try {
        //     const res = await DeleteEventSpeaker(_id)
        //     if (res.data.success) {
        //         toast.success('Speaker deleted successfully')
        //         getAllSpeakers()
        //     }
        //     setDeleteLoader(false)
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setDeleteLoader(false)
        //     console.log(error)

        // }
    }

    return (
        <>
            <div className='edit-accordian-card'>
                <div className="edit-accordian-head">
                    <div className="edit-accordian-toggle p-3">
                        <h4>{name}</h4>
                        <div className='d-flex'>
                            <div onClick={() => handleToggle(_id)} className="pencil-edit-btn accordian-edit-btns">
                                <BiPencil />
                            </div>
                            <div onClick={removeItem} className={`trash-edit-btn accordian-edit-btns ${deleteloader && "theme-red"}`}>
                                {
                                    deleteloader ? <div
                                        className="spinner-border text-white spinner-border-sm"
                                        role="status"
                                    ></div> : <>  <BiTrash /></>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        ref={contentEl}
                        className={`rc-collapse ${active === _id ? "show" : ""}`}
                        style={
                            active === _id
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0px" }
                        }
                    >
                        <div className="rc-accordion-body">
                            <Row>
                                <Col lg={6}>
                                    <div className="half-input-box organiser-profile-icon">
                                        <img src={speakerData?.img} className={`img-size br-50 ${imageLoader && "brightness-50"}`} alt="" />
                                        {
                                            imageLoader && <div
                                                className="spinner-border position-absolute text-white ml-4"
                                                role="status"
                                            ></div>
                                        }
                                        <div class="organiser-camera">
                                            <span className='d-flex m-0'>
                                                <FiCamera />
                                            </span>
                                            <input onChange={handleImage} name="profilePic" type="file" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Speaker Name</label>
                                        <div className='profile-input-field'>
                                            <input name='name' value={speakerData?.name} onChange={handleChange} type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Description</label>
                                        <div className='profile-input-field'>
                                            <textarea value={speakerData?.description} onChange={handleChange} className='faq-answer-textarea' name="description" id="" cols="30" rows=""></textarea>
                                        </div>
                                    </div>
                                </Col>
                                <div className='d-flex justify-content-end mt-4' >
                                    <div className="form-width-btn">
                                        <button onClick={handleSave} className='form-action-next'>
                                            {
                                                submitloader ? <div
                                                    className="spinner-border text-white ml-4"
                                                    role="status"
                                                ></div> : <> Save</>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Row>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SpeakerAccordian
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { AiFillDollarCircle } from 'react-icons/ai'
import { FaClipboardList, FaTicketAlt, FaCoins, FaMoneyBillAlt, FaPercent, FaPiggyBank, FaWindowClose } from 'react-icons/fa'
import { RiComputerFill, RiRefund2Line } from 'react-icons/ri'
import { TiCancel } from 'react-icons/ti'
import "./EventDetails.css"

const TicketProgress = ({ data }) => {

    const { total_tickets, total_revenue, glocal_share, gst, net_payout, } = data

    return (
        <>
            <Row>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Total Tickets Sold</h6>
                            <h5>{total_tickets || 0}</h5>
                        </div>
                        <div className='ticked-icon'>
                            <FaTicketAlt />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Total Revenue</h6>
                            <h5>{total_revenue || 0}</h5>
                        </div>
                        <div className='ticked-icon'>
                            <FaCoins />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Glocal Care X Platform Charges</h6>
                            <h5>0</h5>
                        </div>
                        <div className='ticked-icon'>
                            <RiComputerFill />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Glocal Care X Revenue Share</h6>
                            <h5>{glocal_share || 0}</h5>
                        </div>
                        <div className='ticked-icon'>
                            <FaMoneyBillAlt />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Applicable GST/VAT</h6>
                            <h5>{gst || 0}</h5>
                        </div>
                        <div className='ticked-icon'>
                            <FaPercent />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Net Amount Payable to You</h6>
                            <h5>{net_payout || 0}</h5>
                        </div>
                        <div className='ticked-icon'>
                            <FaPiggyBank />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Total Tickets Cancelled</h6>
                            <h5>0</h5>
                        </div>
                        <div className='ticked-icon'>
                            <FaWindowClose />
                        </div>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="listing-box ticket-box">
                        <div className="d-flex h-100 flex-column justify-content-between">
                            <h6>Total Refund</h6>
                            <h5>0</h5>
                        </div>
                        <div className='ticked-icon'>
                            <RiRefund2Line />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default TicketProgress
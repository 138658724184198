import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setLinkedProduct } from '../../../../../../app/features/adminProducts/productSlice';

const LinkedProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sample = useSelector((state)=>state.product.linkedProduct);
  const [linked, setLinked] = useState({
    upSells: sample?.upSells || '',
    crossSells: sample?.crossSells || '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLinked({ ...linked, [name]: value })
  }

  const handleNext = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0 })
    dispatch(setLinkedProduct(linked));
    navigate('?tab=attributes')
  }

  useEffect(() => {
    setTimeout(() => {
     dispatch(setLinkedProduct(linked));
    }, 300);
  }, [linked])
  return (
   <>
    <form onSubmit={handleNext}>
        <Row className='mt-2'>
          <Col lg={12} className="d-flex flex-column justify-content-between">
            <Row>
              <Col lg={6}>
                <div className="half-input-box">
                  <label>Upsells</label>
                  <div className='profile-input-field'>
                    <input value={linked?.upSells} onChange={handleChange} name='upSells' type="text" placeholder='Search for a product' />
                  </div>
                </div>
              </Col>

              <Col lg={6}>
                <div className="half-input-box">
                  <label>Cross-sells</label>
                  <div className="profile-input-field">
                    <input value={linked?.crossSells} onChange={handleChange} name="crossSells" type="text" placeholder='Search for a product' />
                  </div>
                </div>
              </Col>
            </Row>
            <div className='d-flex justify-content-end mt-5' >
              {/* <div className="form-width-btn">
                <button className='save-draft'>
                  {
                    submitloader ? <div
                      className="spinner-border theme-colour ml-4"
                      role="status"
                    ></div> : <> Save</>
                  }
                </button>
              </div> */}
              <div className="form-width-btn">
                <button className='form-action-next'>Next</button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
   </>
  )
}

export default LinkedProducts
import React, { useEffect, useState } from "react";

import "./Table.css";
import { DeleteBlog, UpdateBlog } from "../../util/Auth/APIs";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";

import { toast, ToastContainer } from "react-toastify";
import ToggleButton from "react-toggle-button";

const BlogTable = ({ data }) => {
  const navigate = useNavigate();
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [blogsList, setBlogsList] = useState(data);

  console.log(data);

  const removeItem = async (id) => {
    setDeleteLoader(true);
    setDeletingItemId(id);
    try {
      const res = await DeleteBlog(id);
      setDeleteLoader(false);
      toast.success("Product deleted successfully");

      setBlogsList(blogsList.filter((item) => item._id !== id)); // update the blogsList state
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteLoader(false);
      console.log(error);
    }
    console.log(id);
  };

 
  const handleToggle = async (id) => {
    const blogIndex = blogsList.findIndex((item) => item._id === id);
    const blogToUpdate = blogsList[blogIndex];
    const updatedBlog = {
      ...blogToUpdate,
      isPublished: !blogToUpdate.isPublished
    };
    try {
      const res = await UpdateBlog({id: blogToUpdate._id, data: updatedBlog});
      setBlogsList([
        ...blogsList.slice(0, blogIndex),
        updatedBlog,
        ...blogsList.slice(blogIndex + 1),
      ]);
      toast.success("Blog Updated Successfully");
    } catch (err) {
      toast.error("Something went wrong");
      console.log(err);
    }
  };

  useEffect(() => {
    setBlogsList(data);
  }, [data.length]);
  return (
    <div className='table-container'>
    <table className='Table'>
      <thead className='table-head'>
        <tr>
          <th>ID</th>
          <th>Publish Date</th>
          <th>Blog Title</th>
          <th>ON/Off</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {blogsList.map((item, index) => (
          <tr key={item.id}>
            <td>{index+1}</td>
            <td>{item.createdAt.slice(0, 10)}</td>
            <td>{item.title.slice(0, 25)}</td>

            <td>
              <div className="d-flex justify-content-center">
                <ToggleButton
                  className="m-auto"
                  value={item.isPublished}
                  onToggle={() => handleToggle(item._id)}
                  inactiveLabel={""}
                  activeLabel={""}
                  colors={{
                    activeThumb: {
                      base: "white",
                    },
                    inactiveThumb: {
                      base: "white",
                    },
                    active: {
                      base: "#4EB7BD",
                      // hover: 'rgb(177, 191, 215)',
                    },
                    inactive: {
                      base: "#D9D9D9",
                      // hover: 'rgb(95,96,98)',
                    },
                  }}
                />
              </div>
            </td>

            <td className="d-flex justify-content-center">
              <div onClick={() => navigate(`/blog/edit/${item?._id}`)}>
                <FiEdit className="edit-icon" />
              </div>
              {/* <a onClick={()=>removeItem(item?._id)} >
                <AiFillDelete className="delete-icon" />
              </a> */}
              <div onClick={() => removeItem(item?._id)}>
                {deleteloader && deletingItemId === item?._id ? (
                  <div
                    className="spinner-border text-white spinner-border-sm"
                    role="status"
                  ></div>
                ) : (
                  <AiFillDelete className="delete-icon" />
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
      <ToastContainer/>
    </table>
    </div>
  );
};

export default BlogTable;

import React from "react";
import "./Loading.css";
import { CircularProgress } from "@mui/material";



export const LoadingPage = () => {
  const circleSize = 30;
  return (
    <div className='loader-height'>
    <div class="loader3">
        <div class="circle1"></div>
        <div class="circle1"></div>
        <div class="circle1"></div>
        <div class="circle1"></div>
        <div class="circle1"></div>
    </div>
</div>
  );
}


export const LoadingButton = () => {
  const circleSize = 20;

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  };

  const circularProgressStyle = {
    color: "white",
    width: circleSize,
    height: circleSize,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <div style={containerStyle}>
      <div style={circularProgressStyle}>
        <CircularProgress size={circleSize} className="circle" thickness={5} />
      </div>
    </div>
  );
};



import React, { useEffect } from 'react'
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setProductInventory } from '../../../../../../app/features/adminProducts/productSlice';
import Imp from '../../../../../../Components/Common/Imp/Imp';

const Inventory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const inventorry = useSelector((state)=>state.product.inventoryy);
  const [inventory, setInventory] = useState({

    sku: inventorry?.sku || '',
    status: inventorry?.status || '',
    isLevelAQuantity: inventorry?.isLevelAQuantity || false,
    isLimitToOneOrder: inventorry?.isLimitToOneOrder || false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'isLevelAQuantity' || name === 'isLimitToOneOrder') {
      setInventory({ ...inventory, [name]: e.target.checked })
    } else {
      setInventory({ ...inventory, [name]: value })
    }
  }

  const handleNext = (e) => {
    e.preventDefault()
    window.scrollTo({ top: 0, left: 0 })
    dispatch(setProductInventory(inventory));
    navigate('?tab=linked')
  }

  useEffect(() => {
    setTimeout(() => {
      dispatch(setProductInventory(inventory));
    }, 300);
  }, [inventory])
  
  return (
   <>
  <form onSubmit={handleNext}>
        <Row className='mt-2'>
          <Col lg={12} className="d-flex flex-column justify-content-between">
            <Row>

              <Col lg={6}>
                <div className="half-input-box">
                  <label>SKU<Imp/></label>
                  <div className='profile-input-field'>
                    <input  value={inventory?.sku} onChange={handleChange} required name="sku" type="text" />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="half-input-box">
                  <label>Stock Status<Imp/></label>
                  <div className="profile-input-field">
                    <select required  value={inventory?.status} onChange={handleChange} name="status" id="">
                      <option value="" disabled selected>Select</option>
                      <option value="In Stock" >In Stock</option>
                      <option value="Out of Stock" >Out of Stock</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Manage Stock?</label>
                  <div className="d-flex mt-2 align-items-center">
                    <input onChange={handleChange} defaultChecked={inventory?.isLevelAQuantity === true ? true : false} type="checkbox" name='isLevelAQuantity' className='me-3 product-checkbox' />
                    <span className='text-style m-0'>Manage stock level (Quantity)</span>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Sold Individually</label>
                  <div className="d-flex mt-2 align-items-center">
                    <input onChange={handleChange} defaultChecked={inventory?.isLimitToOneOrder === true ? true : false} type="checkbox" name="isLimitToOneOrder" className='me-3 product-checkbox' />
                    <span className='text-style m-0'>Limit purchases to 1 item per order</span>
                  </div>
                </div>
              </Col>
            </Row>
            <div className='d-flex justify-content-between mt-5' >
              <div className="form-width-btn">
                {/* <button className='save-draft'>
                  {
                    submitloader ? <div
                      className="spinner-border theme-colour ml-4"
                      role="status"
                    ></div> : <>Save</>
                  }
                </button> */}
              </div>
              <div className="form-width-btn">
                <button className='form-action-next'>Next</button>
              </div>
            </div>
          </Col>
        </Row>
      </form>

   </>
  )
}

export default Inventory
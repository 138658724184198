import React from "react";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import {  useNavigate } from "react-router-dom";

import { Col, Row } from "react-bootstrap";
import { AiOutlineLeft } from "react-icons/ai";
import { useSelector } from "react-redux";
import NewReleaseCard from './NewReleaseCard.js'
import TopHeader from "../../../Components/TopHeader/TopHeader";


const NewsPressRelease = () => {
  const { pressAndNews } = useSelector((state) => state.company);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  console.log(pressAndNews);

  return (
    
      <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"News & Press Release"} type={"back"}/>
      {pressAndNews.length === 0 ? (
        "Please Add News & Press Release"
      ) : (
        <>
          <div className="mt-4 ">
            <Row className="mt-4 news-press-cards-container">
              {pressAndNews.map((e, index) => (
                <Col lg={4}>
                  <NewReleaseCard data={e} key={index} />
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}
    </DashboardBoxStyle>


   
    
  );
};

export default NewsPressRelease;

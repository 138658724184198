import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate, useParams } from "react-router-dom";
import UsageTable from "../../../Pages/Table/UsageTable.js";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import { bannerUsage } from "../../../util/Auth/APIs";
import { toast } from "react-toastify";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import TablePagination from "@mui/material/TablePagination";



const BannerUsage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [data, setData] = useState([]);


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };


  const getDetails = async () => {
    const page = currentPage + 1;
    try {
      const res = await bannerUsage({
        id,
        page,
        limit,
        search,
        month,
      });
      setData(res?.data?.data?.data);
      setTotalPages(res?.data?.data?.total_page * limit);
    
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, [currentPage, search, month, limit]);

  return (
    <DashboardBoxStyle className="scroller">
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <TopHeader headerTitle={"Banner Usage"} type={"back"} />
          <div className="orderSearchContainer">
            <SearchBar
              search={search}
              setSearch={setSearch}
              setMonth={setMonth}
              placeholder="Search for Banner Users"
              showFilter={false}
            />
          </div>
          <UsageTable data={data} />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </>
      )}
    </DashboardBoxStyle>
  );
};

export default BannerUsage;

import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CustomButton from "../../../../Components/Button/CustomButton/CustomButton";
import {  getAdminById ,updateAdminById } from "../../../../util/Auth/APIs";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

const EditSubAdmin = () => {
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isValidMobile, setIsValidMobile] = useState(true);
  const { id } = useParams();
  const [password, setPassword]= useState("");

  const [user, setUser] = useState({
    name: "",
    email: "",
    number: "",
    role: "",
    usageType:"",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "number") {
      const mobilePattern = /^\d{10}$/; // Assuming 10-digit mobile number
      setIsValidMobile(mobilePattern.test(value));
    }
    setUser({
      ...user,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingBtn(true);

    let data= null;

    if (password !== "") {
      const updatedObj = {
        ...user,
        password: password,
      }
     
     
      data = updatedObj;
      console.log(data);
      

      
     
    }else{

      data = user;
      console.log(data);

    }

    

  
    

    

    try {
      const res = await updateAdminById({id,data});
      toast.success(`${user.role} updated successfully`);
      setLoadingBtn(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoadingBtn(false);
    }
  };

  const getAdminDetails = async () => {
    try {
      const res = await getAdminById(id);
      console.log(res?.data?.data);
      setUser({
        name: res?.data?.data?.name,
        email: res?.data?.data?.email,
        number: res?.data?.data?.number,

        role: res?.data?.data?.role,
        usageType:res?.data?.data?.usageType,
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getAdminDetails();
  }, []);

  return (
    <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Edit Sub Admin"} type={"back"} />

      <form onSubmit={handleSubmit} className="create-community-container">
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Name</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="name"
                required
                type="text"
                value={user?.name}
                onChange={handleChange}
                placeholder="Enter Name"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Email</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="email"
                required
                type="email"
                value={user?.email}
                onChange={handleChange}
                placeholder="Enter Email"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Password</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="password"
                type="text"
                onChange={(e)=>setPassword(e.target.value)}
                placeholder="Enter Password (optional)"
              />
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Mobile Number</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <input
                name="number"
                type="number"
                placeholder="Enter Mobile Number"
                required
                value={user?.number}
                onChange={handleChange}
                inputMode="numeric"
              />
            </div>
            {!isValidMobile && (
              <p style={{ color: "red" }}>Invalid mobile number!</p>
            )}
          </div>
        </div>

        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Role</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <select
                placeholder="Select role"
                onChange={handleChange}
                required
                name="role"
                value={user.role}
                id=""
              >
                <option value="" disabled selected>
                  Select role
                </option>
                <option value="sub-admin">Sub Admin</option>
                <option value="admin"> Admin</option>
              </select>
              {/* <input value={companyBlogs.blogCategory} onChange={handleChange} name="blogCategory" type="text" /> */}
            </div>
          </div>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="profile-input-head">
            <h6>Access Type</h6>
          </div>
          <div className="profile-input-width">
            <div className="profile-input-field">
              <select
                placeholder="Select role"
                onChange={handleChange}
                required
                name="usageType"
                value={user.usageType}
                id=""
              >
                <option value="" disabled selected>
                  Select Access Type
                </option>
                <option value="viewer">Viewer</option>
                <option value="editor">Editor</option>
              </select>
              {/* <input value={companyBlogs.blogCategory} onChange={handleChange} name="blogCategory" type="text" /> */}
            </div>
          </div>
        </div>

        <div className="middle">
          <CustomButton loading={loadingBtn} classId="small-btn">
            Submit
          </CustomButton>
        </div>
      </form>
    </DashboardBoxStyle>
  );
};

export default EditSubAdmin;

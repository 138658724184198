import React from 'react'

const AdminNavBar = () => {
    return (
        <>
            <div className="admin-nav">
                <div className="admin-search">
                    <div className="admin-search-bar">
                        <i class="fa-solid admin-search-icon fa-magnifying-glass"></i>
                        <input type="text" placeholder="Search" />
                    </div>
                </div>
                {/* <div className="admin-right">
                    <div className="bell-box">
                    </div>
                    <button onClick={() => setLoginOption(!loginOption)} onBlur={() => setLoginOption(false)}>
                        <div className="login-user-img ">
                            <img src={userData?.img || User2} className="img-size br-50" alt="" />
                            {
                                loginOption && (
                                    <div className="login-options">

                                        <div onClick={() => navigate("/company/user-profile")} className="d-flex my-2 login-option-tab align-items-center">
                                            <BiUser className="loged-icon" />
                                            <span className="loged-heading">Profile</span>
                                        </div>

                                        <div onClick={Logout} className="d-flex my-2 login-option-tab align-items-center">
                                            <BiLogOutCircle className="loged-icon" />
                                            <span className="loged-heading">LogOut</span>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </button>
                </div> */}
            </div>
        </>
    )
}

export default AdminNavBar
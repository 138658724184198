import { Route, Navigate, Routes } from "react-router-dom";
import SupportQueries from "./Pages/Support/SupportQueries/SupportQueries.js";
import SupportTypes from "./Pages/Support/SupportTypes/SupportTypes.js";



function SupportRoutes({ token }) {
  return (
    <Routes>
         <Route
          path="/"
          element={token ? <SupportQueries /> : <Navigate to="/" />}
        />
         <Route
          path="/types"
          element={token ? <SupportTypes /> : <Navigate to="/" />}
        />
        

    </Routes>
   
  )
}

export default SupportRoutes
import React from 'react'
import "./PlanType.css"
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { RxCross2 } from 'react-icons/rx';

const PlanType = ({ isOpen, handleClick }) => {

    const navigate = useNavigate();
    if (!isOpen) {
        return null;
    }


    function handleContinue() {
        const foundationPlan = document.getElementById('foundationPlan');
        const addOn = document.getElementById('addOn');
        if (foundationPlan.checked) {
            navigate("/plan-management/create")
            handleClick();
        } else if (addOn.checked) {
            navigate("/plan-management/add-on")
            handleClick();
        } else {
            toast.error('Please select a plan type');
        }
    }
    return (
        <>

            <div className="planTypeModal">

                <div className='planTypeContainer'>
                    <p>Create a Plan for</p>
                    <div onClick={() => handleClick()} className="planModalCross">
                        <RxCross2 />
                    </div>


                    <div className='planModalInput'>
                        <div>
                            <input type="radio" name="planType" id="foundationPlan" className="custom-radio"/>
                            <label htmlFor="foundationPlan">Foundation Plan</label>
                        </div>
                        <div>
                            <input type="radio" name="planType" id="addOn" className="custom-radio" />
                            <label htmlFor="addOn">Add on</label>
                        </div>


                    </div>



                    <div className="planTypeModalBtn">
                        <button onClick={handleContinue}>Continue</button>
                    </div>

                </div>

            </div>



        </>
    )
}

export default PlanType
import { Route, Navigate, Routes } from "react-router-dom";
import AllTransactions from "./Pages/Transactions/AllTransactions.js";

const TransactionsRoutes = ({ token }) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={token ? <AllTransactions /> : <Navigate to="/" />}
        />
      </Routes>
    </>
  );
};

export default TransactionsRoutes;

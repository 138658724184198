import { Modal } from "react-bootstrap";
import "./CustomModal.css";

const CustomModal = ({ children, show, handleClose, modalStyle, size }) => {
    return (
        <Modal
            size={size ? size : "md"}
            className={modalStyle}
            centered
            show={show}
            onHide={handleClose}
        >
            {children}
        </Modal>
    );
};

export default CustomModal;
import React from 'react'
import { useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { FiGrid } from 'react-icons/fi';
import { NavLink } from 'react-router-dom';

const SubMenuAccordian = (props) => {

    const contentEl = useRef();

    const { active, handleToggle, _id } = props

    const { title } = props.data

    const hideSidebar = false

    return (
        <>
            <div className={`submenu-accordian-card ${active === _id && "submenu-card-active "}`}>
                <div className="submenu-accordian-head">
                    <div onClick={() => handleToggle(_id)} className="submenu-accordian-toggle">
                        <div>
                            <h6 className="sidebar__heading px-3 mt-2">
                                {title}
                            </h6>
                        </div>
                        <BiChevronDown className={`rc-accordion-icon ${active === _id && "rc-accordion-icon-active"}`} />
                    </div>
                    <div
                        ref={contentEl}
                        className={`rc-collapse ${active === _id ? "show" : ""}`}
                        style={
                            active === _id
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0px" }
                        }
                    >
                        <div className="rc-accordion-body">
                            <div className="submenu-list">
                                <ul>
                                    {
                                        props.data.subMenu.map((item, index) => (
                                            <li>
                                                <h5>
                                                    {item.name}
                                                </h5>
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubMenuAccordian
import React, { useEffect, useState } from 'react'
import { LoadingPage } from '../../../../Components/Loading/Loading'
import TopHeader from '../../../../Components/TopHeader/TopHeader'
import { DashboardBoxStyle } from '../../../../SideBar/SidepanelStyled';
import EventsTable from '../../../../Pages/Table/EventsTable';
import { useNavigate } from 'react-router-dom';
import { AllEvents } from '../../../../util/Auth/APIs';
import { toast } from 'react-toastify';
import { eventClear, eventId } from '../../../../app/features/Community/communitySlice';
import { useDispatch } from 'react-redux';
import TablePagination from "@mui/material/TablePagination";
import SearchBar from "../../../../Components/SearchBar/SearchBar";





const EventList = () => {
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");


  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleCreate = () => {
    // navigate("/events/create");
    navigate(`/events/add?tab=basic-info`)
    dispatch(eventId(""))
    dispatch(eventClear())
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const getDetails = async () => {
    const page = currentPage + 1;
    try {
      const { data } = await AllEvents({
        page,
        limit,
        search,
        month,
      });
      setData(data?.message?.data);
      setTotalPages(data?.message?.total_page* limit);

      setLoader(false);
    } catch (error) {

      toast.error("Something went wrong!");

      setLoader(false);

    }

  }

  useEffect(() => {

    getDetails();
  }, [currentPage, search, month, limit])



  return (
    <>
      {
        loader ? (
          <LoadingPage />
        ) : (
          <DashboardBoxStyle className=" scroller">
            <TopHeader headerTitle={"Events Management"} headerBtnName={"Create"} handleClick={handleCreate} />
            <div className="orderSearchContainer">
            <SearchBar
              search={search}
              setSearch={setSearch}
              setMonth={setMonth}
              placeholder="Search for transaction"
              showFilter={false}
            />
          </div>
          

            <EventsTable data={data} />

            <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>

          </DashboardBoxStyle>

        )
      }


    </>
  )
}

export default EventList
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";

import "./AllCategory.css";
import { BsThreeDotsVertical } from "react-icons/bs";
import { getAllCategories } from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import { removeCategory } from "../../../../util/Auth/APIs";
import { ToastContainer, toast } from "react-toastify";
import { Col, Row } from "react-bootstrap";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CategoryCard from "../CategoryLevels/CategoryCard";

const AllCategory = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [cardsData, setCardsData] = useState([]);

  const handleOptionsClick = (id) => {
    const updatedCardsData = cardsData.map((card) => {
      if (card._id === id) {
        return {
          ...card,
          showOptions: !card.showOptions,
        };
      }
      return card;
    });

    setCardsData(updatedCardsData);
  };

  const getCategories = async () => {
    try {
      const res = await getAllCategories();
      setCardsData(
        res?.data?.data.map((card) => ({ ...card, showOptions: false }))
      );
      console.log(cardsData);
      setLoading(false);
      // console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  useEffect(() => {
    console.log(cardsData);
  }, [cardsData.length]);

  const removeItem = async (id) => {
    setLoading(true);

    try {
      const res = await removeCategory(id);
      setLoading(false);
      toast.success("Community deleted successfully");

      setCardsData(cardsData.filter((item) => item._id !== id)); // update the blogsList state
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(error);
    }
  };

  const handleClick = () => {
    navigate("/category/create");
  };

  const handleLevel = (id) => {
    navigate(`/category/level-2/${id}`);
  }

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader
            headerTitle={"Category Section"}
            headerBtnName={"Add Category"}
            handleClick={handleClick}
          />
          <Row>
            {cardsData.map((item) => {
              return (
                <Col lg={4} className="mb-4">
                  <CategoryCard data={item} handleLevel={handleLevel} removeItem={removeItem} />
                </Col>
              );
            })}
          </Row>
          <ToastContainer />
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default AllCategory;

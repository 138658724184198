import { useState } from "react";
import "./chartDashboard.css";
import LineChart from "./LineChart/LineChart.js";


const ChartDashboard = () => {
    function getCurrentMonthYear() {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1;
        return `${year}-${month.toString().padStart(2, "0")}`;
    }

    const [monthYear, setMonthYear] = useState(getCurrentMonthYear);

    return (
        <div className='chartDashboardWrapper'>
           
            <div className="chartDashboard">
                <LineChart />
            </div>
        </div>
    )
}

export default ChartDashboard
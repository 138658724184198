import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./Table.css";
import { Switch } from "@mui/material";
import { toast } from "react-toastify";
import { updateUser } from "../../util/Auth/APIs";

function TableRow({ item, index }) {
  const navigate = useNavigate();
  const [activeStatus , setActiveStatus]= useState(item.isActive);

  const handleRowClick = (id) => {
    navigate(`/user/${id}/feature`);
  };

  const handleToggle = async(id)=>{
    try {
      const data = {
        isActive: !activeStatus,
      };
      const res = await updateUser({ id, data });
      setActiveStatus(!activeStatus);
      if(activeStatus!==true){
        toast.success(`${item.firstName} ${item.lastName} unblocked successfully!`);

      }else{
        toast.success(`${item.firstName} ${item.lastName}  blocked successfully!`);

      }
    
      
    } catch (error) {
      toast.error(error.message);
      
    }
  }

  const handleSwitchClick = (e) => {
   
    e.stopPropagation(); 
    handleToggle(item._id);
  };
  return (
    <>
      <tr key={item._id} onClick={() => handleRowClick(item._id)}>
        <td>{index + 1}</td>
        <td>
          {item.firstName} {item.lastName}
        </td>
        <td>{item.email}</td>
        <td>{item.phone}</td>
        <td>{item.createdAt.slice(0, 10)}</td>
        <td onClick={handleSwitchClick}>
                <Switch
                  checked={activeStatus}
                
                  inputProps={{ "aria-label": "controlled" }}
                />
              </td>
      </tr>
    </>
  );
}

const Table = ({ data }) => {
 

  

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>ID</th>
            <th>User Name</th>
            <th>User Email</th>
            <th>Mobile Number</th>
            <th>Joining Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <TableRow item={item} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

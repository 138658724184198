import React, { useEffect, useState } from "react";

import { data, dataSmallCard } from "./Data.js";
import "./Dashboard.css";
import ChartDashboard from "./ChartDashboard.js";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import { Col, Row } from "react-bootstrap";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";
import { getDashboardDetails } from "../../../util/Auth/APIs.js";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../Components/Loading/Loading.js";

const Dashboard = () => {
  const [details, setDetails] = useState("");

  const [loading, setLoading] = useState(true);

  const getDetails = async () => {
    try {
      const res = await getDashboardDetails();
      setDetails(res?.data?.data);

      setLoading(false);
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Dashboard"} type={"noaction"} />
          <div className="dashboard-container">
            <div className="dashboard-header">
              <Row>
                {data.map((item, index) => {
                  const field = item.value;
                  const fieldValue = details?.[field];
                 
                  
                  
                  return (
                    <Col lg={3} key={index}>
                      <div className="dashboard-header-card">
                        <div
                          className="dashboardTopCardImage"
                          style={{
                            backgroundColor: item.color,
                            color: "white",
                          }}
                        >
                          {item.icon}
                        </div>

                        <div className="hearder-card-details">
                        <h5>{fieldValue !== undefined ? fieldValue : 'NA'}</h5>
                          <p>{item.name}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </div>
            <div className="dashboard-main">
              <Row>
                <Col lg={9}>
                  <ChartDashboard />
                </Col>
                <Col lg={3}>
                  <div className="dashboard-main-right">
                    {dataSmallCard.map((item, index) => {
                      const field = item.value;
                      const fieldValue = details?.[field];
                      return (
                        <div
                          className="dashborad-main-card"
                          style={{ borderLeft: `20px solid ${item.color}` }}
                        >
                          <h5>{item.name}</h5>
                          <p>{fieldValue !== undefined ? fieldValue : 'NA'}</p>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default Dashboard;

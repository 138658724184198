import { Route, Navigate, Routes } from "react-router-dom";
import SubAdminList from "./Pages/SubAdmin/SubAdminList/SubAdminList.js";
import CreateSubAdmin from "./Pages/SubAdmin/CreateSubAdmin/CreateSubAdmin.js";
import EditSubAdmin from "./Pages/SubAdmin/EditSubAdmin/EditSubAdmin.js";


function AdminRoutes({ token }) {
  return (
    <Routes>
         <Route
          path="/"
          element={token ? <SubAdminList /> : <Navigate to="/" />}
        />
         <Route
          path="/create"
          element={token ? <CreateSubAdmin /> : <Navigate to="/" />}
        />
       
         <Route
          path="/update/:id"
          element={token ? <EditSubAdmin /> : <Navigate to="/" />}
        />
       

    </Routes>
  )
}

export default AdminRoutes
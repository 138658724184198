import React from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import CertificateCard from "../../../Components/Common/Card/CertificateCard.js";
import {useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";


const Certification = () => {
  const { certification } = useSelector((state) => state.company);
  console.log(certification);
  const navigate= useNavigate();
  function handleClick() {
    navigate(-1);
  }
  return (
    <>
   
      <DashboardBoxStyle className=" scroller">
       
        <TopHeader headerTitle={"Certification "} type={"back"}/>

        {certification.length === 0 ? (
          <div className="no-list-btn" style={{marginTop:'5vh'}}>
            "No Certificate"

          </div>
          
        ) : (
          <>
            <div className="mt-4">
              <Row className="mt-4">
                {certification.map((e, index) => (
                  <Col lg={4}>
                    <CertificateCard data={e}  />
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </DashboardBoxStyle>
    
    </>
  );
};

export default Certification;

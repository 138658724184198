import React from 'react'
import { useState } from 'react';
import "react-calendar/dist/Calendar.css";
import Calendar from 'react-calendar'
import { useEffect } from 'react';

import "./DateRangePicker.css";

const DateRangePicker = ({ setFilterData, filterData }) => {
    const [value, onChange] = useState(new Date());

    const getDate = (dateString) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }
    useEffect(() => {
        if (value[0] && value[1]) {
            setFilterData({
                ...filterData,
                startDate: getDate(value[0]),
                endDate: getDate(value[1])
            })
        }

        console.log(filterData);
    }, [value]);


    return (
        <Calendar
            showWeekNumbers
            tileClassName="dateRangePicker_theme"
            className="dateRangePicker_wrapper"
            onChange={onChange}
            value={value}
            goToRangeStartOnSelect
            selectRange

        />
    )
}

export default DateRangePicker
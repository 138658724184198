import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { getSingleCategory } from "../../../../util/Auth/APIs";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { Accordion } from "react-bootstrap";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import TopHeader from "../../../../Components/TopHeader/TopHeader";

const CategoryDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [coverImage, setCoverImage] = useState(null);
  const [coverLink, setCoverLink] = useState(null);

  const [CategoryDetails, setCategoryDetails] = useState({
    name: "",
    description: "",
    cover: "",
    keywords: [],
    subCategory: [],
  });

  const getCategoryDetails = async () => {
    try {
      const res = await getSingleCategory(id);
      setCategoryDetails({
        name: res?.data?.data?.name,
        description: res?.data?.data?.description,
        cover: res?.data?.data?.cover,
        subCategory: [...res?.data?.data?.subCategory],

        keywords: [...res?.data?.data?.keywords],
      });
      setTags(res?.data?.data?.keywords);
      setCoverLink(res?.data?.data?.cover);
      setCoverImage(res?.data?.data?.cover);
      setLoading(false);

      //   console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  
  useEffect(() => {
    getCategoryDetails();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={CategoryDetails.name} type="back" />
          <div className="create-community-container">
            <div className="uploader-community-image">
              <div className="communityCover">
                <input
                  type="file"
                  accept="image/*"
                  disabled
                  className="input-cover"
                  hidden
                />

                {coverImage ? (
                  <img src={coverImage} alt="img" />
                ) : (
                  <p>Add Image </p>
                )}
              </div>
            </div>
            <div className="community-input-container">
              <input
                type="text"
                name="name"
                disabled
                placeholder="Category Name"
                className="category-input-full"
                value={CategoryDetails.name}
              />
              <input
                type="text"
                name="description"
                disabled
                placeholder="Category Description"
                className="category-input-full"
                value={CategoryDetails.description}
              />
              <div className="tags-input-container">
                {tags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close">&times;</span>
                  </div>
                ))}
                <input
                  disabled
                  type="text"
                  className="input-tag"
                  placeholder="Add Keywords"
                />
              </div>
            </div>
          </div>

          <div>
           

            {CategoryDetails.subCategory.length === 0 ? (
              <div className="headline-middle">
                <p>No sub categories</p>
              </div>
            ) : (
              <>
              <div className="headline-middle">
                <p>Sub Categories</p>
              </div>
                {CategoryDetails.subCategory.map((category, index) => (
                  <React.Fragment key={index}>
                    <Accordion
                      style={{
                        borderRadius: "1px",
                        marginBottom: "20px",
                      }}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <h5>{category?.name}</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          {category?.subcategory.length === 0 ? (
                            <p>No sub-sub category for this sub-category</p>
                          ) : (
                            category?.subcategory.map((subcategory, index) => (
                              <div
                                key={index}
                                className="sub-category-sub-category"
                              >
                                <p>{subcategory}</p>
                              </div>
                            ))
                          )}
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </React.Fragment>
                ))}
              </>
            )}
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default CategoryDetails;

import React from 'react'
import { useState } from 'react';
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { BiPencil, BiTrash } from "react-icons/bi"
import { toast } from 'react-toastify';
// import { DeleteEventFAQ, UpdateEventFAQ } from '../../../../axios/apis';

const EventFaqAccordian = (props) => {
    const contentEl = useRef();

    const [deleteloader, setDeleteLoader] = useState(false)
    const [submitloader, setSubmitLoader] = useState(false)

    const { handleToggle, getAllFAQ, active, data } = props;

    const { question, _id, answer } = data;

    const [faqForm, setFaqForm] = useState({
        question: question || '',
        answer: answer || ''
    })

    const handleChange = (e) => {
        setFaqForm({ ...faqForm, [e.target.name]: e.target.value })
    }

    const handleSave = async (e) => {
        e.preventDefault();
        setSubmitLoader(true)
        // try {
        //     const res = await UpdateEventFAQ({ id: _id, data: faqForm })
        //     toast.success('FAQ Updated successfully')
        //     setSubmitLoader(false)
        //     if (res.data.success) {
        //         handleToggle(_id)
        //         getAllFAQ()
        //     }
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setSubmitLoader(false)
        //     console.log(error)
        // }
    }

    const removeItem = async () => {
        setDeleteLoader(true)
        // try {
        //     const res = await DeleteEventFAQ(_id)
        //     if (res.data.success) {
        //         toast.success('FAQ deleted successfully')
        //         getAllFAQ()
        //     }
        //     setDeleteLoader(false)
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setDeleteLoader(false)
        //     console.log(error)
        // }
    }

    return (
        <>
            <div className='edit-accordian-card'>
                <div className="edit-accordian-head">
                    <div className="edit-accordian-toggle p-3">
                        <h4>{question}</h4>
                        <div className='d-flex'>
                            <div onClick={() => handleToggle(_id)} className="pencil-edit-btn accordian-edit-btns">
                                <BiPencil />
                            </div>
                            <div onClick={removeItem} className={`trash-edit-btn accordian-edit-btns ${deleteloader && "theme-red"}`}>
                                {
                                    deleteloader ? <div
                                        className="spinner-border text-white spinner-border-sm"
                                        role="status"
                                    ></div> : <>  <BiTrash /></>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        ref={contentEl}
                        className={`rc-collapse ${active === _id ? "show" : ""}`}
                        style={
                            active === _id
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0px" }
                        }
                    >
                        <div className="rc-accordion-body">
                            <Row>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Question</label>
                                        <div className='profile-input-field'>
                                            <input name="question" value={faqForm?.question} onChange={handleChange} type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Answer</label>
                                        <div className='profile-input-field'>
                                            <textarea name="answer" value={faqForm?.answer} onChange={handleChange} className='faq-answer-textarea' id="" cols="30" rows="10"></textarea>
                                        </div>
                                    </div>
                                </Col>
                                <div className='d-flex justify-content-end mt-4' >
                                    <div className="form-width-btn">
                                        <button onClick={handleSave} className='form-action-next'>
                                            {
                                                submitloader ? <div
                                                    className="spinner-border text-white ml-4"
                                                    role="status"
                                                ></div> : <> Save</>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventFaqAccordian
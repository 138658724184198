import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./BlogSection.css";
import CircleIcon from "@mui/icons-material/Circle";
import { useNavigate } from "react-router-dom";
import { DeleteBlog, getAllBlogs, getAllMagzine, removeMagzine } from "../../../../../util/Auth/APIs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { LoadingPage } from "../../../../../Components/Loading/Loading";

const MagzineSection = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [cardsData, setCardsData] = useState([]);

  const handleOptionsClick = (id) => {
    const updatedCardsData = cardsData.map((card) => {
      if (card._id === id) {
        return {
          ...card,
          showOptions: !card.showOptions,
        };
      }
      return card;
    });

    setCardsData(updatedCardsData);
  };

  const getBlogs = async () => {
    try {
      setLoading(true);
      const res = await getAllMagzine();
      setCardsData(
        res?.data?.message?.data.map((card) => ({
          ...card,
          showOptions: false,
        }))
      );

      setLoading(false);
      // console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(cardsData);
  }, [cardsData.length]);

  useEffect(() => {
    getBlogs();
  }, []);

  const removeItem = async (id) => {
    setLoading(true);

    try {
      const res = await removeMagzine(id);
      setLoading(true);
      toast.success("Magzine deleted successfully");
      setLoading(false);

      setCardsData(cardsData.filter((item) => item._id !== id)); // update the blogsList state
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
      console.log(error);
    }
  };

  const getDescriptionText = (description) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(description, 'text/html');
    return htmlDoc.body.textContent.slice(0,100);
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <Container fluid>
          <Row>
            {cardsData.map((item) => (
              <Col lg={4} className="mb">
                <div className="insights-card" key={item._id}>
                  <div className="category-card-menuIcon">
                    <BsThreeDotsVertical
                      onClick={() => handleOptionsClick(item._id)}
                    />
                  </div>
                  {item.showOptions && (
                    <ul className="options-menu">
                      <li
                        onClick={() => navigate(`/insights/whitepaper/edit/${item._id}`)}
                      >
                        Edit
                      </li>

                      <li onClick={() => removeItem(item._id)}>Delete</li>
                    </ul>
                  )}
                  <img
                    src={item?.images[0]}
                    alt=""
                  />
                  <div className="insights-card-details">
                    <button>
                    Magzine{" "}
                      <CircleIcon
                        fontSize="small"
                        style={{ color: "#4DCB52" }}
                      />
                    </button>
                    <h5>{item?.title.slice(0, 40)}
                      {item?.title.length > 40 ? "..." : null}</h5>
                    <p>{getDescriptionText(item?.content)}...</p>
                    <button  onClick={() => navigate(`/insights/whitepaper/${item._id}`)}>View Details</button>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
};

export default MagzineSection;

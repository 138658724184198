import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../firebase";
import { ToastContainer, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import "./CreateCategory.css";
import { createCategory } from "../../../../util/Auth/APIs";
import TopHeader from "../../../../Components/TopHeader/TopHeader";

const CreateCategory = () => {
  
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverFileName, setCoverFileName] = useState(null);
  const [coverLink, setCoverLink] = useState(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);

  const [CategoryDetails, setCategoryDetails] = useState({
    name: "",
    description: "",
    cover: "",
    keywords: [],
  });

  const handleClick = () => {
    navigate(-1);
  };

  const handleCoverImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      setCoverFileName(e.target.files[0].name);
      setCoverImage(URL.createObjectURL(e.target.files[0]));
      const storageRef = ref(storage, `Category/${e.target.files[0].name}`);
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCoverLink(url);
          setCategoryDetails({ ...CategoryDetails, cover: url });
          setImageloader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };
  // console.log(coverLink);
  const deleteFromFirebase = (url) => {
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    deleteObject(pictureRef)
      .then(() => {
        setCoverImage(null);
        setCoverFileName(null);
        setCategoryDetails({ ...CategoryDetails, cover: "" });
        console.log("deleted");

        toast.success("Image removed successfully");
        setDeleteLoader(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error("Cannot Remove Image");
      });
  };

  function handleKeyDown(e) {
    if (e.key === 'Enter' || e.type === 'click') {
      const value = e.target.parentElement.querySelector('.input-tag').value.trim();
      if (value === '') return;
      const updatedTags = [...tags, value];
      setTags(updatedTags);
      setCategoryDetails({ ...CategoryDetails, keywords: [...updatedTags] });
      e.target.parentElement.querySelector('.input-tag').value = '';
    }
  }

  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setCategoryDetails({ ...CategoryDetails, keywords: [...updatedTags] });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setCategoryDetails({ ...CategoryDetails, [name]: value });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name: CategoryDetails.name,
      description: CategoryDetails.description,
      cover: CategoryDetails.cover,
      keywords: CategoryDetails.keywords,
    };
    console.log(data);
    setSubmitLoader(true);

    try {
      const res = await createCategory(data);
      toast.success("Community Created Successfully");
      setSubmitLoader(false);
      navigate(-1);
      // console.log(res);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      // console.log(error);
    }
  };
  return (
    <>
      <DashboardBoxStyle className=" scroller">
        <TopHeader headerTitle={"Create Category"} type="back" />
        <div className="create-community-container">
          <div className="uploader-community-image">
            <div
              className="communityCover"
              onClick={() => document.querySelector(".input-cover").click()}
            >
              <input
                type="file"
                accept="image/*"
                className="input-cover"
                hidden
                onChange={handleCoverImage}
              />
              {imageloader ? (
                <>
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                </>
              ) : (
                <>
                  {coverImage ? (
                    <img src={coverImage} alt={coverFileName} />
                  ) : (
                    <p>Add Image </p>
                  )}
                </>
              )}
            </div>
            <div
              className="CategoryDeleteIcon"
              onClick={() => deleteFromFirebase(coverLink)}
            >
              <RxCross2 />
            </div>
          </div>
          <div className="community-input-container">
            <input
              type="text"
              name="name"
              placeholder="Category Name"
              className="category-input-full"
              onChange={handleChange}
            />
            <input
              type="text"
              name="description"
              placeholder="Category Description"
              className="category-input-full"
              onChange={handleChange}
            />
            <div className="tags-input-container">
              {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{tag}</span>
                  <span className="close" onClick={() => removeTag(index)}>
                    &times;
                  </span>
                </div>
              ))}
              <input

                type="text"
                className="input-tag"
                placeholder="Add Keywords"
              />
              <p onClick={handleKeyDown} className="add-btn" >
                Add
              </p>
            </div>
          </div>
          <div className="CreateCategoryBtn">
            <button onClick={handleSubmit}>
              {submitloader ? (
                <div
                  className="spinner-border text-white ml-4"
                  role="status"
                ></div>
              ) : (
                <> Create Now</>
              )}
            </button>
          </div>
        </div>
        <ToastContainer />
      </DashboardBoxStyle>
    </>
  );
};

export default CreateCategory;

import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RxCross2 } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "../../../../Components/Loading/Loading.js";


import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";

import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { storage } from "../../../../firebase";
import { GetBlogById, UpdateBlog } from "../../../../util/Auth/APIs";
import TopHeader from "../../../../Components/TopHeader/TopHeader.js";
import SelectAllCategories from "../Category/SelectAllCategories.js";

const EditBlog = () => {
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const { id } = useParams();
  const [loader, setLoader] = useState(false);

  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [tags, setTags] = useState([]);

  const [companyBlogs, setCompanyBlogs] = useState({
    isPublished: false,
    blogTitle: "",
    category: "",
    category2: "",
    category3: "",
    category4: "",

    blogDescription: "",
    content: "",
    blogImages: [],
    blogVideos: [],
    remarks: "",
    keywords: [],
    type: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isPublished") {
      setCompanyBlogs({ ...companyBlogs, [name]: e.target.checked });
    } else if (name === "blogVideos" || name === "keywords") {
      setCompanyBlogs({ ...companyBlogs, [name]: [e.target.value] });
    } else {
      setCompanyBlogs({ ...companyBlogs, [name]: value });
    }
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };
  const handleQuill = (content, delta, source, editor) => {
    setCompanyBlogs({ ...companyBlogs, content: editor.getHTML() });
    setValue(editor.getHTML());
  };

  const handleImage = (e) => {
    console.log(e.target.files);
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      const storageRef = ref(
        storage,
        `BlogsPictures/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCompanyBlogs({
            ...companyBlogs,
            blogImages: [...companyBlogs.blogImages, url],
          });
          setImageloader(false);
          // companyBlogs.blogImages.push(url)
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png file");
      return;
    }
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.type === "click") {
      const value = e.target.parentElement.querySelector('.input-tag').value.trim();
      if (value === '') return;
      ;
      const updatedTags = [...tags, value]; // Create a new array with the updated tags
      setTags(updatedTags);
      setCompanyBlogs({ ...companyBlogs, keywords: [...updatedTags] });
      e.target.parentElement.querySelector('.input-tag').value = '';
    }
  }

  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setCompanyBlogs({ ...companyBlogs, keywords: [...updatedTags] });
  }

  const deleteFromFirebase = (url) => {
    console.log(url);
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    deleteObject(pictureRef)
      .then(() => {
        setCompanyBlogs({
          ...companyBlogs,
          blogImages: companyBlogs.blogImages.filter((item) => item !== url),
        });
        console.log("success");
        setDeleteLoader(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error("Cannot Remove Image ");
        // Uh-oh, an error occurred!
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    const payload = {
      id: id,
      data: {
        title: companyBlogs.blogTitle,
        isPublished: companyBlogs.isPublished,
        category: companyBlogs.category,
      category2: companyBlogs.category2,
      category3: companyBlogs.category3,
      category4: companyBlogs.category4,
        
        description: companyBlogs.blogDescription,
        content: companyBlogs.content,
        images: companyBlogs.blogImages,
        videos: companyBlogs.blogVideos,
        remarks: companyBlogs.remarks,
        keywords: companyBlogs.keywords,
        type: companyBlogs.type
      },
    };

    try {
      const res = await UpdateBlog(payload);
      toast.success("Blog Updated Successfully");
      setSubmitLoader(false);
      navigate(-1);
      console.log(res.data.message);
    } catch (error) {
      toast.error("Something went wrong");
      setSubmitLoader(false);
      console.log(error);
    }
  };

  const singleBlog = async () => {
    setLoader(true);
    try {
      const res = await GetBlogById(id);
      setCompanyBlogs({
        isPublished: res.data.message.isPublished,
        blogTitle: res.data.message.title,
        blogDescription: res.data.message.description,
        content: res.data.message.content,
        blogImages: res.data.message.images,
        blogVideos: res.data.message.videos,
        remarks: res.data.message.remarks,
        keywords: res.data.message.keywords,
        type: res.data.message.type,
        category: res.data.message.category,
      category2: res.data.message.category2,
      category3: res.data.message.category3,
      category4: res.data.message.category4,
      });
      setTags(companyBlogs?.keywords);

      setLoader(false);
      console.log(res.data.message);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTags(companyBlogs.keywords);
    
  }, [companyBlogs.keywords.length]);


  useEffect(() => {
    singleBlog();
  }, []);

  useEffect(() => {
    console.log(companyBlogs);
    console.log(companyBlogs.isPublished);
  }, [companyBlogs]);

  return (
    <>{
        loader ? <LoadingPage/>:(
            <form onSubmit={handleSubmit}>
        <DashboardBoxStyle className="scroller">
          

          <TopHeader headerTitle={"Edit Blog"} type="back"/>

          <div className="form-container position-relative mt-4">
            <div className="d-flex align-items-center">
              <input
                type="checkbox"
                defaultChecked={companyBlogs.isPublished ? true : false}
                onChange={handleChange}
                className="hr-select"
                name="isPublished"
                id=""
              />

              <span className="ispublished-text">
                Do you want to add this in company view page
              </span>
            </div>

            {/* --------------  Blog Title ----------- */}
            <div className="d-flex mt-4 align-items-center">
              <div className="profile-input-head">
                <h6>Blog Title</h6>
              </div>
              <div className="profile-input-width">
                <div className="profile-input-field">
                  <input
                    value={companyBlogs.blogTitle}
                    onChange={handleChange}
                    name="blogTitle"
                    type="text"
                  />
                </div>
              </div>
            </div>

             <SelectAllCategories
                selected={companyBlogs}
                setSelected={setCompanyBlogs}
              />


            <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Type</h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <select onChange={handleChange} value={companyBlogs.type}  required name="type" id="">
                  <option value="" selected disabled>
                    select
                  </option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              </div>
            </div>
          </div>

            {/* -------------- Blog Description ----------- */}
            <div className="d-flex mt-4">
              <div className="profile-input-head">
                <h6>Blog Description</h6>
              </div>
              <div className="profile-input-width">
                <div className="profile-input-field">
                  <textarea
                    value={companyBlogs.blogDescription}
                    onChange={handleChange}
                    name="blogDescription"
                    className="description-textarea"
                    id=""
                    cols="30"
                    rows=""
                  ></textarea>
                </div>
              </div>
            </div>

            {/* --------------   Content ----------- */}
            <div className="d-flex mt-4 align-items-center">
              <div className="profile-input-head">
                <h6>Content</h6>
              </div>

              <div className="profile-input-width">
                <ReactQuill
                  style={{ background: "white" }}
                  theme="snow"
                  modules={Boxmodules}
                  value={companyBlogs.content}
                  onChange={handleQuill}
                  placeholder="Description"
                />
                {/* <div className='profile-input-field'>
                                <input onChange={handleChange} name="content" type="text" />
                            </div> */}
              </div>
            </div>

            {/* --------------  Blog Images ----------- */}
            <div className="d-flex mt-4">
              <div className="profile-input-head">
                <h6>Blog Images</h6>
              </div>
              <div className="profile-input-width">
                <div className="upload-button">
                  {imageloader ? (
                    <>
                      <div
                        className="spinner-border text-white ml-4"
                        role="status"
                      ></div>
                    </>
                  ) : (
                    <>
                      <span className="d-flex m-0 text-white">
                        <BiUpload /> Upload Image
                      </span>
                      <input
                        onChange={handleImage}
                        accept="image/png, image/gif, image/jpeg"
                        name="blogImages"
                        type="file"
                      />
                    </>
                  )}
                </div>

                {companyBlogs.blogImages.length !== 0 && (
                  <>
                    <div className="mt-3 profile-input-field">
                      <Row>
                        {companyBlogs.blogImages.map((img, index) => (
                          <Col lg={3}>
                            <div className="upload-img-card">
                              <img
                                src={img}
                                className={`img-size ${
                                  deleteloader && "brightness-50"
                                }`}
                                alt=""
                              />
                              {deleteloader && (
                                <div
                                  className="spinner-border position-absolute text-white ml-4"
                                  role="status"
                                ></div>
                              )}
                              <div
                                onClick={() => deleteFromFirebase(img)}
                                className="upload-img-cross"
                              >
                                <MdCancel />
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </>
                )}
              </div>
            </div>

            {/* --------------   Blog Videos----------- */}
            <div className="d-flex mt-4 align-items-center">
              <div className="profile-input-head">
                <h6>Blog Videos</h6>
              </div>
              <div className="profile-input-width">
                <div className="profile-input-field">
                  <input
                    value={companyBlogs.blogVideos[0]}
                    onChange={handleChange}
                    name="blogVideos"
                    type="text"
                    placeholder="Add Link"
                  />
                </div>
              </div>
            </div>

            {/* -------------- Promotion  ---------- */}
            <div className="d-flex mt-4 align-items-center">
              <div className="profile-input-head">
                <h6>Remarks</h6>
              </div>
              <div className="profile-input-width">
                <div className="profile-input-field">
                  <input
                    value={companyBlogs.remarks}
                    onChange={handleChange}
                    name="remarks"
                    type="text"
                    placeholder=""
                  />
                </div>
              </div>
            </div>

            {/*---------    Keywords ------------------- */}
            <div className="d-flex mt-4 align-items-center">
              <div className="profile-input-head">
                <h6>Keywords</h6>
              </div>
              <div className="tags-container">
                {tags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="text">{tag}</span>
                    <span className="close" onClick={() => removeTag(index)}>
                      &times;
                    </span>
                  </div>
                ))}
                <input
                 
                  type="text"
                  className="input-tag"
                  placeholder="Add Keywords"
                />
                <p onClick={handleKeyDown} className="add-btn">
                Add
              </p>
              </div>
            </div>

            <div className="mt-5 d-flex justify-content-end common-submit-button">
              <button>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Submit</>
                )}
              </button>
            </div>

            <div
              onClick={() => navigate(-1)}
              className="close-job cross-sign-pos"
            >
              <RxCross2 />
            </div>
          </div>
        </DashboardBoxStyle>
      </form>

        )
    }
      
    </>
  );
};

export default EditBlog;

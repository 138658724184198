import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AllCommunities from "./Pages/Communities/AllCommunities/AllCommunities.js";
import CreateCommunity from "./Pages/Communities/CreateCommunity/CreateCommunity.js"; 
import CommunityDetails from "./Pages/Communities/CommunityDetails/CommunityDetails.js";
import EditCommunity from "./Pages/Communities/EditCommunity/EditCommunity.js";

const CommunityRoutes = ({ token }) => {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={token ? <AllCommunities /> : <Navigate to="/" />}
        />
        <Route
          path="/create"
          element={token ? <CreateCommunity /> : <Navigate to="/" />}
        />
        <Route
          path="/edit/:id"
          element={token ? <EditCommunity /> : <Navigate to="/" />}
        />
        <Route
          path="/details/:id"
          element={token ? <CommunityDetails /> : <Navigate to="/" />}
        />
       
      </Routes>
    </>
  );
};

export default CommunityRoutes;

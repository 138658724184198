import React, { useState } from 'react'
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { BiPencil, BiTrash } from "react-icons/bi"
// import { DeleteEventTicket, UpdateEventTicket } from '../../../../axios/apis';
import { toast } from 'react-toastify';
const TicketPriceAccordian = (props) => {
  const contentEl = useRef();

  const { handleToggle, active, getAllTicket, data } = props;
  const { title, type, amount, _id, description, externalLink, buttonTitle ,noOfTickets } = data;

  const [deleteloader, setDeleteLoader] = useState(false)
  const [submitloader, setSubmitLoader] = useState(false)

  const [ticketData, setTicketData] = useState({
    title: title || "",
    type: type || "",
    amount: amount || "",
    description: description || "",
    externalLink: externalLink || "",
    buttonTitle: buttonTitle || "",
    noOfTickets:noOfTickets || null,

  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      setTicketData({ ...ticketData, [name]: parseInt(value) })
    } else {
      setTicketData({ ...ticketData, [name]: value })
    }
  }

  const handleSave = async () => {
    setSubmitLoader(true)
    // try {
    //   const res = await UpdateEventTicket({ id: _id, data: ticketData })
    //   toast.success('Ticket Updated successfully')
    //   setSubmitLoader(false)
    //   if (res.data.success) {
    //     handleToggle(_id)
    //     getAllTicket()
    //   }
    // } catch (error) {
    //   toast.error('Something went wrong')
    //   setSubmitLoader(false)
    //   console.log(error)
    // }
  }


  const removeItem = async () => {
    setDeleteLoader(true)
    // try {
    //   const res = await DeleteEventTicket(_id)
    //   if (res.data.success) {
    //     toast.success('Ticket deleted successfully')
    //     getAllTicket()
    //   }
    //   setDeleteLoader(false)
    // } catch (error) {
    //   toast.error('Something went wrong')
    //   setDeleteLoader(false)
    //   console.log(error)
    // }
  }

  return (
    <>
      <div className='edit-accordian-card'>
        <div className="edit-accordian-head">
          <div className="edit-accordian-toggle p-3">
            <h4>{title}</h4>
            <div className='d-flex'>
              <div onClick={() => handleToggle(_id)} className="pencil-edit-btn accordian-edit-btns">
                <BiPencil />
              </div>
              <div onClick={removeItem} className={`trash-edit-btn accordian-edit-btns ${deleteloader && "theme-red"}`}>
                {
                  deleteloader ? <div
                    className="spinner-border text-white spinner-border-sm"
                    role="status"
                  ></div> : <>  <BiTrash /></>
                }
              </div>
            </div>
          </div>
          <div
            ref={contentEl}
            className={`rc-collapse ${active === _id ? "show" : ""}`}
            style={
              active === _id
                ? { height: contentEl.current.scrollHeight }
                : { height: "0px" }
            }
          >
            <div className="rc-accordion-body">
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Ticket Title</label>
                    <div className='profile-input-field'>
                      <input value={ticketData?.title} onChange={handleChange} name="title" type="text" placeholder='Enter the event name' />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Number of Tickets</label>
                    <div className='profile-input-field'>
                      <input value={ticketData?.noOfTickets} onChange={handleChange} name="noOfTickets" type="number" placeholder='Enter number of tickets' />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Ticketing Type</label>
                    <div className='profile-input-field'>
                      <select value={ticketData?.type} onChange={handleChange} name="type" id="">
                        <option value="" selected>Select</option>
                        <option value="Call To Action">Call To Action</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Ticket Amount</label>
                    <div className='profile-input-field'>
                      <input value={ticketData?.amount} onChange={handleChange} name="amount" type="number" placeholder='Enter the event amount' />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Description</label>
                    <div className='profile-input-field'>
                      <textarea value={ticketData?.description} onChange={handleChange} name="description" className='faq-answer-textarea' id="" cols="30" rows="10"></textarea>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>External Link</label>
                    <div className='profile-input-field'>
                      <input value={ticketData?.externalLink} onChange={handleChange} name="externalLink" type="text" placeholder='Enter the event name' />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Button Title</label>
                    <div className='profile-input-field'>
                      <input value={ticketData?.buttonTitle} onChange={handleChange} name="buttonTitle" type="text" placeholder='Enter the event name' />
                    </div>
                  </div>
                </Col>
                <div className='d-flex justify-content-end mt-4' >
                  <div className="form-width-btn">
                    <button onClick={handleSave} className='form-action-next'>
                      {
                        submitloader ? <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div> : <> Save</>
                      }
                    </button>
                  </div>
                </div>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TicketPriceAccordian
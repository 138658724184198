import React, { useEffect, useState } from "react";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RxCross2 } from "react-icons/rx";
import { Col, Row } from "react-bootstrap";
import { BiUpload } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";

import { MdCancel } from "react-icons/md";
import "react-quill/dist/quill.snow.css";

import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";

import { toast } from "react-toastify";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { CreateBlog, createPodcast } from "../../../../util/Auth/APIs";
import SelectAllCategories from "../../Insights/Category/SelectAllCategories.js";

import TopHeader from "../../../../Components/TopHeader/TopHeader";
import Imp from "../../../../Components/Common/Imp/Imp";


const InsightPodCast = () => {
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [tags, setTags] = useState([]);

  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const [magzineDetails, setMagzineDetails] = useState({
    isPublished: true,
    podcastTitle: "",
    category: "",
    category2: "",
    category3: "",
    category4: "",
    podcastDescription:"",
    podcastLink:"",
    keywords:[],
    type:"",

   
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "isPublished") {
        setMagzineDetails({ ...magzineDetails, [name]: e.target.checked });
    } else if (name === "blogVideos" || name === "keywords") {
        setMagzineDetails({ ...magzineDetails, [name]: [e.target.value] });
    } else {
        setMagzineDetails({ ...magzineDetails, [name]: value });
    }
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const handleQuill = (content, delta, source, editor) => {
    setMagzineDetails({ ...magzineDetails, podcastDescription: editor.getHTML() });
    setValue(editor.getHTML());
    
   
  };

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.type === "click") {
    e.preventDefault(); // Prevent form submission
    const value = e.target.parentElement.querySelector('.input-tag').value.trim();
      if (value === '') return;
      ;
     

        const updatedTags = [...tags, value];
        setTags(updatedTags);
        setMagzineDetails({ ...magzineDetails, keywords: updatedTags });
 e.target.parentElement.querySelector('.input-tag').value = '';

    }
  }
  


  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setMagzineDetails({ ...magzineDetails, keywords: [...updatedTags] });
  }

  

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(magzineDetails.podcastDescription.trim(' ').length < 1){
      toast.warning("Please fill the description!");
      return;
    }
    const data = {
     isPublished:magzineDetails?.isPublished,
     title:magzineDetails?.podcastTitle,
     description:value,
     link:magzineDetails?.podcastLink,
     keywords:magzineDetails?.keywords,
     type:magzineDetails?.type,
     category: magzineDetails.category,
     category2: magzineDetails.category2,
     category3: magzineDetails.category3,
     category4: magzineDetails.category4,

     
    };

    console.log(data);

      setSubmitLoader(true)
      try {
          const response = await createPodcast(data)
          toast.success('Podcast Created Successfully');
          setSubmitLoader(false)
          navigate(-1)
          console.log(response)
      } catch (error) {
          setSubmitLoader(false)
          toast.error('Something went wrong')
          console.log(error)
      }
  };

  useEffect(() => {
    console.log(magzineDetails.magzineImages);
  }, [magzineDetails.magzineImages]);
  return (
    <form onSubmit={handleSubmit}>
      <DashboardBoxStyle className="scroller">
       
        <TopHeader headerTitle={"Create Podcast"} type="back"/>

        <div className="form-container position-relative mt-4">

        <div className="d-flex align-items-center">
              <input
                type="checkbox"
                defaultChecked={magzineDetails?.isPublished ? true : false}
                onChange={handleChange}
                className="hr-select"
                name="isPublished"
                id=""
              />

              <span className="ispublished-text">
                Do you want to add this in company view page
              </span>
            </div>
          {/* --------------  Blog Title ----------- */}
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Podcast Title<Imp/></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <input
                  onChange={handleChange}
                  name="podcastTitle"
                  type="text"
                  required
                />
              </div>
            </div>
          </div>
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Podcast Link<Imp/></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <input
                  onChange={handleChange}
                  name="podcastLink"
                  type="text"
                  required
                  placeholder="Add Link"
                  
                />
              </div>
            </div>
          </div>

          <SelectAllCategories
                selected={magzineDetails}
                setSelected={setMagzineDetails}
              />

          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Type<Imp/></h6>
            </div>
            <div className="profile-input-width">
              <div className="profile-input-field">
                <select onChange={handleChange}  required name="type" id="">
                  <option value="" selected disabled>
                    select
                  </option>
                  <option value="Public">Public</option>
                  <option value="Private">Private</option>
                </select>
              </div>
            </div>
          </div>

             {/* -------------- Blog Description ----------- */}
             <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Keywords</h6>
            </div>
            <div className="tags-container">
              {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{tag}</span>
                  <span className="close" onClick={() => removeTag(index)}>
                    &times;
                  </span>
                </div>
              ))}
              <input
               
                type="text"
                className="input-tag"
                placeholder="Add Keywords"
              />
               <p onClick={handleKeyDown} className="add-btn">
                Add
              </p>
            </div>
            
          </div>

          

         

          {/* --------------   Content ----------- */}
          <div className="d-flex mt-4 align-items-center">
            <div className="profile-input-head">
              <h6>Podcast Discription<Imp/></h6>
            </div>

            <div className="profile-input-width">
              <ReactQuill
                style={{ background: "white" }}
                theme="snow"
                modules={Boxmodules}
                required
                value={magzineDetails.podcastDescription}
                onChange={handleQuill}
                placeholder="Description"
              />
              {/* <div className='profile-input-field'>
                                <input onChange={handleChange} name="content" type="text" />
                            </div> */}
            </div>
          </div>

         

        

          
         

          <div className="mt-5 d-flex justify-content-end common-submit-button">
            <button>
              {submitloader ? (
                <div
                  className="spinner-border text-white ml-4"
                  role="status"
                ></div>
              ) : (
                <> Save & Publish</>
              )}
            </button>
          </div>

          <div
            onClick={() => navigate(-1)}
            className="close-job cross-sign-pos"
          >
            <RxCross2 />
          </div>
        </div>
      </DashboardBoxStyle>
    </form>
  );
};

export default InsightPodCast;

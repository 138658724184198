import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import Button from "react-bootstrap/Button";

import { toast, ToastContainer } from "react-toastify";
import deleteImg from "../../assets/delete.png";
import editImg from "../../assets/edit.png";

import "./Table.css";
import { Switch } from "@mui/material";
import { UpdatePayOut } from "../../util/Auth/APIs";

function TableRow({ item, index }) {
    const [loading ,setLoading]=useState(false);
  const [status,setStatus]= useState(item?.status);

  const handleClick = async()=>{
    setLoading(true);
    const data = {
        status:"Completed"
    }
    const id = item._id

   
    

    
    try {
        const res = await UpdatePayOut({id,data});
        setStatus("Completed")
        setLoading(false);
        
    } catch (error) {
        toast.error(error.message);
        setLoading(false);
        
    }
  }

  useEffect(() => {
   
   setStatus(item.status);
     
  }, [item])
  return (
    <>
      <tr key={item.id}>
        <td>{index + 1}</td>
        <td>
          {item?.company_id?.firstName} {item?.company_id?.lastName}
        </td>
        <td>{item?.company_id?.email}</td>
        <td>{item?.amount}</td>
        <td>{item?.commission}</td>
        <td>
          {status === "Pending" ? (
            <Button
              onClick={!loading ? handleClick : null}
              disabled={loading}
              variant="primary"
              size="sm"
            >
              {loading ? "Loading…" : "Update Status"}
            </Button>
          ) : (
            <Button variant="success" size="sm" >Completed</Button>
          )}
        </td>
      </tr>
    </>
  );
}

const PayOutTable = ({ data }) => {
  const [list, setList] = useState(data);

  const navigate = useNavigate();

  useEffect(() => {
    setList(data);
   
  }, [data]);
  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>ID</th>
            <th>Company Name</th>
            <th>Email</th>
            <th>Amount</th>
            <th>Commission</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {list.map((item, index) => (
            <TableRow item={item} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PayOutTable;

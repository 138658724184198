import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CreateTypeModal from "../../../../Components/Modals/SupportModal/CreateTypeModal";
import { toast } from "react-toastify";
import {
  createSupportType,
  getAllSupportTypes,
} from "../../../../util/Auth/APIs";
import SupportCard from "./SupportCard";
import { LoadingPage } from "../../../../Components/Loading/Loading";

const SupportTypes = () => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const getDetails = async () => {
    try {
      const res = await getAllSupportTypes();
      setData(res?.data?.data);
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    
  }, [data])
  

  const handleCardDelete = async () => {
    await getDetails();
  };

  const handleCreate = async () => {
    if (name.trim() === "") {
      toast.warning("Support types cannot be empty");
      return;
    }
    const data = {
      name: name,
    };
    setLoading(true);

    setName("");

    handleClose();

    try {
      const res = await createSupportType(data);
      toast.success("Support Type created successfully!");
      handleCardDelete();
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader
            headerTitle={"Support Type Management"}
            handleClick={handleShow}
            headerBtnName="Create New"
            type={"both"}
          />
          <CreateTypeModal
            show={show}
            handleCreate={handleCreate}
            placeholderVal="Enter Support Type Name"
            handleClose={handleClose}
            value={name}
            setName={setName}
            btnName={"Create"}
          />

          {data.map((item, index) => {
            return (
              <div key={index}>
                <SupportCard
                  data={item}
                  setLoading={setLoading}
                  onCardDelete={handleCardDelete}
                />
              </div>
            );
          })}
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default SupportTypes;

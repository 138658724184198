import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import "./CreateBanner.css";
import { storage } from "../../../firebase";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./CreateBanner.css";
import { createBanner } from "../../../util/Auth/APIs";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import ReactQuill from "react-quill";

const BannerSize = [
  { value: "Long", width: 1280, height: 350, size: "1280x350" },
  { value: "short", width: 720, height: 350, size: "720x350" },
  { value: "sideShort", width:350 , height: 350, size: "350x350" },
  { value: "sideLong", width: 350, height: 800, size: "350x800" },
];

const Boxmodules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const CreateBanner = () => {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverFileName, setCoverFileName] = useState(null);
  const [coverLink, setCoverLink] = useState(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);

  const [bannerDetails, setBannerDetails] = useState({
    type: "",
    monthPrice: null,
    quarterPrice: null,
    halfyearPrice: null,
    yearPrice: null,
    size: "",
    cover: "",
    isCTA: false,
    isHeading: false,
    isDescription: false,
    isImage: false,
    isSocialLinks: false,
    isHyperlinks: false,
    description: "",
  });

  const handleQuill = (content, delta, source, editor) => {
    setBannerDetails({ ...bannerDetails, description: editor.getHTML() });
  };

  const selectedBanner = BannerSize.find(
    (banner) => banner.value === bannerDetails.type
  );

  const bannerSize = selectedBanner?.size;

  useEffect(() => {
    setBannerDetails((prevBannerDetails) => ({
      ...prevBannerDetails,
      size: bannerSize,
    }));
    // console.log(bannerDetails.size);
  }, [bannerSize]);

  useEffect(() => {
    setBannerDetails((prevBannerDetails) => ({
      ...prevBannerDetails,
      size: bannerSize,
    }));
  }, [bannerDetails.size]);

  const handleClick = () => {
    navigate(-1);
  };
  // const handleCoverImage = (e) => {
  //   if (bannerDetails.type === "") {
  //     toast.warning("Please select a banner type before uploading the image");
  //     return;
  //   }

  //   if (
  //     e.target.files[0].type === "image/jpeg" ||
  //     e.target.files[0].type === "image/png" ||
  //     e.target.files[0].type === "image/jpg"
  //   ) {
  //     setImageloader(true);
  //     setCoverFileName(e.target.files[0].name);
  //     setCoverImage(URL.createObjectURL(e.target.files[0]));
  //     console.log(coverImage);

  //     const storageRef = ref(
  //       storage,
  //       `banner/${bannerDetails.type}/${e.target.files[0].name}` // Include the banner type in the storage reference path
  //     );
  //     uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
  //       console.log(snapshot);
  //       console.log("Uploaded a blob or file!");
  //       getDownloadURL(snapshot.ref).then((url) => {
  //         setCoverLink(url);
  //         setBannerDetails({ ...bannerDetails, cover: url });
  //         setImageloader(false);
  //       });
  //     });
  //   } else {
  //     toast.error("Please upload only jpeg, jpg, or png images");
  //     return;
  //   }
  // };

  // // console.log(coverLink);
  // const deleteFromFirebase = (url) => {
  //   setDeleteLoader(true);
  //   let pictureRef = ref(storage, url);

  //   deleteObject(pictureRef)
  //     .then(() => {
  //       setCoverImage(null);
  //       setCoverFileName(null);
  //       setBannerDetails({ ...bannerDetails, cover: "" });

  //       console.log("deleted");

  //       toast.success("Image removed successfully");
  //       setDeleteLoader(false);
  //     })
  //     .catch((error) => {
  //       setDeleteLoader(false);
  //       toast.error("Something went wrong");
  //     });
  // };

  function handleChange(e) {
    const { name, value } = e.target;
    setBannerDetails({ ...bannerDetails, [name]: value });
  }
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setBannerDetails((prevBannerDetails) => ({
      ...prevBannerDetails,
      [name]: checked,
    }));
  };
  const handleToastClose = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...bannerDetails };
    console.log(data);
    setSubmitLoader(true);

    try {
      const res = await createBanner(data);
      toast.success("Banner Created Successfully");
      setSubmitLoader(false);

      // console.log(res);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      // console.log(error);
    }
  };
  


  return (
    <>
      <DashboardBoxStyle className=" scroller">
        <TopHeader headerTitle={"Create Banner"} type={"back"} />
        <form onSubmit={handleSubmit} className="create-community-container">
          <div className="community-input-container">
            <select
              onChange={(e) =>
                setBannerDetails({ ...bannerDetails, type: e.target.value })
              }
              required
              className="select-input"
              name="type"
            >
              <option value="" selected disabled>
                Select Banner Type
              </option>
              <option value="Long">Long Banner</option>
              <option value="short"> Short Banner</option>
              <option value="sideShort">Side Short </option>
              <option value="sideLong">Side Long </option>
            </select>

            <input
              type="text"
              name="size"
              required
              placeholder="Size"
              className="community-input-half"
              style={{ width: "100%" }}
              value={selectedBanner ? selectedBanner.size : ""}
              readOnly
            />
          </div>
          <h4 className="page-general-heading">Pricing</h4>
          <div className="price-container">
            <div className="price">
              <input
                type="number"
                required
                name="monthPrice"
                placeholder="Monthly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>

            <div className="price">
              <input
                type="number"
                name="quarterPrice"
                required
                placeholder="Quarterly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>
            <div className="price">
              <input
                type="number"
                name="halfyearPrice"
                required
                placeholder="Half Yearly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>
            <div className="price">
              <input
                type="number"
                name="yearPrice"
                required
                placeholder="Yearly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>
          </div>
          <h4 className="page-general-heading mt-20 mb-20">Discription</h4>
          <div>
            <ReactQuill
              theme="snow"
              modules={Boxmodules}
              value={bannerDetails.description}
              onChange={handleQuill}
              placeholder="Description"
            />
          </div>
          {/* <div className="banner-features-container">
            <div>
              <label htmlFor="isCTA">Add CTA</label>
              <input
                type="checkbox"
                name="isCTA"
                checked={bannerDetails.isCTA}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isHeading">Add Heading</label>
              <input
                type="checkbox"
                name="isHeading"
                checked={bannerDetails.isHeading}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isDescription">Add Description</label>
              <input
                type="checkbox"
                name="isDescription"
                checked={bannerDetails.isDescription}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isImage">Add Image</label>
              <input
                type="checkbox"
                name="isImage"
                checked={bannerDetails.isImage}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isSocialLinks">Add Social Links</label>
              <input
                type="checkbox"
                name="isSocialLinks"
                checked={bannerDetails.isSocialLinks}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isHyperlinks">Hyper Links</label>
              <input
                type="checkbox"
                name="isHyperlinks"
                checked={bannerDetails.isHyperlinks}
                onChange={handleCheckboxChange}
              />
            </div>
          </div> */}

          <div className="CreateCategoryBtn">
            <button >
              {submitloader ? (
                <div
                  className="spinner-border text-white ml-4"
                  role="status"
                ></div>
              ) : (
                <> Create Now</>
              )}
            </button>
          </div>
        </form>
        <ToastContainer />
      </DashboardBoxStyle>
    </>
  );
};

export default CreateBanner;

import React, { useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import ReactQuill from "react-quill";
import CustomButton from "../../../../Components/Button/CustomButton/CustomButton";
import { ToastContainer, toast } from "react-toastify";
import { postGuidline } from "../../../../util/Auth/APIs";

const Boxmodules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const CreateGuideline = () => {
  const [Guideline, setGuideline] = useState("");
  const [GuidelineName, setGuidelineName] = useState("");
  const [submitLoader, setSubmitLoader] = useState(false);

  const handleQuill = (content, delta, source, editor) => {
    setGuideline(editor.getHTML());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!Guideline.trim()) {
      toast.warning("guideline cannot be empty!");
      return;
    }

    setSubmitLoader(true);

    const data = {
      name: GuidelineName,
      guideline: Guideline,
    };

    try {
      const res = await postGuidline(data);
      toast.success("Guideline created successfully!");
      setSubmitLoader(false);
    } catch (error) {
      toast.error("Something went wrong!");
      setSubmitLoader(false);
    }
  };
  return (
    <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Create Guideline"} type={"back"} />

      <form onSubmit={handleSubmit} className="create-community-container">
        <div>
          <input
            type="text"
            required
            placeholder="Guideline Name"
            className="category-input-full mb-20"
            onChange={(e) => setGuidelineName(e.target.value)}
          />
        </div>

        <div>
          <ReactQuill
            theme="snow"
            modules={Boxmodules}
            value={Guideline}
            onChange={handleQuill}
            placeholder="Write a Guideline..."
          />
        </div>

        <CustomButton loading={submitLoader} classId="full-btn ">Submit</CustomButton>
      </form>
      <ToastContainer />
    </DashboardBoxStyle>
  );
};

export default CreateGuideline;

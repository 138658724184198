import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { AiOutlineLeft } from "react-icons/ai";
import "./AddSubCategory.css";
import { ToastContainer, toast } from "react-toastify";
import { FiEdit3 } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import {
  addSubCategory,
  getSingleCategory,
  removeSubCategory,
  updateCategory,
} from "../../../../util/Auth/APIs";
import {
  LoadingButton,
  LoadingPage,
} from "../../../../Components/Loading/Loading";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import { Accordion } from "react-bootstrap";


const AddSubCategory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(true);

  const [subCategories, setSubCategories] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [submitloader, setSubmitLoader] = useState(false);
  const { id } = useParams();

  const [formDetails, setFormDetails] = useState({
    name: "",
    subcategory: [],
  });

  const [selectedCard, setSelectedCard] = useState(null);

  const handleChange = (event) => {
    setFormDetails({ ...formDetails, name: event.target.value });
  };

  function handleClick() {
    navigate(-1);
  }

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
    if (e.type === "click") {
      e.preventDefault();
      const inputElement = document.querySelector(".input-tag");
      const value = inputElement.value.trim();

      if (value !== "") {
        setSubCategories([...subCategories, value]);
        setFormDetails({
          ...formDetails,
          subcategory: [...subCategories, value],
        });

        inputElement.value = "";
      }
    }
  };

  function removeTag(index) {
    const updatedSubCategories = subCategories.filter((el, i) => i !== index);
    setSubCategories(updatedSubCategories);
    setFormDetails({ ...formDetails, subcategory: updatedSubCategories });
  }

  const submitForm = async (e) => {
    e.preventDefault();
    if (formDetails.subcategory.length === 0) {
      toast.error("Both fields are required");
      return;
    }
    setLoading(true);
    setData([...data, formDetails]);
    const payload = {
      id: id,
      subCategory: { ...formDetails },
    };
    try {
      const res = await addSubCategory(payload);
      toast.success("SubCategory added successfully");
      setLoading(false);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong");
      setLoading(false);
    }

    setFormDetails({
      name: "",
      subcategory: [],
    });
    setSubCategories([]);
  };
  // useEffect(() => {
  //   console.log(data);
  // }, [data]);

  const handleCardClick = (index) => {
    if (selectedCard === index) {
      setSelectedCard(null);
    } else {
      setSelectedCard(index);
    }
  };
  const handleDeleteSubCategory = (index, subCategoryIndex) => {
    const updatedData = [...data];

    updatedData[index].subcategory.splice(subCategoryIndex, 1);
    setData(updatedData);
  };
  const handleDeleteCategory = async (index, subCategoryId) => {
    const updatedData = [...data];

    const payload = {
      id: id,
      subCategoryId: subCategoryId,
    };

    try {
      const res = await removeSubCategory(payload);
      toast.success("Sub-category deleted successfully");
      updatedData.splice(index, 1);
      setData(updatedData);
    } catch (error) {
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  console.log(data);

  useEffect(() => {
    if (data.length == 0) {
      setShow(true);
    }
  }, [data.length]);

  const getCategoryDetails = async () => {
    try {
      const res = await getSingleCategory(id);
      setName(res?.data?.data?.name);
      setData(res?.data?.data?.subCategory);
      setCoverImage(res?.data?.data?.cover);
      // console.log(res?.data?.data?.subCategory);
      setLoading(false);

      //   console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getCategoryDetails();
  }, []);

  async function SaveChanges() {
    setSubmitLoader(true);
    const payload = {
      id: id,
      data: {
        subCategory: [...data],
      },
    };

    console.log(payload);

    try {
      const res = await updateCategory(payload);
      setSubmitLoader(false);
      toast.success("Changes saved successfully");
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
    }
  }

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <div className="category-container">
            <TopHeader headerTitle={name} type="back" />
            <div className="addSubCategoryContainer">
              <form
                onSubmit={submitForm}
                className="create-community-container addsubcategory-container"
              >
                <input
                  type="text"
                  name="name"
                  required
                  placeholder="Enter Category Name"
                  className="category-input-full"
                  value={formDetails.category}
                  onChange={handleChange}
                />
                <div className="tags-input-container ">
                  {subCategories.map((tag, index) => (
                    <div className="tag-item" key={index}>
                      <span className="text">{tag}</span>
                      <span className="close" onClick={() => removeTag(index)}>
                        &times;
                      </span>
                    </div>
                  ))}
                  <input
                    type="text"
                    className="input-tag"
                    placeholder="Enter Sub-categories"
                  />
                  <p className="add-btn" onClick={handleKeyDown}>
                    Add
                  </p>
                </div>

                <div className="addsubcategorybtn">
                  <button>Create Sub-category</button>
                </div>
              </form>
            </div>

            <div>
              {data.map((category,index) => {
                return (
                  <>
                    <React.Fragment key={index}>
                      <Accordion
                        style={{
                          borderRadius: "1px",
                          marginBottom: "20px",
                        }}
                      >
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <h5>{category?.name}</h5>
                          </Accordion.Header>
                          <Accordion.Body>
                            {category?.subcategory.length === 0 ? (
                              <p>No sub-sub category for this sub-category</p>
                            ) : (
                              category?.subcategory.map(
                                (subcategory, index) => (
                                  <div
                                    key={index}
                                    className="sub-category-sub-category"
                                  >
                                    <p>{subcategory}</p>
                                  </div>
                                )
                              )
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </React.Fragment>
                  </>
                );
              })}
            </div>
          </div>

          <ToastContainer />
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default AddSubCategory;

import React, { useEffect, useState } from "react";
import Table from "../Table/Table.js";
// import { data } from "./dumydata.js";

import searchIcon from "../../assets/searchIcon.svg";

import "../../util/Style/property.css";
import { AllCompanyDetails, getAllCategories } from "../../util/Auth/APIs.js";
import { LoadingPage } from "../../Components/Loading/Loading.js";
import { DashboardBoxStyle } from "../SideBar/SidepanelStyled.js";
import TopHeader from "../../Components/TopHeader/TopHeader.js";
import SearchBar from "../../Components/SearchBar/SearchBar.js";

import FilterModal from "../../Components/Modals/FilterModal/FilterModal";
import TablePagination from "@mui/material/TablePagination";
import LoaderDot from "../../Components/Common/LoaderDot.js";

// import Table from "./TrendingMovieTable";

const TrendingMovies = () => {

  const [status, setStatus] = useState(false)

  const [searchInput, setsearchInput] = useState("");
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);

  const [filterData, setFilterData] = useState({
    category: "",
  });

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const FetchCompanyData = async () => {
    setLoading(true)
    const category = filterData.category;
    const page = currentPage + 1;
    try {
      const res = await AllCompanyDetails({
        page,
        limit,
        search,
        category,
        month,
      });

      setData(res?.data?.message?.data);
      setTotalPages(res?.data?.message?.total_pages * limit);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchCompanyData();
  }, [currentPage, search, month, limit]);

  const getCategories = async () => {
    try {
      const res = await getAllCategories();

      {
        res?.data?.data?.map((item) => {
          categories.push(item.name);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const handleFilter = () => {
    FetchCompanyData();
    setShow(false);
  };

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : <>
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Company's Section"} type={"noaction"} />

          <SearchBar
            search={search}
            setSearch={setSearch}
            setMonth={setMonth}
            placeholder="Search for Company"
            handleShow={handleShow}
          />

          <Table data={data} />

          <FilterModal
            categories={categories}
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleClose={handleClose}
            handleFilter={handleFilter}
          />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DashboardBoxStyle>
      </>}
    </>
  );
};

export default TrendingMovies;

import { Route, Navigate, Routes } from "react-router-dom";
import CreateGuideline from "./Pages/Guideline/CreateGuideline/CreateGuideline.js";
import EditGuideline from "./Pages/Guideline/EditGuideline/EditGuideline.js";
import GuidelineList from "./Pages/Guideline/GuidelineList/GuidelineList.js";


function GuidelinesRoutes({ token }) {
  return (
    <Routes>
         <Route
          path="/"
          element={token ? <GuidelineList /> : <Navigate to="/" />}
        />
         <Route
          path="/create"
          element={token ? <CreateGuideline /> : <Navigate to="/" />}
        />
         <Route
          path="/edit/:id"
          element={token ? <EditGuideline /> : <Navigate to="/" />}
        />

    </Routes>
   
  )
}

export default GuidelinesRoutes
import React from 'react'



import { Button } from "@mui/material";
import { useState } from 'react';


const KeyPersonalCard = (props) => {

   
    const { _id, name, image, designation } = props.data

   

  


    return (
        <>
            <div className="event-speakers-card position-relative">

                <img src={image} className='img-size' alt="" />

                <div class="content-overlay"></div>
                <div className="speakers-details fadeIn-bottom" style={{fontFamily:'Roboto'}}>
                    <h5>{name}</h5>
                    <p>{designation}</p>
                </div>


               


            </div>
        </>
    )
}

export default KeyPersonalCard
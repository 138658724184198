import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import SocialShareAccordian from "./SocialShareAccordian.js";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
// import { AllEventSocial, CreateEventSocial } from '../../../../axios/apis';
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import {
  AllEventSocial,
  CreateEventSocial,
} from "../../../../util/Auth/APIs.js";

const SocialShare = ({ type }) => {
  const [socialShow, setSocialShow] = useState(false);
  const [active, setActive] = useState(null);

  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);

  const [socaillist, setSocialList] = useState([]);

  const EventID = useSelector((state) => state.community.eventId);

  const [socialData, setSocialData] = useState({
    event: EventID,
    socialShare: "",
    url: "",
  });

  const handleChange = (e) => {
    setSocialData({ ...socialData, [e.target.name]: e.target.value });
  };

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleClose = () => setSocialShow(false);

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      if (EventID) {
        const res = await CreateEventSocial(socialData);
        if (res.data.success) {
          getAllSocial();
        }
        toast.success("Social Share created successfully");
        setSubmitLoader(false);
        setSocialShow(false);
      } else {
        toast.error("Please create event first");
      }
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const getAllSocial = async () => {
    setLoader(true);
    try {
      if (EventID) {
        const res = await AllEventSocial(EventID);
        setSocialList(res.data.message.socials.reverse());
        console.log(res);
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllSocial();
  }, []);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Social Share</h5>

        {loader ? (
          <LoadingPage />
        ) : (
          <>
            <Row className="mt-4">
              <Col
                lg={12}
                className="d-flex flex-column justify-content-between"
              >
                <Row>
                  {socaillist.length === 0 && (
                    <>
                      <Col lg={12}>
                        <div className="no-list-btn">No Social Share</div>
                      </Col>
                    </>
                  )}
                  {socaillist.length !== 0 &&
                    socaillist.map((data, index) => (
                      <Col lg={12}>
                        <SocialShareAccordian
                          key={index}
                          active={active}
                          getAllSocial={getAllSocial}
                          handleToggle={handleToggle}
                          data={data}
                        />
                      </Col>
                    ))}
                  {type === "edit" && (
                    <Col lg={12}>
                      <div className="d-flex mt-2 justify-content-end">
                        <div
                          onClick={() => setSocialShow(true)}
                          className="add-sign position-relative"
                        >
                          <i class="fa-solid x-cross2 fa-circle-plus"></i>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Modal show={socialShow} onHide={handleClose} centered>
          <div className="event-form-container">
            <div className="">
              <div className="event-form-top">
                <span>Add Social Share</span>
                <i
                  onClick={handleClose}
                  class="fa-solid form-x-cross fa-circle-xmark"
                ></i>
              </div>
            </div>

            <form onSubmit={handleSave}>
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Social Share</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="socialShare"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Link URL</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="url"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <div className="d-flex justify-content-end mt-4">
                  <div className="form-width-btn">
                    <button className="form-action-next">
                      {submitloader ? (
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      ) : (
                        <> Save</>
                      )}
                    </button>
                  </div>
                </div>
              </Row>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default SocialShare;

export const SupportTableHeader = [
    {
        id: "sno",
        label: "ID",
    },
    {
        id: "name",

        label: "Name",
    },
    {
        id: "email",

        label: "Email Id",
    },

    {
        id: "issue",

        label: "Issue",
    },
    {
        id: "phoneNumber",

        label: "Phone Number",
    },
    {
        id: "Status",

        label: "Status",
    },
]
export const SubAdminTableHeader = [
    {
        id: "sno",
        label: "ID",
    },
    {
        id: "name",

        label: "Name",
    },
    {
        id: "email",

        label: "Email Id",
    },
    {
        id: "phoneNumber",

        label: "Phone Number",
    },
    {
        id: "role",

        label: "Role",
    },
    {
        id: "accessType",

        label: "Access Type",

    },

    {
        id: "toggle",

        label: "Action",
    },
    {
        id: "status",

        label: "Status",

    },
    
]
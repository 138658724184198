import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";

import profileIcon from "../../../../assets/profileIcon.svg";
import { AiOutlineLeft } from "react-icons/ai";
import Select from "react-select";

import { storage } from "../../../../firebase";
import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import {
  createCommunity,
  getAllCategories,
  getCommunityById,
  updateCommunityDetails,
} from "../../../../util/Auth/APIs";
import { RxCross2 } from "react-icons/rx";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SelectCategoryAll from "../CreateCommunity/SelectCategoryAll";
import SelectGuidlines from "../CreateCommunity/SelectGuidlines";

const options = [
  { value: "public", label: "Public" },
  { value: "private", label: "Private" },
];

const EditCommunity = () => {
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [coverFileName, setCoverFileName] = useState(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [profileImageLoader, setProfileImageLoader] = useState(false);
  const [ques, setQues] = useState("");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categoryType, setCategoryType] = useState("");
  const [tags, setTags] = useState([]);
  const { id } = useParams();
  const [condition, setCondition] = useState("");

  const [profileImage, setProfileImage] = useState(null);
  const [profileFileName, setProfileFileName] = useState(null);

  const [communityDetails, setCommunityDetails] = useState({
    name: "",
    category: "",
    category2: "",
    category3: "",
    category4: "",
    cover: "",
    profile: "",
    keyword: [],
    limitation: "",
    guidelines: [],
    condition: "",
    description: "",
    forum: [
      {
        question: "",
        date: "",
      },
    ],
  });

  const getCommunityDetails = async () => {
    try {
      const res = await getCommunityById(id);
      setCommunityDetails({
        name: res?.data?.result?.name,
        category: res?.data?.result?.category,
        category2: res?.data?.result?.category2,
        category3: res?.data?.result?.category3,
        category4: res?.data?.result?.category4,
        cover: res?.data?.result?.cover,
        profile: res?.data?.result?.profile,
        keyword: res?.data?.result?.keyword,
        limitation: res?.data?.result?.limitation,
        guidelines: res?.data?.result?.guidelines,
        condition: res?.data?.result?.condition,
        description: res?.data?.result?.description,
        forum: [...res?.data?.result?.forum],
      });

      setTags(res?.data?.result?.keyword);

      if (res?.data?.result?.condition == "public") {
        setCondition({ value: "public", label: "Public" });
      } else if (res?.data?.result?.condition == "private") {
        setCondition({ value: "private", label: "Private" });
      }

      setCategoryType(res?.data?.result?.type);
      setCoverImage(res?.data?.result?.cover);
      setProfileImage(res?.data?.result?.profile);
      setLoading(false);

      //   console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCommunityDetails();
  }, []);

  const [categoryNames, setCategoryNames] = useState([]);

  const getCategoryDetails = async () => {
    setLoading(true);
    try {
      const res = await getAllCategories();

      const names = res?.data?.data?.map((category) => category.name);
      setCategoryNames(names);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong in fetching category details");
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategoryDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCommunityDetails({ ...communityDetails, [name]: value });
  };
  const handleQuestionChange = (index, e) => {
    const updatedForum = [...communityDetails.forum];
    updatedForum[index].question = e.target.value;

    setCommunityDetails((prevDetails) => ({
      ...prevDetails,
      forum: updatedForum,
    }));
  };

  const handleCoverImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      setCoverFileName(e.target.files[0].name);
      setCoverImage(URL.createObjectURL(e.target.files[0]));
      const storageRef = ref(
        storage,
        `CommunityImage/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCommunityDetails({
            ...communityDetails,
            cover: url,
          });
          setImageloader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };
  const handleProfileImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setProfileImageLoader(true);
      setProfileFileName(e.target.files[0].name);
      setProfileImage(URL.createObjectURL(e.target.files[0]));
      const storageRef = ref(
        storage,
        `CommunityImage/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCommunityDetails({
            ...communityDetails,
            profile: url,
          });
          setProfileImageLoader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      id: id,
      data: {
        name: communityDetails.name,
        category: communityDetails.category,
        category2: communityDetails.category2,
        category3: communityDetails.category3,
        category4: communityDetails.category4,
        cover: communityDetails.cover,
        profile: communityDetails.profile,
        keyword: communityDetails.keyword,
        limitation: parseInt(communityDetails.limitation),
        guidelines: communityDetails.guidelines,
        condition: communityDetails.condition,
        description: communityDetails.description,
        forum: [...communityDetails.forum],
      },
    };
    setSubmitLoader(true);
    console.log(payload);
    try {
      const res = await updateCommunityDetails(payload);
      toast.success("Community Updated Successfully");
      setSubmitLoader(false);
      navigate(-1);
      console.log(res);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
    setSubmitLoader(false);
  };

  const deleteFromFirebase = (url, type) => {
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    if (type == "cover") {
      deleteObject(pictureRef)
        .then(() => {
          setCommunityDetails({ ...communityDetails, cover: "" });
          setCoverImage(null);
          setCoverFileName(null);
          console.log(communityDetails);
          toast.success("Image removed successfully");
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          toast.error("Cannot Remove Image");
        });
    } else if (type == "profile") {
      deleteObject(pictureRef)
        .then(() => {
          setCommunityDetails({ ...communityDetails, profile: "" });
          setProfileImage(null);
          setProfileFileName(null);
          console.log(communityDetails);
          toast.success("Image removed successfully");
          setDeleteLoader(false);
        })
        .catch((error) => {
          setDeleteLoader(false);
          toast.error("Cannot Remove Image");
        });
    }
  };
  const handleClick = () => {
    navigate(-1);
  };
  function removeTag(index) {
    const updatedTags = tags.filter((el, i) => i !== index);
    setTags(updatedTags);
    setCommunityDetails({ ...communityDetails, keyword: [...updatedTags] });
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.type === "click") {
      e.preventDefault(); // Prevent form submission
      const value = e.target.parentElement
        .querySelector(".input-tag")
        .value.trim();
      if (value === "") return;
      const updatedTags = [...tags, value];
      setTags(updatedTags);
      setCommunityDetails({ ...communityDetails, keyword: updatedTags });
      e.target.parentElement.querySelector(".input-tag").value = "";
    }
  }

  const handleSelect = (e) => {
    setCommunityDetails({ ...communityDetails, condition: e.value });
    setCondition(e);
    console.log(e);
  };
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Edit Community"} type={"back"} />
          <div className="create-community-container">
            <div className="uploader-community-image">
              <div
                className="communityCover"
                onClick={() => document.querySelector(".input-cover").click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="input-cover"
                  hidden
                  onChange={handleCoverImage}
                />
                {imageloader ? (
                  <>
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    {coverImage ? (
                      <img src={coverImage} alt={coverFileName} />
                    ) : (
                      <p>Add Cover Image</p>
                    )}
                  </>
                )}
              </div>
              <div
                className="coverDeleteIcon"
                onClick={() =>
                  deleteFromFirebase(communityDetails.cover, "cover")
                }
              >
                <RxCross2 />
              </div>

              <div
                className="communityProfile"
                onClick={() => document.querySelector(".input-profile").click()}
              >
                <input
                  type="file"
                  accept="image/*"
                  className="input-profile"
                  hidden
                  onChange={handleProfileImage}
                />

                {profileImageLoader ? (
                  <>
                    <div
                      className="spinner-border text-white ml-4"
                      role="status"
                    ></div>
                  </>
                ) : (
                  <>
                    {profileImage ? (
                      <img src={profileImage} alt={profileFileName} />
                    ) : (
                      <div className="profileIcon">
                        <img src={profileIcon} alt="image" />
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                className="profileDeleteIcon"
                onClick={() =>
                  deleteFromFirebase(communityDetails.profile, "profile")
                }
              >
                <RxCross2 />
              </div>
            </div>

            <div className="community-input-container">
              <input
                type="text"
                name="name"
                placeholder="Community Name"
                className="community-input-half"
                onChange={handleChange}
                value={communityDetails.name}
              />
              <SelectGuidlines
                communityDetails={communityDetails}
                setCommunityDetails={setCommunityDetails}
              />

              <input
                type="number"
                name="limitation"
                placeholder="Members Limitation"
                className="community-input-half"
                onChange={handleChange}
                value={communityDetails.limitation}
              />

              <Select
                options={options}
                value={condition}
                onChange={handleSelect}
                placeholder="select type"
                className="multi-select-bar"
              />
              <div className="select-category-container">
                <SelectCategoryAll
                  selected={communityDetails}
                  setSelected={setCommunityDetails}
                />
              </div>

              <textarea
                name="description"
                placeholder="Short Description"
                cols="100"
                rows="7"
                onChange={handleChange}
                className="communityTextBox"
                value={communityDetails.description}
              ></textarea>

              {communityDetails?.forum.map((item, index) => (
                <textarea
                  key={index}
                  placeholder="Forum Question for Discussion"
                  onChange={(e) => handleQuestionChange(index, e)}
                  cols="100"
                  className="communityTextBox"
                  rows="7"
                  name="question"
                  value={item.question}
                ></textarea>
              ))}
            </div>

            <div className="tags-container w-full">
              {tags.map((tag, index) => (
                <div className="tag-item" key={index}>
                  <span className="text">{tag}</span>
                  <span className="close" onClick={() => removeTag(index)}>
                    &times;
                  </span>
                </div>
              ))}
              <input
                type="text"
                className="input-tag b-20"
                placeholder="Add Keywords"
              />
              <p onClick={handleKeyDown} className="add-btn">
                Add
              </p>
            </div>

            <div className="CreateCommunityBtn">
              <button onClick={handleSubmit}>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Update Now</>
                )}
              </button>
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default EditCommunity;

import { Route, Navigate, Routes } from "react-router-dom";
import EditProduct from "./Pages/ProductPage/EditProduct/EditProduct.js"
import ProductsList from "./Pages/ProductPage/ProductList/ProductList.js"
import AddProduct from "./Pages/ProductPage/AddProduct/AddProduct.js"

function ProductRoutes({ token }) {
  return (

    <>
      <Routes>
        <Route
          path="/"
          element={token ? <ProductsList /> : <Navigate to="/" />}
        />
        <Route
          path="/edit/:id"
          element={token ? <EditProduct /> : <Navigate to="/" />}
        />
        <Route
          path="/add/"
          element={token ? <AddProduct /> : <Navigate to="/" />}
        />


      </Routes>

    </>
  )
}


export default ProductRoutes;
import React from 'react'
import { useSelector } from 'react-redux';
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import MediaCard from '../../../Components/Common/Card/MediaCard.js'


import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import TopHeader from '../../../Components/TopHeader/TopHeader.js';


const CompanyMedia = () => {
    const { mediaLibrary } = useSelector((state) => state.company);
    const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
    console.log(mediaLibrary);
  return (
    <>
    
      <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Media Library "} type={"back"}/>

                        {
                            mediaLibrary.length === 0 ? "Please Add Media" : <>

                                <div className="mt-4">
                                    <Row className="mt-4">
                                        {
                                            mediaLibrary.map((e, index) => (
                                                <Col lg={4}>
                                                    <MediaCard data={e}  key={index} isDot={true} />
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </div>
                            </>
                        }

                    </DashboardBoxStyle>
                   
    </>
  )
}

export default CompanyMedia
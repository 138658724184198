import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { AiOutlineLeft } from "react-icons/ai";
import ProductCard from "../../../Components/Common/Card/ProductCard.js";
import { Col, Row } from "react-bootstrap";
import "./CompanyProducts.css";
import {  useNavigate } from "react-router-dom";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";


const CompanyProducts = () => {
  const { products } = useSelector((state) => state.company);
  
  console.log(products);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  return (
    <>
   


   
      <DashboardBoxStyle className="scroller position-relative">
      <TopHeader headerTitle={"Product Details "} type={"back"}/>

        {products.length === 0 ? (
          "Please Add Product"
        ) : (
          <>
            <div className="mt-4">
              <Row className=" py-3">
                {products?.map((e, i) => (
                  <Col className="mb-4" lg={4}>
                    <ProductCard data={e} key={i} dot={true} />
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </DashboardBoxStyle>
      
    </>
  );
};

export default CompanyProducts;

import React from "react";
import CommunityHead from "./CommunityHead";
import CommUser from "../../assets/comm-user.svg";
import { useSelector } from "react-redux";

const CommunityForum = () => {
  const { communityDetail } = useSelector((state) => state.community);

  console.log(communityDetail?.forum);

  return (
    <>
      <div className="community-detail-container">
        <CommunityHead secHead="Forum" />

        <div className="community-detail-subhead">
          <div className="community-post-card">
            <div className="w-100 m-0 share-post-bar">
              <span>Request a topic</span>
            </div>
            <div className="community-message-box">
              <div className="d-flex mt-3 message-subcontainer">
                {communityDetail?.forum.map((item, index) => {
                    return(
                        <>
                    <div className="small-message-user">
                      <img src={CommUser} alt="" className="img-size" />
                    </div>
                    <div className="forum-message-sec d-flex justify-content-between">
                      <div className="w-75 ">
                        <p>
                         {item.question}
                        </p>
                       
                      </div>
                      <span>{item?.date==""? "a day ago" : item.date.slice(0,10)}</span>
                    </div>
                  </>

                    )
                  
                })}
              </div>
              <div className="comment-bar">
                <span>Comment</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityForum;

import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom';
import { getPlanById, updatePlan } from '../../../../util/Auth/APIs';
import { LoadingPage } from "../../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { RxCross2 } from "react-icons/rx";
import TopHeader from '../../../../Components/TopHeader/TopHeader';


const EditAddOnPlan = () => {
    const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [form, setForm] = useState({
    name: "",
    monthPrice: null,
    yearPrice: null,
    category:"",
    addOn: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue;
  
    if (value === "") {
      parsedValue = ""; // Set to an empty string when the value is empty
    } else if (!isNaN(value)) {
      parsedValue = parseFloat(value);
    } else {
      parsedValue = value;
    }
   
    setForm({ ...form, [name]: parsedValue });
  };

  const handleQuill = (content, delta, source, editor) => {
    setForm({ ...form, benifits: editor.getHTML() });
  };

  const handleSubmit = async () => {
    console.log(form);
    setSubmitLoader(true);

    const params = {
      id: id,
      data: {
        name: form.name,
        monthPrice: form.monthPrice,
        yearPrice: form.yearPrice,
        category:form.category,
        addOn: form.addOn,
      },
    };
    try {
      const res = await updatePlan(params);
      toast.success("Forms Edited successfully", {
        onClose: () => navigate("/plan-management/all"),
      });
      setSubmitLoader(false);
      navigate(-1);
      console.log(res.data.message);
    } catch (error) {
      toast.error("Something went wrong");
      setSubmitLoader(false);
      console.log(error);
    }
    toast.success("Forms Edited successfully", {
      onClose: () => navigate("/plan-management/all"),
    });
  };

  const singlePlan = async () => {
    setLoader(true);
    try {
      console.log(id);
      const res = await getPlanById(id);
      // console.log(res);

      setForm({
        name: res?.data?.plans?.name,
        monthPrice: res?.data?.plans?.monthPrice,
        yearPrice: res?.data?.plans?.yearPrice,
        category:res?.data?.plans?.category,
        addOn: res?.data?.plans?.addOn

      });
      setLoader(false);
      console.log(form);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    singlePlan();
  }, []);
  return (
    <>
    {loader ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller ">
          <TopHeader headerTitle={"Edit Add-on Plan"} type={"back"}/>

          <div className="edit-plan-container form-container">
            <div
              onClick={() => navigate(-1)}
              className="close-job edit-cross-pos"
            >
              <RxCross2 />
            </div>
            <div className="plan-form-contianer  edit-plan-form">

              
               <div className="input-container">
                <input
                  type="text"
                  value={form.name}
                  name="name"
                  onChange={handleChange}
                  
                />
                <label className={form.name && "filled"}>name </label>
              </div>
             
              <div className="input-container">
                <input
                  type="number"
                  value={form.monthPrice}
                  name="monthPrice"
                  onChange={handleChange}
                  
                />
                <label className={form.monthPrice && "filled"}>Monthly Price</label>
              </div>
            
              <div className="input-container">
                <input
                  type="number"
                  value={form.yearPrice}
                  name="yearPrice"
                  onChange={handleChange}
                  
                />
                <label className={form.yearPrice && "filled"}>Yearly Price</label>
              </div>
              <div className="input-container">
                <input
                  type="text"
                  value={form.category}
                  name="category"
                 disabled
                  
                />
                <label className={form.category && "filled"}>Category</label>
              </div>
              <div className="input-container">
                <input
                  type="number"
                  value={form.addOn}
                  name="addOn"
                  onChange={handleChange}
                  
                />
                <label className={form.addOn && "filled"}>Add On Value</label>
              </div>
             
            </div>
            <div
              className="listing-addnew-btn edit-plan-btn "
              onClick={handleSubmit}
            >
              <button>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Edit Plan</>
                )}
              </button>
            </div>
          </div>

          <ToastContainer />
        </DashboardBoxStyle>
      )}
    
    </>
  )
}

export default EditAddOnPlan
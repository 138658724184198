import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { TbListDetails } from "react-icons/tb";

import { toast, ToastContainer } from "react-toastify";

import "./Table.css";
import { deleteCommunity } from "../../util/Auth/APIs";
import deleteImg from "../../assets/delete.png";
import editImg from "../../assets/edit.png";
import detailsImg from "../../assets/list.png";

const ProductTable = ({ data }) => {
  const type = localStorage.getItem("accessType");
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [communityList, setCommunityList] = useState(data);

  const navigate = useNavigate();

  
  useEffect(() => {
    setCommunityList(data);
   
  }, [data])
  

  const removeItem = async (id,e) => {
    e.stopPropagation();
    setDeleteLoader(true);
    setDeletingItemId(id);
    try {
      const res = await deleteCommunity(id);
      setDeleteLoader(false);
      toast.success("Community deleted successfully");

      setCommunityList(communityList.filter((item) => item._id !== id)); // update the blogsList state
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteLoader(false);
      console.log(error);
    }
    console.log(id);
  };

  const handleRowClick = (id) => {
    navigate(`/community/details/${id}`);
  };
  const handleEditClick = (id,e) => {
   navigate(`/community/edit/${id}`)
   e.stopPropagation();

  };

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>S.No</th>
            <th>Created Date</th>
            <th>Community Name</th>
            <th>Category</th>
            <th>Members</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {communityList.map((item, index) => (
            <tr key={item._id} onClick={() => handleRowClick(item._id)}>
              <td>{index + 1}</td>
              <td>{item?.createdAt.slice(0, 10)}</td>
              <td>{item?.name}</td>
              <td>{item?.category}</td>
              <td>{item?.members}</td>
              <td>
                {" "}
                {item?.description.length > 30
                  ? `${item?.description.slice(0, 30)}...`
                  : item?.description}
              </td>

              <td>
                <div className="d-flex ">
                  <div
                    onClick={(e) => handleEditClick(item._id,e)}
                    className="edit"
                  >
                    <img src={editImg} alt="edit" />
                  </div>
                  <div onClick={(e) => removeItem(item?._id,e)} className="delete">
                    {deleteloader && deletingItemId === item?._id ? (
                      <div
                        className="spinner-border text-white spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <img src={deleteImg} alt="delete" />
                    )}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <ToastContainer />
      </table>
    </div>
  );
};

export default ProductTable;

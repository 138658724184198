import { Navigate, Outlet } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";



export const ProtectedRoutes = () => {


    const token = localStorage.getItem("accessToken");
    const navigate = useNavigate();

    if (!token) {

        return <Navigate to="/" />;
    }


    return <Outlet />;
}


export const EditorRoutes = ()=>{
    const token = localStorage.getItem("accessToken");
    const type = localStorage.getItem("accessType");

    if (!token || type!=="editor" ) {

        return <Navigate to="/" />;
    }

    return <Outlet />;



}
export const SuperAdminRoutes = ()=>{
    const token = localStorage.getItem("accessToken");
    const type = localStorage.getItem("accessType");
    const role = localStorage.getItem("role");

    if (!token || type!=="editor" || role!=="admin") {

        return <Navigate to="/" />;
    }

    return <Outlet />;



}

import React, { useEffect, useState } from "react";

import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { HiUserGroup } from "react-icons/hi";
import { FcLike } from "react-icons/fc";
import { RiShareForwardFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./Blogs.css";
import BlogTable from "../../../Pages/Table/BlogTable.js";
import { getAllBlogs } from "../../../util/Auth/APIs";
import { LoadingPage } from "../../../Components/Loading/Loading";

const Blogs = () => {
  const [blogsList, setBlogsList] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const jobTop = [
    {
      jobIcon: <HiUserGroup style={{ color: "rgba(255, 155, 4,1)" }} />,
      jobhead: "Total Blogs",
      value: blogsList.length,
      bgcolor: "rgba(255, 155, 4,.1)",
    },

    {
      jobIcon: <FcLike style={{ color: "rgba(255, 102, 102, 1)" }} />,
      jobhead: "Total Likes",
      value: "0",
      bgcolor: "rgba(255, 102, 102, 0.1)",
    },
    {
      jobIcon: (
        <RiShareForwardFill style={{ color: "rgba(30, 118, 250, 1)" }} />
      ),
      jobhead: "Total Shares",
      value: "0",
      bgcolor: "rgba(30, 118, 250, 0.1)",
    },
  ];

  const getBlogs = async () => {
    try {
      const res = await getAllBlogs();
      setLoading(false);
      setData(res?.data?.message?.blogs);
      setBlogsList(res?.data?.message?.blogs);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBlogs();
  }, []);

  return (
    <>
      {
        loading ? (
          <LoadingPage />
        ) : (
          <DashboardBoxStyle className="scroller">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="admin-page-heading">Blogs</h4>
              <div
                onClick={() => navigate("/blog/add")}
                className="listing-addnew-btn"
              >
                <button>Write</button>
              </div>
            </div>
            <Row className="mt-4">
              {jobTop.map((e, i) => (
                <Col lg={2}>
                  <div className="company-job-box">
                    <div
                      style={{ backgroundColor: e.bgcolor }}
                      className="jobs-icon d-flex justify-content-center align-item-center"
                    >
                      {e.jobIcon}
                    </div>
                    <div className="company-job-head text-align-center mt-3">
                      <h5>{e.value}</h5>
                      <h6>{e.jobhead}</h6>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
            <BlogTable data={data} />
          </DashboardBoxStyle>
        )}
    </>
  );
};

export default Blogs;

import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TicketPrice from "../CreateEvent/TicketPrice";
import { RxCross2 } from "react-icons/rx";
import "../companyevents.css";
import { eventChange, eventClear, eventId } from "../../../../app/features/Community/communitySlice";
import EventBasicInfo from "../CreateEvent/EventBasicInfo";
import AddOrganizer from "../CreateEvent/AddOrganizer";
import EventFAQ from "../CreateEvent/EventFAQ";
import AddSpeaker from "../CreateEvent/AddSpeaker";
import EventAgenda from "../CreateEvent/EventAgenda";
import SocialShare from "../CreateEvent/SocialShare";
import AddSponsor from "../CreateEvent/AddSponsor";
import OnlineEvent from "../CreateEvent/OnlineEvent";
import OfflineEvent from "../CreateEvent/OfflineEvent";
import { SingleEvent } from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";

const EditEvent = ({type,navigateTo}) => {

    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    const sampleEvent = useSelector((state) => state.community.eventDetails)

    const [loader, setLoader] = useState(true)

    const [activeCompany, setActiveCompany] = useState({
        formHead: "Basic Info",
        formComp: <EventBasicInfo />,
    });

    const [eventForm, setEventForm] = useState([
        {
            formHead: "Basic Info",
            tabLink: "basic-info",
        },
        {
            formHead: "Organizer",
            tabLink: "organizer",
        },
        {
            formHead: "FAQ",
            tabLink: "faq",
        },
        {
            formHead: "Speakers",
            tabLink: "speakers",
        },
        {
            formHead: "Event Agenda",
            tabLink: "agenda",
        },
        {
            formHead: "Ticket Price",
            tabLink: "ticket-price",
        },
        {
            formHead: "Social Share",
            tabLink: "social-share",
        },
        {
            formHead: "Sponsors",
            tabLink: "sponsors",
        },

    ]);

    const handleActiveEvent = e => {
        navigate(`?tab=${e.tabLink}`)
    };

    useEffect(() => {
        switch (searchParams.get("tab")) {
            case "basic-info":
                setActiveCompany({
                    formHead: "Basic Info", formComp: <EventBasicInfo type={type} />
                })
                break;
            case "organizer":
                setActiveCompany({
                    formHead: "Organizer", formComp: <AddOrganizer type={type} />
                })
                break;
            case "faq":
                setActiveCompany({
                    formHead: "FAQ", formComp: <EventFAQ type={type} />
                })
                break;
            case "speakers":
                setActiveCompany({
                    formHead: "Speakers", formComp: <AddSpeaker type={type}  />
                })
                break;
            case "agenda":
                setActiveCompany({
                    formHead: "Event Agenda", formComp: <EventAgenda type={type}/>
                })
                break;
            case "ticket-price":
                setActiveCompany({
                    formHead: "Ticket Price", formComp: <TicketPrice type={type}/>
                })
                break;
            case "social-share":
                setActiveCompany({
                    formHead: "Social Share", formComp: <SocialShare type={type}/>
                })
                break;
            case "sponsors":
                setActiveCompany({
                    formHead: "Sponsors", formComp: <AddSponsor type={type} />
                })
                break;
            case "online":
                setActiveCompany({
                    formHead: "Virtual Details", formComp: <OnlineEvent type={type}/>
                })
                break;
            default:
                setActiveCompany({
                    formHead: "Venue", formComp: <OfflineEvent type={type}/>
                })
                break;
        }
    }, [searchParams.get("tab")]);

    useEffect(() => {
        if (sampleEvent?.basicInfo?.mode === "Online") {
            let arr = eventForm
            let newEvent = arr.filter(e => e.formHead !== "Venue")
            newEvent.splice(2, 0, { formHead: "Virtual Details", tabLink: "online" })
            // newEvent.push({ formHead: "Virtual Details", tabLink: "online" })
            setEventForm(newEvent)
        } else if (sampleEvent?.basicInfo?.mode === "Offline") {
            let arr = eventForm
            let newEvent = arr.filter(e => e.formHead !== "Virtual Details")
            newEvent.splice(2, 0, { formHead: "Venue", tabLink: "offline" })
            // newEvent.push({ formHead: "Venue", tabLink: "offline" })
            setEventForm(newEvent)
            // setEventForm([...eventForm, { formHead: "Venue", tabLink: "offline" }])
            // setEventForm(eventForm.filter(e => e.formHead !== "Virtual Details"))
        }
    }, [sampleEvent?.basicInfo?.mode])


    const singleEvent = async () => {
        setLoader(true)
        try {
            const res = await SingleEvent(id)
            dispatch(eventId(id))
            dispatch(eventChange({name:"basicInfo", payload:res?.data?.message?.basicInfo}))
            dispatch(eventChange({name:"organizer", payload:res?.data?.message?.organizer}))
            dispatch(eventChange({name:"virtualDetail",payload: res?.data?.message?.virtualDetail}))
            dispatch(eventChange({name:"offlineDetail", payload:res?.data?.message?.offlineEvent}))
            console.log(res?.data?.message)
            console.log(res)
            setLoader(false)
        } catch (err) {
            setLoader(false)
            console.log(err)
        }
    }

    useEffect(() => {
        singleEvent()
    }, [])

    const handleClose = () => {
        navigate(navigateTo);
        dispatch(eventClear())
    }


    return (
        <>
            {
                loader ? <LoadingPage /> : <>
                    <DashboardBoxStyle className="scroller">
                        <div className="form-container position-relative p-0 mt-4">
                            <div className="d-flex">
                                <div className="company-left-side col-w-25">
                                    <div className="form-top">
                                        <h5>Add Event</h5>
                                    </div>
                                    <hr className="m-0" />
                                    <div className="form-menu-tab">
                                        {eventForm.map(e => (
                                            <div
                                                onClick={() => handleActiveEvent(e)}
                                                className={` ${activeCompany.formHead === e.formHead && "menu-tab-active"} d-flex align-items-center py-2 my-2 menu-tab`}>
                                                <h6>{e.formHead}</h6>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                {activeCompany.formComp}
                                <div onClick={handleClose}
                                    className="close-job cross-sign-pos">
                                    <RxCross2 />
                                </div>
                            </div>
                        </div>
                    </DashboardBoxStyle>
                </>
            }
        </>
    )
}

export default EditEvent
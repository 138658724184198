import React, { useEffect, useState } from "react";
import { getProfuctDetailsById } from "../../../util/Auth/APIs";
import {  useNavigate, useParams } from "react-router-dom";
import { LoadingPage } from "../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import { AiOutlineAlignLeft, AiOutlineLeft } from "react-icons/ai";
import { Table } from "react-bootstrap";
import "./ProductDetails.css";
import TopHeader from "../../../Components/TopHeader/TopHeader";


const ProductDetails = () => {
  const { id } = useParams();
  const [loader, setLoader] = useState(true);
  const [productDet, setProductDet] = useState(null);

  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }

  const getProductDetails = async () => {
    try {
      setLoader(true); // Set loader to true before fetching data
      const response = await getProfuctDetailsById(id);
      if (response?.data?.message) {
        setProductDet(response?.data?.message?.productDetails);
        setLoader(false); // Set loader to false before fetching data
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  useEffect(() => {
    console.log(productDet);
  }, [productDet]);

 

  return (
    <>
    


    
      {loader ? (
        <LoadingPage />
      ) : (
        <>
          <DashboardBoxStyle className="scroller">
          <TopHeader headerTitle={"Product Details "} type={"back"}/>
            <div className="mt-4">
              {/*----------------General Info ------------ */}
              <h5 className="my-4">General Info</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td>Product Category</td>
                    <td className="table-bold-head" colSpan={3}>
                      {productDet?.general?.category || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Product Name</td>
                    <td className="table-bold-head">
                      {productDet?.general?.name || ""}
                    </td>

                    <td>Discription</td>
                    <td
                      className="table-bold-head"
                      dangerouslySetInnerHTML={{
                        __html: productDet?.general?.description,
                      }}
                    ></td>
                  </tr>
                  <tr>
                    <td>Regular Price</td>
                    <td className="table-bold-head">
                      {productDet?.general?.regularPrice || ""}
                    </td>
                    <td>Product Certificate</td>
                    <td className="table-bold-head">
                      {productDet?.general?.salePrice || ""}
                    </td>
                  </tr>
                  <tr>
                    <td>Product Image</td>
                    <td className="table-image-head">
                      <div className="image-container-product">
                        {productDet?.general?.productsImage.map((link) => (
                          <img src={link} />
                        ))}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>

              {/* ---------- Inventry Detail -------------- */}
              <h5 className="my-4">Inventory Detail</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="w-col-30">SKU</td>
                    <td colSpan={3} className="table-bold-head">
                      {productDet?.inventory?.sku || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Stock Status</td>
                    <td colSpan={3} className="table-bold-head">
                      {productDet?.inventory?.status || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* ---------- Linked Products -------------- */}
              <h5 className="my-4">Linked Products Detail</h5>
              <Table className="company-table" responsive="sm" bordered>
                <tbody>
                  <tr>
                    <td className="w-col-30">Cross-sells</td>
                    <td colSpan={3} className="table-bold-head">
                      {productDet?.linkedProduct?.crossSells || ""}
                    </td>
                  </tr>
                  <tr>
                    <td className="w-col-30">Upsells</td>
                    <td colSpan={3} className="table-bold-head">
                      {productDet?.linkedProduct?.upSells || ""}
                    </td>
                  </tr>
                </tbody>
              </Table>
              {/* ---------- Attributes Details -------------- */}
              <h5 className="my-4">Attributes</h5>
              {productDet?.attribute?.map((item) => {
                return (
                  <Table className="company-table" responsive="sm" bordered>
                    <tbody>
                      <tr>
                        <td className="w-col-30">Name</td>
                        <td colSpan={3} className="table-bold-head">
                          {item?.name || ""}
                        </td>
                      </tr>
                      <tr>
                        <td className="w-col-30">Discription</td>

                        <td
                        colSpan={3}
                          className="table-bold-head"
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                         
                        ></td>
                      </tr>
                    </tbody>
                  </Table>
                );
              })}
            </div>
          </DashboardBoxStyle>
        </>
      )}
     
    </>
  );
};

export default ProductDetails;

import { AiFillQuestionCircle } from "react-icons/ai";
import { ImUserPlus } from "react-icons/im";
import { RiBuildingFill, RiMoneyDollarBoxFill } from "react-icons/ri";

export const data =[
    {
        id: 1,
        name: "Company User",
        icon: <RiBuildingFill />,
        value: "companies",
        color:"#A504DE"

    },
    {
        id: 2,
        name: "Individual User",
        icon: <ImUserPlus />,
        value: "users",
        color:"#4EB7BD"

    },
    {
        id: 3,
        name: "Questions ",
        icon: <AiFillQuestionCircle />,
        value: 29,
        color:"#F85724"

    },
    {
        id: 4,
        name: "Plans Active",
        icon: <RiMoneyDollarBoxFill />,
        value:"plans",
        color:"#389AF1"

    },
]


 export const dataSmallCard = [
    {
        id: 1,
        name: "Plans & Pricing",
        value: "planprices",
        color:"#A504DE"

    },
    {
        id: 2,
        name: "Events & Expo",
        value: "eventprices",
        color:"#4EB7BD"

    },
    {
        id: 3,
        name: "Products ",
        value: "productprices",
        color:"#F85724"

    },
]
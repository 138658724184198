import React from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CustomButton from "../Button/CustomButton/CustomButton";
import "./TopHeader.css";

const TopHeader = ({
  headerTitle,
  headerBtnName,
  handleClick,
  type,
  backLink,
  loading,
  path,
}) => {
  const navigate = useNavigate();
  const accessType = localStorage.getItem("accessType");

  return (
    <div className="topHeaderWrapper">
      <div className="topHeaderTitle">
        {(type === "back" || type === "both") && (
          <div
            onClick={() => navigate(path ? path : -1)}
            className="topHeaderBackBtn backIcon"
          >
            <ArrowBackIcon />
          </div>
        )}
        <h5>{headerTitle}</h5>
      </div>

      <div className="topHeaderButtons">
        {type !== "back" ? (
          type === "noaction" ? null : (
            <CustomButton
              loading={loading}
              handleClick={handleClick}
              classId="topHeaderBtn"
            >
              {headerBtnName}
            </CustomButton>
          )
        ) : null}
      </div>
    </div>
  );
};

export default TopHeader;

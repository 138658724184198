import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import TicketPriceAccordian from "./TicketPriceAccordian.js";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
// import { AllEventTicket, CreateEventTicket } from '../../../../axios/apis';
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import {
  AllEventTicket,
  CreateEventTicket,
} from "../../../../util/Auth/APIs.js";

const TicketPrice = ({ type }) => {
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [ticketlist, setTicketlist] = useState([]);

  const [ticketShow, setTicketShow] = useState(false);
  const [active, setActive] = useState(null);

  const EventID = useSelector((state) => state.community.eventId);

  const [ticketData, setTicketData] = useState({
    event: EventID,
    title: "",
    type: "Ticket To Sell",
    amount: "",
    description: "",
    externalLink: "",
    buttonTitle: "",
    noOfTickets: "",
  });

  console.log(ticketData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "amount") {
      setTicketData({ ...ticketData, [name]: parseInt(value) });
    } else {
      setTicketData({ ...ticketData, [name]: value });
    }
  };

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setSubmitLoader(true);
    try {
      if (EventID) {
        const res = await CreateEventTicket(ticketData);
        console.log(res);
        console.log(res.data.success);
        if (res.data.success) {
          getAllTicket();
        }
        toast.success("Ticket created successfully");
        setSubmitLoader(false);
        setTicketShow(false);
      } else {
        toast.error("Please create event first");
      }
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const handleClose = () => setTicketShow(false);

  const getAllTicket = async () => {
    setLoader(true);
    try {
      if (EventID) {
        const res = await AllEventTicket(EventID);
        setTicketlist(res.data.message.ticketPrice.reverse());
      }
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };

  useEffect(() => {
    getAllTicket();
  }, []);

  return (
    <>
      <div className="company-right-side p-4  col-w-75">
        <h5>Ticket Price</h5>

        {loader ? (
          <LoadingPage />
        ) : (
          <>
            <Row className="mt-4">
              <Col
                lg={12}
                className="d-flex flex-column justify-content-between"
              >
                <Row>
                  {ticketlist.length === 0 && (
                    <>
                      <Col lg={12}>
                        <div>No Ticket</div>
                      </Col>
                    </>
                  )}

                  {ticketlist.length !== 0 &&
                    ticketlist.map((data, index) => (
                      <Col lg={12}>
                        <TicketPriceAccordian
                          key={index}
                          active={active}
                          getAllTicket={getAllTicket}
                          handleToggle={handleToggle}
                          data={data}
                        />
                      </Col>
                    ))}
                  {type === "edit" && (
                    <Col lg={12}>
                      <div className="d-flex mt-2 justify-content-end">
                        <div
                          onClick={() => setTicketShow(true)}
                          className="add-sign position-relative"
                        >
                          <i class="fa-solid x-cross2 fa-circle-plus"></i>
                        </div>
                      </div>
                    </Col>
                  )}
                </Row>
              </Col>
            </Row>
          </>
        )}

        <Modal show={ticketShow} onHide={handleClose} centered>
          <div className="event-form-container">
            <div className="">
              <div className="event-form-top">
                <span>Add Ticket</span>
                <i
                  onClick={handleClose}
                  class="fa-solid form-x-cross fa-circle-xmark"
                ></i>
              </div>
            </div>

            <form onSubmit={handleSave}>
              <Row>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Ticket Title</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="title"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Number of Tickets</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="noOfTickets"
                        type="number"
                        placeholder="Enter number of tickets"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Ticketing Type</label>
                    <div className="profile-input-field">
                      <select onChange={handleChange} name="type" id="">
                        <option value="Ticket To Sell" selected>
                          Ticket To Sell
                        </option>
                        <option value="Call To Action">Call To Action</option>
                      </select>
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Ticket Amount</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="amount"
                        type="number"
                        placeholder="Enter the event amount"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Description</label>
                    <div className="profile-input-field">
                      <textarea
                        onChange={handleChange}
                        name="description"
                        className="faq-answer-textarea"
                        id=""
                        cols="30"
                        rows="10"
                      ></textarea>
                    </div>
                  </div>
                </Col>
                {ticketData.type === "Call To Action" && (
                  <Col lg={12}>
                    <div className="half-input-box">
                      <label>External Link</label>
                      <div className="profile-input-field">
                        <input
                          onChange={handleChange}
                          name="externalLink"
                          type="text"
                          placeholder="Enter the event name"
                        />
                      </div>
                    </div>
                  </Col>
                )}

                <Col lg={12}>
                  <div className="half-input-box">
                    <label>Button Title</label>
                    <div className="profile-input-field">
                      <input
                        onChange={handleChange}
                        name="buttonTitle"
                        type="text"
                        placeholder="Enter the event name"
                      />
                    </div>
                  </div>
                </Col>
                <div className="d-flex justify-content-end mt-4">
                  <div className="form-width-btn">
                    <button className="form-action-next">
                      {submitloader ? (
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      ) : (
                        <> Save</>
                      )}
                    </button>
                  </div>
                </div>
              </Row>
            </form>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default TicketPrice;

import React from "react";
import { useSelector } from "react-redux";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import KeyPersonalCard from "../../../Components/Common/Card/KeyPersonalCard.js";
import "./KeyPersonalCard.css";
import {  useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";


const KeyPersonnel = () => {
  const { keyPersonal } = useSelector((state) => state.company);

  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }
  console.log(keyPersonal);
  return (
    <>
   
  
      <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Key Personnel"} type={"back"}/>

        {keyPersonal.length === 0 ? (
          <div className="no-list-btn" style={{ marginTop: "5vh" }}>
            "Please Add Key Personnel"
          </div>
        ) : (
          <>
            <div className="mt-4">
              <Row>
                {keyPersonal.map((e, i) => (
                  <Col lg={3} className="mb-3">
                    <KeyPersonalCard data={e} />
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </DashboardBoxStyle>
     
    </>
  );
};

export default KeyPersonnel;

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AllGuidlines } from "../../../../util/Auth/APIs";
import Select from 'react-select'

const SelectGuidlines = ({ setCommunityDetails, communityDetails }) => {
  const [guidlines, setGuidlines] = useState([]);

 
  const [id, setID] = useState([]);
  const [selectedGuidlines, setSelectedGuidlines] = useState(communityDetails.guidelines);
  console.log(selectedGuidlines);

  const getGuideLines = async () => {
    try {
      const res = await AllGuidlines();
      setGuidlines(res?.data?.data);
      console.log(res?.data?.data);
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getGuideLines();
  }, []);


  const handleChange = (e) => {
    if (e !== null) {
      setSelectedGuidlines(e);

      const data = guidlines.find((item) => e.value === item.name);
      setCommunityDetails({ ...communityDetails, guidelines: data._id });
    } else {
      setSelectedGuidlines(null);
    }
  };


  const guidelineOptions = guidlines.map(item => ({
    value: item?.name,
    label: item?.name,
  }));

  return (
    <>
      <Select
        value={selectedGuidlines}
        onChange={handleChange}
        isClearable={true}
        required
        options={guidelineOptions}
        // isMulti
        name="category"
        placeholder="select guidelines"
        id=""
        className="multi-select-bar"
        

      />
    </>
  );
};

export default SelectGuidlines;

import React, { useState } from 'react'
import "./submenu.css"
import { FiGrid } from 'react-icons/fi'
import { NavLink } from 'react-router-dom'
import SubMenuAccordian from './SubMenuAccordian'

const SubMenu = ({ data, index, hideSidebar }) => {

    const { title, subMenu } = data

    const [active, setActive] = useState(null);

    const handleToggle = (index) => {
        if (active === index) {
            setActive(null);
        } else {
            setActive(index);
        }
    };


    return (
        <>
            <SubMenuAccordian data={data} handleToggle={handleToggle} active={active} />
        </>
    )
}

export default SubMenu
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";

import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "firebase/storage";

import { ToastContainer, toast } from "react-toastify";
import { RxCross2 } from "react-icons/rx";
import "./CreateBanner.css";
import { storage } from "../../../firebase";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import { RiArrowDropDownLine } from "react-icons/ri";
import "./CreateBanner.css";
import { createBanner, getBannerById, updateBanner } from "../../../util/Auth/APIs";
import { LoadingPage } from "../../../Components/Loading/Loading";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import ReactQuill from "react-quill";

const BannerSize = [
  { value: "Long", width: 768, height: 432, size: "768x432" },
  { value: "1st-short", width: 200, height: 100, size: "200x100" },
  { value: "2nd-short", width: 300, height: 150, size: "300x150" },
  { value: "side-long", width: 400, height: 200, size: "400x200" },
  { value: "side-short", width: 150, height: 75, size: "150x75" },
];


const Boxmodules = {
  toolbar: [
    ["bold", "italic", "underline"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const EditBanner = () => {
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [coverImage, setCoverImage] = useState(null);
  const [coverFileName, setCoverFileName] = useState(null);
  const [coverLink, setCoverLink] = useState(null);
  const [submitloader, setSubmitLoader] = useState(false);
  const [imageloader, setImageloader] = useState(false);
  const [deleteloader, setDeleteLoader] = useState(false);
  const [loading, setLoading] = useState(true);

  const {id} = useParams();

  const [bannerDetails, setBannerDetails] = useState({
    type: "",
    monthPrice: null,
    quarterPrice: null,
    halfyearPrice: null,
    yearPrice: null,
    size: "",
    cover: "",
    isCTA: false,
    isHeading: false,
    isDescription: false,
    isImage: false,
    isSocialLinks: false,
    isHyperlinks: false,
    description: "",
  });

  const selectedBanner = BannerSize.find(
    (banner) => banner.value === bannerDetails.type
  );

  const bannerSize = selectedBanner?.size;

  useEffect(() => {
    setBannerDetails((prevBannerDetails) => ({
      ...prevBannerDetails,
      size: bannerSize,
    }));
    // console.log(bannerDetails.size);
  }, [bannerSize]);

  useEffect(() => {
    setBannerDetails((prevBannerDetails) => ({
      ...prevBannerDetails,
      size: bannerSize,
    }));
  }, [bannerDetails.size]);



  const getBannerDetails = async () => {
    try {
      const res = await getBannerById(id);
      setBannerDetails({
        type: res?.data?.data?.type,
        monthPrice: res?.data?.data?.monthPrice,
        quarterPrice: res?.data?.data?.quarterPrice,
        halfyearPrice: res?.data?.data?.halfyearPrice,
        yearPrice:res?.data?.data?.yearPrice,
        size: res?.data?.data?.size,
        cover: res?.data?.data?.cover,
        isCTA: res?.data?.data?.isCTA,
        isHeading: res?.data?.data?.isHeading,
        isDescription: res?.data?.data?.isDescription,
        isImage: res?.data?.data?.isImage,
        isSocialLinks: res?.data?.data?.isSocialLinks,
        isHyperlinks: res?.data?.data?.isHyperlinks,
        description: res?.data?.data?.description,
       
      });
      setTags(res?.data?.data?.keywords);
      setCoverLink(res?.data?.data?.cover);
      setCoverImage(res?.data?.data?.cover);
      setLoading(false);

    
      //   console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getBannerDetails();
  }, []);

  const handleClick = () => {
    navigate(-1);
  };
  const handleCoverImage = (e) => {
    if (bannerDetails.type === "") {
      toast.warning("Please select a banner type before uploading the image");
      return;
    }

    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      setCoverFileName(e.target.files[0].name);
      setCoverImage(URL.createObjectURL(e.target.files[0]));
      console.log(coverImage);

      const storageRef = ref(
        storage,
        `banner/${bannerDetails.type}/${e.target.files[0].name}` // Include the banner type in the storage reference path
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setCoverLink(url);
          setBannerDetails({ ...bannerDetails, cover: url });
          setImageloader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg, or png images");
      return;
    }
  };

  // console.log(coverLink);
  const deleteFromFirebase = (url) => {
    setDeleteLoader(true);
    let pictureRef = ref(storage, url);

    deleteObject(pictureRef)
      .then(() => {
        setCoverImage(null);
        setCoverFileName(null);
        setBannerDetails({ ...bannerDetails, cover: "" });

        console.log("deleted");

        toast.success("Image removed successfully");
        setDeleteLoader(false);
      })
      .catch((error) => {
        setDeleteLoader(false);
        toast.error("Something went wrong");
      });
  };

  function handleChange(e) {
    const { name, value } = e.target;
    setBannerDetails({ ...bannerDetails, [name]: value });
  }

  const handleQuill = (content, delta, source, editor) => {
    setBannerDetails({ ...bannerDetails, description: editor.getHTML() });
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setBannerDetails((prevBannerDetails) => ({
      ...prevBannerDetails,
      [name]: checked,
    }));
  };
  const handleToastClose = () => {
    navigate(-1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { ...bannerDetails };
    console.log(data);
    setSubmitLoader(true);

    try {
      const res = await updateBanner({id,data});
      toast.success("Banner Updated Successfully");
      

      setSubmitLoader(false);
      
      // console.log(res);
    } catch (error) {
      setSubmitLoader(false);
      toast.error("Something went wrong");
      // console.log(error);
    }
  };

  return (
    <>
    {
      loading? <LoadingPage/>:(
        <DashboardBoxStyle className=" scroller">
        <TopHeader headerTitle={"Edit Banner"} type={"back"}/>
        <form onSubmit={handleSubmit} className="create-community-container">
          {/* <div className="uploader-community-image">
            <div
              className="BannerCover"
              onClick={() => document.querySelector(".input-cover").click()}
            >
              <input
                type="file"
                accept="image/*"
                className="input-cover"
                hidden
                onChange={handleCoverImage}
              />
              {imageloader ? (
                <>
                  <div
                    className="spinner-border  ml-4"
                    role="status"
                    style={{color: 'red'}}
                    
                  ></div>
                </>
              ) : (
                <>
                  {coverImage ? (
                    <img src={coverImage} alt={coverFileName} />
                  ) : (
                    <p>Upload Image </p>
                  )}
                </>
              )}
            </div>
            <div
              className="CategoryDeleteIcon"
              onClick={() => deleteFromFirebase(coverLink)}
            >
              <RxCross2 />
            </div>
          </div> */}
          <div className="community-input-container">
            <select
              onChange={(e) =>
                setBannerDetails({ ...bannerDetails, type: e.target.value })
              }
              required
              className="select-input"
              name="type"
              value={bannerDetails?.type}
            >
              <option value="" selected>
                Select Banner Type
              </option>
              <option value="Long">Long Banner</option>
              <option value="1st-short">1st short Banner</option>
              <option value="2nd-short">2nd short Banner</option>
              <option value="side-long">Side Long Banner</option>
              <option value="side-short">Side short Banner</option>
            </select>

            <input
              type="text"
              name="size"
              required
              placeholder="Size"
              className="community-input-half"
              style={{ width: "100%" }}
              value={selectedBanner ? selectedBanner.size : ""}
              readOnly
            />
          </div>
          <h4 className="page-general-heading">Pricing</h4>
          <div className="price-container">
            <div className="price">
              <input
                type="number"
                name="monthPrice"
                required
                value={bannerDetails.monthPrice}
                placeholder="Monthly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>

            <div className="price">
              <input
                type="number"
                name="quarterPrice"
                required
                value={bannerDetails.quarterPrice}
                placeholder="Quarterly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>
            <div className="price">
              <input
                type="number"
                name="halfyearPrice"
                required
                placeholder="Half Yearly"
                value={bannerDetails.halfyearPrice}
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>
            <div className="price">
              <input
                type="number"
                name="yearPrice"
                required
                value={bannerDetails.yearPrice}
                placeholder="Yearly"
                className="community-input-half"
                onChange={handleChange}
              />
              <p>1500</p>
            </div>
          </div>

          <h4 className="page-general-heading mt-20 mb-20">Discription</h4>
          <div>
            <ReactQuill
              theme="snow"
              modules={Boxmodules}
              value={bannerDetails.description}
              onChange={handleQuill}
              placeholder="Description"
            />
          </div>
          {/* <div className="banner-features-container">
            <div>
              <label htmlFor="isCTA">Add CTA</label>
              <input
                type="checkbox"
                name="isCTA"
                checked={bannerDetails.isCTA}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isHeading">Add Heading</label>
              <input
                type="checkbox"
                name="isHeading"
                checked={bannerDetails.isHeading}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isDescription">Add Description</label>
              <input
                type="checkbox"
                name="isDescription"
                checked={bannerDetails.isDescription}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isImage">Add Image</label>
              <input
                type="checkbox"
                name="isImage"
                checked={bannerDetails.isImage}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isSocialLinks">Add Social Links</label>
              <input
                type="checkbox"
                name="isSocialLinks"
                checked={bannerDetails.isSocialLinks}
                onChange={handleCheckboxChange}
              />
            </div>
            <div>
              <label htmlFor="isHyperlinks">Hyper Links</label>
              <input
                type="checkbox"
                name="isHyperlinks"
                checked={bannerDetails.isHyperlinks}
                onChange={handleCheckboxChange}
              />
            </div>
          </div> */}

          <div className="CreateCategoryBtn">
            <button >
              {submitloader ? (
                <div
                  className="spinner-border text-white ml-4"
                  role="status"
                ></div>
              ) : (
                <> Edit Now</>
              )}
            </button>
          </div>
        </form>
        <ToastContainer />
      </DashboardBoxStyle>

      )
    }
      
    </>
  );
};

export default EditBanner;

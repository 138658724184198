import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  booking:[],
  order:[],

};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    default: (state) => {
      return {
        ...state,
      };
    },

    setUser: (state, value) => {
      state.user = value.payload;
    },
    setBooking: (state, value) => {
      state.booking = [...value.payload];
    },
    setOrder: (state, value) => {
      state.order = [...value.payload];
    },
  },
});

export const { setUser,setBooking,setOrder } = userSlice.actions;

export default userSlice.reducer;

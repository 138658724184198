import React from 'react'
import "./Navbar.css"
import { FaBars } from 'react-icons/fa'

const Navbar = ({toggle}) => {
  return (
    <div className="navbar">
      <div className="hamburger-mobile">

       <FaBars onClick={()=>toggle()} />
      </div>
    </div>
  )
}

export default Navbar
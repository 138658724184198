import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { DeleteAdminProduct } from "../../util/Auth/APIs";
import { toast, ToastContainer } from "react-toastify";
import deleteImg from "../../assets/delete.png";
import editImg from "../../assets/edit.png";

import "./Table.css";

const ProductTable = ({ data }) => {
  const [deleteloader, setDeleteLoader] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState("");
  const [productList, setProductList] = useState(data);

  useEffect(() => {
    setProductList(data);
  }, [data]);

  const navigate = useNavigate();

  const removeItem = async (id) => {
    setDeleteLoader(true);
    setDeletingItemId(id);
    try {
      const res = await DeleteAdminProduct(id);
      setDeleteLoader(false);
      toast.success("Product deleted successfully");
      setProductList(data.filter((item) => item._id !== id));
    } catch (error) {
      toast.error("Something went wrong");
      setDeleteLoader(false);
      console.log(error);
    }
    console.log(id);
  };

  useEffect(() => {}, [data.length]);

  function getDescriptionText(item) {
    if (item?.general?.description) {
      return item.general.description.length > 20
        ? item.general.description.slice(0, 20) + "..."
        : item.general.description;
    } else {
      return "";
    }
  }
  const divStyle = {
    padding: "0",
    textAlign: "center",
    marginTop: "10px",
  };

  return (
    <div className="table-container">
      <table className="Table">
        <thead className="table-head">
          <tr>
            <th>ID</th>
            <th>Product Name</th>
            <th>Category</th>
            <th>Sales Price</th>
            <th>Discription</th>
           <th>Action</th> : 
          </tr>
        </thead>
        <tbody>
          {productList.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item?.general?.name}</td>
              <td>{item?.general?.category}</td>
              <td>{item?.general?.salePrice}</td>
              <td style={divStyle}>
                <p
                  dangerouslySetInnerHTML={{ __html: getDescriptionText(item) }}
                ></p>
              </td>

             
                <td>
                  <div className="d-flex ">
                    <div
                      onClick={() =>
                        navigate(`/product/edit/${item?._id}?tab=general`)
                      }
                      className="edit"
                    >
                      <img src={editImg} alt="edit" />
                    </div>

                    <div
                      onClick={() => removeItem(item?._id)}
                      className="delete"
                    >
                      {deleteloader && deletingItemId === item?._id ? (
                        <div
                          className="spinner-border text-white spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <img src={deleteImg} alt="delete" />
                      )}
                    </div>
                  </div>
                </td>
              
            </tr>
          ))}
        </tbody>
        <ToastContainer />
      </table>
    </div>
  );
};

export default ProductTable;

import React from 'react'
import BookedtableRow from './BookedtableRow.js';

const BookedTable = ({ bookedList, setBookedList }) => {


    return (
        <div className="table-wrapper" id="#scrollBar">
            <table className="fl-table">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Ticket Type</th>
                        <th>Quantity</th>
                        <th>Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        bookedList.map((data, index) => {
                            return (
                                <BookedtableRow
                                    key={index}
                                    index={index}
                                    data={data}
                                    list={bookedList}
                                    setBookedList={setBookedList}
                                />
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default BookedTable
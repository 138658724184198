import axios from "axios";
import ct from "countries-and-timezones";

const API = axios.create({
    baseURL: "https://api.countrystatecity.in/v1/"
});

let arr = []

export const getCountry = async () => {
    const res = await axios.get("https://api.countrystatecity.in/v1/countries", {
        headers: {
            'X-CSCAPI-KEY': 'OVZUZER6Y3o3UFFzQzdhcDFKVzNPTlFHSUpUb3pvNXZyWEFqVWh2Vw=='
        }
    });
    arr = res.data;
    return res.data;
};

export const getState = async (country) => {
    const countryData = arr.find((item) => item.name === country)
    const res = await axios.get(`https://api.countrystatecity.in/v1/countries/${countryData.iso2}/states`, {
        headers: {
            'X-CSCAPI-KEY': 'OVZUZER6Y3o3UFFzQzdhcDFKVzNPTlFHSUpUb3pvNXZyWEFqVWh2Vw=='
        }
    });
    return res.data;
};

export const getTimeZone = async (country) => {
    const countryData = arr.find((item) => item.name === country)
    const res = await axios.get(`https://api.countrystatecity.in/v1/countries/${countryData.iso2}`, {
        headers: {
            'X-CSCAPI-KEY': 'OVZUZER6Y3o3UFFzQzdhcDFKVzNPTlFHSUpUb3pvNXZyWEFqVWh2Vw=='
        }
    });
    const countryDetails = {
        timezone: JSON.parse(res?.data?.timezones),
        details: res?.data
    }
    return countryDetails;
}


export const labelSelect = (label) => {
    let arr = []
    label.map((item) => {
        arr.push({ value: item.name, label: item.name })
    })
    return arr
}

export const TimeZoneLabelSelect = (label) => {
    let arr = []
    label.map((item) => {
        arr.push({ value: item.zoneName, label: item.tzName + ' ' + item.gmtOffsetName })
    })
    return arr
}

export const CountryAndTime = () => {
    // const countries = ct.getAllCountries();
    const countries = ct.getAllTimezones();
    return countries
}
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import CustomTable from "../../../../Components/CustomTable/CustomTable.jsx";
import { SupportTableHeader } from "../../../../data/TableHeaderData";
import { GetAllSupport } from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";

const SupportQueries = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const getSupportData = async () => {
   
    try {
      const res = await GetAllSupport();
      console.log(res?.data?.data?.data);
      setData(res?.data?.data?.data);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getSupportData();
  }, []);

  const handleBtnClick = ()=>{
    navigate("/support/types")
  }

  return (
    <>
      {loader ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Support Management"} handleClick={handleBtnClick} headerBtnName="Support Types" />

          <CustomTable
            data={data}
            header={SupportTableHeader}
            type={"supportTable"}
          />
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default SupportQueries;

import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import { LoadingPage } from "../../../Components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import BannerTable from "../../../Pages/Table/BannerTable.js";
import { getAllBanner } from "../../../util/Auth/APIs";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import { Pagination, Stack } from "@mui/material";
import FilterModal from "../../../Components/Modals/FilterModal/FilterModal";

const AllBanner = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [filterData, setFilterData] = useState({
    startDate: "",
    endDate: "",
    category: "",
  });

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const navigate = useNavigate();

  const getBanner = async () => {
    try {
      const res = await getAllBanner();

      setData(res?.data?.data?.data);
      console.log(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getBanner();
  }, []);

  const handleClick = () => {
    navigate("/banner/create");
  };

  const handleDateFilter = () => {

  }
  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className="scroller">
          <TopHeader
            headerTitle={"Banner Section"}
            headerBtnName={"Create Banner"}
            handleClick={handleClick}
          />

          {/* <SearchBar
            search={search}
            setSearch={setSearch}
            placeholder="Search for Banner"
            handleShow={handleShow}
          /> */}

          <BannerTable data={data} />

          {/* <FilterModal
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleFilter={handleDateFilter}
            handleClose={handleClose}
          /> */}
          {/* 
          <div
            style={{ display: "grid", placeItems: "center", marginTop: "40px" }}
          >
            <Stack spacing={2}>
              <Pagination count={10} variant="outlined" shape="rounded" />
            </Stack>
          </div> */}
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default AllBanner;

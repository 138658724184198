import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingPage } from '../../../../Components/Loading/Loading';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import TopHeader from '../../../../Components/TopHeader/TopHeader';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AllCategoryForLevel, AllCategoryLevel2, getSingleCategory, removeCategory } from '../../../../util/Auth/APIs';
import { toast } from 'react-toastify';
import NewTopHeader from '../../../../Components/TopHeader/NewTopHeader';
import AddSubCategory from '../AddSubCategory';
import { DashboardBoxStyle } from '../../../../Components/NewSidebar/SidepanelStyled';
import CategoryLevel2 from './CategoryLevel2';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CategoryCard from './CategoryCard';

const CategoryLevel1 = () => {

    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);

    const [categoryLev1, setCategoryLev1] = useState(false)
    const [catdata, setCatData] = useState([])

    const [singleCategory, setSingleCategory] = useState({})
    const [categoryDetails, setCategoryDetails] = useState([])

    const [categoryShow, setCategoryShow] = useState(false)


    const getCategoryDetails = async () => {
        setLoading(true);
        try {
            const resSingle = await getSingleCategory(id);
            setSingleCategory(resSingle.data.data)
            const res = await AllCategoryForLevel(id, 2);
            // const res = await AllCategoryLevel2(id);
            setCategoryDetails(res.data.data)
            setLoading(false);
            //   console.log(res?.data?.data);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const removeItem = async (id) => {
        setLoading(true);
        try {
            const res = await removeCategory(id);
            toast.success("Community deleted successfully");
            setLoading(false);
            setCategoryDetails(categoryDetails.filter((item) => item._id !== id)); // update the blogsList state
        } catch (error) {
            toast.error("Something went wrong");
            setLoading(false);
            console.log(error);
        }
    };


    const handleClick = () => setCategoryShow(true);

    const handleClose = () => setCategoryShow(false);

    const handleLevel = (id) => {
        // setCategoryLev1(true)
        navigate(`/category/level-3/${id}`)
    }

    useEffect(() => {
        getCategoryDetails();
    }, []);

    return (
        <>
            {
                loading ? <LoadingPage /> :
                    <>
                        <DashboardBoxStyle className=" scroller">
                            <Container fluid>
                                {
                                    !categoryLev1 ? <div>

                                        <NewTopHeader addBtn={true} backBtn={true} headerTitle={singleCategory?.name} headerBtnName="Add Sub-Category" handleClick={handleClick} />

                                        <Row>
                                            {
                                                categoryDetails?.length !== 0 ? categoryDetails?.map((item) => {

                                                    return (
                                                        <Col lg={4} className='mb-4'>
                                                            <CategoryCard data={item} handleLevel={handleLevel} removeItem={removeItem} />
                                                        </Col>
                                                    );
                                                }) : <h5>No Sub Categories</h5>
                                            }
                                        </Row>
                                    </div> : <div>
                                        <CategoryLevel2 prevTitle={categoryDetails?.name} />
                                    </div>
                                }

                            </Container>
                        </DashboardBoxStyle>
                    </>
            }

            <Modal show={categoryShow} onHide={handleClose}>
                <AddSubCategory level="2" id={singleCategory._id} getCategoryDetails={getCategoryDetails} handleClose={handleClose} />
            </Modal>
        </>


    )
}

export default CategoryLevel1
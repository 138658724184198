import React from "react";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled.js";
import { Col, Row } from "react-bootstrap";
import BlogCard from '../../../Components/Common/Card/BlogCard.js'
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../Components/TopHeader/TopHeader.js";





const CompanyBlogs = () => {
  const { blogs } = useSelector((state) => state.company);


  console.log(blogs);
  const navigate = useNavigate();

  function handleClick() {
    navigate(-1);
  }


  return (
    <>
   

   
      <DashboardBoxStyle className=" scroller">
      <TopHeader headerTitle={"Blogs Details "} type={"back"}/>
        <div className="mt-4">
          <Row className="py-3">
            {blogs.length !== 0 &&
              blogs.map((e, i) => (
                <Col className="mb-4" lg={4}>
                  <BlogCard
                    data={e}
                    isDot={true}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </DashboardBoxStyle>
      
    </>
  );
};

export default CompanyBlogs;

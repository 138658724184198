import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../SideBar/SidepanelStyled";
import searchIcon from "../../../assets/searchIcon.svg";
import { getAllTransactions } from "../../../util/Auth/APIs";
import TopHeader from "../../../Components/TopHeader/TopHeader";
import SearchBar from "../../../Components/SearchBar/SearchBar";
import TransactionTable from "../../../Pages/Table/TransactionTable";
import TablePagination from "@mui/material/TablePagination";
import { toast } from "react-hot-toast";


const AllTransactions = () => {
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [data, setData] = useState([]);


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };


 
  const FetchTransactionDetails = async () => {
    const page = currentPage + 1;
    try {
      const { data } = await getAllTransactions({
        page,
        limit,
        search,
        month,
      });
      setData(data?.message?.data);
      setTotalPages(data?.message?.total_pages * limit);

     
      setLoading(false);
    } catch (error) {
      toast.error(error.message);
    }
  };



  useEffect(() => {
    FetchTransactionDetails();
  }, [currentPage, search, month, limit]);

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"Transaction Section"} type={"noaction"} />
          <div className="orderSearchContainer">
            <SearchBar
              search={search}
              setSearch={setSearch}
              setMonth={setMonth}
              placeholder="Search for transaction"
              showFilter={false}
            />
          </div>
          
            <TransactionTable data={data} />
          

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default AllTransactions;

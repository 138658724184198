import { Route, Navigate, Routes } from "react-router-dom";
import PromotionsList from "./Pages/Promotion/PromotionList/PromotionList.js";
import CreatePromotion from "./Pages/Promotion/CreatePromotion/CreatePromotion.js";
import EditPromotion from "./Pages/Promotion/EditPromotion/EditPromotion.js";

function PromotionRoutes({ token }) {
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={token ? <PromotionsList /> : <Navigate to="/" />}
        />

        <Route
          path="/create"
          element={token ? <CreatePromotion /> : <Navigate to="/" />}
        />
        <Route
          path="/update/:id"
          element={token ? <EditPromotion /> : <Navigate to="/" />}
        />
      </Routes>
    </>
  );
}

export default PromotionRoutes;

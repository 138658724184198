import React, { useEffect, useState } from "react";
import { getAllInsights } from "../../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../../Components/Loading/Loading";
import { Col, Container, Row } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";

const OverviewSection = () => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  async function getInsights() {
    const res = await getAllInsights();

    setData(
      res?.data?.message?.map((card) => ({
        ...card,
        showOptions: false,
      }))
    );
    console.log(data);
    setLoader(false);
  }

  useEffect(() => {
    getInsights();
  }, []);

  const getDescriptionText = (description) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(description, "text/html");
    return htmlDoc.body.textContent.slice(0, 70);
  };

  const handleOptionsClick = (id) => {
    const updatedCardsData = data.map((card) => {
      if (card._id === id) {
        return {
          ...card,
          showOptions: !card.showOptions,
        };
      }
      return card;
    });

    setData(updatedCardsData);
  };

  
  return (
    <>
      {loader ? (
        <LoadingPage />
      ) : (
        <div>
          <Container fluid>
            <Row>
              {data.map((item) => {
                if (item.insightType === "Podcast") {
                  return (
                    <Col lg={4} className="mb">
                      <div className="insights-card" key={item._id}>
                        <div className="category-card-menuIcon">
                          <BsThreeDotsVertical
                            onClick={() => handleOptionsClick(item._id)}
                          />
                        </div>
                        {item.showOptions && (
                          <ul className="options-menu">
                            <li
                              onClick={() =>
                                navigate(`/insights/podcast/edit/${item._id}`)
                              }
                            >
                              Edit
                            </li>
                          </ul>
                        )}
                        <img
                          src="https://wallpapercave.com/wp/wp7293419.jpg"
                          alt=""
                        />
                        <div className="insights-card-details">
                          <button>
                            Podcast{" "}
                            <CircleIcon
                              fontSize="small"
                              style={{ color: "#4DCB52" }}
                            />
                          </button>
                          <h5>{item?.title.slice(0, 40)}
                      {item?.title.length > 40 ? "..." : null}</h5>
                          <p>{getDescriptionText(item?.description)}...</p>
                          <button
                            onClick={() =>
                              navigate(`/insights/podcast/${item._id}`)
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </Col>
                  );
                } else if (item.insightType === "Blog") {
                  return (
                    <Col lg={4} className="mb">
                      <div className="insights-card" key={item._id}>
                        <div className="category-card-menuIcon">
                          <BsThreeDotsVertical
                            onClick={() => handleOptionsClick(item._id)}
                          />
                        </div>
                        {item.showOptions && (
                          <ul className="options-menu">
                            <li
                              onClick={() =>
                                navigate(`/insights/blog/edit/${item._id}`)
                              }
                            >
                              Edit
                            </li>
                          </ul>
                        )}
                        <img
                          src={item?.images}
                          alt=""
                        />
                        <div className="insights-card-details">
                          <button>
                            Blogs{" "}
                            <CircleIcon
                              fontSize="small"
                              style={{ color: "#4DCB52" }}
                            />
                          </button>
                          <h5>{item?.title.slice(0, 40)}
                      {item?.title.length > 40 ? "..." : null}</h5>
                          <p> {item?.description.slice(0, 70)}
                      {item?.title.length > 70 ? "..." : null}</p>
                          <button
                            onClick={() =>
                              navigate(`/insights/blog/${item._id}`)
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </Col>
                  );
                } else if (item.insightType === "Whitepaper") {
                  return (
                    <Col lg={4} className="mb">
                      <div className="insights-card" key={item._id}>
                        <div className="category-card-menuIcon">
                          <BsThreeDotsVertical
                            onClick={() => handleOptionsClick(item._id)}
                          />
                        </div>
                        {item.showOptions && (
                          <ul className="options-menu">
                            <li
                              onClick={() =>
                                navigate(
                                  `/insights/whitepaper/edit/${item._id}`
                                )
                              }
                            >
                              Edit
                            </li>
                          </ul>
                        )}
                        <img src={item?.images[0]} alt="" />
                        <div className="insights-card-details">
                          <button>
                            Magzine{" "}
                            <CircleIcon
                              fontSize="small"
                              style={{ color: "#4DCB52" }}
                            />
                          </button>
                          <h5>{item?.title.slice(0, 40)}
                      {item?.title.length > 40 ? "..." : null}</h5>
                          <p>
                            It is a long established fact that a render will be
                            page when looking its layout
                          </p>
                          <button
                            onClick={() =>
                              navigate(`/insights/whitepaper/${item._id}`)
                            }
                          >
                            View Details
                          </button>
                        </div>
                      </div>
                    </Col>
                  );
                }
              })}
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default OverviewSection;

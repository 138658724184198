import { Route, Navigate, Routes } from "react-router-dom";
import AllPlans from "./Pages/PlanManagement/AllPlans/AllPlans.js";
import CreatePlan from "./Pages/PlanManagement/CreatePlan/CreatePlan.js";
import EditPlan from "./Pages/PlanManagement/EditPlan/EditPlan.js";
import EditAddOnPlan from "./Pages/PlanManagement/EditPlan/EditAddOnPlan.js";
import AddOnPlan from "./Pages/PlanManagement/AddOnPlan/AddOnPlan.js";



function PlanManagementRoutes({ token }) {
  return (

    <>
      <Routes>
        <Route
          path="/"
          element={token ? <AllPlans /> : <Navigate to="/" />}
        />
        <Route
          path="/create"
          element={token ? <CreatePlan /> : <Navigate to="/" />}
        />
        <Route
          path="/add-on"
          element={token ? <AddOnPlan /> : <Navigate to="/" />}
        />
        <Route
          path="/edit/foundation/:id"
          element={token ? <EditPlan /> : <Navigate to="/" />}
        />
        <Route
          path="/edit/add-on/:id"
          element={token ? <EditAddOnPlan /> : <Navigate to="/" />}
        />



      </Routes>

    </>
  )
}


export default PlanManagementRoutes;
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";


import { LoadingPage } from "../../../../Components/Loading/Loading";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { Col, Row, ToastContainer } from "react-bootstrap";

import CommunityAbout from "../../../../Pages/CommunityPages/CommunityAbout.js";
import CommunityMembers from "../../../../Pages/CommunityPages/CommunityMembers.js";
import CommunityForum from "../../../../Pages/CommunityPages/CommunityForum.js";
import companyCover from "../../../../assets/companyCover.svg"
import companyProfile from "../../../../assets/companyProfile.svg"
import { BsThreeDotsVertical } from "react-icons/bs";
import "./CommunityDetails.css"
import { getCommunityById } from "../../../../util/Auth/APIs";
import { setCommunityDetail } from "../../../../app/features/Community/communitySlice";
import { AiOutlineLeft } from "react-icons/ai";
import TopHeader from "../../../../Components/TopHeader/TopHeader";

const communityShow = [
  {
    head: "About",
    show: <CommunityAbout />,
  },
  {
    head: "Members",
    show: <CommunityMembers />,
  },
  {
      head: "Forum",
      show: <CommunityForum />
  }
];

const CommunityDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const {id}= useParams();

  const [communityDetails, setCommunityDetails] = useState({
    name: "",
    type: "",
    cover: "",
    profile: "",
    keyword: "",
    limitation: "",
    guidelines: "",
    condition: "",
    description: "",
    forum: [
      {
        question: "",
        date: "",
      },
    ],
  });

  const [activeTab, setActiveTab] = useState({
    head: "About",
    show: <CommunityAbout />,
  });

  const handleCommunity = (name) => {
    const data = communityShow.find((e) => e.head === name);
    setActiveTab(data);
  };

  function handleClick() {
    navigate(-1);
  }

  
  const getCommunityDetails = async () => {
    try {
      const res = await getCommunityById(id);
      setCommunityDetails({
        name: res?.data?.result?.name,
        type: res?.data?.result?.type,
        cover: res?.data?.result?.cover,
        profile: res?.data?.result?.profile,
        keyword: res?.data?.result?.keyword,
        limitation: res?.data?.result?.limitation,
        guidelines: res?.data?.result?.guidelines,
        condition: res?.data?.result?.condition,
        description: res?.data?.result?.description,
        forum: [...res?.data?.result?.forum],
       

      });
      dispatch(setCommunityDetail(res?.data?.result));
     
      setLoading(false);

      
        // console.log(res?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCommunityDetails();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
         

        <TopHeader headerTitle={communityDetails?.name} type={"back"}/>

          <div className="community-inside mt-4">
            <Row>
              <Col lg={12}>
                <div className="community-inside-top">
                  <div className="community-card1">
                    <div className="community-inside-img">
                      <img
                        src={communityDetails?.cover}
                        style={{ objectFit: "cover" }}
                        alt=""
                        className="img-size"
                      />
                    </div>

                    <div className="comContainer px-3 py-1">
                      <div className="comBox d-flex justify-content-between ">
                        <div className="cmBox">
                          <img src={communityDetails?.profile} alt="" />
                        </div>
                        <div className="dotBox">
                          <BsThreeDotsVertical />
                        </div>
                      </div>

                      <h5>
                        {communityDetails?.type} Community <br />
                       {communityDetails?.guidelines}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="community-inside-tab">
                  <div
                    onClick={() => handleCommunity("About")}
                    className={`community-tab ${
                      activeTab.head === "About" && "tab-active"
                    }`}
                  >
                    <span>About</span>
                  </div>
                  <div
                    onClick={() => handleCommunity("Members")}
                    className={`community-tab ${
                      activeTab.head === "Members" && "tab-active"
                    }`}
                  >
                    <span>Members</span>
                  </div>
                  <div
                    onClick={() => handleCommunity("Forum")}
                    className={`community-tab ${
                      activeTab.head === "Forum" && "tab-active"
                    }`}
                  >
                    <span>Forum</span>
                  </div>
                  <div className="community-tab">
                    <span>Insights</span>
                  </div>
                  <div className="community-tab">
                    <span>Jobs</span>
                  </div>
            
                </div>
              </Col>
            </Row>
          </div>
          <Row className="mt-4">
            <Col lg={8}>
              {activeTab.show}
              {/* <Discussion /> */}
            </Col>
            <Col lg={4}></Col>
          </Row>
        </DashboardBoxStyle>
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default CommunityDetails;

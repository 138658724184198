import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import UserTable from "../../../../Pages/Table/UserTable.js";

import "../../../../util/Style/property.css";
import searchIcon from "../../../../assets/searchIcon.svg";
import { getAllUsers } from "../../../../util/Auth/APIs";

import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SearchBar from "../../../../Components/SearchBar/SearchBar";
// import { Pagination } from "antd";
import FilterModal from "../../../../Components/Modals/FilterModal/FilterModal";
import TablePagination from "@mui/material/TablePagination";

const UsersList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState(["company", "user"]);

  const [filterData, setFilterData] = useState({
    category: "",
  });




  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };


  const FetchUserData = async () => {
    setLoading(true);
    const category = filterData.category;
    const page = currentPage + 1;
    try {
      const { data } = await getAllUsers({
        page,
        limit,
        search,
        category,
        month,
      });

      setData(data?.message?.data);
      setTotalPages(data?.message?.total_pages * limit);

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    FetchUserData();
  }, [currentPage, search, month, limit]);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);



  const handleFilter = () => {

    FetchUserData();
    setShow(false);

  }

  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader headerTitle={"User Management "} type={"noaction"} />

          <SearchBar
            search={search}
            setSearch={setSearch}
            setMonth={setMonth}
            placeholder="Search for User"
            handleShow={handleShow}
          />

          <UserTable data={data} />

          <FilterModal
            categories={categories}
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleClose={handleClose}
            handleFilter={handleFilter}
          />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default UsersList;

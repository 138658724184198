import React from 'react'
import { useRef } from 'react';
import { Col, Row } from 'react-bootstrap'
import { BiPencil, BiTrash } from "react-icons/bi"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useState } from 'react';
import Select from 'react-select';
import { useEffect } from 'react';
// import { DeleteEventAgenda, UpdateEventAgenda } from '../../../../axios/apis';
import { toast } from 'react-toastify';


const EventAgendaAccordian = (props) => {

    const contentEl = useRef();

    const { handleToggle, active, data, getAllAgenda, speakersOptions } = props;
    const { title, groupTitle, start, end, date, _id, about, speakers } = data;
    const [submitloader, setSubmitLoader] = useState(false)
    const [deleteloader, setDeleteLoader] = useState(false)


    const [selectedSpeakers, setselectedSpeakers] = useState([]);

    const [agendaData, setAgendaData] = useState({
        title: title || '',
        groupTitle: groupTitle || '',
        date: date || '',
        start: start || '',
        end: end || '',
        about: about || '',
        speakers: speakers || []
    })

    const convermaterialuiDate = (date) => {
        return date.format('YYYY-MM-DD');
    }

    const handleChange = (e) => {
        setAgendaData({ ...agendaData, [e.target.name]: e.target.value })
    }

    const handleInputSpeakers = (value) => {
        console.log(value)
        let arr = []
        value.map((data) => {
            arr = [...arr, { name: data.label, _id: data.value }]
        })
        setAgendaData({ ...agendaData, speakers: arr })
        setselectedSpeakers(value);
    };

    const getSpeakerValue = () => {
        let arrSpeak = [];
        speakers.map((item) => {
            arrSpeak = [...arrSpeak, { value: item._id, label: item.name }]
        })
        setselectedSpeakers(arrSpeak)
    }

    useEffect(() => {
        getSpeakerValue()
    }, [])


    const handleSave = async () => {
        setSubmitLoader(true)
        // try {
        //     const res = await UpdateEventAgenda({ id: _id, data: agendaData })
        //     toast.success('Agenda updated successfully')
        //     setSubmitLoader(false)
        //     if (res.data.success) {
        //         handleToggle(_id)
        //         getAllAgenda()
        //     }
        // } catch (error) {
        //     console.log(error)
        //     toast.error('Something went wrong')
        //     setSubmitLoader(false)
        // }
    }


    const removeItem = async () => {
        setDeleteLoader(true)
        // try {
        //     const res = await DeleteEventAgenda(_id)
        //     if (res.data.success) {
        //         toast.success('Agenda deleted successfully')
        //         getAllAgenda()
        //     }
        //     setDeleteLoader(false)
        // } catch (error) {
        //     toast.error('Something went wrong')
        //     setDeleteLoader(false)
        //     console.log(error)
        // }
    }

    return (
        <>
            <div className='edit-accordian-card'>
                <div className="edit-accordian-head">
                    <div className="edit-accordian-toggle p-3">
                        <h4>{title}</h4>
                        <div className='d-flex'>
                            <div onClick={() => handleToggle(_id)} className="pencil-edit-btn accordian-edit-btns">
                                <BiPencil />
                            </div>
                            <div onClick={removeItem} className={`trash-edit-btn accordian-edit-btns ${deleteloader && "theme-red"}`}>
                                {
                                    deleteloader ? <div
                                        className="spinner-border text-white spinner-border-sm"
                                        role="status"
                                    ></div> : <>  <BiTrash /></>
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        ref={contentEl}
                        className={`rc-collapse ${active === _id ? "show" : ""}`}
                        style={
                            active === _id
                                ? { height: contentEl.current.scrollHeight }
                                : { height: "0px" }
                        }
                    >
                        <div className="rc-accordion-body">
                            <Row>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Title</label>
                                        <div className='profile-input-field'>
                                            <input value={agendaData?.title} onChange={handleChange} name='title' type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Group Title</label>
                                        <div className='profile-input-field'>
                                            <input value={agendaData?.groupTitle} onChange={handleChange} name="groupTitle" type="text" placeholder='Enter the event name' />
                                        </div>
                                    </div>
                                </Col>

                                <Col lg={4}>
                                    <div className="half-input-box">
                                        <label>Date</label>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DesktopDatePicker
                                                inputFormat="MM/DD/YYYY"
                                                placeHolder="select Time"
                                                value={dayjs(agendaData.date)}
                                                onChange={(value) => setAgendaData({ ...agendaData, date: convermaterialuiDate(value) })}
                                                renderInput={(params) => <TextField sx={{
                                                    '.MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'rgba(0, 0, 0, 0.23) !important',
                                                        borderRadius: "10px"
                                                    },
                                                    '.MuiInputBase-input': {
                                                        fontFamily: 'Roboto',
                                                        fontStyle: "normal",
                                                        fontWeight: "400",
                                                        fontSize: "15px",
                                                    }
                                                }} {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="half-input-box">
                                        <label>Start Time</label>
                                        <div className='profile-input-field'>
                                            <input type="time" value={agendaData.start} onChange={(e) => setAgendaData({ ...agendaData, start: e.target.value })} />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4}>
                                    <div className="half-input-box">
                                        <label>End Time</label>
                                        <div className='profile-input-field'>
                                            <input type="time" value={agendaData.end} onChange={(e) => setAgendaData({ ...agendaData, end: e.target.value })} />
                                        </div>
                                    </div>
                                </Col>


                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>Speaker</label>
                                        <Select
                                            value={selectedSpeakers}
                                            options={speakersOptions}
                                            isMulti
                                            className="speakers-multiselect"
                                            // id={error.amenities ? 'red-border' : ''}
                                            onChange={(e) => handleInputSpeakers(e)}
                                        />
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="half-input-box">
                                        <label>About Agenda</label>
                                        <div className='profile-input-field'>
                                            <textarea value={agendaData?.about} onChange={handleChange} className='faq-answer-textarea' name="about" id="" cols="30" rows="10"></textarea>
                                        </div>
                                    </div>
                                </Col>

                                <div className='d-flex justify-content-end mt-4' >
                                    <div className="form-width-btn">
                                        <button onClick={handleSave} className='form-action-next'>
                                            {
                                                submitloader ? <div
                                                    className="spinner-border text-white ml-4"
                                                    role="status"
                                                ></div> : <> Save</>
                                            }
                                        </button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EventAgendaAccordian
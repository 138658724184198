import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import { Col, Row } from "react-bootstrap";
import "./InsightsOverview.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import OverviewSection from "./Section/OverviewSection.js";
import MagzineSection from "./Section/MagzineSection.js";
import PodcastSection from "./Section/PodcastSection.js";
import BlogSection from "./Section/BlogSection.js";

const data = [
  {
    id: "overview",
    name: "Overview",
    tabLink: "overview",
  },
  {
    id: "blogs",
    name: "Blogs",
    tabLink: "blogs",
  },
  {
    id: "whitepaper",
    name: "White Paper",
    tabLink: "whitepaper",
  },
  {
    id: "podcast",
    name: "Podcast",
    tabLink: "podcast",
  },
];

const InsightsOverview = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [activeInsight, setActiveInsight] = useState({
    formHead: "Overview",
    formComp: <OverviewSection />,
    target: "overview",
  });

  const handleActiveInsight = (e) => {
    navigate(`?tab=${e.tabLink}`);
  };

  useEffect(() => {
    switch (searchParams.get("tab")) {
      case "overview":
        setActiveInsight({
          formHead: "Overview",
          formComp: <OverviewSection />,
          target: "overview",
        });
        break;
      case "blogs":
        setActiveInsight({
          formHead: "Blogs",
          formComp: <BlogSection />,
          target: "blogs",
        });
        break;
      case "whitepaper":
        setActiveInsight({
          formHead: "White Paper",
          formComp: <MagzineSection />,
          target: "whitepaper",
        });
        break;
      case "podcast":
        setActiveInsight({
          formHead: "Podcast",
          formComp: <PodcastSection />,
          target: "podcast",
        });
        break;
      default:
        setActiveInsight({
          formHead: "Overview",
          formComp: <OverviewSection />,
          target: "overview",
        });
    }
  }, [searchParams.get("tab")]);

  return (
    <DashboardBoxStyle className="scroller">
      <Row>
        {data.map((item) => (
          <Col lg={3} key={item.id} onClick={() => handleActiveInsight(item)}>
            <div
              className={`insights-tabs ${
                item.name === activeInsight.formHead ? "active-insight-tab" : ""
              }`}
            >
              {item.name}
            </div>
          </Col>
        ))}
      </Row>
      <div className="ingights-container">
        <div className="insights-container-header">
          <h5>{activeInsight.formHead}</h5>
          {activeInsight.formHead !== "Overview" && (
            <button
              onClick={() =>
                navigate(`/insights/create/insight-${activeInsight.target}`)
              }
            >
              Create {activeInsight.formHead}
            </button>
          )}
        </div>
      </div>
      {activeInsight.formComp}
    </DashboardBoxStyle>
  );
};

export default InsightsOverview;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import {
  ref,
  getDownloadURL,
  deleteObject,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { storage } from "../../../../../../firebase.js";
import Imp from "../../../../../../Components/Common/Imp/Imp.jsx"

import "./EditProduct.css";

import { toast } from "react-toastify";

import { MdCancel } from "react-icons/md";
import { BiUpload } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeneral,
  setIsVirtual,
} from "../../../../../../app/features/adminProducts/productSlice";
import { setRef } from "@mui/material";

const ProductGeneral = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const Virtual = useSelector((state) => state.product.isVirtual);

  const [prodType, setProdType] = useState(Virtual);

  const sample = useSelector((state) => state.product.general);
  // console.log(general);

  // console.log(general);

  const [imageloader, setImageloader] = useState(false);

 
  
  const [productGeneral, setProductGeneral] = useState({
    category: sample?.category || '',
    name: sample?.name || '',
    description: sample?.description || '',
    regularPrice: sample?.regularPrice || '',
    salePrice: sample?.salePrice || '',
    productsImage: sample?.productsImage || '',
    taxStatus: '',
    taxClass: '',


   });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductGeneral({ ...productGeneral, [name]: value });
  };

  const handleTypeChange = (event) => {
   
    const isVirtualTrue = event.target.value === 'Virtual';
    setProdType(isVirtualTrue);
    dispatch(setIsVirtual(isVirtualTrue));
     console.log(prodType);
     
  };

  const handleImage = (e) => {
    if (
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "image/jpg"
    ) {
      setImageloader(true);
      const storageRef = ref(
        storage,
        `ProductPicture/${e.target.files[0].name}`
      );
      uploadBytes(storageRef, e.target.files[0]).then((snapshot) => {
        console.log(snapshot);
        console.log("Uploaded a blob or file!");
        getDownloadURL(snapshot.ref).then((url) => {
          setProductGeneral({
            ...productGeneral,
            productsImage: [...productGeneral.productsImage, url],
          });
          setImageloader(false);
        });
      });
    } else {
      toast.error("Please upload only jpeg, jpg or png image");
      return;
    }
  };

  const deleteFromFirebase = (url) => {
    let pictureRef = ref(storage, url);
    deleteObject(pictureRef)
      .then(() => {
        setProductGeneral({
          ...productGeneral,
          productsImage: productGeneral.productsImage.filter(
            (item) => item !== url
          ),
        });
        console.log("success");
      })
      .catch((error) => {
        toast.error("Something went wrong");
        // Uh-oh, an error occurred!
      });
  };

  const Boxmodules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],['link', 'image'],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const handleQuill = (content, delta, source, editor) => {
    setProductGeneral({ ...productGeneral, description: editor.getHTML() });
  };

  const handleNext = (e) => {
    e.preventDefault();

    if(productGeneral.productsImage.length<1){
      toast.warning("Please fill all the required fields");
      return;
    }

    window.scrollTo({ top: 0, left: 0 });
    dispatch(setGeneral(productGeneral));
    navigate("?tab=inventory");
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(setGeneral(productGeneral));
    }, 300);
  }, [productGeneral]);

  return (
    <>
      <form onSubmit={handleNext}>
        <Row className="mt-2">
          <Col lg={12} className="d-flex flex-column justify-content-between">
            <Row>
              <Col lg={9}>
                <div className="d-flex justify-content-between half-input-box">
                  <div className="d-flex align-item-center">
                    <span className="m-0">Physical Product :</span>
                    <input
                      className="ms-3 product-checkbox"
                      name="type"
                      value="Physical"
                      checked={!prodType}
                      onChange={handleTypeChange}
                      type="radio"
                    />
                  </div>
                  <div className="d-flex align-item-center">
                    <span className="m-0">Virtual Product :</span>
                    <input
                      className="ms-3 product-checkbox"
                      name="type"
                      value="Virtual"
                      checked={prodType}
                      onChange={handleTypeChange}
                      type="radio"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Product Category<Imp/></label>
                  <div className="profile-input-field">
                    <select
                      value={productGeneral?.category}
                      onChange={handleChange}
                      required
                      name="category"
                      id=""
                    >
                      <option value="" selected>
                        select category
                      </option>
                      <option value="Electrical">Electrical</option>
                      <option value="HomeBase">HomeBase</option>
                    </select>
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                <div className="half-input-box">
                  <label>Product Name<Imp/></label>
                  <div className="profile-input-field">
                    <input
                      value={productGeneral?.name}
                      onChange={handleChange}
                      name="name"
                      required
                      type="text"
                    />
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="half-input-box">
                  <label>Product Description</label>
                  <ReactQuill
                    theme="snow"
                    modules={Boxmodules}
                    value={productGeneral.description}
                    onChange={handleQuill}
                    placeholder="Description"
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div className="half-input-box">
                  <label>Regular Price ($)<Imp/></label>
                  <div className="profile-input-field">
                    <input
                      value={productGeneral?.regularPrice}
                      onChange={handleChange}
                      name="regularPrice"
                      required
                      type="number"
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="half-input-box">
                  <label>Sale Price ($)<Imp/></label>
                  <div className="profile-input-field">
                    <input
                      value={productGeneral?.salePrice}
                      onChange={handleChange}
                      required
                      name="salePrice"
                      type="number"
                    />
                  </div>
                </div>
              </Col>

              <Col lg={12}>
                <div className="half-input-box">
                  <label>Product Image<Imp/></label>
                  <div className="upload-button">
                    {imageloader ? (
                      <>
                        <div
                          className="spinner-border text-white ml-4"
                          role="status"
                        ></div>
                      </>
                    ) : (
                      <>
                        <span className="d-flex m-0 text-white">
                          <BiUpload /> Upload Image
                        </span>
                        <input
                          onChange={handleImage}
                          accept="image/png, image/gif, image/jpeg"
                          name="blogImages"
                          type="file"
                        />
                      </>
                    )}
                  </div>
                  {productGeneral?.productsImage.length !== 0 && (
                    <>
                      <div className="mt-3 profile-input-field">
                        <Row>
                          {productGeneral?.productsImage.map((img, index) => (
                            <Col lg={3}>
                              <div className="upload-img-card">
                                <img src={img} className="img-size" alt="" />
                                <div
                                  onClick={() => deleteFromFirebase(img)}
                                  className="upload-img-cross"
                                >
                                  <MdCancel />
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </>
                  )}
                </div>
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-5">
              {/* <div className="form-width-btn">
                <button className='save-draft'>
                  {
                    submitloader ? <div
                      className="spinner-border theme-colour ml-4"
                      role="status"
                    ></div> : <> Save</>
                  }
                </button>
              </div> */}
              <div className="form-width-btn">
                <button className="form-action-next">Next</button>
              </div>
            </div>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default ProductGeneral;

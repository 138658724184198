import React from "react";
import "./UserProfile.css";
import { FiMail } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import { useSelector } from "react-redux";
import userImage from "../../../../assets/user.png";


const UserProfile = () => {
  const navigate = useNavigate();

  const {user} = useSelector((state) => state.user);
  console.log(user);

  function handleClick() {
    navigate(-1);
  }

  return (
    <>


      <DashboardBoxStyle className=" scroller">
        <TopHeader headerTitle={"User's Profile "} type={"back"} />
        <div className="profile-container">
          <div className="profile-col">
            <div className="profile-left">Name</div>

            <div className="profile-right">
              <div className="userInfoBox">{user?.firstName}</div>
              <div className="userInfoBox">{user?.lastName}</div>
            </div>
          </div>
          <div className="profile-col">
            <div className="profile-left">Email Address</div>
            <div className="profile-right">
              {/* <FiMail className=''/> */}

              <div className="userInfoBox">✉️ {user?.email}</div>
            </div>
          </div>
          <div className="profile-col">
            <div className="profile-left">Your Photo</div>
            <div className="user-profile-img">
              <img
                src={userImage}
                alt=""
              />
            </div>
          </div>
          <div className="profile-col">
            <div className="profile-left">Country</div>
            <div className="profile-right">
              <div className="userInfoBoxes">{user?.country?.countryName}</div>
              <div className="userInfoBoxes">{user?.country?.state}</div>
              <div className="userInfoBoxes">{user?.country?.address}</div>
              <div className="userInfoBoxes">{user?.country?.postCode}</div>
            </div>
          </div>
          <div className="profile-col">
            <div className="profile-left">Bio</div>
            <div className="profile-right">
              <div className="profile-text-area">
                <p>{user.bio}</p>
              </div>
            </div>
          </div>
        </div>
      </DashboardBoxStyle>

    </>
  );
};

export default UserProfile;

import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import ReactQuill from "react-quill";
import { AiFillPlusCircle } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

import { getPlanById, updatePlan } from "../../../../util/Auth/APIs";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import { RxCross2 } from "react-icons/rx";
import "./EditPlan.css";
import { TextField, inputLabelClasses } from "@mui/material";
import TopHeader from "../../../../Components/TopHeader/TopHeader";

const EditPlan = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [submitloader, setSubmitLoader] = useState(false);
  const [form, setForm] = useState({
    name: "",
    monthPrice: null,
    yearPrice: null,
    isActive: null,
    products: null,
    deals: null,
    communities: null,
    events: null,
    jobs: null,
    coverages: null,
    gallery: null,
    forms: null,
    blogs: null,
    eventCommission: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let parsedValue;

    if (value === "") {
      parsedValue = ""; // Set to an empty string when the value is empty
    } else if (!isNaN(value)) {
      parsedValue = parseFloat(value);
    } else {
      parsedValue = value;
    }

    setForm({ ...form, [name]: parsedValue });
  };

  const handleQuill = (content, delta, source, editor) => {
    setForm({ ...form, benifits: editor.getHTML() });
  };

  const handleSubmit = async () => {
    console.log(form);
    setSubmitLoader(true);

    const params = {
      id: id,
      data: {
        name: form.name,
        monthPrice: form.monthPrice,
        yearPrice: form.yearPrice,
        isActive: form.isActive,
        products: form.products,
        deals: form.deals,
        communities: form.communities,
        events: form.events,
        jobs: form.jobs,
        coverages: form.coverages,
        gallery: form.gallery,
        forms: form.forms,
        blogs: form.blogs,
        eventCommission: form.eventCommission
      },
    };
    try {
      const res = await updatePlan(params);
      toast.success("Plan Edited successfully", {
        onClose: () => navigate("/plan-management"),
      });
      setSubmitLoader(false);
      navigate(-1);
      console.log(res.data.message);
    } catch (error) {
      toast.error("Something went wrong");
      setSubmitLoader(false);
      console.log(error);
    }
   
  };

  const singlePlan = async () => {
    setLoader(true);
    try {
      console.log(id);
      const res = await getPlanById(id);
      // console.log(res);

      setForm({
        name: res?.data?.plans?.name,
        monthPrice: res?.data?.plans?.monthPrice,
        yearPrice: res?.data?.plans?.yearPrice,
        isActive: res?.data.plans?.isActive,
        products: res?.data?.plans?.products,
        deals: res?.data?.plans?.deals,
        communities: res?.data?.plans?.communities,
        events: res?.data?.plans?.events,
        jobs: res?.data?.plans?.jobs,
        coverages: res?.data?.plans?.coverages,
        gallery: res?.data?.plans?.gallery,
        forms: res?.data?.plans?.forms,
        blogs: res?.data?.plans?.blogs,
        eventCommission:res?.data?.plans?.eventCommission,
      });
      setLoader(false);
      console.log(form);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    singlePlan();
  }, []);

  const borderStyle = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#acb5bd",
      },
      "&:hover fieldset": {
        borderColor: "#acb5bd",
      },
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
      "& fieldset": {
        borderColor: "#4eb7bd",
      },
    },
  };

  const labelInput = {
    sx: {
      color: "#acb5bd",
      [`&.${inputLabelClasses.focused}`]: {
        color: "#4eb7bd",
      },
    },
  };

  return (
    <>
      {loader ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller ">
          <TopHeader headerTitle={"Edit Foundation Plan"} type={"back"} />

          <div className="edit-plan-container form-container">
            <div
              onClick={() => navigate(-1)}
              className="close-job edit-cross-pos"
            >
              <RxCross2 />
            </div>
            <div className="plan-form-contianer  edit-plan-form">
              <TextField
                label="Plan Name"
                type="text"
                value={form.name}
                name="name"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Monthly Price"
                type="number"
                value={form.monthPrice}
                name="monthPrice"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Yearly Price"
                type="number"
                value={form.yearPrice}
                name="yearPrice"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

              <TextField
                label="Products"
                type="number"
                value={form.products}
                name="products"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Deals"
                type="number"
                value={form.deals}
                name="deals"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Communities"
                type="number"
                value={form.communities}
                name="communities"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Events"
                type="number"
                value={form.events}
                name="events"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Jobs"
                type="number"
                value={form.jobs}
                name="jobs"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Coverages"
                type="number"
                value={form.coverages}
                name="coverages"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Gallery"
                type="number"
                value={form.gallery}
                name="gallery"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Forms"
                type="number"
                value={form.forms}
                name="forms"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Blogs"
                type="number"
                value={form.blogs}
                name="blogs"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />
              <TextField
                label="Event Commission"
                type="number"
                value={form.eventCommission}
                name="eventCommission"
                onChange={handleChange}
                variant="outlined"
                InputLabelProps={labelInput}
                sx={borderStyle}
                className="text-field"
              />

            </div>
            <div
              className="listing-addnew-btn edit-plan-btn "
              onClick={handleSubmit}
            >
              <button>
                {submitloader ? (
                  <div
                    className="spinner-border text-white ml-4"
                    role="status"
                  ></div>
                ) : (
                  <> Edit Plan</>
                )}
              </button>
            </div>
          </div>

          <ToastContainer />
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default EditPlan;

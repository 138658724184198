import React, { useEffect, useState } from "react";
import { LoadingPage } from "../../../../Components/Loading/Loading";
import ProductTable from "../../../../Pages/Table/ProductTable.js";

import "../../../../util/Style/property.css";
import searchIcon from "../../../../assets/searchIcon.svg";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllAdminProducts, getAllCategories } from "../../../../util/Auth/APIs";
import { DashboardBoxStyle } from "../../../../SideBar/SidepanelStyled";
import TopHeader from "../../../../Components/TopHeader/TopHeader";
import SearchBar from "../../../../Components/SearchBar/SearchBar";
import TablePagination from "@mui/material/TablePagination";
import FilterModal from "../../../../Components/Modals/FilterModal/FilterModal"

const ProductList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const [currentPage, setCurrentPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(null);
  const [month, setMonth] = useState("");
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);

  const [filterData, setFilterData] = useState({
    category: "",
  });

  const handleClick = () => {
    navigate(`/product/add`);
  };

  const getProducts = async () => {
    const category = filterData.category;
    const page = currentPage + 1;

    try {
      const res = await getAllAdminProducts({
        page,
        limit,
        search,
        category,
        month,
      });


      setData(res?.data?.message?.data);
      console.log(res?.data);
      setTotalPages(res?.data?.message?.total_page * limit);

      setLoading(false);

    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [currentPage, search, month, limit]);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);


  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };


  const getCategories = async () => {
    try {
      const res = await getAllCategories();

      {
        res?.data?.data?.map((item) => {
          categories.push(item.name);
        });
      }
      console.log(categories);

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const handleFilter = () => {

    getProducts();
    setShow(false);

  }

  useEffect(() => {

  }, [data])


  return (
    <>
      {loading ? (
        <LoadingPage />
      ) : (
        <DashboardBoxStyle className=" scroller">
          <TopHeader
            headerBtnName={"Create "}
            headerTitle={"Product Management Section"}
            handleClick={handleClick}
          />


          <SearchBar
            search={search}
            setSearch={setSearch}
            setMonth={setMonth}
            placeholder="Search for Product"
            handleShow={handleShow}
          />

          <FilterModal
            categories={categories}
            filterData={filterData}
            setFilterData={setFilterData}
            show={show}
            handleClose={handleClose}
            handleFilter={handleFilter}
          />

          <ProductTable data={data} />

          <div className="pagination-container">
            <TablePagination
              component="div"
              className="pagination"
              count={totalPages}
              page={currentPage}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};

export default ProductList;
